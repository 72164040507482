import React, { useState, useRef, useEffect, useMemo } from 'react'
import { Box, TextField, InputAdornment, Button, Snackbar } from '@mui/material'
import Alert from '@mui/material/Alert'
import { Icon as Iconify } from '@iconify/react'
import backGroundImage from '../assets/landingPageBackground.png'
import innovaLogo from '../assets/innovaLogo.png'
import innovaLogoBlack from '../assets/innovaLogoBlack.png'
import { appColors } from 'utils'
import useInnovaAuth from './common/hooks/useInnovaAuth'
import useAzureAd from './common/hooks/useAzureAd'
import useOkta from './common/hooks/useOkta'
import OtpInput from './common/OtpInput'
import QRCode from 'react-qr-code'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { saveItemToLS } from 'utils/localStorage'
import useUnprotectedAxios from './common/hooks/useUnprotectedAxios'

import { useSearchParams } from 'react-router-dom'

const LandingPage = () => {
  const _isMounted = useRef()
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const userName = useRef('')
  const password = useRef()
  const [showResetPassword, setShowResetPassword] = useState(false)
  const newPassword = useRef(null)
  const confirmPassword = useRef(null)
  const [newPasswordsOk, setNewPasswordsOk] = useState({ ok: false, error: '' })
  const [showPassword, setShowPassword] = useState(false)
  const [loginError, setLoginError] = useState(false)
  const { login, mfaAuth, setAuthenticated, saveDatabaseOrg } = useInnovaAuth()
  const {
    setConfig: setConfigAzure,
    isAuthenticated: isAuthenticatedAzure,
    loginAttempts: loginAtemptsAzure,
  } = useAzureAd()
  const { setConfig: setConfigOkta, isAuthenticated: isAuthenticatedOkta, loginAttempts: loginAtemptsOkta } = useOkta()
  const redirectInfo = useRef(null)
  const [showMfa, setShowMfa] = useState(false)
  const [showMfaEnroll, setShowMfaEnroll] = useState(false)
  const [showDeviceAuth, setShowDeviceAuth] = useState(false)
  const [enrollAuth, setEnrollAuth] = useState(null)
  const [otp, setOtp] = useState('')
  const { theme, getLinearGradient } = useInnovaTheme()
  const [showForgotPassword, setShotForgotPassword] = useState(false)
  const [searchParams] = useSearchParams()
  const [showRecoverPassword, setShowRecoverPassword] = useState(false)

  const updatePwd = useInnovaAxios({
    url: '/admin/resetUserPwd',
  })

  const forgotPwd = useUnprotectedAxios({
    url: '/forgotPwd',
  })

  const recoverPwd = useUnprotectedAxios({
    url: '/recoverPwd',
  })

  const textFieldStyle = useMemo(
    () => ({
      margin: '5px',
      height: '50px',
      backgroundColor: theme === 'dark' ? '#2d2d2d !important' : '#f0f0f0 !important',
      width: '100%',
      padding: '2px',
      maxWidth: '400px',
    }),
    [theme],
  )

  const inputLabelStyle = useMemo(
    () => ({
      style: { color: theme === 'dark' ? appColors.headerTextColor : '#000', margin: '5px' },
    }),
    [theme],
  )

  useEffect(() => {
    _isMounted.current = true

    const token = searchParams.get('token')
    if (token) {
      setShowRecoverPassword(true)
    }

    return () => {
      _isMounted.current = false
    }
  }, [searchParams])

  useEffect(() => {
    if (!_isMounted.current) return
    setShowDeviceAuth(false)
    if (loginAtemptsAzure > 0) setLoginError(!isAuthenticatedAzure)
    if (isAuthenticatedAzure) setAuthenticated()
    if (isAuthenticatedAzure) saveItemToLS('authType', 'authType', 'Azure AD')
  }, [loginAtemptsAzure]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!_isMounted.current) return
    setShowDeviceAuth(false)
    if (loginAtemptsOkta > 0) setLoginError(!isAuthenticatedOkta)
    if (isAuthenticatedOkta) setAuthenticated()
    if (isAuthenticatedOkta) saveItemToLS('authType', 'authType', 'Okta')
  }, [loginAtemptsOkta]) // eslint-disable-line react-hooks/exhaustive-deps

  const loginHandler = async () => {
    saveDatabaseOrg('')
    let result = await login(userName.current, password.current)

    if (_isMounted.current) {
      switch (result?.response) {
        case 'success':
          setLoginError(false)
          saveItemToLS('authType', 'authType', 'Innova')
          break
        case 'mfa required':
          setLoginError(false)
          if (result.hasOwnProperty('client')) {
            redirectInfo.current = { ...result.client }
            setShowDeviceAuth(true)
          } else {
            setShowMfa(true)
          }

          break
        case 'register mfa':
          setLoginError(false)
          setShowMfaEnroll(true)
          setEnrollAuth(result?.auth)
          break
        case 'failed':
          setLoginError(true)
          break
        case 'password reset requried':
          setShowResetPassword(true)
          break
        default:
          setLoginError(true)
          break
      }
    }
  }

  const renderIcon = (iconName, onClick) => {
    return (
      <InputAdornment position='start' onClick={onClick}>
        <Iconify
          icon={iconName}
          size={30}
          style={{
            color: appColors.headerTextColor,
            height: '25px',
            width: '25px',
            marginBottom: '10px',
            cursor: 'pointer',
          }}
        />
      </InputAdornment>
    )
  }

  const toggleShowPassword = () => {
    if (_isMounted.current) setShowPassword((prevVal) => !prevVal)
  }

  const otpChange = (newValue) => {
    setOtp(newValue)
  }

  const otpComplete = async (value) => {
    await otpHandler(userName.current, otp)
  }

  const otpHandler = async (user, otpCode) => {
    let result = await mfaAuth(user, otpCode, password.current)
    if (_isMounted.current) {
      if (result === 'success') {
        setLoginError(false)
      }
      if (result === 'failed') {
        setLoginError(true)
      }
    }
  }

  const MfaCard = ({ otpValue }) => {
    return (
      <Box
        sx={{
          background: getLinearGradient(),
          minWidth: (theme) => theme.spacing(30),
          height: (theme) => theme.spacing(65),
          width: '400px',
          borderRadius: '20px',
          border: `1px solid`,
          borderColor: appColors.itemBackColor,
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'column',
          flexFlow: '',
        }}>
        <Box
          component='img'
          sx={{
            height: '250px',
            width: '306px',
          }}
          alt='innova logo'
          src={getInnovaLogo()}
        />
        <Box
          sx={{
            color: appColors.itemTextColor,
            fontWeight: '700',
          }}>
          Enter Code from MFA Authenicator
        </Box>
        <OtpInput value={otpValue} valueLength={6} onChange={otpChange} />
        <Box />
        {loginError ? <Box sx={{ color: 'yellow' }}>login failed, incorrect username or password</Box> : null}
        <Button
          variant='contained'
          color='primary'
          onClick={otpComplete}
          sx={{
            marginBottom: '16px',
          }}>
          Submit MFA
        </Button>
      </Box>
    )
  }

  const OtpAuthCard = ({ otpValue }) => {
    return (
      <Box
        sx={{
          background: getLinearGradient(),
          minWidth: (theme) => theme.spacing(30),
          height: (theme) => theme.spacing(65),
          width: '400px',
          borderRadius: '20px',
          border: `1px solid`,
          borderColor: appColors.itemBackColor,
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'column',
          flexFlow: '',
        }}>
        <Box
          sx={{
            color: appColors.itemTextColor,
            fontWeight: '700',
          }}>
          Scan the QR code in authenticator app
        </Box>
        <QRCode value={enrollAuth.barcode_uri} />
        <Box
          sx={{
            color: appColors.itemTextColor,
            fontWeight: '700',
          }}>
          Register your authentication device
        </Box>
        <OtpInput value={otpValue} valueLength={6} onChange={otpChange} />
        <Box />
        <ErrorText showError={loginError} error={'login failed, incorrect username or password'} />
        <Button
          variant='contained'
          color='primary'
          onClick={otpComplete}
          sx={{
            marginBottom: '16px',
          }}>
          Submit MFA
        </Button>
      </Box>
    )
  }

  const RedirectAuthCard = () => {
    return (
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}>
        {redirectInfo.current.auth_type === 'Azure AD' ? <span>Organization uses Azure AD</span> : null}
        {redirectInfo.current.auth_type === 'Okta' ? <span>Organization uses Okta</span> : null}
        <Button
          sx={{ marginTop: 10 }}
          variant='contained'
          color='primary'
          onClick={() => {
            if (redirectInfo.current.auth_type === 'Azure AD') setConfigAzure(redirectInfo.current)
            if (redirectInfo.current.auth_type === 'Okta') setConfigOkta(redirectInfo.current)
          }}>
          {redirectInfo.current.auth_type === 'Azure AD' ? 'Login with Azure AD' : null}
          {redirectInfo.current.auth_type === 'Okta' ? 'Login with Okta' : null}
        </Button>
      </Box>
    )
  }

  const DeviceAuthCard = () => {
    return (
      <Box
        sx={{
          background: getLinearGradient(),
          minWidth: (theme) => theme.spacing(30),
          height: (theme) => theme.spacing(65),
          width: '400px',
          borderRadius: '20px',
          border: `1px solid`,
          borderColor: appColors.itemBackColor,
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'column',
          flexFlow: '',
        }}>
        <Box
          component='img'
          sx={{
            height: '250px',
            width: '306px',
          }}
          alt='innova logo'
          src={getInnovaLogo()}
        />
        <Box
          sx={{
            color: appColors.itemTextColor,
            fontWeight: '700',
            textAlign: 'center',
          }}>
          <RedirectAuthCard />
        </Box>
        <Box />
      </Box>
    )
  }

  const submitChangePassword = async () => {
    let passwordCheck = checkPasswordsMatch()
    if (!passwordCheck.ok) return

    setShowResetPassword(false)

    let saveResponse = await updatePwd({
      userName: userName.current,
      authUserName: userName.current,
      password: newPassword.current,
      sendEmail: true,
      incPwdInEmail: false,
      resetRequiredOnNextLogin: false,
    })
    if (saveResponse.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `Password reset failed: ${saveResponse?.error?.message}`,
      })
    } else {
      setStatus({ show: true, severity: 'success', message: 'Password reset success' })
    }
  }

  const submitRecoverPassword = async () => {
    let passwordCheck = checkPasswordsMatch()
    if (!passwordCheck.ok) return

    setShowRecoverPassword(false)

    let saveResponse = await recoverPwd({
      token: searchParams.get('token'),
      password: newPassword.current,
      sendEmail: true,
      incPwdInEmail: false,
      resetRequiredOnNextLogin: false,
    })
    if (saveResponse.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `Password reset failed: ${saveResponse?.error?.message}`,
      })
    } else {
      setStatus({ show: true, severity: 'success', message: 'Password reset success' })
    }
  }

  const submitForgotPassword = async () => {
    setShotForgotPassword(false)

    let response = await forgotPwd({ userName: userName.current, url: window.location.href })

    if (response.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `Password reset failed: ${response.error?.response?.data?.error}`,
      })
    } else {
      setStatus({ show: true, severity: 'success', message: `${response.data}` })
    }
  }

  const checkPasswordsMatch = () => {
    if (typeof newPassword.current !== 'string') return { ok: false, error: 'passwords dont match' }
    if (typeof confirmPassword.current !== 'string') return { ok: false, error: 'passwords dont match' }
    if (newPassword.current === '') return { ok: false, error: 'passwords dont match' }
    if (confirmPassword.current === '') return { ok: false, error: 'passwords dont match' }

    if (newPassword.current !== confirmPassword.current) {
      setNewPasswordsOk({ ok: false, error: 'passwords dont match' })
      return { ok: false, error: 'passwords dont match' }
    }

    let regEx = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-])[A-Za-z\d@$!%*#?&-]{8,}$/
    if (!regEx.test(newPassword.current)) {
      setNewPasswordsOk({
        ok: false,
        error: 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
      })
      return { ok: false, error: 'invalid password' }
    }

    setNewPasswordsOk({ ok: true, error: '' })
    return { ok: true, error: '' }
  }

  const handleClose = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const PasswordTextField = ({ label, placeHolder, onChange, defaultValue }) => {
    return (
      <TextField
        sx={textFieldStyle}
        defaultValue={defaultValue}
        InputLabelProps={inputLabelStyle}
        label={label}
        variant='standard'
        type={showPassword ? '' : 'password'}
        placeholder={placeHolder}
        onChange={onChange}
        InputProps={{
          endAdornment: renderIcon('ant-design:eye-invisible-filled', toggleShowPassword),
        }}
        onKeyUp={(e) => { if (e.key === 'Enter' && label === 'Password') loginHandler() }}
      />
    )
  }

  const ErrorText = ({ showError, error }) => {
    return showError ? <Box sx={{ color: 'yellow', margin: '5px' }}>{error}</Box> : null
  }

  const CardContainer = ({ children }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '90%',
        }}>
        {children}
      </Box>
    )
  }

  const getInnovaLogo = () => {
    return theme === 'dark' ? innovaLogo : innovaLogoBlack
  }

  const LoginCard = () => {
    return (
      <>
        <TextField
          sx={textFieldStyle}
          InputLabelProps={inputLabelStyle}
          defaultValue={userName.current}
          label='Username'
          variant='standard'
          placeholder='username'
          onChange={(e) => {
            if (!_isMounted.current) return
            if (loginError) setLoginError(false)
            userName.current = e.target.value
          }}
          onKeyUp={(e) => { if (e.key === 'Enter') loginHandler() }}
        />
        <PasswordTextField
          label={'Password'}
          placeholder={'password'}
          defaultValue={password.current}
          onChange={(e) => {
            if (!_isMounted.current) return
            setLoginError(false)
            let pwd = e.target.value
            if (typeof e.target.value === 'string') {
              pwd = pwd.trim()
            }

            password.current = pwd
          }}
        />
        <ErrorText showError={loginError} error={'login failed, incorrect username or password'} />
        <Button variant='contained' color='primary' onClick={loginHandler}>
          Login
        </Button>
        <Box
          sx={{
            marginTop: '10px',
            cursor: 'pointer',
            color: appColors.headerTextColor,
            textDecoration: 'underline',
          }}
          onClick={() => setShotForgotPassword(true)}>
          Forgot Password?
        </Box>
      </>
    )
  }

  const ResetPasswordCard = () => {
    return (
      <>
        <PasswordTextField
          label='New Password'
          placeholder='new password'
          defaultValue={newPassword.current}
          onChange={(e) => {
            if (!_isMounted.current) return
            setLoginError(false)
            newPassword.current = e.target.value
          }}
        />
        <PasswordTextField
          label='Confirm Password'
          placeholder='confirm password'
          defaultValue={confirmPassword.current}
          onChange={(e) => {
            if (!_isMounted.current) return
            setLoginError(false)
            confirmPassword.current = e.target.value
          }}
        />
        <ErrorText showError={!newPasswordsOk?.ok} error={newPasswordsOk?.error} />
        <Button variant='contained' color='primary' onClick={submitChangePassword}>
          Update Password
        </Button>
      </>
    )
  }

  const ForgotPasswordCard = () => {
    return (
      <>
        <TextField
          sx={textFieldStyle}
          InputLabelProps={inputLabelStyle}
          defaultValue={userName.current}
          label='Username'
          variant='standard'
          placeholder='username'
          onChange={(e) => {
            if (!_isMounted.current) return
            if (loginError) setLoginError(false)
            userName.current = e.target.value
          }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', marginTop: '10px' }}>
          <Button variant='contained' color='primary' onClick={submitForgotPassword} sx={{ marginRight: '10px' }}>
            Submit
          </Button>
          <Button variant='contained' color='primary' onClick={() => setShotForgotPassword(false)}>
            Cancel
          </Button>
        </Box>
      </>
    )
  }

  const RecoverPasswordCard = () => {
    return (
      <>
        <PasswordTextField
          label='New Password'
          placeholder='new password'
          defaultValue={newPassword.current}
          onChange={(e) => {
            if (!_isMounted.current) return
            setLoginError(false)
            newPassword.current = e.target.value
          }}
        />
        <PasswordTextField
          label='Confirm Password'
          placeholder='confirm password'
          defaultValue={confirmPassword.current}
          onChange={(e) => {
            if (!_isMounted.current) return
            setLoginError(false)
            confirmPassword.current = e.target.value
          }}
        />
        <ErrorText showError={!newPasswordsOk?.ok} error={newPasswordsOk?.error} />
        <Button variant='contained' color='primary' onClick={submitRecoverPassword}>
          Reset Password
        </Button>
      </>
    )
  }

  return (
    <Box
      sx={{
        backgroundImage: `url(${backGroundImage})`,
        backgroundSize: 'cover',
        backGroundPosition: 'center',
        height: '100%',
        width: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
      }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '130px' }}>
        {showMfa ? <MfaCard otpValue={otp} /> : null}
        {showMfaEnroll ? <OtpAuthCard otpValue={otp} /> : null}
        {showDeviceAuth ? <DeviceAuthCard /> : null}
        {!showMfa && !showMfaEnroll && !showDeviceAuth ? (
          <Box
            sx={{
              background: getLinearGradient(),
              width: '400px',
              height: '500px',
              borderRadius: '20px',
              border: `1px solid`,
              borderColor: appColors.itemBackColor,
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}>
            <Box
              component='img'
              sx={{
                height: '250px',
                width: '306px',
              }}
              alt='innova logo'
              src={getInnovaLogo()}
            />
            <CardContainer>
              {(() => {
                if (showResetPassword) return <ResetPasswordCard />
                if (showForgotPassword) return <ForgotPasswordCard />
                if (showRecoverPassword) return <RecoverPasswordCard />
                return <LoginCard />
              })()}
            </CardContainer>
          </Box>
        ) : null}
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleClose}>
          <Alert onClose={handleClose} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default LandingPage
