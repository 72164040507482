import { Box } from '@mui/material'
import { forwardRef, useImperativeHandle, useRef } from 'react'
import { UNITS_FOR } from 'components/common/hooks/useUnits'
import ForceDeflectionContourChart from '../BhaAnalysis/ForceDeflectionContour'
import BhaTendencyChart from './BhaTendencyChart'
import BhaModeShapesChart from './BhaModeShapesChart'
import BhaCriticalRpmChart from './BhaCriticalRpmChart'

const BhaChartsReport = forwardRef((props, ref) => {
  const chartBendingMomentRef = useRef(null)
  const chartContactForceRef = useRef(null)
  const sheerLoadRef = useRef(null)
  const chartForcesRef = useRef(null)
  const chartTendencyRef = useRef(null)
  const chartCriticalRPMRef = useRef(null)

  const CHARTS = [
    {
      title: 'HS Bending Moment',
      tag: 'hsBendingMoment',
      units: UNITS_FOR.BendingMoment,
      yAxisTitle: 'Bending Moment',
    },
    {
      title: 'RS Bending Moment',
      tag: 'rsBendingMoment',
      units: UNITS_FOR.BendingMoment,
      yAxisTitle: 'Bending Moment',
    },
    {
      title: 'Total Bending Moment',
      tag: 'totalBendingMoment',
      units: UNITS_FOR.BendingMoment,
      yAxisTitle: 'Bending Moment',
    },
    {
      title: 'HS Contact Force',
      tag: 'hsContactForce',
      units: UNITS_FOR.Force,
      yAxisTitle: 'Force',
    },
    {
      title: 'RS Contact Force',
      tag: 'rsContactForce',
      units: UNITS_FOR.Force,
      yAxisTitle: 'Force',
    },
    {
      title: 'Total Contact Force',
      tag: 'totalContactForce',
      units: UNITS_FOR.Force,
      yAxisTitle: 'Force',
    },
    {
      title: 'HS Shear Load',
      tag: 'hsShearLoad',
      units: UNITS_FOR.Force,
      yAxisTitle: 'Shear',
    },
    {
      title: 'RS Shear Load',
      tag: 'rsShearLoad',
      units: UNITS_FOR.Force,
      yAxisTitle: 'Shear',
    },
    {
      title: 'Total Shear Load',
      tag: 'totalShearLoad',
      units: UNITS_FOR.Force,
      yAxisTitle: 'Shear',
    },
    {
      title: 'Axial Deflection',
      tag: 'axial',
      units: UNITS_FOR.Diameter,
      yAxisTitle: 'Force',
    },
    {
      title: 'Torsion',
      tag: 'torsion',
      units: 'degrees',
      yAxisTitle: 'Torsion',
    },
    {
      title: 'Tension',
      tag: 'tension',
      units: UNITS_FOR.Weight,
      yAxisTitle: 'Tension',
    },
    {
      title: 'Torque',
      tag: 'torque',
      units: UNITS_FOR.Torque,
      yAxisTitle: 'Torque',
    },
  ]

  useImperativeHandle(ref, () => {
    return {
      getChartType(chartType) {
        if (chartType === 'bendingMoment') return chartBendingMomentRef
        if (chartType === 'contactForce') return chartContactForceRef
        if (chartType === 'sheerLoad') return sheerLoadRef
        if (chartType === 'forces') return chartForcesRef
        if (chartType === 'tendency') return chartTendencyRef
        if (chartType === 'criticalRPM') return chartCriticalRPMRef
        return null
      },
    }
  })

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box ref={chartBendingMomentRef} sx={{ width: '100%', height: '200%' }}>
        <Box sx={{ width: '100%', height: '33%' }}>
          <ForceDeflectionContourChart
            data={props.props.analysisData?.results}
            chartData={CHARTS[0]}
            height='100%'
            printing={true}
          />
        </Box>
        <Box sx={{ width: '100%', height: '33%' }}>
          <ForceDeflectionContourChart
            data={props.props.analysisData?.results}
            chartData={CHARTS[1]}
            height='100%'
            printing={true}
          />
        </Box>
        <Box sx={{ width: '100%', height: '33%' }}>
          <ForceDeflectionContourChart
            data={props.props.analysisData?.results}
            chartData={CHARTS[2]}
            height='100%'
            printing={true}
          />
        </Box>
      </Box>
      <Box ref={chartContactForceRef} sx={{ width: '100%', height: '200%' }}>
        <Box sx={{ width: '100%', height: '33%' }}>
          <ForceDeflectionContourChart
            data={props.props.analysisData?.results}
            chartData={CHARTS[3]}
            height='100%'
            printing={true}
          />
        </Box>
        <Box sx={{ width: '100%', height: '33%' }}>
          <ForceDeflectionContourChart
            data={props.props.analysisData?.results}
            chartData={CHARTS[4]}
            height='100%'
            printing={true}
          />
        </Box>
        <Box sx={{ width: '100%', height: '33%' }}>
          <ForceDeflectionContourChart
            data={props.props.analysisData?.results}
            chartData={CHARTS[5]}
            height='100%'
            printing={true}
          />
        </Box>
      </Box>
      <Box ref={sheerLoadRef} sx={{ width: '100%', height: '200%' }}>
        <Box sx={{ width: '100%', height: '33%' }}>
          <ForceDeflectionContourChart
            data={props.props.analysisData?.results}
            chartData={CHARTS[6]}
            height='100%'
            printing={true}
          />
        </Box>
        <Box sx={{ width: '100%', height: '33%' }}>
          <ForceDeflectionContourChart
            data={props.props.analysisData?.results}
            chartData={CHARTS[7]}
            height='100%'
            printing={true}
          />
        </Box>
        <Box sx={{ width: '100%', height: '33%' }}>
          <ForceDeflectionContourChart
            data={props.props.analysisData?.results}
            chartData={CHARTS[8]}
            height='100%'
            printing={true}
          />
        </Box>
      </Box>
      <Box ref={chartForcesRef} sx={{ width: '100%', height: '200%' }}>
        <Box sx={{ width: '100%', height: '25%' }}>
          <ForceDeflectionContourChart
            data={props.props.analysisData?.results}
            chartData={CHARTS[9]}
            height='100%'
            printing={true}
          />
        </Box>
        <Box sx={{ width: '100%', height: '25%' }}>
          <ForceDeflectionContourChart
            data={props.props.analysisData?.results}
            chartData={CHARTS[10]}
            height='100%'
            printing={true}
          />
        </Box>
        <Box sx={{ width: '100%', height: '25%' }}>
          <ForceDeflectionContourChart
            data={props.props.analysisData?.results}
            chartData={CHARTS[11]}
            height='100%'
            printing={true}
          />
        </Box>
        <Box sx={{ width: '100%', height: '25%' }}>
          <ForceDeflectionContourChart
            data={props.props.analysisData?.results}
            chartData={CHARTS[12]}
            height='100%'
            printing={true}
          />
        </Box>
      </Box>
      <Box ref={chartTendencyRef} sx={{ width: '100%', height: '100%' }}>
        <BhaTendencyChart
          chartData={props.props.tendencyData}
          printing={true}
          units={props.props.tendencyUnits}
          variable={props.props.tendencyVariable}
        />
      </Box>
      <Box ref={chartCriticalRPMRef} sx={{ width: '100%', height: '200%' }}>
        <Box sx={{ width: '100%', height: '50%' }}>
          <BhaCriticalRpmChart
            chartData={props.props.criticalRpmData}
            printing={true}
            chartUnits={props.props.criticalRpmUnits}
          />
          </Box>
        <Box sx={{ width: '100%', height: '50%' }}>
          <BhaModeShapesChart
            chartData={props.props.modeShapesData}
            chartTitle = {props.props.modeShapesTitle}
            printing={true}
            />
          </Box>
        </Box>
    </Box>
  )
})

export default BhaChartsReport
