// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useRef, useContext, createContext } from 'react'
import axios from 'axios'
import { getItemFromLS, saveItemToLS } from 'utils/localStorage'
import {userRoleSelector} from 'atoms'
import {useRecoilState} from 'recoil'

const authTokenUrl = '/authToken'
const refreshTokenUrl = '/refreshToken'
const mfaTokenUrl = '/mfaToken'

const authContext = createContext()
export function ProvideAuth({ children }) {
  const auth = useAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

const useInnovaAuth = () => {
  return useContext(authContext)
}

export default useInnovaAuth

const defAuthData = {
  access_token: null,
  refresh_token: null,
  user: {
    name: null,
  },
  expires_at: null,
  expires_in: null,
  issueTime: null,
  mfa_token: null,
  role: null,
}

const useAuth = () => {
  const _isMounted = useRef()
  const [user, setUser] = useState(checkAuthenticatedUser())
  const [isAuthenticated, setIsAuthenticated] = useState(checkAuthenticated())
  const authType = getItemFromLS('authType', 'authType')
  const [, setUserRole] = useRecoilState(userRoleSelector)

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    updateIsAuthenticated()
    updateUser()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function setAuthenticated() {
    setIsAuthenticated(checkAuthenticated())
  }

  async function updateIsAuthenticated() {
    let authenticated = checkAuthenticated()
    if (!authenticated) {
      let exchangeRes = await exchangeRefreshToken()
      authenticated = exchangeRes !== 'failed'
    }

    if (authenticated !== isAuthenticated && _isMounted.current) setIsAuthenticated(authenticated)
  }

  function getDatabaseOrg() {
    let databaseOrg = getItemFromLS('databaseOrg', 'databaseOrg')
    if (!databaseOrg) return ''
    return databaseOrg
  }

  function saveDatabaseOrg(org) {
    if (!org || typeof org !== 'string') org = ''
    saveItemToLS('databaseOrg', 'databaseOrg', org)
  }

  function getUserLs() {
    let currentAuth = getItemFromLS('auth', 'currentAuth')
    if (!currentAuth) return ''
    if (!currentAuth.hasOwnProperty('user')) return ''
    if (currentAuth.user === null) return ''
    if (!currentAuth.user.hasOwnProperty('name')) return ''
    if (!currentAuth.user.name) return ''
    return currentAuth.user.name
  }

  function getUserCurrent() {
    if (!user) return ''
    if (!user.hasOwnProperty('name')) return ''
    if (user.name === null) return ''
    return user.name
  }

  function updateUser() {
    let userNameLs = getUserLs()
    let userName = getUserCurrent()
    if (userNameLs !== userName && _isMounted.current) setUser({ name: userNameLs })
  }

  function checkAuthenticated() {
    let currentAuth = getItemFromLS('auth', 'currentAuth')
    if (!currentAuth) return false
    if (!currentAuth.hasOwnProperty('access_token')) return false
    if (!currentAuth.hasOwnProperty('refresh_token')) return false
    if (!currentAuth.hasOwnProperty('issueTime')) return false
    if (!currentAuth.hasOwnProperty('user')) return false
    if (currentAuth.hasOwnProperty('passwordResetRequired') && currentAuth.passwordResetRequired) return false
    if (currentAuth.access_token === null) return false
    if (currentAuth.refresh_token === null) return false
    if (currentAuth.issueTime === null) return false
    if (currentAuth.user === null) return false
    if (!tokenStillValid()) return false
    return true
  }

  function checkAuthenticatedUser() {
    let currentAuth = getItemFromLS('auth', 'currentAuth')
    if (!currentAuth) return ''
    if (!currentAuth.hasOwnProperty('access_token')) return ''
    if (!currentAuth.hasOwnProperty('refresh_token')) return ''
    if (!currentAuth.hasOwnProperty('issueTime')) return ''
    if (!currentAuth.hasOwnProperty('user')) return ''
    if (currentAuth.access_token === null) return ''
    if (currentAuth.refresh_token === null) return ''
    if (currentAuth.issueTime === null) return ''
    if (currentAuth.user === null) return ''
    if (!tokenStillValid()) return ''
    return currentAuth.user
  }

  function tokenStillValid() {
    let currentAuth = getItemFromLS('auth', 'currentAuth')
    if (!currentAuth) return false
    if (!currentAuth.hasOwnProperty('expires_at')) return false
    if (typeof currentAuth.expires_at !== 'number') return false

    let currentTimeMs = new Date(Date.now()).getTime()
    let fiveMinsMs = 5 * 60 * 1000

    if (currentTimeMs > currentAuth.expires_at - fiveMinsMs) return false
    return true
  }

  function updateAuthData(data, userName) {
    if (!data) return 'failed'
    if (!data.hasOwnProperty('statusCode')) return 'failed'
    if (!data.hasOwnProperty('access_token')) return 'failed'
    if (!data.hasOwnProperty('expires_in')) return 'failed'
    if (!data.hasOwnProperty('refresh_token')) return 'failed'
    if (!data.hasOwnProperty('role')) return 'failed'
    if (typeof userName !== 'string') return 'failed'
    if (!userName) return 'failed'
    if (!data.statusCode) return 'failed'
    if (!data.access_token) return 'failed'
    // check for expires_in being 'falsy', but allow number 0, which is valid (means no expiration)
    if (
      typeof data.expires_in === 'undefined' ||
      data.expires_in === null ||
      (typeof data.expires_in !== 'number' && isNaN(data.expires_in))
    )
      return 'failed'
    if (!data.refresh_token) return 'failed'
    if (typeof data.statusCode !== 'number') return 'failed'
    if (data.statusCode !== 200) return 'failed'

    let currentAuth = getItemFromLS('auth', 'currentAuth')
    currentAuth.user.name = userName
    currentAuth.access_token = data.access_token
    currentAuth.refresh_token = data.refresh_token
    if (data.expires_in === 0) data.expires_in = 86400
    currentAuth.expires_in = data.expires_in
    currentAuth.passwordResetRequired = false
    if (data.hasOwnProperty('passwordResetRequired') && data.passwordResetRequired) {
      currentAuth.passwordResetRequired = true
    }
    currentAuth.issueTime = new Date(Date.now()).getTime()
    currentAuth.expires_at = currentAuth.issueTime + currentAuth.expires_in * 1000
    setUserRole(data.role)
    currentAuth.role = data.role
    
    saveItemToLS('auth', 'currentAuth', currentAuth)
    return 'success'
  }

  function updateMfaInitialAuthData(data, userName) {
    if (!data) return 'failed'
    if (!data.hasOwnProperty('statusCode')) return 'failed'
    if (typeof userName !== 'string') return 'failed'
    if (!userName) return 'failed'
    if (!data.statusCode) return 'failed'
    if (typeof data.statusCode !== 'number') return 'failed'
    if (data.statusCode !== 200) return 'failed'

    let currentAuth = getItemFromLS('auth', 'currentAuth')
    currentAuth.user.name = userName
    currentAuth.mfa_token = data.mfa_token

    let authType = 'Innova'
    if (data.hasOwnProperty('auth_type')) {
      authType = data.authType
    }

    if (!data.mfa_token && authType === 'Innova') return 'failed'
    setUserRole(data.role)
    saveItemToLS('auth', 'currentAuth', currentAuth)
    return 'success'
  }

  function updateMfaFinalAuthData(data, userName) {
    if (!data) return 'failed'
    if (!data.hasOwnProperty('statusCode')) return 'failed'
    if (!data.hasOwnProperty('access_token')) return 'failed'
    if (!data.hasOwnProperty('expires_in')) return 'failed'
    if (!data.hasOwnProperty('refresh_token')) return 'failed'
    if (!data.hasOwnProperty('role')) return 'failed'
    if (typeof userName !== 'string') return 'failed'
    if (!userName) return 'failed'
    if (!data.statusCode) return 'failed'
    if (!data.access_token) return 'failed'
    // check for expires_in being 'falsy', but allow number 0, which is valid (means no expiration)
    if (
      typeof data.expires_in === 'undefined' ||
      data.expires_in === null ||
      (typeof data.expires_in !== 'number' && isNaN(data.expires_in))
    )
      return 'failed'
    if (!data.refresh_token) return 'failed'
    if (typeof data.statusCode !== 'number') return 'failed'
    if (data.statusCode !== 200) return 'failed'

    let currentAuth = getItemFromLS('auth', 'currentAuth')
    currentAuth.user.name = userName
    currentAuth.access_token = data.access_token
    currentAuth.refresh_token = data.refresh_token
    if (data.expires_in === 0) data.expires_in = 86400
    currentAuth.expires_in = data.expires_in
    currentAuth.issueTime = new Date(Date.now()).getTime()
    currentAuth.expires_at = currentAuth.issueTime + currentAuth.expires_in * 1000
    
    setUserRole(data.role)
    currentAuth.role = data.role
    saveItemToLS('auth', 'currentAuth', currentAuth)
    return 'success'
  }

  async function exchangeRefreshToken() {
    let currentAuth = getItemFromLS('auth', 'currentAuth')
    if (!currentAuth) return 'failed'
    if (!currentAuth.hasOwnProperty('refresh_token')) return 'failed'
    if (!currentAuth.hasOwnProperty('user')) return 'failed'
    if (currentAuth.refresh_token === null) return 'failed'
    if (currentAuth.user === null) return 'failed'
    if (typeof currentAuth.user.name !== 'string') return 'failed'

    const data = new URLSearchParams()
    data.append('userName', currentAuth.user.name)
    data.append('refreshToken', currentAuth.refresh_token)
    data.append('productKey', process.env.REACT_APP_ICP_PRODUCT_KEY)

    try {
      const axiosParams = {
        method: 'post',
        baseURL: process.env.REACT_APP_ICP_API,
        timeout: 30000,
        url: refreshTokenUrl,
        data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }

      let response = await axios(axiosParams)

      if (response?.error) return 'failed'
      if (!response.data) return 'failed'
      if (!response.data.hasOwnProperty('role')) return 'failed'
      if (response?.data) return updateAuthData(response.data, currentAuth.user.name)
    } catch (err) {
      return 'failed'
    }

    return 'failed'
  }

  async function getAuthToken(userName, password) {
    if (!userName) return { response: 'failed' }
    if (!password) return { response: 'failed' }
    if (typeof userName !== 'string') return { response: 'failed' }
    if (typeof password !== 'string') return { response: 'failed' }

    const data = new URLSearchParams()
    data.append('userName', userName)
    data.append('pwd', password)
    data.append('productKey', process.env.REACT_APP_ICP_PRODUCT_KEY)

    if (userName === "") {
      return { response: 'no username' }
    }

    try {
      const axiosParams = {
        method: 'post',
        baseURL: process.env.REACT_APP_ICP_API,
        timeout: 30000,
        url: authTokenUrl,
        data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }

      let response = await axios(axiosParams)

      if (response?.error) return { response: 'failed' }
      if (response?.data) {
        if (!response?.data.hasOwnProperty('role')) return { response: 'failed' }
        if (response.data.access_token !== '') {
          if (response.data.hasOwnProperty('passwordResetRequired') && response.data.passwordResetRequired) {
            let res = updateAuthData(response.data, userName)
            if (res === 'success') setUser({ name: userName })
            return { response: res === 'success' ? 'password reset requried' : 'failed' }
          }
          return { response: updateAuthData(response.data, userName) }
        } else {
          if (response.data.error === 'mfa_required') {
            const result = updateMfaInitialAuthData(response.data, userName)

            if (response.data.hasOwnProperty('auth_type')) {
              if (result === 'success')
                return {
                  response: 'mfa required',
                  client: {
                    auth_type: response.data.auth_type,
                    client_id: response.data.client_id,
                    client_secret: response.data.client_secret,
                    tenant_id: response.data.tenant_id,
                    user_name: userName,
                    role: response.data.role,
                  },
                }
            }

            if (response.data.auth_enroll) {
              if (result === 'success') {
                return {
                  response: 'register mfa',
                  auth: {
                    authenticator_type: response.data.auth_enroll.authenticator_type,
                    barcode_uri: response.data.auth_enroll.barcode_uri,
                    recovery_codes: response.data.auth_enroll.recovery_codes,
                    secret: response.data.auth_enroll.secret,
                  },
                }
              }
            } else {
              if (result === 'success') return { response: 'mfa required' }
            }
            return { response: 'failed' }
          }
        }
      }
    } catch (err) {
      console.error('getMfaAuth error', err)
      return { response: 'failed' }
    }

    return { response: 'failed' }
  }

  const getAccessTokenSilently = async () => {
    let authenticated = checkAuthenticated()

    if (!authenticated) {
      authenticated = await exchangeRefreshToken()
    }

    let currentAuth = getItemFromLS('auth', 'currentAuth')
    if (authenticated) return currentAuth?.access_token
    return ''
  }

  function logout() {
    saveItemToLS('auth', 'currentAuth', defAuthData)
    setUserRole('')
    setIsAuthenticated(false)
    setUser({ name: '' })
  }

  async function login(userName, password) {
    saveItemToLS('auth', 'currentAuth', defAuthData)

    let result = await getAuthToken(userName, password)

    if (_isMounted.current) {
      if (result.response === 'success') {
        await new Promise((resolve) => setTimeout(resolve, 1000)) //delay 1 sec to avoid "use token before issued"
        setIsAuthenticated(true)
        setUser({ name: userName })
      }
    }
    return result
  }

  const getMfaToken = async (userName, mfaToken, otp, password) => {
    if (!userName) return 'failed'
    if (typeof userName !== 'string') return 'failed'
    if (typeof mfaToken !== 'string') return 'failed'
    if (typeof otp !== 'string') return 'failed'

    const data = new URLSearchParams()
    data.append('userName', userName)
    data.append('mfa_token', mfaToken)
    data.append('otp', otp)
    data.append('pwd', password)

    try {
      const axiosParams = {
        method: 'post',
        baseURL: process.env.REACT_APP_ICP_API,
        timeout: 30000,
        url: mfaTokenUrl,
        data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }

      let response = await axios(axiosParams)
      if (response?.error) return 'failed'
      if (!response.data) return 'failed'
      if (!response.data.hasOwnProperty('role')) return 'failed'
      if (response?.data) return updateMfaFinalAuthData(response.data, userName)
    } catch (err) {
      return 'failed'
    }

    return 'success'
  }

  const mfaAuth = async (user, otp, password) => {
    let currentAuth = getItemFromLS('auth', 'currentAuth')
    // not sure if we need to check the next line...
    // if (currentAuth.user.name !== user) return 'failed'
    let mfatoken = ''
    if (currentAuth && currentAuth.mfa_token) {
      mfatoken = currentAuth.mfa_token
    }

    let result = await getMfaToken(user, mfatoken, otp, password)
    if (_isMounted.current) {
      if (result === 'success') {
        await new Promise((resolve) => setTimeout(resolve, 1000)) //delay 1 sec to avoid "use token before issued"
        setIsAuthenticated(true)
        setUser({ name: user })
      }
    }
    return result
  }

  return { getDatabaseOrg, saveDatabaseOrg, getAccessTokenSilently, user, login, logout, isAuthenticated, mfaAuth, setAuthenticated, setUser, authType }
}
