import React, { useEffect, useRef } from 'react'

import { Line } from 'react-chartjs-2'
import { CircularProgress } from '@mui/material'
import { appColors } from 'utils'
import { DRAWERWIDE, DRAWERSLIM } from 'components/WellPages/EngineeringDashboard/EngineeringToolBar'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { normalize } from 'utils/numberFunctions.js'
import { lerpColor } from 'utils/colorFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const BhaCriticalRpmChart = ({
  showLegend,
  chartData,
  chartUnits,
  loading,
  xBeginAtZero = true,
  isToolbarExpanded,
  slideSheetData = [],
  witsData = [],
  printing = false,
}) => {
  const _isMounted = useRef(false)
  const { theme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const getChartOptions = () => {
    return {
      animation: false,
      layout: {
        padding: {
          left: 20 + (isToolbarExpanded ? DRAWERWIDE : DRAWERSLIM),
          bottom: 50,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      scaleShowLabels: false,
      elements: {
        point: {
          radius: 0,
          hitRadius: 1,
        },
      },
      interaction: {
        mode: 'y',
        intersect: false,
      },
      plugins: {
        GradientPlugIn: {
          showGradient: !printing,
          theme: theme,
        },
        annotation: {
          annotations: null,
        },
        title: {
          display: true,
          text: `BHA Critical RPM${chartUnits.title}`,
          color: appColors.itemTextColor,
          font: {
            size: 20,
          },
        },
        tooltip: {
          mode: 'nearest',
          intersect: false,
          callbacks: {
            title: (item) => {
              if (!Array.isArray(item)) return ''
              if (item.length === 0) return ''
              if (item[0]?.label === 'x' || item[0]?.label === 'y') return ''
              if (item[0].formattedValue.includes('(')) return ''
              return `${chartUnits.variable}: ${item[0].formattedValue} ${chartUnits.units}`
            },
            label: (item) => {
              if (item?.label === 'x' || item?.label === 'y') return ''
              let rpm = numberWithCommasDecimals(parseFloat(item?.label), 2)
              let node = item?.dataset?.label
                ?.replace('Mode: ', '')
                .replace('-', '')
                .replace('Drilling Data (', '')
                .replace(')', '')
                .split(' ')[0]
              if (isNaN(rpm)) return ''
              return `RPM: ${rpm}, Mode: ${node}`
            },
          },
        },
        legend: {
          display: showLegend,
          position: 'right',
          labels: {
            generateLabels: (chart) => {
              const data = chart.data
              if (data.labels.length && data.datasets.length) {
                let dataSetToUse = data.datasets.filter((dataSet) => !dataSet.label.includes('#####'))
                return dataSetToUse.map((dataSet, i) => {
                  let meta = chart.getDatasetMeta(i)

                  let textColor = '#ffffff'
                  if (printing || theme !== 'dark') {
                    textColor = '#000000'
                  }

                  return {
                    text: dataSet.label,
                    fillStyle: dataSet.borderColor,
                    strokeStyle: dataSet.borderColor,
                    hidden: meta?.hidden,
                    fontColor: textColor,
                    datasetIndex: i,
                  }
                })
              }
              return []
            },
          },
          onClick: (e, legendItem, legend) => {
            const chart = legend.chart

            if (!Array.isArray(chart?.data?.datasets)) return
            for (let i = 0; i < chart.data.datasets.length; i++) {
              if (chart.data.datasets[i].label.includes(legendItem.text)) {
                chart.data.datasets[i].hidden = !chart.data.datasets[i].hidden
              }
            }

            chart.update()
          },
          onHover: (event, item, legend) => {
            let dataChartToUse = legend.chart.data.datasets.filter((dataSet) => !dataSet.label.includes('#####'))
            dataChartToUse.forEach((dataset, index) => {
              dataset.borderWidth = item.datasetIndex === index ? 5 : 1
            })
            legend.chart.update()
          },
          onLeave: (event, item, legend) => {
            item.lineWidth = 3
            let dataChartToUse = legend.chart.data.datasets.filter((dataSet) => !dataSet.label.includes('#####'))
            dataChartToUse.forEach((dataset) => {
              dataset.borderWidth = 3
            })
            legend.chart.update()
          },
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'xy',
          },
          limits: {
            x: {
              min: 'original',
              max: 'original',
            },
            y: {
              min: 'original',
              max: 'original',
            },
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: 'xy',
          },
        },
        datalabels: {
          labels: {
            value: {
              font: {
                weight: 'bold',
              },
              color: '#000',
            },
          },
        },
      },
      scales: {
        x: {
          type: 'linear',
          align: 'center',
          beginAtZero: xBeginAtZero,
          title: {
            display: true,
            text: `RPM`,
            font: {
              size: 15,
            },
            color: appColors.itemTextColor,
          },
          ticks: {
            color: printing ? '#000000' : appColors.headerTextColor,
          },
          grid: {
            color: printing ? '#000000' : appColors.headerTextColor,
          },
        },
        y: {
          type: 'linear',
          beginAtZero: false,
          reverse: false,
          title: {
            display: true,
            text: `${chartUnits?.variable} (${chartUnits?.units})`,
            font: {
              size: 15,
            },
            color: appColors.itemTextColor,
          },
          ticks: {
            color: printing ? '#000000' : appColors.headerTextColor,
          },
          grid: {
            color: printing ? '#000000' : appColors.headerTextColor,
          },
        },
      },
    }
  }

  const interpChartColor = (pallete, index, maxSeries) => {
    let bluePalette = ['#006cd1', '#00deff']
    let redPalette = ['#fa6e7a', '#fa000c']
    let greenPalette = ['#93fab4', '#8ee800']
    let cyanPalette = ['#49ecf5', '#226f73']
    let purplePalette = ['#e300b2', '#bc6bdb']
    let yellowPalette = ['#fffb05', '#ffa505']

    let startColor = bluePalette[0]
    let endColor = bluePalette[1]
    if (pallete === 'green') {
      startColor = greenPalette[0]
      endColor = greenPalette[1]
    }

    if (pallete === 'red') {
      startColor = redPalette[0]
      endColor = redPalette[1]
    }

    if (pallete === 'cyan') {
      startColor = cyanPalette[0]
      endColor = cyanPalette[1]
    }

    if (pallete === 'purple') {
      startColor = purplePalette[0]
      endColor = purplePalette[1]
    }

    if (pallete === 'yellow') {
      startColor = yellowPalette[0]
      endColor = yellowPalette[1]
    }

    let ratio = normalize(index, 0, maxSeries)
    return lerpColor(startColor, endColor, ratio)
  }

  function adjustHexOpacity(hexColor, alpha) {
    // Remove the leading '#' if present
    const sanitizedColor = hexColor.replace('#', '')

    // Convert the hex color to RGBA
    const red = parseInt(sanitizedColor.substring(0, 2), 16)
    const green = parseInt(sanitizedColor.substring(2, 4), 16)
    const blue = parseInt(sanitizedColor.substring(4, 6), 16)

    // Convert the alpha value from a range of 0-100 to a range of 0-255
    const alphaValue = Math.round((alpha / 100) * 255)

    // Convert RGBA values to a new hex string
    const updatedHex = `#${((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1)}${alphaValue.toString(
      16,
    )}`

    return updatedHex
  }

  const getSeriesValues = (data) => {
    if (!Array.isArray(data)) return []
    let output = []
    data.forEach((pt) => {
      if (!output.some((item) => item.x === pt.rpm && item.y === pt.wob)) {
        if (pt.hasOwnProperty('rpm') && pt.hasOwnProperty('wob')) {
          if (pt.rpm < 250.0 && pt.rpm > 0) {
            output.push({
              x: pt.rpm,
              y: pt.wob,
            })
          }
        }
      }
    })

    return output
  }

  const slideSheetDataFormatted = () => {
    if (slideSheetData.length === 0) return []
    let chartData = []

    let rpmIndex = slideSheetData.findIndex((item) => item.label === 'RPM')
    if (rpmIndex === -1) return []

    let wobIndex = slideSheetData.findIndex((item) => item.label === 'WOB')
    if (wobIndex === -1) return []

    let rpmDepths = []
    for (let i = 0; i < slideSheetData[rpmIndex].data.length; i++) {
      rpmDepths.push(slideSheetData[rpmIndex].data[i].y)
    }

    let criticalRPMData = []
    for (let i = 0; i < rpmDepths.length; i++) {
      let wobDataIndex = slideSheetData[wobIndex].data.findIndex((item) => item.y === rpmDepths[i])
      let rpmDataIndex = slideSheetData[rpmIndex].data.findIndex((item) => item.y === rpmDepths[i])
      if (wobDataIndex !== -1 && rpmDataIndex !== -1) {
        criticalRPMData.push({
          wob: slideSheetData[wobIndex].data[wobDataIndex].x,
          rpm: slideSheetData[rpmIndex].data[rpmDataIndex].x,
        })
      }
    }

    chartData.push({
      label: `Drilling Data (Slide Sheet)`,
      borderColor: interpChartColor('green', 0, 1),
      backgroundColor: interpChartColor('green', 0, 1),
      pointBorderColor: adjustHexOpacity(interpChartColor('green', 0, 1), 50),
      pointBackgroundColor: adjustHexOpacity(interpChartColor('green', 0, 1), 50),
      showLine: false,
      fill: false,
      pointRadius: 3,
      pointHoverRadius: 5,
      pointstyle: 'circle',
      data: getSeriesValues(criticalRPMData),
    })

    return chartData
  }

  const witsDataFormatted = () => {
    if (witsData.length === 0) return []
    let chartData = []

    let rpmIndex = witsData.findIndex((item) => item.label === 'RPM (WITS)')
    if (rpmIndex === -1) return []

    let wobIndex = witsData.findIndex((item) => item.label === 'WOB (WITS)')
    if (wobIndex === -1) return []

    let rpmDepths = []
    for (let i = 0; i < witsData[rpmIndex].data.length; i++) {
      rpmDepths.push(witsData[rpmIndex].data[i].y)
    }

    let criticalRPMData = []
    for (let i = 0; i < rpmDepths.length; i++) {
      let wobDataIndex = witsData[wobIndex].data.findIndex((item) => item.y === rpmDepths[i])
      let rpmDataIndex = witsData[rpmIndex].data.findIndex((item) => item.y === rpmDepths[i])
      if (wobDataIndex !== -1 && rpmDataIndex !== -1) {
        criticalRPMData.push({
          wob: witsData[wobIndex].data[wobDataIndex].x,
          rpm: witsData[rpmIndex].data[rpmDataIndex].x,
        })
      }
    }

    chartData.push({
      label: `Drilling Data (WITS)`,
      borderColor: interpChartColor('purple', 0, 1),
      backgroundColor: interpChartColor('purple', 0, 1),
      pointBorderColor: adjustHexOpacity(interpChartColor('purple', 0, 1), 50),
      pointBackgroundColor: adjustHexOpacity(interpChartColor('purple', 0, 1), 50),
      showLine: false,
      fill: false,
      pointRadius: 3,
      pointHoverRadius: 5,
      pointstyle: 'circle',
      data: getSeriesValues(criticalRPMData),
    })

    return chartData
  }

  return (
    <>
      {loading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            height: '100px',
            width: '100px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 999999,
          }}
        />
      ) : null}
      <Line
        options={getChartOptions()}
        data={{
          labels: chartData.labels,
          datasets: chartData.datasets.concat(slideSheetDataFormatted(), witsDataFormatted()),
        }}
      />
    </>
  )
}

export default BhaCriticalRpmChart
