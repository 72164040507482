import { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import * as XLSX from '@sheet/core'
import { array2pipestr } from 'utils'
import { analyticsSelectedWells, currentPageAtom } from 'atoms'
import { removeSpecialSymbols } from 'utils/stringFunctions'
import StripChart from './StripChart'
import ParamsStripChart from '../ParameterComparison/StripChart'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import CircularProgress from '@mui/material/CircularProgress'
import { Box } from '@mui/material'
import { AnalyticsChartButton } from '../AnalyticsBarChart'
import { appColors } from 'utils'
import InputModal from 'components/common/InputModal'
import * as yup from 'yup'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const getDistinctPropList = (data, prop) => {
  if (
    !data ||
    !Array.isArray(data) ||
    data.length === 0 ||
    prop === undefined ||
    prop === null ||
    typeof prop !== 'string' ||
    prop === ''
  ) {
    return []
  }

  const distinctValues = new Map()
  data.forEach((well) => {
    well?.parameters?.forEach((pt) => {
      if (!pt.hasOwnProperty(prop)) return
      distinctValues.set(pt[prop], { label: pt[prop], value: pt[prop] })
    })
  })

  let output = []
  distinctValues.forEach((value) => {
    if (value.value === null || value.value === undefined || value.value === '') return
    output.push(value)
  })

  return output
}

const getPickListObjArray = (prop, chartData) => {
  if (!prop) return []
  if (typeof prop !== 'string') return []
  if (prop === 'none') return []
  if (!Array.isArray(chartData)) return []

  let pickListVals = getDistinctPropList(chartData, prop)
  if (!Array.isArray(pickListVals)) return []

  let newObjArray = []
  pickListVals.forEach((item) => {
    newObjArray.push({
      id: item.value,
      desc: item.label,
    })
  })

  return newObjArray
}

const getPickListValArray = (objArray, type, chartData) => {
  if (!Array.isArray(objArray)) return []

  let valDescArray = []
  if (type === 'phase') {
    //Required becasue bha / Activity description is not the same as the value
    valDescArray = getPickListObjArray(type, chartData)
  }

  let newObjArray = []
  objArray.forEach((item) => {
    newObjArray.push({
      label: valDescArray?.length > 0 ? valDescArray.find((x) => x.id === item)?.desc : item,
      desc: item,
    })
  })

  return newObjArray
}

const PhaseFilterModal = ({ data, submitFunction, cancelFunction, chartData }) => {
  const formValidationSchema = yup.object({})

  if (!data) {
    data = {
      filterParam: 'none',
      filterValues: [],
    }
  }

  if (typeof data?.filterParam !== 'string') data.filterParam = 'none'
  if (!Array.isArray(data?.filterValues)) data.filterValues = []

  let formData = [
    {
      tag: 'filterParam',
      value: data.filterParam,
      text: 'Parameter',
      inputType: 'dropdown',
      dropDownValues: [
        { label: 'None', value: 'none' },
        { label: 'Phase', value: 'phase' },
      ],
      useLabel: true,
    },
    {
      tag: 'filterValuesPhase',
      value: getPickListValArray(data.filterValues),
      text: 'Phases',
      inputType: 'picklist',
      pickListValues: getPickListObjArray('phase', chartData),
      useLabel: true,
      filterProperty: 'filterParam',
      filterValue: 'phase',
    },
  ]

  return (
    <InputModal
      open={true}
      onClose={cancelFunction}
      title={'Select filter parameter'}
      formData={formData}
      submitForm={submitFunction}
      cancelFunction={cancelFunction}
      validationSchema={formValidationSchema}
    />
  )
}

const MotorYieldChart = () => {
  const _isMounted = useRef(false)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const [showPhaseFilterModal, setShowPhaseFilterModal] = useState(false)
  const [filterByParams, setFilterByParams] = useState(null)
  const [showPlanned, setShowPlanned] = useState(false)
  const { getUnitsText } = useUnits()
  const { theme } = useInnovaTheme()

  const getKpiData = useInnovaAxios({
    url: '/kpi/motorYieldKpi',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.motorYieldKey)

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData()
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    if (isLoading) return
    if (!_isMounted.current) return

    if (!Array.isArray(selectedWells)) {
      setData([])
      return
    }

    if (selectedWells.length < 1) {
      setData([])
      return
    }

    setIsLoading(true)
    const res = await getKpiData({ wellList: array2pipestr(selectedWells) })
    if (!_isMounted.current) return
    if (res?.error) return

    setIsLoading(false)
    setData(Array.isArray(res?.data) ? res.data : [])
  }

  const getParameterAtDepth = (md, params) => {
    if (!params) return
    if (!params.hasOwnProperty('parameters')) return
    if (!Array.isArray(params.parameters)) return

    let parameter = null
    for (let i = 0; i < params.parameters.length; i++) {
      if (md >= params.parameters[i].md && md < params.parameters[i].mdEnd) {
        parameter = params.parameters[i]
        break
      }
    }

    return parameter
  }

  const getBhaAtDepth = (md, bha) => {
    if (!bha) return
    if (!Array.isArray(bha)) return
    if (bha.length === 0) return

    let bhaOut = null
    for (let i = 0; i < bha.length; i++) {
      if (md >= bha[i].depthIn && md < bha[i].depthOut) {
        bhaOut = bha[i]
        break
      }
    }

    if (!bhaOut) return bhaOut
    if (!bhaOut.hasOwnProperty('bhaComponents')) return bhaOut
    if (!Array.isArray(bhaOut.bhaComponents)) return bhaOut
    if (bhaOut.bhaComponents.length === 0) return bhaOut

    for (let i = 0; i < bhaOut.bhaComponents.length; i++) {
      if (bhaOut.bhaComponents[i].type === 'Drill Bit') {
        bhaOut.holeSize = bhaOut.bhaComponents[i].od
        break
      }
    }

    return bhaOut
  }

  const getSlidePercBha = (slideSeen, bha) => {
    if (!slideSeen) return 0
    if (!bha) return 0
    if (!bha.hasOwnProperty('slideDrilled')) return 0
    if (bha.slideDrilled === 0) return 0

    return (slideSeen / bha.slideDrilled) * 100.0
  }

  const getSlidePerc = (slideSeen, bha) => {
    if (!slideSeen) return 0
    if (!bha) return 0
    if (!Array.isArray(bha)) return 0
    if (bha.length === 0) return 0

    let slideDrilled = 0
    for (let i = 0; i < bha.length; i++) {
      if (!bha[i].hasOwnProperty('slideDrilled')) continue
      slideDrilled += bha[i].slideDrilled
    }

    if (slideDrilled === 0) return 0

    return (slideSeen / slideDrilled) * 100.0
  }

  const getDlsPer10k = (md, surveys) => {
    if (!surveys) return 0
    if (!Array.isArray(surveys)) return 0
    if (surveys.length === 0) return 0

    let dlsPer10k = 0

    let mdStart = md - 10000

    for (let i = 0; i < surveys.length; i++) {
      if (surveys[i].md >= mdStart && surveys[i].md <= md) {
        dlsPer10k += surveys[i].dls
      }
    }

    return dlsPer10k
  }

  const getLithologyAtDepth = (md, actualWellData) => {
    if (!actualWellData) return ''
    if (!Array.isArray(actualWellData)) return ''
    if (actualWellData.length === 0) return ''
    if (!actualWellData[0].hasOwnProperty('lithologies')) return ''
    if (!Array.isArray(actualWellData[0].lithologies)) return ''
    if (actualWellData[0].lithologies.length === 0) return ''

    let lithology = ''

    for (let i = 0; i < actualWellData[0].lithologies.length; i++) {
      if (md >= actualWellData[0].lithologies[i].md) {
        lithology = actualWellData[0].lithologies[i].formation
      }
    }

    return lithology
  }

  const onXlsxExport = () => {
    if (!data) return
    if (!Array.isArray(data)) return

    let headers = []
    headers.push('Actual Well')
    headers.push(`Depth (${getUnitsText(UNITS_FOR.Depth)})`)
    headers.push('Inc °')
    headers.push('Azi °')
    headers.push(`TVD (${getUnitsText(UNITS_FOR.Depth)})`)
    headers.push(`DLS (${getUnitsText(UNITS_FOR.Dogleg)})`)
    headers.push(`Tort (${getUnitsText(UNITS_FOR.Dogleg)})`)
    headers.push('Motor Yield')
    headers.push('Slide Seen')
    headers.push(`Cum. DLS (${getUnitsText(UNITS_FOR.Dogleg)})`)
    headers.push(`Cum. Tort (${getUnitsText(UNITS_FOR.Dogleg)})`)
    headers.push(`Cum. DLS BHA (${getUnitsText(UNITS_FOR.Dogleg)})`)
    headers.push(`Cum. Tort BHA (${getUnitsText(UNITS_FOR.Dogleg)})`)
    headers.push(`ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`)
    headers.push('RPM')
    headers.push(`Diff Press. (${getUnitsText(UNITS_FOR.Pressure)})`)
    headers.push(`On Btm. Press. (${getUnitsText(UNITS_FOR.Pressure)})`)
    headers.push(`Off Btm. Press. (${getUnitsText(UNITS_FOR.Pressure)})`)
    headers.push(`Flow Rate (${getUnitsText(UNITS_FOR.FlowRate)})`)
    headers.push(`WOB (${getUnitsText(UNITS_FOR.Weight)})`)
    headers.push(`Torque (${getUnitsText(UNITS_FOR.Torque)})`)
    headers.push(`Cum. DLS Per 10k (${getUnitsText(UNITS_FOR.Dogleg)})`)
    headers.push(`Cum. Slide Perc. (%)`)
    headers.push(`Cum. Slide Perc. BHA (%)`)
    headers.push('BHA')
    headers.push('BHA Num. Rep')
    headers.push('Phase')
    headers.push(`Hole Size (${getUnitsText(UNITS_FOR.Diameter)})`)
    headers.push('Lithology')
    headers.push('Temp °F')

    const wb = XLSX.utils.book_new()

    let sheetNames = []
    for (let i = 0; i < data.length; i++) {
      if (!data[i].surveys) return
      if (!Array.isArray(data[i].surveys)) return

      let ws = XLSX.utils.aoa_to_sheet([headers], { origin: 'A1' })
      let xlExport = []

      let cumDlsBha = 0
      let cumSlidePerc = 0
      let cumSlidePercBha = 0
      let cumDlsPer10k = 0
      let cumTort = 0
      let cumTortBha = 0

      let bhaDesc = ''
      for (let j = 0; j < data[i].surveys.length; j++) {
        const parameter = getParameterAtDepth(data[i].surveys[j].md, data[i].params)
        const bha = getBhaAtDepth(data[i].surveys[j].md, data[i].bha)
        const lithology = getLithologyAtDepth(data[i].surveys[j].md, data[i].actualWellData)

        if (bhaDesc !== bha?.bhaDescription) {
          cumDlsBha = 0
          cumSlidePercBha = 0
          cumTortBha = 0
          bhaDesc = bha?.bhaDescription
        }

        const slidePercBha = getSlidePercBha(data[i].surveys[j].slideSeen, bha)
        const slidePerc = getSlidePerc(data[i].surveys[j].slideSeen, data[i].bha)
        cumSlidePerc += slidePerc
        cumSlidePercBha += slidePercBha
        cumTort += data[i].surveys[j].tort
        cumDlsPer10k = getDlsPer10k(data[i].surveys[j].md, data[i].surveys)
        cumDlsBha += data[i].surveys[j].dls
        cumTortBha += data[i].surveys[j].tort

        let newRow = []
        newRow.push(data[i].wellName)
        newRow.push(data[i].surveys[j].md)
        newRow.push(data[i].surveys[j].inc)
        newRow.push(data[i].surveys[j].azi)
        newRow.push(data[i].surveys[j].tvd)
        newRow.push(data[i].surveys[j].dls)
        newRow.push(data[i].surveys[j].tort)
        newRow.push(data[i].surveys[j].motorYield)
        newRow.push(data[i].surveys[j].slideSeen)
        newRow.push(data[i].surveys[j].cumDls)
        newRow.push(cumTort)
        newRow.push(cumDlsBha)
        newRow.push(cumTortBha)
        newRow.push(parameter?.rop)
        newRow.push(parameter?.rpm)
        newRow.push(parameter?.diffPress)
        newRow.push(parameter?.onBtmPress)
        newRow.push(parameter?.offBtmPress)
        newRow.push(parameter?.flow)
        newRow.push(parameter?.wob)
        newRow.push(parameter?.tq)
        newRow.push(cumDlsPer10k)
        newRow.push(cumSlidePerc)
        newRow.push(cumSlidePercBha)
        newRow.push(bha?.bhaDescription)
        newRow.push(bha?.bhaNumRep)
        newRow.push(parameter?.phase)
        newRow.push(bha?.holeSize)
        newRow.push(lithology)
        newRow.push(parameter?.temp)

        xlExport.push(newRow)
      }

      let sheetName = removeSpecialSymbols(data[i].wellName).slice(0, 30)
      let index = sheetNames.findIndex((element) => element === sheetName)
      if (index < 0) sheetNames.push(sheetName)
      if (index >= 0) continue

      XLSX.utils.sheet_add_aoa(ws, xlExport, { origin: 'A2' })
      XLSX.utils.book_append_sheet(wb, ws, sheetName)
    }

    XLSX.writeFile(wb, 'motorYield.xlsx')
  }

  const getParamsData = () => {
    if (!data) return
    if (!Array.isArray(data)) return
    let paramsData = []

    for (let i = 0; i < data.length; i++) {
      if (!data[i].params) return
      paramsData.push(data[i].params)
    }

    return paramsData
  }

  const getParamsFilter = (prop) => {
    if (!filterByParams) return []
    if (!filterByParams.hasOwnProperty('filterParam')) return []
    if (filterByParams.filterParam !== prop) return []
    if (!filterByParams.hasOwnProperty('filterValues')) return []

    return filterByParams.filterValues
  }

  const getData = (planned) => {
    if (!data) return []
    if (!Array.isArray(data)) return []

    let output = []
    for (let i = 0; i < data.length; i++) {
      output.push(data[i])
    }

    if (planned) {
      for (let i = 0; i < data.length; i++) {
        let principalPlan = {
          wellName: `${data[i].wellName} - Principal Plan`,
          surveys: data[i].principalPlan,
          params: data[i].params,
        }
        output.push(principalPlan)
      }
    }

    return output
  }

  return (
    <Box
      id={'div1'}
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}>
      {showPhaseFilterModal ? (
        <PhaseFilterModal
          data={filterByParams}
          chartData={getParamsData()}
          cancelFunction={() => setShowPhaseFilterModal(false)}
          submitFunction={(data) => {
            setShowPhaseFilterModal(false)
            if (data.filterParam === 'none') {
              setFilterByParams(null)
              return
            }

            let filterValues = []

            if (data.filterParam === 'phase') {
              filterValues = data.filterValuesPhase
            }

            setFilterByParams({ filterParam: data.filterParam, filterValues: filterValues })
            setShowPhaseFilterModal(false)
          }}
        />
      ) : null}
      {isLoading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : null}
      <AnalyticsChartButton
        onClick={onXlsxExport}
        icon={'file-icons:microsoft-excel'}
        toolTipText={'Export to Excel'}
        color={'green'}
        theme={theme}
      />
      <AnalyticsChartButton
        onClick={() => setShowPhaseFilterModal(true)}
        icon={'icon-park-outline:filter'}
        toolTipText={'Phase Filter'}
        color={appColors.itemTextColor}
        left={50}
        theme={theme}
      />
      <AnalyticsChartButton
        onClick={() => setShowPlanned(!showPlanned)}
        icon={'fa6-solid:oil-well'}
        toolTipText={'Toggle Planned Wells'}
        color={showPlanned ? appColors.itemTextColor : appColors.headerTextColor}
        left={100}
        theme={theme}
      />
      <StripChart wellData={getData(showPlanned)} parameter={'inc'} filter={getParamsFilter('phase')} />
      <StripChart wellData={getData(showPlanned)} parameter={'azi'} filter={getParamsFilter('phase')} />
      <StripChart wellData={getData(showPlanned)} parameter={'dls'} filter={getParamsFilter('phase')} />
      <StripChart wellData={getData(showPlanned)} parameter={'cumDls'} filter={getParamsFilter('phase')} />
      <StripChart wellData={getData()} parameter={'motorYield'} filter={getParamsFilter('phase')} />
      <StripChart wellData={getData()} parameter={'slideSeen'} filter={getParamsFilter('phase')} />
      <ParamsStripChart wellData={getParamsData()} parameter={'rop'} filter={getParamsFilter('phase')} />
    </Box>
  )
}

export default MotorYieldChart
