import React from 'react'
import '../chart.css'
import { Box } from '@mui/material'
import { Scatter } from 'react-chartjs-2'
import crossHairPlugin from './crossHairPlugin'
import { appColors, chartSeriesColors } from 'utils'
import { getRandomColor } from 'utils/colorFunctions'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

export const LineChartCjs = ({ title, wellData, yAxisTitle, xAxisTitle, parameter, label, filter }) => {
  const { getUnitsText } = useUnits()
  const { theme } = useInnovaTheme()

  const segColor = (ctx, defaultColor) => {
    return ctx.p0.raw.color ? ctx.p0.raw.color : defaultColor
  }

  const interpolate = (md, wellIndex) => {
    if (!wellData) return -999.25
    if (!Array.isArray(wellData)) return -999.25
    if (wellIndex < 0 || wellIndex >= wellData.length) return -999.25
    if (!Array.isArray(wellData[wellIndex].parameters)) return -999.25
    if (wellData[wellIndex].parameters.length <= 1) return -999.25
    if (!md) return -999.25
    if (typeof md !== 'number') return -999.25
    if (md < wellData[wellIndex].parameters[0].md) return -999.25
    if (md > wellData[wellIndex].parameters[wellData[wellIndex].parameters.length - 1].md) return -999.25

    for (let i = 1; i < wellData[wellIndex].parameters.length; i++) {
      if (md < wellData[wellIndex].parameters[i - 1].md || md > wellData[wellIndex].parameters[i].md) continue
      let x0 = wellData[wellIndex].parameters[i - 1].md
      let x1 = wellData[wellIndex].parameters[i].md
      let y0 = wellData[wellIndex].parameters[i - 1][parameter]
      let y1 = wellData[wellIndex].parameters[i][parameter]
      let x = md

      return y0 + (y1 - y0) * ((x - x0) / (x1 - x0))
    }

    return -999.25
  }

  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div')

    if (!tooltipEl) {
      tooltipEl = document.createElement('div')
      tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)'
      tooltipEl.style.borderRadius = '3px'
      tooltipEl.style.color = 'white'
      tooltipEl.style.opacity = 1
      tooltipEl.style.pointerEvents = 'none'
      tooltipEl.style.position = 'absolute'
      tooltipEl.style.transform = 'translate(-50%, 0)'
      tooltipEl.style.transition = 'all .1s ease'

      const table = document.createElement('table')
      table.style.margin = '0px'

      tooltipEl.appendChild(table)
      chart.canvas.parentNode.appendChild(tooltipEl)
    }

    return tooltipEl
  }

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context
    const tooltipEl = getOrCreateTooltip(chart)

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0
      return
    }

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || []

      //This is a hack, not sure how to directly set the body element as its an object
      //Footer is just an array of strings
      let bodyLines = []
      let bodyColors = []
      if (Array.isArray(tooltip.footer)) {
        for (let i = 0; i < tooltip.footer.length; i++) {
          bodyLines.push(`${tooltip.footer[i].actualWell} - ${tooltip.footer[i].value}`)
          let wellIndex = wellData.findIndex((well) => well.actualWell === tooltip.footer[i].actualWell)

          let col = wellIndex >= 0 ? getSeriesColor(wellIndex) : '#FFFFFF'
          bodyColors.push({
            borderColor: col,
            backgroundColor: col,
            borderWidth: 2,
            borderRadius: 0,
          })
        }
      }

      const tableHead = document.createElement('thead')

      titleLines.forEach((title) => {
        const tr = document.createElement('tr')
        tr.style.borderWidth = 0

        const th = document.createElement('th')
        th.style.fontSize = '12px'
        th.style.borderWidth = 0
        const text = document.createTextNode(title)

        th.appendChild(text)
        tr.appendChild(th)
        tableHead.appendChild(tr)
      })

      const tableBody = document.createElement('tbody')
      bodyLines.forEach((body, i) => {
        const colors = bodyColors[i]

        const span = document.createElement('span')
        span.style.background = colors.backgroundColor
        span.style.borderColor = colors.borderColor
        span.style.borderWidth = '2px'
        span.style.marginRight = '10px'
        span.style.height = '10px'
        span.style.width = '10px'
        span.style.display = 'inline-block'

        const tr = document.createElement('tr')
        tr.style.backgroundColor = 'inherit'
        tr.style.borderWidth = 0
        tr.style.fontSize = '10px'

        const td = document.createElement('td')
        td.style.borderWidth = 0

        const text = document.createTextNode(body)

        td.appendChild(span)
        td.appendChild(text)
        tr.appendChild(td)
        tableBody.appendChild(tr)
      })

      const tableRoot = tooltipEl.querySelector('table')

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove()
      }

      // Add new children
      tableRoot.appendChild(tableHead)
      tableRoot.appendChild(tableBody)
    }

    let parent = document.getElementById('div1')
    const { top: parentTop } = parent.getBoundingClientRect()
    const scrollTop = parent.scrollTop || parent.scrollTop === 0 ? parent.scrollTop : document.documentElement.scrollTop

    // Calculate position relative to the parent container
    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas
    const adjustedY = positionY - parentTop + tooltip.caretY - scrollTop

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1
    tooltipEl.style.left = positionX + tooltip.caretX + 'px'
    tooltipEl.style.top = adjustedY + 'px'
    tooltipEl.style.font = tooltip.options.bodyFont.string
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px'
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: externalTooltipHandler,
        callbacks: {
          title: function (a) {
            return `${label} - ${numberWithCommasDecimals(a[0].parsed.x, 2)} (${getUnitsText(UNITS_FOR.Depth)})`
          },
          footer: function (d) {
            if (!Array.isArray(d) || d.length === 0) return []
            let labelData = []
            for (let i = 0; i < wellData.length; i++) {
              let interpValue = interpolate(d[0].parsed.x, i)
              if (interpValue === -999.25) continue
              labelData.push({ actualWell: wellData[i].actualWell, value: numberWithCommasDecimals(interpValue, 2) })
            }

            return labelData
          },
        },
        filter: function (tooltipItem, index) {
          if (index > 0) return false
          return true
        },
      },
      crosshair: {
        line: {
          color: appColors.headerTextColor,
          width: 0.5,
        },
        sync: {
          enabled: true,
          group: 1,
          suppressTooltips: false,
        },
        zoom: {
          enabled: true,
          zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',
          zoomboxBorderColor: '#48F',
          zoomButtonText: 'Reset',
          zoomButtonClass: 'resetZoom',
        },
      },
    },
    scales: {
      x: {
        type: 'linear',
        title: {
          display: false,
          text: xAxisTitle,
          color: appColors.headerTextColor,
        },
        grid: {
          color: '#404040',
        },
      },
      y: {
        title: {
          display: true,
          text: yAxisTitle,
          color: appColors.headerTextColor,
        },
        grid: {
          color: '#404040',
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'nearest',
    },
    animation: {
      duration: 0, // Disable animation duration
    },
  }

  const getSeriesColor = (index) => {
    if (index < 0 || index >= chartSeriesColors.length) return getRandomColor()
    return chartSeriesColors[index]
  }

  const getChartData = () => {
    if (!wellData) return { datasets: [] }
    if (!Array.isArray(wellData)) return { datasets: [] }
    let filterLength = 0

    let paramData = []
    for (let i = 0; i < wellData.length; i++) {
      if (!Array.isArray(wellData[i].parameters)) continue
      if (filter && Array.isArray(filter) && filter.length > 0) {
        filterLength = filter.length

        for (let j = 0; j < filterLength; j++) {
          let series = { name: `${wellData[i].actualWell}-${filter[j]}`, dataVals: [] }
          for (let k = 0; k < wellData[i].parameters.length; k++) {
            if (wellData[i].parameters[k].phase === filter[j]) {
              series.dataVals.push({ x: wellData[i].parameters[k].md, y: wellData[i].parameters[k][parameter] })
            }
          }

          series.dataVals.sort((firstEl, secondEl) => (firstEl.md < secondEl.md ? -1 : 1))
          paramData.push(series)
        }
      } else {
        let newSeries = { name: wellData[i].actualWell, dataVals: [] }

        for (let j = 0; j < wellData[i].parameters.length; j++) {
          newSeries.dataVals.push({ x: wellData[i].parameters[j].md, y: wellData[i].parameters[j][parameter] })
        }

        newSeries.dataVals.sort((firstEl, secondEl) => (firstEl.md < secondEl.md ? -1 : 1))
        paramData.push(newSeries)
      }
    }

    const chartData = {
      datasets: paramData.map((series, index) => ({
        type: 'line',
        label: series.name,
        data: series.dataVals,
        borderColor: getSeriesColor(index % filterLength),
        backgroundColor: getSeriesColor(index % filterLength),
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 0,
        interpolate: true,
        steppedLine: true,
        segment: {
          borderColor: (ctx) => segColor(ctx, chartSeriesColors[index % filterLength]),
          borderWidth: 2,
        },
      })),
    }

    return chartData
  }

  return <Scatter datasetIdKey={parameter} options={options} data={getChartData()} plugins={[crossHairPlugin]} />
}

const ParameterComparisonChart = ({ wellData, parameter, filter }) => {
  const { getUnitsText } = useUnits()
  const { getChartBackColor } = useInnovaTheme()

  const getUnitData = () => {
    if (parameter === 'rop') return { units: `${getUnitsText(UNITS_FOR.Depth)}/hr`, label: 'ROP' }
    if (parameter === 'wob') return { units: getUnitsText(UNITS_FOR.Weight), label: 'WOB' }
    if (parameter === 'rpm') return { units: '', label: 'RPM' }
    if (parameter === 'flow') return { units: getUnitsText(UNITS_FOR.FlowRate), label: 'Flow Rate' }
    if (parameter === 'tq') return { units: getUnitsText(UNITS_FOR.Torque), label: 'Torque' }
    if (parameter === 'onBtmPress') return { units: getUnitsText(UNITS_FOR.Pressure), label: 'On Btm Press' }
    if (parameter === 'offBtmPress') return { units: getUnitsText(UNITS_FOR.Pressure), label: 'Off Btm Press' }
    if (parameter === 'diffPress') return { units: getUnitsText(UNITS_FOR.Pressure), label: 'Diff Press' }
    if (parameter === 'temp') return { units: getUnitsText(UNITS_FOR.Temperature), label: 'Temperature' }
  }

  const { label, units } = getUnitData()

  return (
    <Box
      sx={{
        backgroundColor: getChartBackColor(),
        padding: '5px',
        display: 'flex',
        height: '300px',
        width: '100%',
      }}>
      <LineChartCjs
        title={`${label}`}
        xAxisTitle={`Depth (${getUnitsText(UNITS_FOR.Depth)})`}
        yAxisTitle={units !== '' ? `${label} (${units})` : `${label}`}
        wellData={wellData}
        parameter={parameter}
        label={label}
        filter={filter}
      />
    </Box>
  )
}

export default ParameterComparisonChart
