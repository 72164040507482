import React, { useEffect, useRef, useState } from 'react'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import Canvas from 'components/common/Canvas'
import { appColors } from 'utils'
import { roundUp } from 'utils/numberFunctions'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import * as d3 from 'd3'
import { uuidv4 } from 'utils/stringFunctions'
import { Box } from '@mui/material'
import { useResizeDetector } from 'react-resize-detector'
import AutoSizer from 'react-virtualized-auto-sizer'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const WellSchematic = ({
  casingData,
  backGroundGradient = true,
  numDepthTicks = 10,
  tickColor = '#A0A0A0',
  depthLabelColor = appColors.itemTextColor,
  labelColor = '#000000',
  titleColor = appColors.itemTextColor,
  title = 'Well Schematic',
  useLogScale = true,
  showLabels = true,
  labelBackColor = '#ffffff',
  toolTipBackColor = 'rgba(32,32,32,0.5)',
  toolTipTextColor = 'rgba(200,200,200,0.5)',
  id = 'wellSchematic',
  printing = false,
}) => {
  const { theme } = useInnovaTheme()
  const mousePosition = useRef({ x: null, y: null, valid: false })
  const _isMounted = useRef(false)
  const [schematicData, setSchematicData] = useState([])
  const { getUnitsText } = useUnits()
  const CEMENT_COLOR = '#303030'
  const OPEN_HOLE_LINE_COLOR = '#D27D2D'
  const OPEN_HOLE_FILL_COLOR = '#7B3F0070'
  const CASING_COLOR = '#000000'
  const PADDING_TOP = 0.075 //Space for Title
  const PADDING_LEFT = 0.2 //Space for depth legend
  const PADDING_BOTTOM = 0.025
  const SCHEMATIC_WIDTH = 0.66 //Max width of the schematic
  const SCHEMATIC_PADDING = 0.05
  const CASING_TRIANGLE_WIDTH = 0.025

  const { width: wellSchematicWidth, height: wellSchematicHeight, ref: wellSchematcRef } = useResizeDetector()

  const getFontString = (context, size) => {
    let scale = getFontScale(context)
    return size * scale
  }

  const getFontScale = (context) => {
    const { width } = getWidthHeight(context)
    const BASE_LINE_WIDTH = 300
    if (width >= BASE_LINE_WIDTH) return 1
    return Math.max(0.1, width / BASE_LINE_WIDTH)
  }

  function handleMouseMove(event) {
    mousePosition.current = { x: event.offsetX, y: event.offsetY, valid: true }
  }

  function handleMouseOut(event) {
    mousePosition.current = { x: null, y: null, valid: false }
  }

  useEffect(() => {
    _isMounted.current = true

    if (document.getElementById(id)) {
      document.getElementById(id).addEventListener('mousemove', handleMouseMove)
      document.getElementById(id).addEventListener('mouseout', handleMouseOut)
    }

    return () => {
      _isMounted.current = false
      document.getElementById(id)?.removeEventListener('mousemove', handleMouseMove)
      document.getElementById(id)?.removeEventListener('mouseout', handleMouseOut)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!_isMounted.current) return
    if (!casingData) return
    if (!Array.isArray(casingData)) return

    let wellboreData = []

    for (let i = 0; i < casingData.length; i++) {
      if (casingData[i].Type !== 'Open Hole' && casingData[i].HoleSize - casingData[i].OD > 0.5) {
        wellboreData.push({
          type: 'cement',
          description: '',
          depthFrom: 0,
          depthTo: casingData[i].MD,
          tvdTo: casingData[i].TVD,
          od: casingData[i].HoleSize,
          id: casingData[i].OD,
        })
      }

      wellboreData.push({
        type: casingData[i].Type !== 'Open Hole' ? 'casing' : 'openhole',
        description: `${casingData[i].OD}${getUnitsText(UNITS_FOR.Diameter)} ${casingData[i].Type}`,
        depthFrom: 0,
        depthTo: casingData[i].MD,
        tvdTo: casingData[i].TVD,
        od: casingData[i].OD,
        id: casingData[i].HoleSize,
      })
    }

    setSchematicData(wellboreData)
  }, [casingData]) // eslint-disable-line react-hooks/exhaustive-deps

  const getWidthHeight = (context) => {
    return {
      width: getWidth(),
      height: getHeight(),
    }
  }

  function getMaxValues() {
    let output = { minX: 0, maxX: 10, minY: 0, maxY: 150, logScaleSegments: [0, 150] }
    if (!Array.isArray(schematicData)) return output
    if (schematicData.length === 0) return output

    let nSegments = 0
    output.logScaleSegments = [0]

    schematicData.forEach((segment) => {
      if (segment.depthTo > output.maxY) output.maxY = segment.depthTo
      if (segment.od > output.maxX) output.maxX = segment.od
      if (segment.type !== 'cement' && segment.depthTo > output.logScaleSegments[output.logScaleSegments.length - 1])
        output.logScaleSegments.push(segment.depthTo)
      nSegments++
    })

    if (output.logScaleSegments.length < 2) output.logScaleSegments = [output.minY, output.maxY]
    if (nSegments === 1) {
      output.maxX *= 3
    }

    return output
  }

  const drawDepthScale = (context) => {
    const { width, height } = getWidthHeight(context)
    const { minY, maxY } = getMaxValues()
    const { depthScaleTopY, depthScaleBottomY, depthScaleRightX } = calculateWellBoreRect(context)

    if (printing || theme !== 'dark') {
      context.fillStyle = '#FFFFFF'
      context.fillRect(0, 0, width, height)
    }

    //Left hand vertical line
    context.strokeStyle = tickColor
    if (printing || theme !== 'dark') {
      context.strokeStyle = '#000000'
    }
    context.lineWidth = 1
    context.beginPath()
    context.moveTo(1, depthScaleTopY)
    context.lineTo(1, height - 1)
    context.stroke()

    //Right hand vertical line
    context.beginPath()
    context.moveTo(depthScaleRightX - 1, depthScaleTopY)
    context.lineTo(depthScaleRightX - 1, height - 1)
    context.stroke()

    //Draw ticks
    context.lineWidth = 1
    context.font = `${getFontString(context, 0.75)}em sans-serif`
    context.fillStyle = tickColor
    context.textAlign = 'center'
    context.textBaseline = 'middle'

    let tickWidth = (width * PADDING_LEFT) / 10
    let tickInteval = (depthScaleBottomY - depthScaleTopY) / (numDepthTicks - 1)
    for (let i = 0; i < numDepthTicks; i++) {
      let tickY = depthScaleTopY + i * tickInteval
      context.strokeStyle = tickColor
      context.fillStyle = tickColor

      if (printing || theme !== 'dark') {
        context.strokeStyle = '#000000'
        context.fillStyle = '#FFFFFF'
      }

      context.beginPath()
      context.moveTo(1, tickY)
      context.lineTo(tickWidth, tickY)
      context.stroke()

      context.beginPath()
      context.moveTo(depthScaleRightX - 1, tickY)
      context.lineTo(depthScaleRightX - tickWidth, tickY)
      context.stroke()

      const { depth } = calcXYuserUnits(tickY, context)
      let textLabel = roundUp(depth, 10)
      if (i === 0) textLabel = minY
      if (i === numDepthTicks - 1) textLabel = maxY

      context.strokeStyle = depthLabelColor
      context.fillStyle = depthLabelColor

      if (printing || theme !== 'dark') {
        context.strokeStyle = '#FFFFFF'
        context.fillStyle = '#000000'
      }

      context.beginPath()
      context.fillText(numberWithCommasDecimals(textLabel, 0), depthScaleRightX / 2, tickY)
    }

    //Depth units text
    context.strokeStyle = depthLabelColor
    context.fillStyle = depthLabelColor

    if (printing || theme !== 'dark') {
      context.strokeStyle = '#FFFFFF'
      context.fillStyle = '#000000'
    }

    context.beginPath()
    context.fillText(`MD, ${getUnitsText(UNITS_FOR.Depth)}`, depthScaleRightX / 2, depthScaleTopY / 2)
  }

  const createLogScaleArray = (startVal, endVal, inputArr) => {
    let segmentLength = (endVal - startVal) / (inputArr.length - 1)
    let outputScale = []
    for (let i = 0; i < inputArr.length; i++) {
      outputScale.push(startVal + segmentLength * i)
    }

    return outputScale
  }

  const calcXYpixels = (depth, od, context) => {
    const { maxX, minY, maxY, logScaleSegments } = getMaxValues()
    const { depthScaleTopY, depthScaleBottomY, depthScaleRightX, schematicRightX } = calculateWellBoreRect(context)

    let linearScaleY = d3.scaleLinear().domain([minY, maxY]).range([depthScaleTopY, depthScaleBottomY])
    let y = linearScaleY(depth)

    if (useLogScale) {
      let logScale = d3
        .scaleLinear()
        .domain(logScaleSegments)
        .range(createLogScaleArray(depthScaleTopY, depthScaleBottomY, logScaleSegments))
      y = logScale(depth)
    }

    let maxRadius = (maxX / 2) * (1 + SCHEMATIC_PADDING)
    let linearScaleX = d3.scaleLinear().domain([-maxRadius, maxRadius]).range([depthScaleRightX, schematicRightX])
    let x = linearScaleX(od)
    return { x, y }
  }

  const calcXYuserUnits = (y, context) => {
    const { minY, maxY, logScaleSegments } = getMaxValues()
    const { depthScaleTopY, depthScaleBottomY } = calculateWellBoreRect(context)

    let linearScale = d3.scaleLinear().domain([depthScaleTopY, depthScaleBottomY]).range([minY, maxY])
    let depth = linearScale(y)

    if (useLogScale) {
      let logScale = d3
        .scaleLinear()
        .domain(createLogScaleArray(depthScaleTopY, depthScaleBottomY, logScaleSegments))
        .range(logScaleSegments)
      depth = logScale(y)
    }

    if (!Array.isArray(schematicData)) return { od: 0, depth, label: '' }
    if (schematicData.length === 0) return { od: 0, depth, label: '' }

    let segmentIndex = -1
    for (let i = 0; i < schematicData.length; i++) {
      if (schematicData[i].type === 'cement') continue
      if (depth < schematicData[i].depthFrom || depth > schematicData[i].depthTo) continue
      if (segmentIndex < 0) segmentIndex = i
      if (schematicData[i].od > schematicData[segmentIndex].od) segmentIndex = i
    }

    return {
      depth,
      od: segmentIndex >= 0 ? schematicData[segmentIndex].od : 0,
      label: segmentIndex >= 0 ? schematicData[segmentIndex].description : '',
    }
  }

  const drawCementTexture = (context, x1, y1, width, height) => {
    let yDotSpacing = 5
    let xDotSpacing = 1.5
    let dotRadius = 0.5
    context.fillStyle = '#a0a0a0'
    let padding = 1

    for (let y = y1 + padding; y <= y1 + height - padding; y = y + yDotSpacing) {
      let xCounter = 0
      for (let x = x1 + padding; x <= x1 + width - padding; x = x + xDotSpacing) {
        let offset = xCounter % 2 ? 0 : yDotSpacing / 2
        context.beginPath()
        context.arc(x, y + offset, dotRadius, 0, 2 * Math.PI)
        context.fill()
        xCounter++
      }
    }
  }

  const drawCement = (context, section) => {
    context.strokeStyle = CEMENT_COLOR
    context.fillStyle = CEMENT_COLOR
    context.lineWidth = 1

    const { casingTriangleWidth } = calculateWellBoreRect(context)
    const { y: y1 } = calcXYpixels(section.depthFrom, section.id / 2, context)
    const { x: x2, y: y2 } = calcXYpixels(section.depthTo, section.id / 2, context)
    const { x: x3 } = calcXYpixels(section.depthFrom, -section.id / 2, context)

    context.beginPath()
    context.fillRect(x2, y1, casingTriangleWidth, y2 - y1)

    context.beginPath()
    context.fillRect(x3 - casingTriangleWidth, y1, casingTriangleWidth, y2 - y1)

    drawCementTexture(context, x2, y1, casingTriangleWidth, y2 - y1)
    drawCementTexture(context, x3 - casingTriangleWidth, y1, casingTriangleWidth, y2 - y1)
  }

  const drawCasing = (context, section) => {
    const { casingTriangleWidth } = calculateWellBoreRect(context)

    context.lineWidth = 1.5

    const { x: x1, y: y1 } = calcXYpixels(section.depthFrom, section.od / 2, context)
    const { x: x2, y: y2 } = calcXYpixels(section.depthTo, section.od / 2, context)
    const { x: x3, y: y3 } = calcXYpixels(section.depthFrom, -section.od / 2, context)
    const { x: x4, y: y4 } = calcXYpixels(section.depthTo, -section.od / 2, context)

    let gradient = context.createLinearGradient(x3, 0, x1, 0)
    gradient.addColorStop(0, '#404040')
    gradient.addColorStop(0.25, '#a0a0a0')
    gradient.addColorStop(0.5, '#d0d0d0')
    gradient.addColorStop(0.75, '#a0a0a0')
    gradient.addColorStop(1, '#404040')
    context.fillStyle = gradient

    context.beginPath()
    context.fillRect(x3, y1, x1 - x3, y2 - y1)

    //Vertical line right hand side
    context.strokeStyle = CASING_COLOR
    context.fillStyle = CASING_COLOR
    context.beginPath()
    context.moveTo(x1, y1)
    context.lineTo(x2, y2)
    context.stroke()

    //Triangle right hand side
    context.beginPath()
    context.moveTo(x2, y2)
    context.lineTo(x2 + casingTriangleWidth, y2)
    context.lineTo(x2, y2 - casingTriangleWidth)
    context.fill()

    //Vertical line left hand side
    context.strokeStyle = CASING_COLOR
    context.fillStyle = CASING_COLOR
    context.beginPath()
    context.moveTo(x3, y3)
    context.lineTo(x4, y4)
    context.stroke()

    //Triangle left hand side
    context.beginPath()
    context.moveTo(x4, y4)
    context.lineTo(x4 - casingTriangleWidth, y4)
    context.lineTo(x4, y4 - casingTriangleWidth)
    context.fill()
  }

  const drawCasingTriangles = (context, section) => {
    const { casingTriangleWidth } = calculateWellBoreRect(context)

    context.lineWidth = 1
    context.strokeStyle = CASING_COLOR
    context.fillStyle = CASING_COLOR

    const { x: x2, y: y2 } = calcXYpixels(section.depthTo, section.od / 2, context)
    const { x: x4, y: y4 } = calcXYpixels(section.depthTo, -section.od / 2, context)

    //Triangle right hand side
    context.beginPath()
    context.moveTo(x2, y2)
    context.lineTo(x2 + casingTriangleWidth, y2)
    context.lineTo(x2, y2 - casingTriangleWidth)
    context.fill()

    //Triangle left hand side
    context.beginPath()
    context.moveTo(x4, y4)
    context.lineTo(x4 - casingTriangleWidth, y4)
    context.lineTo(x4, y4 - casingTriangleWidth)
    context.fill()
  }

  const drawCasingBackground = (context, section) => {
    //Draw open hole sections behind casing
    const { casingTriangleWidth } = calculateWellBoreRect(context)
    const { x: x1, y: y1 } = calcXYpixels(section.depthFrom, section.od / 2, context)
    const { x: x2, y: y2 } = calcXYpixels(section.depthTo, -section.od / 2, context)

    context.lineWidth = 1
    context.fillStyle = OPEN_HOLE_FILL_COLOR

    context.beginPath()
    context.fillRect(x2 - casingTriangleWidth, y1, x1 - x2 + 2 * casingTriangleWidth, y2 - y1)
  }

  const drawOpenHole = (context, sections) => {
    const { x: x11, y: y11 } = calcXYpixels(sections[0].depthFrom, sections[0].id / 2, context)
    const { x: x21, y: y21 } = calcXYpixels(sections[0].depthTo, -sections[0].id / 2, context)
    const { x: x12, y: y12 } = calcXYpixels(sections[0].depthTo, sections[1].id / 2, context)
    const { x: x22, y: y22 } = calcXYpixels(sections[1].depthTo, -sections[1].id / 2, context)

    context.lineWidth = 1
    let gradient = context.createLinearGradient(x21, 0, x11, 0)
    gradient.addColorStop(0, '#7B3F00')
    gradient.addColorStop(0.5, OPEN_HOLE_LINE_COLOR)
    gradient.addColorStop(1, '#7B3F00')
    context.fillStyle = gradient

    context.strokeStyle = OPEN_HOLE_LINE_COLOR

    context.beginPath()
    context.fillRect(x21, y11, x11 - x21, y21 - y11)

    context.beginPath()
    context.moveTo(x21, y11)
    context.lineTo(x21, y21)
    context.stroke()

    context.beginPath()
    context.moveTo(x11, y11)
    context.lineTo(x11, y21)
    context.stroke()

    context.beginPath()
    context.moveTo(x11, y21)
    context.lineTo(x21, y21)
    context.stroke()

    context.beginPath()
    context.moveTo(x12, y12)
    context.lineTo(x12, y22)
    context.stroke()

    context.beginPath()
    context.moveTo(x22, y12)
    context.lineTo(x22, y22)
    context.stroke()

    context.beginPath()
    context.moveTo(x12, y12)
    context.lineTo(x22, y12)
    context.stroke()

    context.beginPath()
    context.moveTo(x12, y22)
    context.lineTo(x22, y22)
    context.stroke()

    context.beginPath()
    context.fillRect(x22, y12, x12 - x22, y22 - y12)

    context.beginPath()
    context.moveTo(x21, y21)
    context.lineTo(x12, y12)
    context.stroke()

    context.beginPath()
    context.moveTo(x21, y21)
    context.lineTo(x12, y12)
    context.stroke()
  }

  const drawSchematic = (context) => {
    if (!Array.isArray(schematicData)) return
    if (schematicData.length === 0) return

    for (let i = 0; i < schematicData.length; i++) {
      if (schematicData[i].type === 'casing') drawCasingBackground(context, schematicData[i])
    }

    for (let i = 0; i < schematicData.length; i++) {
      if (schematicData[i].type === 'casing') drawCasing(context, schematicData[i])
    }

    for (let i = 0; i < schematicData.length; i++) {
      if (schematicData[i].type === 'cement') drawCement(context, schematicData[i])
    }

    for (let i = 0; i < schematicData.length; i++) {
      if (schematicData[i].type === 'casing') drawCasingTriangles(context, schematicData[i])
    }

    let smallestId = schematicData[0].id
    let schematicId = -1
    for (let i = 0; i < schematicData.length; i++) {
      if (schematicData[i].id <= smallestId) {
        smallestId = schematicData[i].id
        schematicId = i
      }
    }

    for (let i = 0; i < schematicData.length; i++) {
      if (schematicData[i].type === 'openhole') {
        if (schematicId < 0) schematicId = i
        drawOpenHole(context, [schematicData[schematicId], schematicData[i]])
      }
    }
  }

  const drawLabels = (context) => {
    if (!Array.isArray(schematicData)) return
    if (schematicData.length === 0) return

    const { casingTriangleWidth, schematicRightX } = calculateWellBoreRect(context)
    const { width } = getWidthHeight(context)
    let lineHeight = 12
    let labelPadding = 5

    for (let i = 0; i < schematicData.length; i++) {
      if (schematicData[i].type === 'casing' || schematicData[i].type === 'openhole') {
        let padding = 0
        if (schematicData[i].type === 'casing') padding = casingTriangleWidth

        const { y, x } = calcXYpixels(schematicData[i].depthTo, schematicData[i].od / 2, context)

        context.fillStyle = '#ff0000'
        context.strokeStyle = '#ff0000'
        context.beginPath()
        context.setLineDash([5])
        context.moveTo(x + padding, y)
        context.lineTo(schematicRightX, y)
        context.stroke()

        let label = `${schematicData[i].description} @ ${numberWithCommasDecimals(
          schematicData[i].depthTo,
          2,
        )}${getUnitsText(UNITS_FOR.Depth)} MD / ${numberWithCommasDecimals(schematicData[i]?.tvdTo, 2)}${getUnitsText(
          UNITS_FOR.Depth,
        )} TVD`

        let numLines = getNumLines(
          context,
          label,
          schematicRightX + labelPadding,
          y,
          width - schematicRightX,
          lineHeight,
        )

        context.fillStyle = labelBackColor
        context.strokeStyle = appColors.itemTextColor

        if (printing || theme !== 'dark') {
          context.strokeStyle = '#000000'
        }

        let top = y - lineHeight * (numLines / 2)
        const height = lineHeight * (numLines + 0.5)

        let fontSize = 0.5
        
        if (printing) {
          top -= lineHeight
          fontSize = 0.7
        }

        context.beginPath()
        context.fillRect(schematicRightX, top, width - schematicRightX - labelPadding, height)

        context.font = `${getFontString(context, fontSize)}em sans-serif`
        context.fillStyle = labelColor
        context.strokeStyle = labelColor
        context.textAlign = 'left'
        context.textBaseline = 'middle'
        wrapText(
          context,
          label,
          schematicRightX + labelPadding,
          top + lineHeight / 2,
          width - schematicRightX - labelPadding * 2,
          lineHeight,
        )
      }
    }

    context.setLineDash([])
  }

  function getNumLines(context, text, x, y, maxWidth, lineHeight) {
    let words = text.split(' ')
    let line = ''
    let nLines = 1

    for (let n = 0; n < words.length; n++) {
      let testLine = line + words[n] + ' '
      let metrics = context.measureText(testLine)
      let testWidth = metrics.width
      if (testWidth > maxWidth && n > 0) {
        line = words[n] + ' '
        y += lineHeight
        nLines++
      } else {
        line = testLine
      }
    }

    return nLines
  }

  function wrapText(context, text, x, y, maxWidth, lineHeight) {
    let words = text.split(' ')
    let line = ''

    for (let n = 0; n < words.length; n++) {
      let testLine = line + words[n] + ' '
      let metrics = context.measureText(testLine)
      let testWidth = metrics.width
      if (testWidth > maxWidth && n > 0) {
        context.fillText(line, x, y)
        line = words[n] + ' '
        y += lineHeight
      } else {
        line = testLine
      }
    }

    context.fillText(line, x, y)
  }

  const calculateWellBoreRect = (context) => {
    const { width, height } = getWidthHeight(context)
    let depthScaleTopY = height * PADDING_TOP
    let depthScaleBottomY = height - height * PADDING_BOTTOM
    let depthScaleRightX = width * PADDING_LEFT
    let schematicCenterX = depthScaleRightX + ((width - depthScaleRightX) * SCHEMATIC_WIDTH) / 2
    let schematicRightX = depthScaleRightX + (width - depthScaleRightX) * SCHEMATIC_WIDTH
    let casingTriangleWidth = (schematicRightX - depthScaleRightX) * CASING_TRIANGLE_WIDTH
    if (casingTriangleWidth > 100) {
      casingTriangleWidth = 100
    }

    if (casingTriangleWidth < 10) {
      casingTriangleWidth = 10
    }

    return {
      depthScaleTopY,
      depthScaleBottomY,
      depthScaleRightX,
      schematicCenterX,
      schematicRightX,
      casingTriangleWidth,
    }
  }

  const drawBackGround = (context) => {
    context.clearRect(0, 0, context.canvas.width, context.canvas.height)
    context.fillStyle = 'rgba(32,32,32,1.0)'

    context.beginPath()
    context.fillRect(0, 0, context.canvas.width, context.canvas.height)

    if (!backGroundGradient) return
    const { depthScaleTopY, depthScaleRightX } = calculateWellBoreRect(context)
    let gradient = context.createLinearGradient(0, 0, 0, context.canvas.height)
    if (!printing || theme !== 'dark') {
      gradient.addColorStop(0, '#404040')
      gradient.addColorStop(1, '#101010')
    }
    if (printing || theme !== 'dark') {
      gradient.addColorStop(0, '#FFFFFF')
      gradient.addColorStop(1, '#FFFFFF')
    }
    context.fillStyle = gradient

    context.beginPath()
    context.fillRect(depthScaleRightX + 1, depthScaleTopY, context.canvas.width - 2, context.canvas.height)
  }

  const drawTitle = (context) => {
    const { width } = getWidthHeight(context)
    const { depthScaleTopY, depthScaleRightX } = calculateWellBoreRect(context)

    if (printing || theme !== 'dark') {
      context.fillStyle = '#FFFFFF'
      context.fillRect(depthScaleRightX, 0, width, depthScaleTopY)
    }

    context.font = `${getFontString(context, 1)}em sans-serif`
    context.fillStyle = tickColor
    context.textAlign = 'center'
    context.textBaseline = 'middle'
    context.strokeStyle = titleColor

    if (printing || theme !== 'dark') {
      context.fillStyle = '#000000'
      context.strokeStyle = '#000000'
    }

    context.beginPath()
    context.fillText(title, depthScaleRightX + (width - depthScaleRightX) / 2, depthScaleTopY / 2)
  }

  const drawTopVerticalLine = (context) => {
    const { width } = getWidthHeight(context)
    const { depthScaleTopY, depthScaleRightX } = calculateWellBoreRect(context)
    context.strokeStyle = '#000000'
    context.lineWidth = 1
    context.beginPath()
    context.moveTo(depthScaleRightX, depthScaleTopY)
    context.lineTo(width, depthScaleTopY)
    context.stroke()
  }

  const drawToolTip = (context) => {
    if (!mousePosition.current.valid) return
    const { depthScaleTopY, depthScaleRightX } = calculateWellBoreRect(context)
    const { width } = getWidthHeight(context)
    let offset = 20
    let padding = 10
    let lineHeight = 20

    if (mousePosition.current.y < depthScaleTopY) return

    //Horizontal dashed line
    context.strokeStyle = '#ff0000'
    context.lineWidth = 1
    context.setLineDash([3])
    context.beginPath()
    context.moveTo(depthScaleRightX, mousePosition.current.y)
    context.lineTo(width, mousePosition.current.y)
    context.stroke()
    context.setLineDash([])

    //Background box
    context.fillStyle = toolTipBackColor
    context.strokeStyle = 'rgba(32,32,32,0.75)'
    context.fillRect(mousePosition.current.x + offset, mousePosition.current.y + offset, width * 0.5, width * 0.2)
    context.strokeRect(mousePosition.current.x + offset, mousePosition.current.y + offset, width * 0.5, width * 0.2)

    //Text
    context.font = `${getFontString(context, 1)}em sans-serif`
    context.fillStyle = toolTipTextColor
    context.strokeStyle = toolTipTextColor
    context.textAlign = 'left'
    context.textBaseline = 'middle'

    const { depth, od, label } = calcXYuserUnits(mousePosition.current.y, context)
    context.fillText(label, mousePosition.current.x + offset + padding, mousePosition.current.y + offset + padding)

    context.fillText(
      `Depth: ${roundUp(depth, 1)} ${getUnitsText(UNITS_FOR.Depth)}`,
      mousePosition.current.x + offset + padding,
      mousePosition.current.y + offset + padding + lineHeight,
    )
    context.fillText(
      `Diameter: ${od} ${getUnitsText(UNITS_FOR.Diameter)}`,
      mousePosition.current.x + offset + padding,
      mousePosition.current.y + offset + padding + lineHeight * 2,
    )
  }

  const draw = (context, frameCount) => {
    //Frame count can be used for animations
    drawBackGround(context)
    drawDepthScale(context)
    drawTitle(context)
    drawSchematic(context)
    drawTopVerticalLine(context)
    if (showLabels) drawLabels(context)
    drawToolTip(context)
  }

  const getWidth = () => {
    return wellSchematicWidth ? wellSchematicWidth : 1
  }

  const getHeight = () => {
    return wellSchematicHeight ? wellSchematicHeight : 1
  }

  return (
    <Box
      sx={{
        overflow: 'hidden',
        border: '1px solid ',
        width: '100%',
        height: '100%',
      }}
      id={id ? id : uuidv4()}
      ref={wellSchematcRef}>
      <AutoSizer>
        {({ height, width }) => (
          <Canvas
            square={false}
            draw={draw}
            width={width}
            height={height}
            style={{
              width: width,
              height: height,
            }}
          />
        )}
      </AutoSizer>
    </Box>
  )
}

export default WellSchematic
