import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { currentPageAtom, actionBarWidthAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { Box, Tooltip, IconButton, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import { appColors } from 'utils'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import {
  sortColDefs,
  relativeTime,
  dateComparator,
  isDateLessThan,
  CustomLoadingOverlay,
  getStringId,
} from 'components/common/AgGridUtils'
import ConfirmDialog from 'components/common/ConfirmDialog'
import AddIcon from '@mui/icons-material/Add'
import axios from 'axios'
import PopupCellRenderer from 'components/WellPages/DailyReportsPages/PopupCellRenderer'
import { styled } from '@mui/styles'
import { debounce } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledMenuIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const WellSeekerUpdateFilesPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const selectFileRef = useRef(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const isDeleting = useRef(false)
  const selectedFileRef = useRef(null)
  const [isLoading, setLoading] = useState(false)
  const [fileData, setFileData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [resetCols, setResetCols] = useState(false)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const { getAccessTokenSilently, user, getDatabaseOrg } = useInnovaAuth()
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()

  const setCurrentVersion = useInnovaAxios({
    url: '/admin/wsUpdates/setUpdateFileAsCurrentVer',
  })

  const getData = useInnovaAxios({
    url: '/admin/wsUpdates/getDesktopAppUpdateFileInfo',
  })

  const deleteFileInfo = useInnovaAxios({
    url: '/admin/wsUpdates/deleteDesktopAppUpdateFile',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.adminWellSeekerUpdateFilesKey)
    fetchData()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedFile !== null) {
      handleUploadFile()
    }
  }, [selectedFile]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const fetchData = async () => {
    if (isLoading) return
    setLoading(true)
    const response = await getData()

    if (response?.error) return
    if (!_isMounted.current) return

    setFileData(Array.isArray(response.data) ? response.data : [])
    setLoading(false)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onClickSetCurrent = async (data) => {
    if (!data) return
    if (isLoading) return

    if (_isMounted.current) setLoading(true)
    const res = await setCurrentVersion({
      uid: data?.uid,
    })

    if (!_isMounted.current) return
    setLoading(false)

    if (res.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    setStatus({ show: true, severity: 'success', message: `${data.fileName} set as current version` })
  }

  const uploadFile = async (file) => {
    if (!file) return false

    let formData = new FormData()
    const accessToken = await getAccessTokenSilently()

    let options = {
      url: '/admin/wsUpdates/uploadDesktopAppUpdateFile',
    }

    formData.append('userName', user?.name)
    formData.append('databaseOrg', getDatabaseOrg())
    formData.append('productKey', process.env.REACT_APP_ICP_PRODUCT_KEY)
    formData.append('file', file)

    const response = await axios({
      method: 'post',
      baseURL: process.env.REACT_APP_ICP_API,
      timeout: 60000,
      ...options,
      data: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response
  }

  const handleUploadFile = async () => {
    if (!selectedFile) return
    if (isLoading) return

    setLoading(true)
    const res = await uploadFile(selectedFile)
    setLoading(false)

    if (!_isMounted.current) return
    if (res.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    if (!res.data) {
      setStatus({ show: true, severity: 'error', message: `file upload failed` })
      return
    }

    if (gridApi.current) {
      gridApi.current.applyTransaction({
        add: [res.data],
      })
    }

    setStatus({ show: true, severity: 'success', message: 'File uploaded' })
    setSelectedFile(null)
    return
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('wellSeekerUpdatesFileGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('wellSeekerUpdatesFileGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'UpdateFiles.xlsx',
            sheetName: 'Files',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const onClickDelete = (data) => {
    selectedFileRef.current = data
    setConfirm({
      show: true,
      title: 'Delete desktop update file',
      text: `Are you sure you want to delete ${data.fileName}?`,
      onConfirm: handleDelete,
    })
  }

  const handleDelete = async () => {
    if (!selectedFileRef.current) return
    if (!selectedFileRef.current.hasOwnProperty('uid')) return

    if (isDeleting.current) return
    isDeleting.current = true
    let res = await deleteFileInfo(selectedFileRef.current)
    isDeleting.current = false

    if (!_isMounted.current) return

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: [selectedFileRef.current],
      })
    }

    selectedFileRef.current = null
  }

  const actionIconRenderer = (params) => {
    return (
      <React.Fragment>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <IconButton
              style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => onClickDelete(params.data)}
              size='large'>
              <Iconify color='red' icon='fa-regular:trash-alt' fontSize={16} />
            </IconButton>
          </Tooltip>
          <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
        </Box>
      </React.Fragment>
    )
  }

  const dateFilterComparator = useCallback(dateComparator, [])

  const relativeTimeFormatter = useCallback(relativeTime, [])

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [])

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const handleMenuClick = (action, data) => {
    if (typeof action !== 'string') return
    if (!data) return

    if (action === 'setVersion') {
      onClickSetCurrent(data)
    }
  }

  let columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        colId: 'actions',
        width: 86,
        headerName: '',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        headerName: '',
        colId: 'actions',
        cellStyle: centerAlignCell,
        cellRenderer: PopupCellRenderer,
        cellRendererParams: (params) => {
          return {
            menuItems: [
              {
                label: 'Set as current version',
                action: 'setVersion',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='codicon:vm-active' />
                  </StyledIconContainer>
                ),
              },
            ],
          }
        },
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        width: 70,
      },
      {
        field: 'fileName',
        colId: 'fileName',
        headerName: 'Filename',
      },
      {
        field: 'applicationType',
        colId: 'applicationType',
        headerName: 'Application',
      },
      {
        field: 'fileVersion',
        colId: 'fileVersion',
        headerName: 'Version',
      },
      {
        field: 'platform',
        colId: 'platform',
        headerName: 'Platform',
      },
      {
        field: 'uploadedBy',
        colId: 'uploadedBy',
        headerName: 'Uploaded By',
      },
      {
        field: 'uploadedDatetime',
        colId: 'uploadedDatetime',
        headerName: 'Uploaded Date',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        valueFormatter: (params) =>
          `${dateTimeFormatter(params.data?.uploadedDatetime)} [${relativeTimeFormatter(
            params.data?.uploadedDatetime,
          )}]`,
      },
    ],
    [dateFilterComparator, dateTimeFormatter, relativeTimeFormatter], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const onClickSelectFile = () => {
    selectFileRef.current.click()
  }

  const getFileVersion = (fileName) => {
    let version = ''
    if (fileName && fileName.length > 0) {
      let parts = fileName.split('-')
      if (parts.length >= 2) {
        version = parts[1].replaceAll('v', '')
        version = version.replaceAll('.zip', '')
        version = version.trim().toLowerCase()
      }
    }

    return version
  }

  const verifyFileNameFormat = (fileName) => {
    const version = getFileVersion(fileName)
    const versionFormatCheck = version.split('.')
    if (versionFormatCheck.length !== 4) {
      return false
    }

    if (fileName.toLowerCase().includes('seeker')) return true
    if (fileName.toLowerCase().includes('engineering')) return true

    return false
  }

  const onSelectFile = (event) => {
    if (verifyFileNameFormat(event.target.files[0].name)) {
      if (_isMounted.current) setSelectedFile(event.target.files[0])
      return
    }

    if (_isMounted.current) setStatus({ show: true, severity: 'error', message: 'Not a valid file name' })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItmes: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: `calc(100vh - 64px)`,
      }}>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={confirm.onConfirm}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <input
        style={{ display: 'none' }}
        ref={selectFileRef}
        type='file'
        multiple={true}
        accept='application/zip'
        onChange={onSelectFile}
      />
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            rowData={fileData}
            loading={isLoading}
            columnDefs={sortColDefs(columnDefs, 'wellSeekerUpdatesFileGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <AddIcon />,
              name: 'Add',
              onClick: () => {
                onClickSelectFile()
              },
            },
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchData()
              },
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default WellSeekerUpdateFilesPage
