import React from 'react'
import { useNavigate } from 'react-router-dom'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import {
  Drawer,
  Divider,
  Toolbar,
  Tooltip,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material'

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  actionBarWidthAtom,
  wellViewsOpenAtom,
  currentPageAtom,
  userRoleSelector,
  analyticsChartIndexAtom,
  analyticsMapMountedAtom,
} from 'atoms'
import { Icon as Iconify } from '@iconify/react'

import WellListDefaultIcon from 'assets/wellScreenIcons/view-list-grey.png'
import WellListActiveIcon from 'assets/wellScreenIcons/view-list-blue.png'
import WellViewDefaultIcon from 'assets/wellScreenIcons/drilling-parameters-grey.png'
import WellViewActiveIcon from 'assets/wellScreenIcons/drilling-parameters-blue.png'
import WellDetailsDefaultIcon from 'assets/wellScreenIcons/well-summary-grey.png'
import WellDetailsDefaultIconLight from 'assets/wellScreenIcons/well-summary-black.png'
import WellDetailsActiveIcon from 'assets/wellScreenIcons/well-summary-blue.png'
import DailyActivityDefaultIcon from 'assets/wellScreenIcons/daily-report-grey.png'
import DailyActivityDefaultIconLight from 'assets/wellScreenIcons/daily-report-black.png'
import DailyActivityActiveIcon from 'assets/wellScreenIcons/daily-report-blue.png'
import SurveysDefaultIcon from 'assets/wellScreenIcons/surveys-grey.png'
import SurveysDefaultIconLight from 'assets/wellScreenIcons/surveys-black.png'
import SurveysActiveIcon from 'assets/wellScreenIcons/surveys-blue.png'
import ToolInventoryDefaultIcon from 'assets/wellScreenIcons/inventory-grey.png'
import ToolInventoryDefaultIconLight from 'assets/wellScreenIcons/inventory-black.png'
import ToolInventoryActiveIcon from 'assets/wellScreenIcons/inventory-blue.png'
import DrillStringDefaultIcon from 'assets/wellScreenIcons/drill-string-grey.png'
import DrillStringDefaultIconLight from 'assets/wellScreenIcons/drill-string-black.png'
import DrillStringActiveIcon from 'assets/wellScreenIcons/drill-string-blue.png'
import CostsDefaultIcon from 'assets/wellScreenIcons/currency-usd-grey.png'
import CostsDefaultIconLight from 'assets/wellScreenIcons/currency-usd-black.png'
import CostsActiveIcon from 'assets/wellScreenIcons/currency-usd-blue.png'
import SlideSheetDefaultIcon from 'assets/wellScreenIcons/drilling-parameters-grey.png'
import SlideSheetDefaultIconLight from 'assets/wellScreenIcons/drilling-parameters-black.png'
import SlideSheetActiveIcon from 'assets/wellScreenIcons/drilling-parameters-blue.png'
import LogisticsDefaultIcon from 'assets/wellScreenIcons/logistics-flatbed-grey.png'
import LogisticsActiveIcon from 'assets/wellScreenIcons/logistics-flatbed-blue.png'
import AnalyticsDefaultIcon from 'assets/wellScreenIcons/analytics-ring-grey.png'
import AnalyticsActiveIcon from 'assets/wellScreenIcons/analytics-ring-blue.png'
import EdrDefaultIcon from 'assets/wellScreenIcons/edr-grey.png'
import EdrDefaultIconLight from 'assets/wellScreenIcons/edr-black.png'
import EdrActiveIcon from 'assets/wellScreenIcons/edr-blue.png'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { appColors } from 'utils'
import { checkFeature } from 'components/userPermissions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { Box } from '@mui/material'

const DRAWERWIDE = 160
const DRAWERSLIM = 60
const ICON_ACTIVE_COLOR = '#34c9eb'

const ActionBar = () => {
  const [drawerWidth, setDrawerWidth] = useRecoilState(actionBarWidthAtom)
  const [wellListOpen, setWellsListOpen] = useRecoilState(wellViewsOpenAtom)
  const { isAuthenticated } = useInnovaAuth()
  const [activePage, setActivePage] = useRecoilState(currentPageAtom)
  const userRole = useRecoilValue(userRoleSelector)
  const setAnalyticsPageIndex = useSetRecoilState(analyticsChartIndexAtom)
  const analyticsMapMounted = useRecoilValue(analyticsMapMountedAtom)
  const navigate = useNavigate()
  const { theme, getActionBarColor, getBackColor, getTextColor, getChartBackColor, getAgGridTheme } = useInnovaTheme()

  if (!isAuthenticated) return null

  const handleActionClick = (pageKey, route) => {
    setActivePage(pageKey)
    navigate(route)
  }

  const isWellViewActive = () => {
    const wellViewPages = [
      PAGE_KEYS.antiCollisionPageKey,
      PAGE_KEYS.wellDetailsKey,
      PAGE_KEYS.inventoryKey,
      PAGE_KEYS.surveyKey,
      PAGE_KEYS.slideSheetKey,
      PAGE_KEYS.costsHeadersKey,
      PAGE_KEYS.drillStringKey,
      PAGE_KEYS.ticketPageKey,
      PAGE_KEYS.pipeTallysPageKey,
      PAGE_KEYS.drillingDashboardKey,
      PAGE_KEYS.engineeringDashboardKey,
      PAGE_KEYS.wallplotComposerPageKey,
    ]
    return wellViewPages.includes(activePage)
  }

  const isAnalyticsActive = () => {
    const analyticsPages = [
      PAGE_KEYS.depthVDaysKey,
      PAGE_KEYS.slideRotateFootageKey,
      PAGE_KEYS.slideRotateFootageAltKey,
      PAGE_KEYS.slideRotateRopKey,
      PAGE_KEYS.slideRotateHoursKey,
      PAGE_KEYS.costPerWellKey,
      PAGE_KEYS.costPerDayKey,
      PAGE_KEYS.costPerOperatorKey,
      PAGE_KEYS.rigDaysKey,
      PAGE_KEYS.motorKpiKey,
      PAGE_KEYS.motorPerPhaseKpiKey,
      PAGE_KEYS.wellRankingKey,
      PAGE_KEYS.phaseKpiKey,
      PAGE_KEYS.fastestSectionKpiKey,
      PAGE_KEYS.longestSectionKpiKey,
      PAGE_KEYS.maxDrilledPerDayKpiKey,
      PAGE_KEYS.maxRopKpiKey,
      PAGE_KEYS.parameterComparisonKey,
      PAGE_KEYS.bhaResultsKpiKey,
      PAGE_KEYS.directionalScoreCardKpiKey,
      PAGE_KEYS.activitySummary,
    ]
    return analyticsPages.includes(activePage)
  }

  const ExpandContractListItem = () => {
    const tooltipTitle = drawerWidth === DRAWERWIDE ? 'collapse' : 'expand'
    const arrowIcon = drawerWidth === DRAWERWIDE ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />

    return (
      <ListItemButton
        onClick={() => setDrawerWidth((prevWidth) => (prevWidth === DRAWERWIDE ? DRAWERSLIM : DRAWERWIDE))}>
        <Tooltip
          title={tooltipTitle}
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <ListItemIcon
            sx={{
              color: getTextColor(),
              width: '35px',
              minWidth: '35px',
            }}>
            {arrowIcon}
          </ListItemIcon>
        </Tooltip>
      </ListItemButton>
    )
  }

  const ListItem = ({
    image,
    pageKey,
    height = 28,
    width = 28,
    text,
    route,
    onClick,
    isVisible = true,
    isNested = false,
  }) => {
    if (!isVisible) return null

    let isImage = false
    if (typeof image === 'string') {
      if (image.includes('data:image') || image.includes('/static/')) {
        isImage = true
      }
    }

    const iconComponent = isImage ? (
      <img alt={'img'} src={image} style={{ height: height, width: width }} />
    ) : (
      <Iconify
        icon={image}
        style={{
          color:
            activePage === pageKey
              ? appColors.itemTextColorMobile
              : theme === 'dark' || !isNested
              ? appColors.headerTextColor
              : '#000',
          height: height,
          width: width,
        }}
      />
    )

    let style = {
      paddingLeft: '5px',
    }

    return drawerWidth === DRAWERWIDE ? (
      <ListItemButton onClick={onClick ? onClick : () => handleActionClick(pageKey, route)} sx={style}>
        <ListItemIcon
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            color: getActionBarColor(),
            width: '35px',
            minWidth: '35px',
          }}>
          {iconComponent}
        </ListItemIcon>
        <ListItemText
          primary={text}
          sx={{
            '& .MuiTypography-root': {
              color: theme === 'dark' || !isNested ? appColors.headerTextColor : '#000',
              fontSize: '14px',
              fontFamily: 'Roboto',
              fontWeight: 'bold',
            },
          }}
        />
      </ListItemButton>
    ) : (
      <ListItemButton onClick={onClick ? onClick : () => handleActionClick(pageKey, route)} sx={style}>
        <Tooltip
          title={text}
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <ListItemIcon
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              color: getActionBarColor(),
              width: '35px',
              minWidth: '35px',
            }}>
            {iconComponent}
          </ListItemIcon>
        </Tooltip>
      </ListItemButton>
    )
  }

  const ANALYTICS_LIST_ITEMS = [
    {
      image: 'mdi:map-marker-radius',
      text: 'Well Search',
      pageKey: PAGE_KEYS.mapSearchKpiKey,
      onClick: () => {
        setAnalyticsPageIndex('map')
        setActivePage(PAGE_KEYS.mapSearchKpiKey)
      },
    },
    { isDivider: true },
    {
      image: 'ci:line-chart-down',
      text: 'Depth V Days',
      pageKey: PAGE_KEYS.depthVDaysKey,
      onClick: () => setAnalyticsPageIndex('depthVDays'),
    },
    { isDivider: true },
    {
      image: 'ant-design:bar-chart-outlined',
      text: 'Slide Rotate Footage',
      pageKey: PAGE_KEYS.slideRotateFootageKey,
      onClick: () => setAnalyticsPageIndex('slideRotateFootage'),
    },
    {
      image: 'carbon:chart-line-data',
      text: 'Slide Rotate ROP',
      pageKey: PAGE_KEYS.slideRotateRopKey,
      onClick: () => setAnalyticsPageIndex('slideRotateRop'),
    },
    {
      image: 'healthicons:chart-bar-stacked-outline',
      text: 'Slide Rotate Hours',
      pageKey: PAGE_KEYS.slideRotateHoursKey,
      onClick: () => setAnalyticsPageIndex('slideRotateHours'),
    },
    {
      image: 'tdesign:activity',
      text: 'Activity Summary',
      pageKey: PAGE_KEYS.activitySummary,
      onClick: () => setAnalyticsPageIndex('activitySummary'),
    },
    { isDivider: true },
    {
      image: 'streamline:money-cash-bag-dollar-bag-payment-cash-money-finance',
      text: 'Cost Per Well',
      pageKey: PAGE_KEYS.costPerWellKey,
      onClick: () => setAnalyticsPageIndex('costPerWell'),
    },
    {
      image: 'mdi:finance',
      text: 'Cost Per Day',
      pageKey: PAGE_KEYS.costPerDayKey,
      onClick: () => setAnalyticsPageIndex('costPerDay'),
    },
    {
      image: 'streamline:money-bank-institution-money-saving-bank-payment-finance',
      text: 'Cost Per Operator',
      pageKey: PAGE_KEYS.costPerOperatorKey,
      onClick: () => setAnalyticsPageIndex('costPerOperator'),
    },
    {
      image: 'raphael:dollar',
      text: 'Cost Per Ft',
      pageKey: PAGE_KEYS.costPerFootKey,
      onClick: () => setAnalyticsPageIndex('costPerFt'),
    },
    { isDivider: true },
    {
      image: 'carbon:chart-multi-line',
      text: 'Parameter comparison',
      pageKey: PAGE_KEYS.parameterComparisonKey,
      onClick: () => setAnalyticsPageIndex('paramComparison'),
    },
    {
      image: 'lucide:bar-chart-horizontal',
      text: 'Motor Yield',
      pageKey: PAGE_KEYS.motorYieldKey,
      onClick: () => setAnalyticsPageIndex('motorYield'),
    },
    {
      image: 'fa-solid:road',
      text: 'Parameter roadmap',
      pageKey: PAGE_KEYS.parametersRoadmapKey,
      onClick: () => setAnalyticsPageIndex('parametersRoadmap'),
    },
    { isDivider: true },
    {
      image: 'mdi:pipe-disconnected',
      text: 'Well Connection Analysis',
      pageKey: PAGE_KEYS.connectionAnalysisKey,
      onClick: () => setAnalyticsPageIndex('connectionAnalysisWell'),
    },
    {
      image: 'mdi:pipe-disconnected',
      text: 'Rig Connection Analysis',
      pageKey: PAGE_KEYS.connectionAnalysisRigKey,
      onClick: () => setAnalyticsPageIndex('connectionAnalysisRig'),
    },
    { isDivider: true },
    {
      image: 'ant-design:pie-chart-outlined',
      text: 'BHA Results',
      pageKey: PAGE_KEYS.bhaResultsKpiKey,
      onClick: () => setAnalyticsPageIndex('bhaResults'),
    },
    { isDivider: true },
    {
      image: 'grommet-icons:scorecard',
      text: 'Dir. Scorecard',
      pageKey: PAGE_KEYS.directionalScoreCardKpiKey,
      onClick: () => setAnalyticsPageIndex('directionalScoreCard'),
    },
    { isDivider: true },
    {
      image: 'game-icons:oil-rig',
      text: 'Rig Days',
      pageKey: PAGE_KEYS.rigDaysKey,
      onClick: () => setAnalyticsPageIndex('rigDays'),
    },
    {
      image: 'solar:ranking-outline',
      text: 'Well Ranking',
      pageKey: PAGE_KEYS.wellRankingKey,
      onClick: () => setAnalyticsPageIndex('wellRanking'),
    },
    { isDivider: true },
    {
      image: 'game-icons:drill',
      text: 'Motor KPI',
      pageKey: PAGE_KEYS.motorKpiKey,
      onClick: () => setAnalyticsPageIndex('motorKpi'),
    },
    {
      image: 'grommet-icons:cluster',
      text: 'BHA Grouped by Phase',
      pageKey: PAGE_KEYS.motorPerPhaseKpiKey,
      onClick: () => setAnalyticsPageIndex('motorPerPhaseKpi'),
    },
    {
      image: 'material-symbols:insert-chart-outline-sharp',
      text: 'Phase KPI',
      pageKey: PAGE_KEYS.phaseKpiKey,
      onClick: () => setAnalyticsPageIndex('phaseKpi'),
    },
    {
      image: 'fa6-solid:oil-well',
      text: 'Well KPI',
      pageKey: PAGE_KEYS.wellKpiKey,
      onClick: () => setAnalyticsPageIndex('wellKpi'),
    },
    {
      image: 'game-icons:hole',
      text: 'Hole Size KPI',
      pageKey: PAGE_KEYS.holeSizeKpiKey,
      onClick: () => setAnalyticsPageIndex('holeSizeKpi'),
    },
    {
      image: 'mdi:calendar-today-outline',
      text: 'Days KPI',
      pageKey: PAGE_KEYS.daysKpiKey,
      onClick: () => setAnalyticsPageIndex('daysKpi'),
    },
    {
      image: 'octicon:log-16',
      text: 'Activity KPI',
      pageKey: PAGE_KEYS.activityKpiKey,
      onClick: () => setAnalyticsPageIndex('activityKpi'),
    },
    {
      image: 'ic:outline-inventory',
      text: 'Inventory KPI',
      pageKey: PAGE_KEYS.inventoryKpiKey,
      onClick: () => setAnalyticsPageIndex('inventoryKpi'),
    },
    {
      image: 'game-icons:oil-rig',
      text: 'Tower Comparison KPI',
      pageKey: PAGE_KEYS.towerComparisonKpiKey,
      onClick: () => setAnalyticsPageIndex('towerComparisonKpi'),
    },
    { isDivider: true },
    {
      image: 'material-symbols:fast-forward-rounded',
      text: 'Fastest Well',
      pageKey: PAGE_KEYS.fastestSectionKpiKey,
      onClick: () => setAnalyticsPageIndex('fastestSectionKpi'),
    },
    {
      image: 'tabler:ruler-measure',
      text: 'Longest Well',
      pageKey: PAGE_KEYS.longestSectionKpiKey,
      onClick: () => setAnalyticsPageIndex('longestSectionKpi'),
    },
    {
      image: 'mdi:hours-24',
      text: 'Max drilled per day',
      pageKey: PAGE_KEYS.maxDrilledPerDayKpiKey,
      onClick: () => setAnalyticsPageIndex('maxDrilledPerDayKpi'),
    },
    {
      image: 'ic:baseline-speed',
      text: 'Max ROP',
      pageKey: PAGE_KEYS.maxRopKpiKey,
      onClick: () => setAnalyticsPageIndex('maxRopKpi'),
    },
    { isDivider: true },
    {
      image: 'game-icons:needle-drill',
      text: 'Footage / ROP By Motor Make',
      pageKey: PAGE_KEYS.footageRopByMotorPageKey,
      onClick: () => setAnalyticsPageIndex('footageRopByMotor'),
    },
    {
      image: 'game-icons:trefoil-shuriken',
      text: 'Footage / ROP By Bit',
      pageKey: PAGE_KEYS.footageRopByBitPageKey,
      onClick: () => setAnalyticsPageIndex('footageRopByBit'),
    },
    {
      image: 'carbon:soil-moisture',
      text: 'Footage / ROP By Formation',
      pageKey: PAGE_KEYS.footageRopByFormationPageKey,
      onClick: () => setAnalyticsPageIndex('footageRopByFormation'),
    },
    {
      image: 'game-icons:oil-rig',
      text: 'Footage / ROP By Rig',
      pageKey: PAGE_KEYS.footageRopByRigPageKey,
      onClick: () => setAnalyticsPageIndex('footageRopByRig'),
    },
    {
      image: 'ion:business',
      text: 'Footage / ROP By Directional Co.',
      pageKey: PAGE_KEYS.footageRopByDirCoPageKey,
      onClick: () => setAnalyticsPageIndex('footageRopByDirCo'),
    },
    {
      image: 'carbon:drill-through',
      text: 'Footage / ROP By HoleSize',
      pageKey: PAGE_KEYS.footageRopByHoleSizePageKey,
      onClick: () => setAnalyticsPageIndex('footageRopByHoleSize'),
    },
  ]

  const AnalyticsList = () => {
    if (!checkFeature(2, userRole?.roleAttributes?.featureId)) return null

    return (
      <Collapse in={analyticsMapMounted} timeout='auto' unmountOnExit>
        <List
          sx={{
            border: '1px solid',
            borderColor: 'rgb(56, 219, 255)',
            backgroundColor: getBackColor(),
          }}>
          {ANALYTICS_LIST_ITEMS.map((item, index) =>
            item?.isDivider ? (
              <Divider key={index} variant='middle' color={appColors.headerTextColor} />
            ) : (
              <ListItem
                key={index}
                image={item.image}
                text={item.text}
                pageKey={item.pageKey}
                onClick={item.onClick}
                isNested={true}
              />
            ),
          )}
        </List>
      </Collapse>
    )
  }

  return (
    <Drawer
      variant='permanent'
      sx={{
        '& .MuiDrawer-paper': {
          zIndex: (theme) => theme.zIndex.drawer - 1,
          backgroundColor: getActionBarColor(),
          width: drawerWidth,
          '&::-webkit-scrollbar': {
            width: '10px',
            backgroundColor: getChartBackColor(),
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3) !important',
            WebkitBoxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3) !important',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme === 'dark' ? '#133f60' : '#A0A0A0',
          },
        },
        className: getAgGridTheme(),
      }}>
      <Box sx={{height: '100%', overflowY: 'auto' , overflowX: 'hidden' }} className={getAgGridTheme()}>
        <Toolbar />
        <List>
          <ExpandContractListItem />
          <ListItem
            image={activePage === PAGE_KEYS.wellListKey ? WellListActiveIcon : WellListDefaultIcon}
            text={'Well List'}
            onClick={() => handleActionClick(PAGE_KEYS.wellListKey, '/well-list')}
          />
          <ListItem
            image={'clarity:tree-view-solid'}
            text={'Object Explorer'}
            pageKey={PAGE_KEYS.dbTreePageKey}
            route={'/databaseTree'}
            isVisible={checkFeature(26, userRole?.roleAttributes?.featureId)}
          />
          <ListItem
            image={isWellViewActive() ? WellViewActiveIcon : WellViewDefaultIcon}
            text={'Well Views'}
            onClick={() => setWellsListOpen(!wellListOpen)}
          />
          <Collapse in={wellListOpen} timeout='auto' unmountOnExit>
            <List
              sx={{
                border: '1px solid',
                borderColor: ICON_ACTIVE_COLOR,
                backgroundColor: theme === 'dark' ? 'rgba(8,8,8)' : '#FFF',
              }}>
              <ListItem
                image={
                  activePage === PAGE_KEYS.wellDetailsKey
                    ? WellDetailsActiveIcon
                    : theme === 'dark'
                    ? WellDetailsDefaultIcon
                    : WellDetailsDefaultIconLight
                }
                text={'Well Details'}
                pageKey={PAGE_KEYS.wellDetailsKey}
                route={'/well-pages/well-page'}
                isNested={true}
              />
              <ListItem
                image={
                  activePage === PAGE_KEYS.edrPageKey
                    ? EdrActiveIcon
                    : theme === 'dark'
                    ? EdrDefaultIcon
                    : EdrDefaultIconLight
                }
                text={'EDR'}
                pageKey={PAGE_KEYS.edrPageKey}
                route={'/edr'}
                isNested={true}
                isVisible={checkFeature(8, userRole?.roleAttributes?.featureId)}
              />
              <ListItem
                image={'solar:target-linear'}
                text={'Anti Collision'}
                pageKey={PAGE_KEYS.antiCollisionPageKey}
                route={'/well-pages/anticollision'}
                isNested={true}
                isVisible={checkFeature(3, userRole?.roleAttributes?.featureId)}
              />
              <ListItem
                image={
                  activePage === PAGE_KEYS.dailyReportKey
                    ? DailyActivityActiveIcon
                    : theme === 'dark'
                    ? DailyActivityDefaultIcon
                    : DailyActivityDefaultIconLight
                }
                text={'Daily Reports'}
                pageKey={PAGE_KEYS.dailyReportKey}
                route={'/well-pages/daily-report'}
                isNested={true}
                isVisible={checkFeature(35, userRole?.roleAttributes?.featureId)}
              />
              <ListItem
                image={
                  activePage === PAGE_KEYS.inventoryKey
                    ? ToolInventoryActiveIcon
                    : theme === 'dark'
                    ? ToolInventoryDefaultIcon
                    : ToolInventoryDefaultIconLight
                }
                text={'Inventory'}
                pageKey={PAGE_KEYS.inventoryKey}
                route={'/well-pages/inventory'}
                isNested={true}
                isVisible={checkFeature(41, userRole?.roleAttributes?.featureId)}
              />
              <ListItem
                image={
                  activePage === PAGE_KEYS.surveyKey
                    ? SurveysActiveIcon
                    : theme === 'dark'
                    ? SurveysDefaultIcon
                    : SurveysDefaultIconLight
                }
                text={'Surveys'}
                pageKey={PAGE_KEYS.surveyKey}
                route={'/well-pages/surveys'}
                isNested={true}
                isVisible={checkFeature(34, userRole?.roleAttributes?.featureId)}
              />
              <ListItem
                image={
                  activePage === PAGE_KEYS.slideSheetKey
                    ? SlideSheetActiveIcon
                    : theme === 'dark'
                    ? SlideSheetDefaultIcon
                    : SlideSheetDefaultIconLight
                }
                text={'Slide Sheets'}
                pageKey={PAGE_KEYS.slideSheetKey}
                route={'/well-pages/slide-sheets'}
                isNested={true}
                isVisible={checkFeature(40, userRole?.roleAttributes?.featureId)}
              />
              <ListItem
                image={
                  activePage === PAGE_KEYS.costsHeadersKey
                    ? CostsActiveIcon
                    : theme === 'dark'
                    ? CostsDefaultIcon
                    : CostsDefaultIconLight
                }
                text={'Costs'}
                pageKey={PAGE_KEYS.costsHeadersKey}
                route={'/well-pages/costs/dailyCostHeaders'}
                isNested={true}
                width={20}
                height={28}
                isVisible={checkFeature(36, userRole?.roleAttributes?.featureId)}
              />
              <ListItem
                image={
                  activePage === PAGE_KEYS.drillStringKey
                    ? DrillStringActiveIcon
                    : theme === 'dark'
                    ? DrillStringDefaultIcon
                    : DrillStringDefaultIconLight
                }
                text={'Drill String'}
                pageKey={PAGE_KEYS.drillStringKey}
                route={'/well-pages/drill-string'}
                isNested={true}
                width={24}
                height={28}
                isVisible={checkFeature(37, userRole?.roleAttributes?.featureId)}
              />
              <ListItem
                image={'gridicons:shipping'}
                text={'Shipping Tickets'}
                pageKey={PAGE_KEYS.ticketPageKey}
                route={'/well-pages/tickets'}
                isNested={true}
                isVisible={checkFeature(38, userRole?.roleAttributes?.featureId)}
              />
              <ListItem
                image={'fluent:pipeline-20-regular'}
                text={'Pipe Tallys'}
                pageKey={PAGE_KEYS.pipeTallysPageKey}
                route={'/well-pages/pipeTallys'}
                isNested={true}
                isVisible={checkFeature(39, userRole?.roleAttributes?.featureId)}
              />
              <ListItem
                image={'emojione-monotone:hammer-and-wrench'}
                text={'Engineering Dashboard'}
                pageKey={PAGE_KEYS.engineeringDashboardKey}
                route={'/well-pages/engineering-dashboard'}
                isNested={true}
                isVisible={
                  checkFeature(9, userRole?.roleAttributes?.featureId) ||
                  checkFeature(10, userRole?.roleAttributes?.featureId) ||
                  checkFeature(32, userRole?.roleAttributes?.featureId)
                }
              />
              <ListItem
                image={'fa-solid:road'}
                text={'Parameter Roadmap'}
                pageKey={PAGE_KEYS.wellParametersRoadmapKey}
                route={'/well-pages/parameterRoadmap'}
                isNested={true}
                isVisible={checkFeature(43, userRole?.roleAttributes?.featureId)}
              />
              <ListItem
                image={'mdi:pipe-disconnected'}
                text={'Connection Analysis'}
                pageKey={PAGE_KEYS.wellConnectionAnalysisKey}
                route={'/well-pages/connectionAnalysis'}
                isNested={true}
                isVisible={checkFeature(2, userRole?.roleAttributes?.featureId)}
              />
              <ListItem
                image={'mdi:land-plots'}
                text={'Wallplot Composer (BETA)'}
                pageKey={PAGE_KEYS.wallplotComposerPageKey}
                route={'/well-pages/wallplotComposer'}
                isNested={true}
                isVisible={checkFeature(50, userRole?.roleAttributes?.featureId)}
              />
            </List>
          </Collapse>
          <ListItem
            image={'ic:outline-dashboard-customize'}
            text={'Multiwell Dashboard'}
            pageKey={PAGE_KEYS.multiWellDashboardPageKey}
            route={'/multiwell-dashboard'}
            isVisible={checkFeature(4, userRole?.roleAttributes?.featureId)}
          />
          <ListItem
            image={'solar:target-linear'}
            text={'Anti-Collision Dashboard'}
            pageKey={PAGE_KEYS.antiCollisionDashboardPageKey}
            route={'/anti-collision-dashboard'}
            isVisible={checkFeature(3, userRole?.roleAttributes?.featureId)}
          />
          <ListItem
            image={activePage === PAGE_KEYS.logisticsKey ? LogisticsActiveIcon : LogisticsDefaultIcon}
            text={'Logistics'}
            pageKey={PAGE_KEYS.antiCollisionDashboardPageKey}
            route={'/logistics'}
            height={20}
            isVisible={checkFeature(42, userRole?.roleAttributes?.featureId)}
          />
          <ListItem
            image={isAnalyticsActive() ? AnalyticsActiveIcon : AnalyticsDefaultIcon}
            text={'Analytics'}
            pageKey={PAGE_KEYS.mapSearchKpiKey}
            onClick={() => {
              if (!analyticsMapMounted) handleActionClick(PAGE_KEYS.mapSearchKpiKey, '/analytics/analyticsPage')
            }}
            isVisible={checkFeature(2, userRole?.roleAttributes?.featureId)}
          />
          <AnalyticsList />
        </List>
      </Box>
    </Drawer>
  )
}

export default ActionBar
