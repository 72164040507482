import { useEffect, useState, useRef } from 'react'
import { OktaAuth } from '@okta/okta-auth-js'
import { getItemFromLS, saveItemToLS } from 'utils/localStorage'

function useOkta() {
  const _isMounted = useRef(true)
  const oktaAuth = useRef(null)
  const [loginAttempts, setLoginAttempts] = useState(0)
  const [config, setConfig] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    initializeAuthProvider()
  }, [config]) // eslint-disable-line react-hooks/exhaustive-deps

  function handleFailedLogin() {
    setLoginAttempts(loginAttempts + 1)
  }

  async function initializeAuthProvider() {
    oktaAuth.current = null
    if (!config) return

    if (!_isMounted.current) {
      handleFailedLogin()
      return
    }

    setIsLoading(true)
    if (!config) {
      handleFailedLogin()
      return
    }

    if (!config.hasOwnProperty('auth_type')) {
      handleFailedLogin()
      return
    }

    if (!config.hasOwnProperty('client_id')) {
      handleFailedLogin()
      return
    }

    if (!config.hasOwnProperty('client_secret')) {
      handleFailedLogin()
      return
    }

    if (!config.hasOwnProperty('tenant_id')) {
      handleFailedLogin()
      return
    }

    if (!config.hasOwnProperty('role')) {
      handleFailedLogin()
      return
    }

    let oktaConfig = {
      issuer: `https://${config.tenant_id}/oauth2/default`,
      clientId: config.client_id,
      clientSecret: config.client_secret,
      redirectUri: `${window.location.origin}/login/callback`,
      scopes: ['openid'],
      pkce: false,
    }

    oktaAuth.current = new OktaAuth(oktaConfig)

    try {
      let loginResponse = await oktaAuth.current.token.getWithPopup({ prompt: 'login', loginHint: config.user_name })

      if (loginResponse && loginResponse.hasOwnProperty('tokens')) {
        if (!loginResponse.tokens.hasOwnProperty('accessToken')) return
        if (typeof loginResponse.tokens.accessToken.accessToken !== 'string') return
        if (loginResponse.tokens.accessToken.accessToken === '') return
        loginResponse.tokens.role = config.role
        updateAuthData(loginResponse.tokens, config.user_name)
        setIsAuthenticated(true)
      }
    } catch (err) {
      console.error(err)
    }

    setLoginAttempts(loginAttempts + 1)
    setIsLoading(false)
  }

  function updateAuthData(data, userName) {
    if (!data) return
    if (!data.accessToken.hasOwnProperty('accessToken')) return
    if (typeof userName !== 'string') return
    if (!userName) return

    let currentAuth = getItemFromLS('auth', 'currentAuth')
    currentAuth.user.name = userName
    currentAuth.access_token = data.accessToken.accessToken
    currentAuth.refresh_token = '-1'
    currentAuth.issueTime = new Date(Date.now()).getTime()
    currentAuth.expires_at = data.accessToken.expiresAt * 1000
    currentAuth.expires_in = currentAuth.expires_at - currentAuth.issueTime
    currentAuth.role = data.role
    saveItemToLS('auth', 'currentAuth', currentAuth)
  }

  return { setConfig, isLoading, isAuthenticated, loginAttempts }
}

export default useOkta
