import { useEffect, useState, useRef, useCallback } from 'react'

import useAxiosGzip from '../useAxiosGzip'
import { useRecoilValue } from 'recoil'
import { currentWellAtom, selectedEngineeringCaseAtom, userUserRoleAtom } from 'atoms'
import { normalize } from 'utils/numberFunctions.js'
import { lerpColor } from 'utils/colorFunctions'
import { appColors } from 'utils'
import html2canvas from 'html2canvas'
import PdfDocument from 'components/common/PDFGen/PdfDocument'
import { removeSpecialSymbols, replaceEscapeSymbols, numberWithCommasDecimals } from 'utils/stringFunctions'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import useInnovaAxios from '../useInnovaAxios'
import { multiParse } from 'utils'
import { Buffer } from 'buffer'
import { getUserNameFromEmail } from 'utils/chatFunctions'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import useBhaImages from '../useBhaImages'
import { getItemFromLS, saveItemToLS } from 'utils/localStorage'

function useTorqueAndDrag() {
  const _isMounted = useRef(true)
  const tndData = useRef({})
  const isLoading = useRef(false)
  const [loading, setLoading] = useState(false)
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const currentWellRef = useRef(currentWell)
  const selectedEngCase = useRecoilValue(selectedEngineeringCaseAtom)
  const { getUnitsText } = useUnits()
  const userRole = useRecoilValue(userUserRoleAtom)
  const [logos, setLogos] = useState([])
  const { user } = useInnovaAuth()
  const bhaImages = useBhaImages()

  const getData = useAxiosGzip({
    url: '/engineering/getTorqueAndDragGz',
  })

  const getOperatorLogos = useInnovaAxios({
    url: '/operator/getLogos',
  })

  useEffect(() => {
    _isMounted.current = true
    saveItemToLS('weightUnits', 'weightUnits', getUnitsText(UNITS_FOR.Weight))

    return () => {
      _isMounted.current = false
    }
  }, [getUnitsText])

  useEffect(() => {
    currentWellRef.current = currentWell
  }, [currentWell])

  function isDateLessThan(date1, date2) {
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()

    return time1 < time2
  }

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [])

  const fetchTnd = async () => {
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    if (!selectedEngCase) return
    if (!selectedEngCase.hasOwnProperty('recordId')) return
    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)

    const response = await getData({ wellName: currentWellRef.current, engCase: selectedEngCase?.recordId })
    if (_isMounted.current) {
      setLoading(false)
      tndData.current = response?.data ? response.data : null
    }

    isLoading.current = false

    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)

    const responseLogos = await getOperatorLogos({ operator: tndData.current?.jobDetails?.operator })

    if (!_isMounted.current) return
    setLoading(false)
    isLoading.current = false

    if (responseLogos?.error) return
    if (!responseLogos?.data) return

    const mfpBoundary = responseLogos.data.substring(2, responseLogos.data.search('Content')).trim()
    let parts = multiParse(Buffer.from(responseLogos.data), mfpBoundary)
    let logoData = parts.map((part) => {
      let logoObj = { ...part }
      logoObj.data = 'data:image/*;base64,' + part.data
      return logoObj
    })
    setLogos(logoData)
  }

  const interpChartColor = (pallete, index, maxSeries) => {
    let bluePalette = ['#006cd1', '#00deff']
    let redPalette = ['#fa6e7a', '#fa000c']
    let greenPalette = ['#93fab4', '#8ee800']
    let cyanPalette = ['#49ecf5', '#226f73']
    let purplePalette = ['#e300b2', '#bc6bdb']
    let yellowPalette = ['#fffb05', '#ffa505']

    let startColor = bluePalette[0]
    let endColor = bluePalette[1]
    if (pallete === 'green') {
      startColor = greenPalette[0]
      endColor = greenPalette[1]
    }

    if (pallete === 'red') {
      startColor = redPalette[0]
      endColor = redPalette[1]
    }

    if (pallete === 'cyan') {
      startColor = cyanPalette[0]
      endColor = cyanPalette[1]
    }

    if (pallete === 'purple') {
      startColor = purplePalette[0]
      endColor = purplePalette[1]
    }

    if (pallete === 'yellow') {
      startColor = yellowPalette[0]
      endColor = yellowPalette[1]
    }

    let ratio = normalize(index, 0, maxSeries)
    return lerpColor(startColor, endColor, ratio)
  }

  const getSeriesValues = (data) => {
    if (!Array.isArray(data)) return []
    let output = []
    data.forEach((pt) => {
      output.push({
        x: pt.hasOwnProperty('data') ? pt.data : 0,
        y: pt.depth,
      })
    })

    return output
  }

  const getGeometryAnnotations = () => {
    if (!tndData.current) return null
    if (!tndData.current.hasOwnProperty('geometry')) return null
    if (!Array.isArray(tndData.current.geometry?.geometry)) return null
    if (!tndData.current.hasOwnProperty('drillersOutput')) return null
    if (!tndData.current.drillersOutput.hasOwnProperty('tripInWeight')) return null
    if (!Array.isArray(tndData.current.drillersOutput.tripInWeight)) return null

    let maxDepth = 0
    if (tndData.current.drillersOutput.tripInWeight > 0) {
      let curSeries = tndData.current.drillersOutput.tripInWeight[0]
      if (Array.isArray(curSeries.data)) {
        maxDepth = curSeries.data[curSeries.data.length - 1].depth
      }
    }

    const annotations = {}
    for (let i = 0; i < tndData.current.geometry?.geometry.length; i++) {
      if (tndData.current.geometry?.geometry[i].md > maxDepth) continue

      annotations[`geometry` + (i + 1)] = {
        type: 'line',
        value: tndData.current.geometry?.geometry[i].md,
        scaleID: 'y',
        borderColor: appColors.itemTextColor,
        borderDash: [10, 4],
        borderDashOffset: 5,
        borderWidth: 1,
        lineTension: 0.8,
        label: {
          content: `${tndData.current.geometry?.geometry[i].od} ${tndData.current.geometry?.geometry[i].compType}`,
          enabled: true,
          backgroundColor: 'transparent',
          position: 'start',
          color: appColors.itemTextColor,
          font: { weight: 'normal' },
          yAdjust: -5,
          display: true,
        },
      }
    }
    return annotations
  }

  const getDrillersHookloadsData = (shadeBuckling) => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!tndData.current) return chartData
    if (!tndData.current.hasOwnProperty('drillersOutput')) return chartData
    if (!tndData.current.drillersOutput.hasOwnProperty('tripInWeight')) return chartData
    if (!tndData.current.drillersOutput.hasOwnProperty('tripOutWeight')) return chartData
    if (!tndData.current.drillersOutput.hasOwnProperty('rotatingOffBottomWeight')) return chartData
    if (!tndData.current.drillersOutput.hasOwnProperty('minBuckling')) return chartData
    if (!Array.isArray(tndData.current.drillersOutput.tripInWeight)) return chartData
    if (!Array.isArray(tndData.current.drillersOutput.tripOutWeight)) return chartData
    if (!Array.isArray(tndData.current.drillersOutput.rotatingOffBottomWeight)) return chartData
    if (!Array.isArray(tndData.current.drillersOutput.minBuckling)) return chartData
    if (tndData.current.drillersOutput.tripInWeight.length === 0) return chartData
    if (tndData.current.drillersOutput.tripOutWeight.length === 0) return chartData

    for (let i = 0; i < tndData.current.drillersOutput.tripOutWeight.length; i++) {
      let curSeries = tndData.current.drillersOutput.tripOutWeight[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('blue', i, tndData.current.drillersOutput.tripOutWeight.length),
        backgroundColor: interpChartColor('blue', i, tndData.current.drillersOutput.tripOutWeight.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
        borderDash: [i *2, i*2],
        borderDashOffset: 5,
        borderWidth: 2,
      })
    }

    for (let i = 0; i < tndData.current.drillersOutput.rotatingOffBottomWeight.length; i++) {
      let curSeries = tndData.current.drillersOutput.rotatingOffBottomWeight[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('green', i, tndData.current.drillersOutput.rotatingOffBottomWeight.length),
        backgroundColor: interpChartColor('green', i, tndData.current.drillersOutput.rotatingOffBottomWeight.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
      })
    }

    for (let i = 0; i < tndData.current.drillersOutput.tripInWeight.length; i++) {
      let curSeries = tndData.current.drillersOutput.tripInWeight[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, tndData.current.drillersOutput.tripInWeight.length),
        backgroundColor: interpChartColor('red', i, tndData.current.drillersOutput.tripInWeight.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
        borderDash: [i *2, i*2],
        borderDashOffset: 5,
        borderWidth: 2,
      })
    }

    for (let i = 0; i < tndData.current.drillersOutput.minBuckling.length; i++) {
      let curSeries = tndData.current.drillersOutput.minBuckling[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('purple', i, tndData.current.drillersOutput.minBuckling.length),
        backgroundColor: interpChartColor('purple', i, tndData.current.drillersOutput.minBuckling.length),
        data: getSeriesValues(curSeries.data),
        showShading: shadeBuckling,
        color: shadeBuckling ? 'rgb(255, 0, 0)' : null,
      })
    }

    return chartData
  }

  const getDrillersHookloadsDataArray = () => {
    const drillersHookloadsData = getDrillersHookloadsData(false)
    if (!drillersHookloadsData) return []
    if (!drillersHookloadsData.hasOwnProperty('datasets')) return []
    if (!Array.isArray(drillersHookloadsData.datasets)) return []
    if (drillersHookloadsData.datasets.length === 0) return []
    if (drillersHookloadsData.datasets.length < 3) return []

    const offset = drillersHookloadsData.datasets.length / 2

    let pUData = drillersHookloadsData.datasets.slice(0, offset - 1)
    let sOData = drillersHookloadsData.datasets.slice(offset, drillersHookloadsData.datasets.length - 1)
    let headerData = []
    headerData.push(drillersHookloadsData.datasets[offset - 1])
    headerData.push(drillersHookloadsData.datasets[drillersHookloadsData.datasets.length - 1])

    let drillerHookloadsArray = []

    let dataSets = []

    for (let i = 0; i < pUData.length; i++) {
      dataSets.push(pUData[i])
      dataSets.push(sOData[i])

      if (dataSets.length === 6 || i === pUData.length - 1) {
        dataSets.push(...headerData)
        drillerHookloadsArray.push(dataSets)
        dataSets = []
      }
    }

    return drillerHookloadsArray
  }

  const getDrillersTorquesDataArray = () => {
    const drillersTorquesData = getDrillersTorquesData()
    if (!drillersTorquesData) return []
    if (!drillersTorquesData.hasOwnProperty('datasets')) return []
    if (!Array.isArray(drillersTorquesData.datasets)) return []
    if (drillersTorquesData.datasets.length === 0) return []
    if (drillersTorquesData.datasets.length < 2) return []

    const offset = drillersTorquesData.datasets.length / 2

    let offBtmTq = drillersTorquesData.datasets.slice(0, offset)
    let onBtmTq = drillersTorquesData.datasets.slice(offset, drillersTorquesData.datasets.length)

    let drillerTorquesArray = []

    let dataSets = []

    for (let i = 0; i < offBtmTq.length; i++) {
      dataSets.push(offBtmTq[i])
      dataSets.push(onBtmTq[i])

      if (dataSets.length === 6 || i === offBtmTq.length - 1) {
        drillerTorquesArray.push(dataSets)
        dataSets = []
      }
    }

    return drillerTorquesArray
  }

  const getDrillersTorquesData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!tndData.current) return chartData
    if (!tndData.current.hasOwnProperty('drillersOutput')) return chartData
    if (!tndData.current.drillersOutput.hasOwnProperty('offBottomTorque')) return chartData
    if (!tndData.current.drillersOutput.hasOwnProperty('onBottomTorque')) return chartData
    if (!Array.isArray(tndData.current.drillersOutput.offBottomTorque)) return chartData
    if (!Array.isArray(tndData.current.drillersOutput.onBottomTorque)) return chartData
    if (tndData.current.drillersOutput.offBottomTorque.length === 0) return chartData
    if (tndData.current.drillersOutput.onBottomTorque.length === 0) return chartData

    for (let i = 0; i < tndData.current.drillersOutput.offBottomTorque.length; i++) {
      let curSeries = tndData.current.drillersOutput.offBottomTorque[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('blue', i, tndData.current.drillersOutput.offBottomTorque.length),
        backgroundColor: interpChartColor('blue', i, tndData.current.drillersOutput.offBottomTorque.length),
        data: getSeriesValues(curSeries.data),
        borderDash: [i *2, i*2],
        borderDashOffset: 5,
        borderWidth: 2,
      })
    }

    for (let i = 0; i < tndData.current.drillersOutput.onBottomTorque.length; i++) {
      let curSeries = tndData.current.drillersOutput.onBottomTorque[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, tndData.current.drillersOutput.onBottomTorque.length),
        backgroundColor: interpChartColor('red', i, tndData.current.drillersOutput.onBottomTorque.length),
        data: getSeriesValues(curSeries.data),
        borderDash: [i *2, i*2],
        borderDashOffset: 5,
        borderWidth: 2,
      })
    }

    return chartData
  }

  const getTensionSnapshotData = (shadeBuckling) => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!tndData.current) return chartData
    if (!tndData.current.hasOwnProperty('snapShotOutput')) return chartData
    if (!tndData.current.snapShotOutput.hasOwnProperty('slidingTension')) return chartData
    if (!tndData.current.snapShotOutput.hasOwnProperty('rotatingOnBottomTension')) return chartData
    if (!tndData.current.snapShotOutput.hasOwnProperty('sinBuckling')) return chartData
    if (!tndData.current.snapShotOutput.hasOwnProperty('helicalBuckling')) return chartData
    if (!Array.isArray(tndData.current.snapShotOutput.slidingTension)) return chartData
    if (!Array.isArray(tndData.current.snapShotOutput.rotatingOnBottomTension)) return chartData
    if (!Array.isArray(tndData.current.snapShotOutput.sinBuckling)) return chartData
    if (!Array.isArray(tndData.current.snapShotOutput.helicalBuckling)) return chartData
    if (tndData.current.snapShotOutput.slidingTension.length === 0) return chartData
    if (tndData.current.snapShotOutput.rotatingOnBottomTension.length === 0) return chartData
    if (tndData.current.snapShotOutput.sinBuckling.length === 0) return chartData
    if (tndData.current.snapShotOutput.helicalBuckling.length === 0) return chartData

    for (let i = 0; i < tndData.current.snapShotOutput.rotatingOnBottomTension.length; i++) {
      let curSeries = tndData.current.snapShotOutput.rotatingOnBottomTension[i]

      chartData.datasets.push({
        label: 'Rot on Bottom',
        borderColor: interpChartColor('green', i, tndData.current.snapShotOutput.rotatingOnBottomTension.length),
        backgroundColor: interpChartColor('green', i, tndData.current.snapShotOutput.rotatingOnBottomTension.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
      })
    }

    for (let i = 0; i < tndData.current.snapShotOutput.slidingTension.length; i++) {
      let curSeries = tndData.current.snapShotOutput.slidingTension[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, tndData.current.snapShotOutput.slidingTension.length),
        backgroundColor: interpChartColor('red', i, tndData.current.snapShotOutput.slidingTension.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
        borderDash: [i *2, i*2],
        borderDashOffset: 5,
        borderWidth: 2,
      })
    }

    for (let i = 0; i < tndData.current.snapShotOutput.sinBuckling.length; i++) {
      let curSeries = tndData.current.snapShotOutput.sinBuckling[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('cyan', i, tndData.current.snapShotOutput.sinBuckling.length),
        backgroundColor: interpChartColor('cyan', i, tndData.current.snapShotOutput.sinBuckling.length),
        data: getSeriesValues(curSeries.data),
        showShading: shadeBuckling,
        color: shadeBuckling ? 'rgb(255, 255, 0)' : null,
      })
    }

    for (let i = 0; i < tndData.current.snapShotOutput.helicalBuckling.length; i++) {
      let curSeries = tndData.current.snapShotOutput.helicalBuckling[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('purple', i, tndData.current.snapShotOutput.helicalBuckling.length),
        backgroundColor: interpChartColor('purple', i, tndData.current.snapShotOutput.helicalBuckling.length),
        data: getSeriesValues(curSeries.data),
        showShading: shadeBuckling,
        color: shadeBuckling ? 'rgb(255, 0, 0)' : null,
        borderDash: [i *2, i*2],
        borderDashOffset: 5,
        borderWidth: 2,
      })
    }

    return chartData
  }

  const getTensionOffBtmSnapshotData = (shadeBuckling) => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!tndData.current) return chartData
    if (!tndData.current.hasOwnProperty('snapShotOutput')) return chartData
    if (!tndData.current.snapShotOutput.hasOwnProperty('tripInTension')) return chartData
    if (!tndData.current.snapShotOutput.hasOwnProperty('rotatingOffBottomTension')) return chartData
    if (!tndData.current.snapShotOutput.hasOwnProperty('tripOutTension')) return chartData
    if (!tndData.current.snapShotOutput.hasOwnProperty('sinBuckling')) return chartData
    if (!tndData.current.snapShotOutput.hasOwnProperty('helicalBuckling')) return chartData
    if (!Array.isArray(tndData.current.snapShotOutput.tripInTension)) return chartData
    if (!Array.isArray(tndData.current.snapShotOutput.rotatingOffBottomTension)) return chartData
    if (!Array.isArray(tndData.current.snapShotOutput.tripOutTension)) return chartData
    if (!Array.isArray(tndData.current.snapShotOutput.sinBuckling)) return chartData
    if (!Array.isArray(tndData.current.snapShotOutput.helicalBuckling)) return chartData
    if (tndData.current.snapShotOutput.tripInTension.length === 0) return chartData
    if (tndData.current.snapShotOutput.rotatingOffBottomTension.length === 0) return chartData
    if (tndData.current.snapShotOutput.tripOutTension.length === 0) return chartData
    if (tndData.current.snapShotOutput.sinBuckling.length === 0) return chartData
    if (tndData.current.snapShotOutput.helicalBuckling.length === 0) return chartData

    for (let i = 0; i < tndData.current.snapShotOutput.tripInTension.length; i++) {
      let curSeries = tndData.current.snapShotOutput.tripInTension[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, tndData.current.snapShotOutput.tripInTension.length),
        backgroundColor: interpChartColor('red', i, tndData.current.snapShotOutput.tripInTension.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
        borderDash: [i *2, i*2],
        borderDashOffset: 5,
        borderWidth: 2,
      })
    }

    for (let i = 0; i < tndData.current.snapShotOutput.rotatingOffBottomTension.length; i++) {
      let curSeries = tndData.current.snapShotOutput.rotatingOffBottomTension[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('green', i, tndData.current.snapShotOutput.rotatingOffBottomTension.length),
        backgroundColor: interpChartColor('green', i, tndData.current.snapShotOutput.rotatingOffBottomTension.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
      })
    }

    for (let i = 0; i < tndData.current.snapShotOutput.tripOutTension.length; i++) {
      let curSeries = tndData.current.snapShotOutput.tripOutTension[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('blue', i, tndData.current.snapShotOutput.tripOutTension.length),
        backgroundColor: interpChartColor('blue', i, tndData.current.snapShotOutput.tripOutTension.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
        borderDash: [i *2, i*2],
        borderDashOffset: 5,
        borderWidth: 2,
      })
    }

    for (let i = 0; i < tndData.current.snapShotOutput.sinBuckling.length; i++) {
      let curSeries = tndData.current.snapShotOutput.sinBuckling[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('cyan', i, tndData.current.snapShotOutput.sinBuckling.length),
        backgroundColor: interpChartColor('cyan', i, tndData.current.snapShotOutput.sinBuckling.length),
        data: getSeriesValues(curSeries.data),
        showShading: shadeBuckling,
        color: shadeBuckling ? 'rgb(255, 255, 0)' : null,
      })
    }

    for (let i = 0; i < tndData.current.snapShotOutput.helicalBuckling.length; i++) {
      let curSeries = tndData.current.snapShotOutput.helicalBuckling[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('purple', i, tndData.current.snapShotOutput.helicalBuckling.length),
        backgroundColor: interpChartColor('purple', i, tndData.current.snapShotOutput.helicalBuckling.length),
        data: getSeriesValues(curSeries.data),
        showShading: shadeBuckling,
        color: shadeBuckling ? 'rgb(255, 0, 0)' : null,
      })
    }

    return chartData
  }

  const getAppWobData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!tndData.current) return chartData
    if (!tndData.current.hasOwnProperty('drillersOutput')) return chartData
    if (!tndData.current.drillersOutput.hasOwnProperty('apparentWob')) return chartData
    if (!Array.isArray(tndData.current.drillersOutput.apparentWob)) return chartData
    if (tndData.current.drillersOutput.apparentWob.length === 0) return chartData

    for (let i = 0; i < tndData.current.drillersOutput.apparentWob.length; i++) {
      let curSeries = tndData.current.drillersOutput.apparentWob[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, tndData.current.drillersOutput.apparentWob.length),
        backgroundColor: interpChartColor('red', i, tndData.current.drillersOutput.apparentWob.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
        borderDash: [i *2, i*2],
        borderDashOffset: 5,
        borderWidth: 2,
      })
    }

    return chartData
  }

  const getWrapsData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!tndData.current) return chartData
    if (!tndData.current.hasOwnProperty('drillersOutput')) return chartData
    if (!tndData.current.drillersOutput.hasOwnProperty('wraps')) return chartData
    if (!Array.isArray(tndData.current.drillersOutput.wraps)) return chartData
    if (tndData.current.drillersOutput.wraps.length === 0) return chartData

    for (let i = 0; i < tndData.current.drillersOutput.wraps.length; i++) {
      let curSeries = tndData.current.drillersOutput.wraps[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, tndData.current.drillersOutput.wraps.length),
        backgroundColor: interpChartColor('red', i, tndData.current.drillersOutput.wraps.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
        borderDash: [i *2, i*2],
        borderDashOffset: 5,
        borderWidth: 2,
      })
    }

    return chartData
  }

  const getStretchData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!tndData.current) return chartData
    if (!tndData.current.hasOwnProperty('drillersOutput')) return chartData
    if (!tndData.current.drillersOutput.hasOwnProperty('stretch')) return chartData
    if (!Array.isArray(tndData.current.drillersOutput.stretch)) return chartData
    if (tndData.current.drillersOutput.stretch.length === 0) return chartData

    for (let i = 0; i < tndData.current.drillersOutput.stretch.length; i++) {
      let curSeries = tndData.current.drillersOutput.stretch[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, tndData.current.drillersOutput.stretch.length),
        backgroundColor: interpChartColor('red', i, tndData.current.drillersOutput.stretch.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
        borderDash: [i *2, i*2],
        borderDashOffset: 5,
        borderWidth: 2,
      })
    }

    return chartData
  }

  const getSideForceUnits = () => {
    if (!tndData.current) return ''
    if (!tndData.current.hasOwnProperty('params')) return ''
    let unitsText = `${getUnitsText(UNITS_FOR.Weight)}/${numberWithCommasDecimals(
      tndData.current.params.sfUnits,
      0,
    )}${getUnitsText(UNITS_FOR.Depth)}`
    return unitsText
  }

  const getWOBtext = () => {
    if (!tndData.current) return ''
    if (!tndData.current.hasOwnProperty('snapShotOutput')) return ''
    if (!tndData.current.snapShotOutput.hasOwnProperty('rotatingOnBottomTension')) return ''
    if (!Array.isArray(tndData.current.snapShotOutput.rotatingOnBottomTension)) return ''
    if (tndData.current.snapShotOutput.rotatingOnBottomTension.length === 0) return ''
    let unitsText = tndData.current.snapShotOutput.rotatingOnBottomTension[0].title
    unitsText = unitsText.replace('Rot On Bottom @ WOB ', '')
    return unitsText
  }

  const getSideForceData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!tndData.current) return chartData
    if (!tndData.current.hasOwnProperty('snapShotOutput')) return chartData
    if (!tndData.current.snapShotOutput.hasOwnProperty('sideForceTripIn')) return chartData
    if (!tndData.current.snapShotOutput.hasOwnProperty('sideForceRotating')) return chartData
    if (!tndData.current.snapShotOutput.hasOwnProperty('sideForceTripOut')) return chartData
    if (!Array.isArray(tndData.current.snapShotOutput.sideForceTripIn)) return chartData
    if (!Array.isArray(tndData.current.snapShotOutput.sideForceRotating)) return chartData
    if (!Array.isArray(tndData.current.snapShotOutput.sideForceTripOut)) return chartData
    if (tndData.current.snapShotOutput.sideForceTripIn.length === 0) return chartData
    if (tndData.current.snapShotOutput.sideForceRotating.length === 0) return chartData
    if (tndData.current.snapShotOutput.sideForceTripOut.length === 0) return chartData

    for (let i = 0; i < tndData.current.snapShotOutput.sideForceTripIn.length; i++) {
      let curSeries = tndData.current.snapShotOutput.sideForceTripIn[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, tndData.current.snapShotOutput.sideForceTripIn.length),
        backgroundColor: interpChartColor('red', i, tndData.current.snapShotOutput.sideForceTripIn.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
        borderDash: [i *2, i*2],
        borderDashOffset: 5,
        borderWidth: 2,
      })
    }

    for (let i = 0; i < tndData.current.snapShotOutput.sideForceRotating.length; i++) {
      let curSeries = tndData.current.snapShotOutput.sideForceRotating[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('green', i, tndData.current.snapShotOutput.sideForceRotating.length),
        backgroundColor: interpChartColor('green', i, tndData.current.snapShotOutput.sideForceRotating.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
      })
    }

    for (let i = 0; i < tndData.current.snapShotOutput.sideForceTripOut.length; i++) {
      let curSeries = tndData.current.snapShotOutput.sideForceTripOut[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('cyan', i, tndData.current.snapShotOutput.sideForceTripOut.length),
        backgroundColor: interpChartColor('cyan', i, tndData.current.snapShotOutput.sideForceTripOut.length),
        data: getSeriesValues(curSeries.data),
        borderDash: [i *2, i*2],
        borderDashOffset: 5,
        borderWidth: 2,
      })
    }

    return chartData
  }

  const getStabToBit = (bhaData, stabilizerData, index) => {
    let stabToBit = stabilizerData[index].stabDfb
    for (let i = 0; i < stabilizerData[index].sequenceNo; i++) {
      stabToBit += bhaData[i].length
    }
    return stabToBit
  }

  const getTndWarnings = () => {
    if (!tndData.current) return []
    if (!tndData.current.hasOwnProperty('warnings')) return []
    if (!Array.isArray(tndData.current.warnings)) return []
    if (tndData.current.warnings.length === 0) return []

    return tndData.current.warnings
  }

  const getTndErrors = () => {
    if (!tndData.current) return []
    if (!tndData.current.hasOwnProperty('errors')) return []
    if (!Array.isArray(tndData.current.errors)) return []
    if (tndData.current.errors.length === 0) return []

    return tndData.current.errors
  }

  const getLogo = (type) => {
    if (!Array.isArray(logos)) return null
    if (logos.length === 0) return null
    if (type === 'Primary') return logos[0].data
    if (type === 'Secondary' && logos.length > 1) return logos[1].data
    return null
  }

  const getLogoDimensions = (logo) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => resolve({ width: img.width, height: img.height })
      img.onerror = (err) => reject(err)
      img.src = logo
    })
  }

  const calculateDimensions = async (logo) => {
    if (!logo) return null

    try {
      const { width, height } = await getLogoDimensions(logo)
      return width > height ? 'contain' : 'cover'
    } catch (err) {
      console.error('Error calculating dimensions:', err)
      return null
    }
  }

  const getLogoFill = async (type) => {
    if (!Array.isArray(logos) || logos.length === 0) return null

    const logo = type === 'Primary' ? logos[0]?.data : logos[1]?.data
    if (!logo) return null

    const fillType = await calculateDimensions(logo)
    return fillType
  }

  const getReportDetails = () => {
    const reportDetails = getItemFromLS('engineeringReportDetails', 'engineeringReportDetails')
    if (!reportDetails) return null

    let reportName = 'Torque & Drag'

    if (!Array.isArray(reportDetails)) return null
    const reportDetail = reportDetails.find((detail) => detail.reportName === reportName)
    if (!reportDetail) return null
    if (!Array.isArray(reportDetail.reportDetails)) return null

    return reportDetail.reportDetails
  }

  const generatePdfDocument = async (
    slideSheetData,
    witsData,
    drillersHookloadsImage,
    calculatedFrictionFactorsImage,
    drillersTorquesImage,
    tensionSnapshotImage,
    tensionOffBtmSnapshotImage,
    sideForceImage,
    appWobImage,
    wrapsImage,
    stretchImage,
    imageHeight,
  ) => {
    if (!tndData.current) return
    let logoFill = await getLogoFill('Primary')
    let logoSecondaryFill = await getLogoFill('Secondary')

    let reportDetails = getReportDetails()

    let docData = [
      {
        tableType: 'header',
        showTitle: true,
        title: 'Torque And Drag Report',
        showLogo: true,
        logo: getLogo('Primary'),
        logoWidth: '25%',
        logoFill: logoFill,
        logoSecondary: getLogo('Secondary'),
        logoSecondaryWidth: '25%',
        logoSecondaryFill: logoSecondaryFill,
        fontSize: 6,
        sectionAfter: 5,
        manualWidth: true,
        columnWidths: ['50%', '50%'],
        data: [
          [
            { text: 'Well:', isHeader: true },
            { text: currentWellRef.current, textAlign: 'left' },
          ],
          [
            { text: 'Case:', isHeader: true },
            { text: unescapeHtml(selectedEngCase?.caseDesc.replace('&dq;', '"')), textAlign: 'left' },
          ],
        ],
      },
      {
        columnWidths: ['15%', '18%', '15%', '18%', '15%', '18%'],
        manualWidth: true,
        fontSize: 6,
        sectionAfter: 5,
        data: [
          [
            { text: 'Client:', isHeader: true },
            { text: tndData.current.jobDetails?.operator, textAlign: 'left' },
            { text: 'Field:', isHeader: true },
            { text: tndData.current.jobDetails?.field, textAlign: 'left' },
            { text: 'Well:', isHeader: true },
            { text: tndData.current.jobDetails?.wellName, textAlign: 'left' },
          ],
          [
            { text: 'Case:', isHeader: true },
            { text: unescapeHtml(selectedEngCase?.caseDesc.replace('&dq;', '"')), textAlign: 'left' },
            { text: 'Prepared By:', isHeader: true },
            { text: getUserNameFromEmail(user?.name), textAlign: 'left' },
            { text: 'Date:', isHeader: true },
            { text: dateTimeFormatter(new Date(Date.now()).toISOString()), textAlign: 'left' },
          ],
        ],
      },
      {
        columnWidths: ['15%', '18%', '15%', '18%', '15%', '18%'],
        manualWidth: true,
        fontSize: 6,
        sectionAfter: 5,
        data: addCalculationParameters(tndData.current.params, tndData.current.fluids, tndData.current.drillString),
      },
    ]

    if (tndData.current.params?.tortApply) {
      docData.push({
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['25%', '25%', '25%', '25%'],
        data: addTortuositySettings(tndData.current.params),
      })
    }

    docData.push(
      {
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['20%', '20%', '20%', '20%', '20%'],
        wrap: false,
        data: addWellboreGeometry(tndData.current.wellGeometry),
      },
      {
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['50%', '50%'],
        wrap: false,
        data: addStaticLosses(tndData.current.staticLosses),
      },
      {
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['4%', '4%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '12%'],
        wrap: false,
        data: addDrillStrings(tndData.current.drillStrings, tndData.current.params?.calcDepth),
      },
    )

    if (tndData.current?.params?.includeStabs) {
      docData.push({
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['16%', '14%', '14%', '14%', '14%', '14%', '14%'],
        wrap: false,
        data: addStabData(tndData.current.drillString),
      })
    }

    let breakSummary = true
    const drillersHookloadsReport = reportDetails.find((detail) => detail.reportType === 'Drillers Hookloads')
    if (drillersHookloadsReport && drillersHookloadsReport?.summaryData) {
      docData.push({
        breakHeader: breakSummary,
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['20%', '15%', '10%', '10%', '10%', '10%', '10%', '10%', '5%'],
        data: addTndSummary(tndData.current.drillersOutput, tndData.current.tndSummary, tndData.current.snapShotOutput),
      })
      breakSummary = false
    }

    const calculatedFrictionFactorsReport = reportDetails.find(
      (detail) => detail.reportType === 'Calculated Friction Factor',
    )
    if (calculatedFrictionFactorsReport && calculatedFrictionFactorsReport?.summaryData) {
      docData.push({
        breakHeader: breakSummary,
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['16%', '14%', '14%', '14%', '14%', '14%', '14%'],
        data: addFrictionFactorSummary(getCalculatedFrictionFactorData(slideSheetData, witsData)),
      })
      breakSummary = false
    }

    let images = getImagesForChart(
      drillersHookloadsImage,
      calculatedFrictionFactorsImage,
      drillersTorquesImage,
      tensionSnapshotImage,
      tensionOffBtmSnapshotImage,
      sideForceImage,
      appWobImage,
      wrapsImage,
      stretchImage,
      imageHeight,
    )

    for (let i = 0; i < images.length; i++) {
      docData.push(images[i])
    }

    if (drillersHookloadsReport && drillersHookloadsReport?.calculatedData) {
      const drillersHookloadsData = getDrillersHookloadsDataArray()
      for (let i = 0; i < drillersHookloadsData.length; i++) {
        if (drillersHookloadsData[i].length < 4) continue
        let columns = ['14%', '14%', '12%', '12%', '12%', '12%', '12%', '12%']
        if (drillersHookloadsData[i].length === 6) {
          columns = ['18%', '18%', '16%', '16%', '16%', '16%']
        }
        if (drillersHookloadsData[i].length === 4) {
          columns = ['25%', '25%', '25%', '25%']
        }

        docData.push({
          breakHeader: true,
          fontSize: 6,
          manualWidth: true,
          sectionAfter: 5,
          multiPage: true,
          multiPageRow: 3,
          columnWidths: columns,
          data: addDrillersHookloadResults(drillersHookloadsData[i]),
        })
      }
    }

    if (calculatedFrictionFactorsReport && calculatedFrictionFactorsReport?.calculatedData) {
      docData.push({
        breakHeader: true,
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        multiPage: true,
        multiPageRow: 3,
        columnWidths: ['16%', '14%', '14%', '14%', '14%', '14%', '14%'],
        data: addCalculatedFrictionFactors(getCalculatedFrictionFactorData(slideSheetData, witsData)),
      })
    }

    const drillersTorquesReport = reportDetails.find((detail) => detail.reportType === 'Driller Torques')
    if (drillersTorquesReport && drillersTorquesReport?.calculatedData) {
      const drillersTorquesData = getDrillersTorquesDataArray()
      for (let i = 0; i < drillersTorquesData.length; i++) {
        if (drillersTorquesData[i].length < 2) continue
        let columns = ['16%', '14%', '14%', '14%', '14%', '14%', '14%']
        if (drillersTorquesData[i].length === 4) {
          columns = ['20%', '20%', '20%', '20%', '20%']
        }
        if (drillersTorquesData[i].length === 2) {
          columns = ['34%', '33%', '33%']
        }

        docData.push({
          breakHeader: true,
          fontSize: 6,
          manualWidth: true,
          sectionAfter: 5,
          multiPage: true,
          multiPageRow: 3,
          columnWidths: columns,
          data: addDrillersTorqueResults(drillersTorquesData[i]),
        })
      }
    }

    const tensionSnapshotOnBottomReport = reportDetails.find(
      (detail) => detail.reportType === 'On Btm Tension Snapshot',
    )
    if (tensionSnapshotOnBottomReport && tensionSnapshotOnBottomReport?.calculatedData) {
      docData.push({
        breakHeader: true,
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        multiPage: true,
        multiPageRow: 2,
        columnWidths: ['10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%'],
        data: addTensionsSnapshotResults(
          'On',
          getTensionSnapshotData(),
          tndData.current.params,
          tndData.current.drillString,
        ),
      })
    }

    const tensionSnapshotOffBottomReport = reportDetails.find(
      (detail) => detail.reportType === 'Off Btm Tension Snapshot',
    )
    if (tensionSnapshotOffBottomReport && tensionSnapshotOffBottomReport?.calculatedData) {
      docData.push({
        breakHeader: true,
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        multiPage: true,
        multiPageRow: 2,
        columnWidths: ['10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%'],
        data: addTensionsSnapshotResults(
          'Off',
          getTensionOffBtmSnapshotData(),
          tndData.current.params,
          tndData.current.drillString,
        ),
      })
    }

    const sideForceReport = reportDetails.find((detail) => detail.reportType === 'Side Force')
    if (sideForceReport && sideForceReport?.calculatedData) {
      docData.push({
        breakHeader: true,
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        multiPage: true,
        multiPageRow: 3,
        columnWidths: ['10%', '9%', '9%', '9%', '9%', '9%', '9%', '9%', '9%', '9%', '9%'],
        data: addSideForceResults(getSideForceData(), tndData.current.params, tndData.current.drillString),
      })
    }

    const appWobReport = reportDetails.find((detail) => detail.reportType === 'Apparent WOB')
    if (appWobReport && appWobReport?.calculatedData) {
      docData.push({
        breakHeader: true,
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        multiPage: true,
        multiPageRow: 2,
        columnWidths: ['20%', '20%', '20%', '20%', '20%'],
        data: addAppWobResults(getAppWobData(), tndData.current.params),
      })
    }

    const pipeTwistReport = reportDetails.find((detail) => detail.reportType === 'Pipe Twist')
    if (pipeTwistReport && pipeTwistReport?.calculatedData) {
      docData.push({
        breakHeader: true,
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        multiPage: true,
        multiPageRow: 2,
        columnWidths: ['20%', '20%', '20%', '20%', '20%'],
        data: addPipeTwistResults(getWrapsData()),
      })
    }

    const pipeStretchReport = reportDetails.find((detail) => detail.reportType === 'Pipe Stretch')
    if (pipeStretchReport && pipeStretchReport?.calculatedData) {
      docData.push({
        breakHeader: true,
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        multiPage: true,
        multiPageRow: 2,
        columnWidths: ['20%', '20%', '20%', '20%', '20%'],
        data: addPipeStretchResults(getStretchData()),
      })
    }

    return docData
  }

  const addStaticLosses = (staticLosses) => {
    if (!staticLosses) return []
    if (!Array.isArray(staticLosses)) return []

    let data = []

    data.push(
      [{ text: 'Static Losses', columnSpan: 2 }],
      [
        { text: 'Name', isHeader: true },
        { text: `Pressure Loss (${getUnitsText(UNITS_FOR.Pressure)})`, isHeader: true },
      ],
    )

    for (let i = 0; i < staticLosses.length; i++) {
      data.push([
        { text: unescapeHtml(staticLosses[i].name) },
        { text: numberWithCommasDecimals(staticLosses[i].pressureLoss, 2) },
      ])
    }

    return data
  }

  const addPipeStretchResults = (stretchData) => {
    if (!stretchData) return []
    if (!stretchData.hasOwnProperty('datasets')) return []
    if (!Array.isArray(stretchData.datasets)) return []
    if (stretchData.datasets.length < 4) return []

    let labels = []

    for (let i = 0; i < stretchData.datasets.length; i++) {
      let label = stretchData.datasets[i].label.replace('Stretch PU', `Stretch (${getUnitsText(UNITS_FOR.Diameter)})`)
      if (!labels.includes(label)) {
        labels.push(label)
      }
    }

    if (labels.length < 4) return []

    let md = []

    for (let i = 0; i < stretchData.datasets.length; i++) {
      if (!stretchData.datasets[i].hasOwnProperty('data')) return []
      if (!Array.isArray(stretchData.datasets[i].data)) return []
      for (let j = 0; j < stretchData.datasets[i].data.length; j++) {
        if (!md.includes(stretchData.datasets[i].data[j].y)) {
          md.push(stretchData.datasets[i].data[j].y)
        }
      }
    }

    if (md.length === 0) return []
    md.sort((a, b) => a - b)

    let data = []

    data.push(
      [{ text: 'Pipe Stretch Results (Pick Up)', columnSpan: 5 }],
      [
        { text: `MD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `${labels[0]}`, isHeader: true },
        { text: `${labels[1]}`, isHeader: true },
        { text: `${labels[2]}`, isHeader: true },
        { text: `${labels[3]}`, isHeader: true },
      ],
    )

    let stretchIndex1 = stretchData.datasets.findIndex((data) => data.label === stretchData.datasets[0].label)
    if (stretchIndex1 === -1) return []
    let stretch1 = stretchData.datasets[stretchIndex1].data
    let stretchIndex2 = stretchData.datasets.findIndex((data) => data.label === stretchData.datasets[1].label)
    if (stretchIndex2 === -1) return []
    let stretch2 = stretchData.datasets[stretchIndex2].data
    let stretchIndex3 = stretchData.datasets.findIndex((data) => data.label === stretchData.datasets[2].label)
    if (stretchIndex3 === -1) return []
    let stretch3 = stretchData.datasets[stretchIndex3].data
    let stretchIndex4 = stretchData.datasets.findIndex((data) => data.label === stretchData.datasets[3].label)
    if (stretchIndex4 === -1) return []
    let stretch4 = stretchData.datasets[stretchIndex4].data

    for (let i = 0; i < md.length; i++) {
      let depth = md[i]
      let stretch1Value = stretch1.find((data) => data.y === depth)
      let stretch2Value = stretch2.find((data) => data.y === depth)
      let stretch3Value = stretch3.find((data) => data.y === depth)
      let stretch4Value = stretch4.find((data) => data.y === depth)

      data.push([
        { text: numberWithCommasDecimals(depth, 2) },
        { text: stretch1Value ? numberWithCommasDecimals(stretch1Value.x, 2) : '' },
        { text: stretch2Value ? numberWithCommasDecimals(stretch2Value.x, 2) : '' },
        { text: stretch3Value ? numberWithCommasDecimals(stretch3Value.x, 2) : '' },
        { text: stretch4Value ? numberWithCommasDecimals(stretch4Value.x, 2) : '' },
      ])
    }

    return data
  }

  const addPipeTwistResults = (wrapsData) => {
    if (!wrapsData) return []
    if (!wrapsData.hasOwnProperty('datasets')) return []
    if (!Array.isArray(wrapsData.datasets)) return []
    if (wrapsData.datasets.length < 4) return []

    let labels = []

    for (let i = 0; i < wrapsData.datasets.length; i++) {
      let label = wrapsData.datasets[i].label.replace('(Off Btm))', '')
      if (!labels.includes(label)) {
        labels.push(label)
      }
    }

    if (labels.length < 4) return []

    let md = []

    for (let i = 0; i < wrapsData.datasets.length; i++) {
      if (!wrapsData.datasets[i].hasOwnProperty('data')) return []
      if (!Array.isArray(wrapsData.datasets[i].data)) return []
      for (let j = 0; j < wrapsData.datasets[i].data.length; j++) {
        if (!md.includes(wrapsData.datasets[i].data[j].y)) {
          md.push(wrapsData.datasets[i].data[j].y)
        }
      }
    }

    if (md.length === 0) return []
    md.sort((a, b) => a - b)

    let data = []

    data.push(
      [{ text: 'Pipe Twist Results (Rotate Off Bottom)', columnSpan: 5 }],
      [
        { text: `MD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `${labels[0]}`, isHeader: true },
        { text: `${labels[1]}`, isHeader: true },
        { text: `${labels[2]}`, isHeader: true },
        { text: `${labels[3]}`, isHeader: true },
      ],
    )

    let wrapsIndex1 = wrapsData.datasets.findIndex((data) => data.label === wrapsData.datasets[0].label)
    if (wrapsIndex1 === -1) return []
    let wraps1 = wrapsData.datasets[wrapsIndex1].data
    let wrapsIndex2 = wrapsData.datasets.findIndex((data) => data.label === wrapsData.datasets[1].label)
    if (wrapsIndex2 === -1) return []
    let wraps2 = wrapsData.datasets[wrapsIndex2].data
    let wrapsIndex3 = wrapsData.datasets.findIndex((data) => data.label === wrapsData.datasets[2].label)
    if (wrapsIndex3 === -1) return []
    let wraps3 = wrapsData.datasets[wrapsIndex3].data
    let wrapsIndex4 = wrapsData.datasets.findIndex((data) => data.label === wrapsData.datasets[3].label)
    if (wrapsIndex4 === -1) return []
    let wraps4 = wrapsData.datasets[wrapsIndex4].data

    for (let i = 0; i < md.length; i++) {
      let depth = md[i]
      let wraps1Value = wraps1.find((data) => data.y === depth)
      let wraps2Value = wraps2.find((data) => data.y === depth)
      let wraps3Value = wraps3.find((data) => data.y === depth)
      let wraps4Value = wraps4.find((data) => data.y === depth)

      data.push([
        { text: numberWithCommasDecimals(depth, 2) },
        { text: wraps1Value ? numberWithCommasDecimals(wraps1Value.x, 2) : '' },
        { text: wraps2Value ? numberWithCommasDecimals(wraps2Value.x, 2) : '' },
        { text: wraps3Value ? numberWithCommasDecimals(wraps3Value.x, 2) : '' },
        { text: wraps4Value ? numberWithCommasDecimals(wraps4Value.x, 2) : '' },
      ])
    }

    return data
  }

  const addAppWobResults = (appWobData, params) => {
    if (!appWobData) return []
    if (!appWobData.hasOwnProperty('datasets')) return []
    if (!Array.isArray(appWobData.datasets)) return []
    if (appWobData.datasets.length < 4) return []
    if (!params) return []
    if (!params.hasOwnProperty('wob')) return []

    let labels = []

    for (let i = 0; i < appWobData.datasets.length; i++) {
      let label = appWobData.datasets[i].label.replace('WOB', `WOB (${getUnitsText(UNITS_FOR.Weight)})`)
      if (!labels.includes(label)) {
        labels.push(label)
      }
    }

    if (labels.length < 4) return []

    let md = []

    for (let i = 0; i < appWobData.datasets.length; i++) {
      if (!appWobData.datasets[i].hasOwnProperty('data')) return []
      if (!Array.isArray(appWobData.datasets[i].data)) return []
      for (let j = 0; j < appWobData.datasets[i].data.length; j++) {
        if (!md.includes(appWobData.datasets[i].data[j].y)) {
          md.push(appWobData.datasets[i].data[j].y)
        }
      }
    }

    if (md.length === 0) return []
    md.sort((a, b) => a - b)

    let data = []

    data.push(
      [
        {
          text: `Apparent WOB Results (Sliding) - ${numberWithCommasDecimals(params.wob, 2)} ${getUnitsText(
            UNITS_FOR.Weight,
          )}`,
          columnSpan: 5,
        },
      ],
      [
        { text: `MD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `${labels[0]}`, isHeader: true },
        { text: `${labels[1]}`, isHeader: true },
        { text: `${labels[2]}`, isHeader: true },
        { text: `${labels[3]}`, isHeader: true },
      ],
    )

    let appWobIndex1 = appWobData.datasets.findIndex((data) => data.label === appWobData.datasets[0].label)
    if (appWobIndex1 === -1) return []
    let appWob1 = appWobData.datasets[appWobIndex1].data
    let appWobIndex2 = appWobData.datasets.findIndex((data) => data.label === appWobData.datasets[1].label)
    if (appWobIndex2 === -1) return []
    let appWob2 = appWobData.datasets[appWobIndex2].data
    let appWobIndex3 = appWobData.datasets.findIndex((data) => data.label === appWobData.datasets[2].label)
    if (appWobIndex3 === -1) return []
    let appWob3 = appWobData.datasets[appWobIndex3].data
    let appWobIndex4 = appWobData.datasets.findIndex((data) => data.label === appWobData.datasets[3].label)
    if (appWobIndex4 === -1) return []
    let appWob4 = appWobData.datasets[appWobIndex4].data

    for (let i = 0; i < md.length; i++) {
      let depth = md[i]
      let appWob1Value = appWob1.find((data) => data.y === depth)
      let appWob2Value = appWob2.find((data) => data.y === depth)
      let appWob3Value = appWob3.find((data) => data.y === depth)
      let appWob4Value = appWob4.find((data) => data.y === depth)

      data.push([
        { text: numberWithCommasDecimals(depth, 2) },
        { text: appWob1Value ? numberWithCommasDecimals(appWob1Value.x, 2) : '' },
        { text: appWob2Value ? numberWithCommasDecimals(appWob2Value.x, 2) : '' },
        { text: appWob3Value ? numberWithCommasDecimals(appWob3Value.x, 2) : '' },
        { text: appWob4Value ? numberWithCommasDecimals(appWob4Value.x, 2) : '' },
      ])
    }

    return data
  }

  const addSideForceResults = (sideForceData, params, drillStrings) => {
    if (!sideForceData) return []
    if (!sideForceData.hasOwnProperty('datasets')) return []
    if (!Array.isArray(sideForceData.datasets)) return []
    if (sideForceData.datasets.length < 9) return []
    if (!drillStrings) return []
    if (!Array.isArray(drillStrings)) return []
    if (drillStrings.length === 0) return []
    if (!params) return []

    let drillStringData = []

    let cumLength = 0
    for (let i = 0; i < drillStrings.length; i++) {
      cumLength += drillStrings[i].length
      let top = params.calcDepth - cumLength
      let bottom = top + drillStrings[i].length

      drillStringData.push({ top: top, bottom: bottom, component: drillStrings[i].description })
    }

    if (drillStringData.length === 0) return []

    let md = []

    for (let i = 0; i < sideForceData.datasets.length; i++) {
      if (!sideForceData.datasets[i].hasOwnProperty('data')) return []
      if (!Array.isArray(sideForceData.datasets[i].data)) return []
      for (let j = 0; j < sideForceData.datasets[i].data.length; j++) {
        if (!md.includes(sideForceData.datasets[i].data[j].y)) {
          md.push(sideForceData.datasets[i].data[j].y)
        }
      }
    }

    if (md.length === 0) return []
    md.sort((a, b) => a - b)

    let labels = []

    let sfUnits = `${getUnitsText(UNITS_FOR.Force)}/${numberWithCommasDecimals(params.sfUnits, 1)}`
    let sfUnitsBad = `klbs/${numberWithCommasDecimals(params.sfUnitsBase, 1)}`

    for (let i = 0; i < sideForceData.datasets.length; i++) {
      let label = sideForceData.datasets[i].label
        .replace(`SF SO ${sfUnits}(ft) @ `, '')
        .replace(`SF PU ${sfUnits}(ft) @ `, '')
        .replace(`SF SO ${sfUnitsBad} @ `, '')
        .replace(`SF PU ${sfUnitsBad} @ `, '')
      if (!labels.includes(label)) {
        labels.push(label)
      }
    }

    if (labels.length < 5) return []

    let data = []

    data.push(
      [{ text: 'Side Force Results', columnSpan: 11 }],
      [
        { text: '', isHeader: true },
        { text: '', isHeader: true },
        { text: '', isHeader: true },
        { text: `FF ${labels[0]}`, isHeader: true, columnSpan: 2 },
        { text: `FF ${labels[1]}`, isHeader: true, columnSpan: 2 },
        { text: `FF ${labels[2]}`, isHeader: true, columnSpan: 2 },
        { text: `FF ${labels[3]}`, isHeader: true, columnSpan: 2 },
      ],
      [
        { text: `MD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: 'Component', isHeader: true },
        { text: `SF Rot Off Btm (${sfUnits})`, isHeader: true },
        { text: `SF SO (${sfUnits}${getUnitsText(UNITS_FOR.Depth)}) @ ${labels[0]}`, isHeader: true },
        { text: `SF PU (${sfUnits}${getUnitsText(UNITS_FOR.Depth)}) @ ${labels[0]}`, isHeader: true },
        { text: `SF SO (${sfUnits}${getUnitsText(UNITS_FOR.Depth)}) @ ${labels[1]}`, isHeader: true },
        { text: `SF PU (${sfUnits}${getUnitsText(UNITS_FOR.Depth)}) @ ${labels[1]}`, isHeader: true },
        { text: `SF SO (${sfUnits}${getUnitsText(UNITS_FOR.Depth)}) @ ${labels[2]}`, isHeader: true },
        { text: `SF PU (${sfUnits}${getUnitsText(UNITS_FOR.Depth)}) @ ${labels[2]}`, isHeader: true },
        { text: `SF SO (${sfUnits}${getUnitsText(UNITS_FOR.Depth)}) @ ${labels[3]}`, isHeader: true },
        { text: `SF PU (${sfUnits}${getUnitsText(UNITS_FOR.Depth)}) @ ${labels[3]}`, isHeader: true },
      ],
    )

    const SFRotIndex = sideForceData.datasets.findIndex((data) => data.label === labels[labels.length - 1])
    if (SFRotIndex === -1) return []
    const SFRot = sideForceData.datasets[SFRotIndex].data

    let SFSOIndex1 = sideForceData.datasets.findIndex((data) => data.label === `SF SO ${sfUnits}(ft) @ ${labels[0]}`)
    if (SFSOIndex1 === -1) {
      SFSOIndex1 = sideForceData.datasets.findIndex((data) => data.label === `SF SO ${sfUnitsBad} @ ${labels[0]}`)
    }
    if (SFSOIndex1 === -1) return []
    const SFSO1 = sideForceData.datasets[SFSOIndex1].data

    let SFPUIndex1 = sideForceData.datasets.findIndex((data) => data.label === `SF PU ${sfUnits}(ft) @ ${labels[0]}`)
    if (SFPUIndex1 === -1) {
      SFPUIndex1 = sideForceData.datasets.findIndex((data) => data.label === `SF PU ${sfUnitsBad} @ ${labels[0]}`)
    }
    if (SFPUIndex1 === -1) return []
    const SFPU1 = sideForceData.datasets[SFPUIndex1].data

    let SFSOIndex2 = sideForceData.datasets.findIndex((data) => data.label === `SF SO ${sfUnits}(ft) @ ${labels[1]}`)
    if (SFSOIndex2 === -1) {
      SFSOIndex2 = sideForceData.datasets.findIndex((data) => data.label === `SF SO ${sfUnitsBad} @ ${labels[1]}`)
    }
    if (SFSOIndex2 === -1) return []
    const SFSO2 = sideForceData.datasets[SFSOIndex2].data

    let SFPUIndex2 = sideForceData.datasets.findIndex((data) => data.label === `SF PU ${sfUnits}(ft) @ ${labels[1]}`)
    if (SFPUIndex2 === -1) {
      SFPUIndex2 = sideForceData.datasets.findIndex((data) => data.label === `SF PU ${sfUnitsBad} @ ${labels[1]}`)
    }
    if (SFPUIndex2 === -1) return []
    const SFPU2 = sideForceData.datasets[SFPUIndex2].data

    let SFSOIndex3 = sideForceData.datasets.findIndex((data) => data.label === `SF SO ${sfUnits}(ft) @ ${labels[2]}`)
    if (SFSOIndex3 === -1) {
      SFSOIndex3 = sideForceData.datasets.findIndex((data) => data.label === `SF SO ${sfUnitsBad} @ ${labels[2]}`)
    }
    if (SFSOIndex3 === -1) return []
    const SFSO3 = sideForceData.datasets[SFSOIndex3].data

    let SFPUIndex3 = sideForceData.datasets.findIndex((data) => data.label === `SF PU ${sfUnits}(ft) @ ${labels[2]}`)
    if (SFPUIndex3 === -1) {
      SFPUIndex3 = sideForceData.datasets.findIndex((data) => data.label === `SF PU ${sfUnitsBad} @ ${labels[2]}`)
    }
    if (SFPUIndex3 === -1) return []
    const SFPU3 = sideForceData.datasets[SFPUIndex3].data

    let SFSOIndex4 = sideForceData.datasets.findIndex((data) => data.label === `SF SO ${sfUnits}(ft) @ ${labels[3]}`)
    if (SFSOIndex4 === -1) {
      SFSOIndex4 = sideForceData.datasets.findIndex((data) => data.label === `SF SO ${sfUnitsBad} @ ${labels[3]}`)
    }
    if (SFSOIndex4 === -1) return []
    const SFSO4 = sideForceData.datasets[SFSOIndex4].data
    let SFPUIndex4 = sideForceData.datasets.findIndex((data) => data.label === `SF PU ${sfUnits}(ft) @ ${labels[3]}`)
    if (SFPUIndex4 === -1) {
      SFPUIndex4 = sideForceData.datasets.findIndex((data) => data.label === `SF PU ${sfUnitsBad} @ ${labels[3]}`)
    }
    if (SFPUIndex4 === -1) return []
    const SFPU4 = sideForceData.datasets[SFPUIndex4].data

    for (let i = 0; i < md.length; i++) {
      let depth = md[i]
      let component = ''
      for (let j = 0; j < drillStringData.length; j++) {
        if (depth >= drillStringData[j].top && depth <= drillStringData[j].bottom) {
          component = drillStringData[j].component
        }
      }

      let SFRotValue = SFRot.find((data) => data.y === depth)
      let SFSOValue1 = SFSO1.find((data) => data.y === depth)
      let SFPUValue1 = SFPU1.find((data) => data.y === depth)
      let SFSOValue2 = SFSO2.find((data) => data.y === depth)
      let SFPUValue2 = SFPU2.find((data) => data.y === depth)
      let SFSOValue3 = SFSO3.find((data) => data.y === depth)
      let SFPUValue3 = SFPU3.find((data) => data.y === depth)
      let SFSOValue4 = SFSO4.find((data) => data.y === depth)
      let SFPUValue4 = SFPU4.find((data) => data.y === depth)

      data.push([
        { text: numberWithCommasDecimals(depth, 2) },
        { text: component },
        { text: SFRotValue ? numberWithCommasDecimals(SFRotValue.x, 2) : '' },
        { text: SFSOValue1 ? numberWithCommasDecimals(SFSOValue1.x, 2) : '' },
        { text: SFPUValue1 ? numberWithCommasDecimals(SFPUValue1.x, 2) : '' },
        { text: SFSOValue2 ? numberWithCommasDecimals(SFSOValue2.x, 2) : '' },
        { text: SFPUValue2 ? numberWithCommasDecimals(SFPUValue2.x, 2) : '' },
        { text: SFSOValue3 ? numberWithCommasDecimals(SFSOValue3.x, 2) : '' },
        { text: SFPUValue3 ? numberWithCommasDecimals(SFPUValue3.x, 2) : '' },
        { text: SFSOValue4 ? numberWithCommasDecimals(SFSOValue4.x, 2) : '' },
        { text: SFPUValue4 ? numberWithCommasDecimals(SFPUValue4.x, 2) : '' },
      ])
    }

    return data
  }

  const addTensionsSnapshotResults = (title, tensionSnapshot, params, drillStrings) => {
    if (!tensionSnapshot) return []
    if (!tensionSnapshot.hasOwnProperty('datasets')) return []
    if (!Array.isArray(tensionSnapshot.datasets)) return []
    if (tensionSnapshot.datasets.length < 7) return []
    if (!drillStrings) return []
    if (!Array.isArray(drillStrings)) return []
    if (drillStrings.length === 0) return []
    if (!params) return []
    if (!params.hasOwnProperty('wob')) return []
    if (!params.hasOwnProperty('calcDepth')) return []

    let drillStringData = []

    let cumLength = 0
    for (let i = 0; i < drillStrings.length; i++) {
      cumLength += drillStrings[i].length
      let top = params.calcDepth - cumLength
      let bottom = top + drillStrings[i].length

      drillStringData.push({ top: top, bottom: bottom, component: drillStrings[i].description })
    }

    if (drillStringData.length === 0) return []

    let md = []

    for (let i = 0; i < tensionSnapshot.datasets.length; i++) {
      if (!tensionSnapshot.datasets[i].hasOwnProperty('data')) return []
      if (!Array.isArray(tensionSnapshot.datasets[i].data)) return []
      for (let j = 0; j < tensionSnapshot.datasets[i].data.length; j++) {
        if (!md.includes(tensionSnapshot.datasets[i].data[j].y)) {
          md.push(tensionSnapshot.datasets[i].data[j].y)
        }
      }
    }

    if (md.length === 0) return []
    md.sort((a, b) => a - b)

    const slidingLabels = []

    if (title === 'On') {
      for (let i = 0; i < tensionSnapshot.datasets.length; i++) {
        if (
          tensionSnapshot.datasets[i].label.includes(`Sliding (${getUnitsText(UNITS_FOR.Weight)}) @ `) ||
          tensionSnapshot.datasets[i].label.includes(`Sliding @ `)
        ) {
          let label = tensionSnapshot.datasets[i].label
            .replace(`Sliding (${getUnitsText(UNITS_FOR.Weight)}) @ `, '')
            .replace(`Sliding @ `, '')
          if (!slidingLabels.includes(label)) {
            slidingLabels.push(label)
          }
        }
      }
    }

    if (title === 'Off') {
      for (let i = 0; i < tensionSnapshot.datasets.length; i++) {
        if (
          tensionSnapshot.datasets[i].label.includes(`SO (${getUnitsText(UNITS_FOR.Weight)}) @`) ||
          tensionSnapshot.datasets[i].label.includes(`SO @`)
        ) {
          let label = tensionSnapshot.datasets[i].label
            .replace(`SO (${getUnitsText(UNITS_FOR.Weight)}) @ `, '')
            .replace(`SO @ `, '')
          if (!slidingLabels.includes(label)) {
            slidingLabels.push(label)
          }
        }
      }
    }

    if (slidingLabels.length < 4) return []

    let headerText = 'Sliding'
    if (title === 'Off') headerText = 'SO / PU'

    let wobTitle = `${params.wob} ${getUnitsText(UNITS_FOR.Weight)}`
    if (title === 'Off') wobTitle = ''

    let data = []
    data.push(
      [
        {
          text: `${title} Bottom Tension Snapshot Results ${wobTitle} @ ${numberWithCommasDecimals(
            params.calcDepth,
            2,
          )} ${getUnitsText(UNITS_FOR.Depth)} MD WOB ${numberWithCommasDecimals(params.wob, 2)} ${getUnitsText(
            UNITS_FOR.Weight,
          )}`,
          columnSpan: 10,
        },
      ],
      [
        { text: `MD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: 'Component', isHeader: true },
        { text: 'Buckling', isHeader: true },
        { text: `Sin Buckling (${getUnitsText(UNITS_FOR.Torque)})`, isHeader: true },
        { text: `Hel Buckling (${getUnitsText(UNITS_FOR.Torque)})`, isHeader: true },
        { text: `Rotating (${getUnitsText(UNITS_FOR.Torque)})`, isHeader: true },
        { text: `${headerText} (${getUnitsText(UNITS_FOR.Torque)}) @ ${slidingLabels[0]}`, isHeader: true },
        { text: `${headerText} (${getUnitsText(UNITS_FOR.Torque)}) @ ${slidingLabels[1]}`, isHeader: true },
        { text: `${headerText} (${getUnitsText(UNITS_FOR.Torque)}) @ ${slidingLabels[2]}`, isHeader: true },
        { text: `${headerText} (${getUnitsText(UNITS_FOR.Torque)}) @ ${slidingLabels[3]}`, isHeader: true },
      ],
    )

    let sinBucklingIndex = tensionSnapshot.datasets.findIndex(
      (data) => data.label === `Sin Buckling (rot)(${getUnitsText(UNITS_FOR.Weight)})`,
    )
    if (sinBucklingIndex === -1) {
      sinBucklingIndex = tensionSnapshot.datasets.findIndex((data) => data.label === `Sin Buckling`)

      if (sinBucklingIndex === -1) return []
    }
    const sinBuckling = tensionSnapshot.datasets[sinBucklingIndex].data

    let helBucklingIndex = tensionSnapshot.datasets.findIndex(
      (data) => data.label === `Hel Buckling (rot)(${getUnitsText(UNITS_FOR.Weight)})`,
    )
    if (helBucklingIndex === -1) {
      helBucklingIndex = tensionSnapshot.datasets.findIndex((data) => data.label === `Hel Buckling`)
      if (helBucklingIndex === -1) return []
    }
    const helBuckling = tensionSnapshot.datasets[helBucklingIndex].data

    let rotatingText = `Rot On Bottom @ WOB ${numberWithCommasDecimals(params.wob, 2)}(klbs)`
    if (title === 'Off') rotatingText = `Rot Off Bottom (klbs)`

    let rotatingIndex = tensionSnapshot.datasets.findIndex((data) => data.label === rotatingText)
    if (rotatingIndex === -1) {
      rotatingText = `Rot On Bottom @ WOB ${numberWithCommasDecimals(params.wobBase, 2)}`
      if (title === 'Off') rotatingText = `Rot Off Bottom`

      rotatingIndex = tensionSnapshot.datasets.findIndex((data) => data.label === rotatingText)
      if (rotatingIndex === -1 && title === 'Off') return
      if (rotatingIndex === -1) {
        rotatingText = 'Rot on Bottom'
        rotatingIndex = tensionSnapshot.datasets.findIndex((data) => data.label === rotatingText)
        if (rotatingIndex === -1) return
      }
    }
    const rotating = tensionSnapshot.datasets[rotatingIndex].data

    let slidingText = `Sliding`
    if (title === 'Off') slidingText = `SO`
    const sliding1Index = tensionSnapshot.datasets.findIndex(
      (data) => data.label === `${slidingText} @ ${slidingLabels[0]}`,
    )
    if (sliding1Index === -1) return []
    const sliding1 = tensionSnapshot.datasets[sliding1Index].data

    const sliding2Index = tensionSnapshot.datasets.findIndex(
      (data) => data.label === `${slidingText} @ ${slidingLabels[1]}`,
    )
    if (sliding2Index === -1) return []
    const sliding2 = tensionSnapshot.datasets[sliding2Index].data

    const sliding3Index = tensionSnapshot.datasets.findIndex(
      (data) => data.label === `${slidingText} @ ${slidingLabels[2]}`,
    )
    if (sliding3Index === -1) return []
    const sliding3 = tensionSnapshot.datasets[sliding3Index].data

    const sliding4Index = tensionSnapshot.datasets.findIndex(
      (data) => data.label === `${slidingText} @ ${slidingLabels[3]}`,
    )
    if (sliding4Index === -1) return []
    const sliding4 = tensionSnapshot.datasets[sliding4Index].data

    if (title === 'Off') slidingText = `PU`
    const pu1Index = tensionSnapshot.datasets.findIndex((data) => data.label === `${slidingText} @ ${slidingLabels[0]}`)
    if (title === 'Off' && pu1Index === -1) return []
    const pu1 = tensionSnapshot.datasets[pu1Index].data
    const pu2Index = tensionSnapshot.datasets.findIndex((data) => data.label === `${slidingText} @ ${slidingLabels[1]}`)
    if (title === 'Off' && pu2Index === -1) return []
    const pu2 = tensionSnapshot.datasets[pu2Index].data
    const pu3Index = tensionSnapshot.datasets.findIndex((data) => data.label === `${slidingText} @ ${slidingLabels[2]}`)
    if (title === 'Off' && pu3Index === -1) return []
    const pu3 = tensionSnapshot.datasets[pu3Index].data
    const pu4Index = tensionSnapshot.datasets.findIndex((data) => data.label === `${slidingText} @ ${slidingLabels[3]}`)
    if (title === 'Off' && pu4Index === -1) return []
    const pu4 = tensionSnapshot.datasets[pu4Index].data

    const sinColor = '#ffa500'
    const helColor = '#ff0000'

    for (let i = 0; i < md.length; i++) {
      let depth = md[i]
      let bucklingRotating = { type: '-', color: null }
      let bucklingType1 = { type: '-', color: null }
      let bucklingType2 = { type: '-', color: null }
      let bucklingType3 = { type: '-', color: null }
      let bucklingType4 = { type: '-', color: null }
      let sinBucklingValue = sinBuckling.find((data) => data.y === depth)
      let helBucklingValue = helBuckling.find((data) => data.y === depth)
      let rotatingValue = rotating.find((data) => data.y === depth)
      let sliding1Value = sliding1.find((data) => data.y === depth)
      let sliding2Value = sliding2.find((data) => data.y === depth)
      let sliding3Value = sliding3.find((data) => data.y === depth)
      let sliding4Value = sliding4.find((data) => data.y === depth)

      if (rotatingValue.x < sinBucklingValue.x) bucklingRotating = { type: 'Sin', color: sinColor }
      if (rotatingValue.x < helBucklingValue.x) bucklingRotating = { type: 'Hel', color: helColor }
      if (sliding1Value.x < sinBucklingValue.x) bucklingType1 = { type: 'Sin', color: sinColor }
      if (sliding1Value.x < helBucklingValue.x) bucklingType1 = { type: 'Hel', color: helColor }
      if (sliding2Value.x < sinBucklingValue.x) bucklingType2 = { type: 'Sin', color: sinColor }
      if (sliding2Value.x < helBucklingValue.x) bucklingType2 = { type: 'Hel', color: helColor }
      if (sliding3Value.x < sinBucklingValue.x) bucklingType3 = { type: 'Sin', color: sinColor }
      if (sliding3Value.x < helBucklingValue.x) bucklingType3 = { type: 'Hel', color: helColor }
      if (sliding4Value.x < sinBucklingValue.x) bucklingType4 = { type: 'Sin', color: sinColor }
      if (sliding4Value.x < helBucklingValue.x) bucklingType4 = { type: 'Hel', color: helColor }

      let bucklingType = '-'
      if (
        bucklingRotating.type === 'Sin' ||
        bucklingType1.type === 'Sin' ||
        bucklingType2.type === 'Sin' ||
        bucklingType3.type === 'Sin' ||
        bucklingType4.type === 'Sin'
      ) {
        bucklingType = 'Sin'
      }
      if (
        bucklingRotating.type === 'Hel' ||
        bucklingType1.type === 'Hel' ||
        bucklingType2.type === 'Hel' ||
        bucklingType3.type === 'Hel' ||
        bucklingType4.type === 'Hel'
      ) {
        bucklingType = 'Hel'
      }

      let component = ''
      for (let j = 0; j < drillStringData.length; j++) {
        if (depth >= drillStringData[j].top && depth < drillStringData[j].bottom) {
          component = drillStringData[j].component
          break
        }
      }

      let value1 = sliding1Value ? numberWithCommasDecimals(sliding1Value.x, 2) : ''
      let value2 = sliding2Value ? numberWithCommasDecimals(sliding2Value.x, 2) : ''
      let value3 = sliding3Value ? numberWithCommasDecimals(sliding3Value.x, 2) : ''
      let value4 = sliding4Value ? numberWithCommasDecimals(sliding4Value.x, 2) : ''

      if (title === 'Off') {
        value1 = `${value1} / ${numberWithCommasDecimals(pu1.find((data) => data.y === depth).x, 2)}`
        value2 = `${value2} / ${numberWithCommasDecimals(pu2.find((data) => data.y === depth).x, 2)}`
        value3 = `${value3} / ${numberWithCommasDecimals(pu3.find((data) => data.y === depth).x, 2)}`
        value4 = `${value4} / ${numberWithCommasDecimals(pu4.find((data) => data.y === depth).x, 2)}`
      }

      data.push([
        { text: numberWithCommasDecimals(depth, 2) },
        { text: component },
        { text: bucklingType },
        { text: sinBucklingValue ? numberWithCommasDecimals(sinBucklingValue.x, 2) : '' },
        { text: helBucklingValue ? numberWithCommasDecimals(helBucklingValue.x, 2) : '' },
        {
          text: rotatingValue ? numberWithCommasDecimals(rotatingValue.x, 2) : '',
          backgroundColor: bucklingRotating.color,
        },
        {
          text: value1,
          backgroundColor: bucklingType1.color,
        },
        {
          text: value2,
          backgroundColor: bucklingType2.color,
        },
        {
          text: value3,
          backgroundColor: bucklingType3.color,
        },
        {
          text: value4,
          backgroundColor: bucklingType4.color,
        },
      ])
    }

    return data
  }

  const addDrillersTorqueResults = (drillersTorques) => {
    if (!drillersTorques) return []
    if (!Array.isArray(drillersTorques)) return []
    if (drillersTorques.length === 0) return []

    let md = []

    for (let i = 0; i < drillersTorques.length; i++) {
      if (!drillersTorques[i].hasOwnProperty('data')) return []
      if (!Array.isArray(drillersTorques[i].data)) return []
      for (let j = 0; j < drillersTorques[i].data.length; j++) {
        if (!md.includes(drillersTorques[i].data[j].y)) {
          md.push(drillersTorques[i].data[j].y)
        }
      }
    }

    if (md.length === 0) return []
    md.sort((a, b) => a - b)

    const labels = []

    for (let i = 0; i < drillersTorques.length; i++) {
      let label = drillersTorques[i].label.replace(`Off Btm Tq @ `, '').replace(`On Btm Tq @ `, '')
      if (!labels.includes(label)) {
        labels.push(label)
      }
    }

    if (labels.length < 2) return []

    let data = []
    data.push([{ text: "Driller's Torques Results", columnSpan: 9 }])

    let row = []
    row.push({ text: '', isHeader: true })
    for (let i = 0; i < labels.length; i++) {
      row.push({ text: `FF ${labels[i]}`, isHeader: true, columnSpan: 2 })
    }
    data.push(row)

    row = []
    row.push({ text: `MD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true })
    for (let i = 0; i < labels.length; i++) {
      row.push({ text: `Off Bottom Torque (${getUnitsText(UNITS_FOR.Torque)})`, isHeader: true })
      row.push({ text: `On Bottom Torque (${getUnitsText(UNITS_FOR.Torque)})`, isHeader: true })
    }

    data.push(row)

    let offBtmData = []
    let onBtmData = []

    for (let i = 0; i < labels.length; i++) {
      let offBtmIndex = drillersTorques.findIndex((data) => data.label === `Off Btm Tq @ ${labels[i]}`)
      if (offBtmIndex === -1) continue
      offBtmData.push(drillersTorques[offBtmIndex].data)

      let onBtmIndex = drillersTorques.findIndex((data) => data.label === `On Btm Tq @ ${labels[i]}`)
      if (onBtmIndex === -1) continue
      onBtmData.push(drillersTorques[onBtmIndex].data)
    }

    for (let i = 0; i < md.length; i++) {
      let depth = md[i]
      let row = []
      row.push({ text: numberWithCommasDecimals(depth, 2) })

      for (let j = 0; j < offBtmData.length; j++) {
        let offBtmValue = offBtmData[j].find((data) => data.y === depth)
        let onBtmValue = onBtmData[j].find((data) => data.y === depth)
        row.push({ text: offBtmValue ? numberWithCommasDecimals(offBtmValue.x, 2) : '' })
        row.push({ text: onBtmValue ? numberWithCommasDecimals(onBtmValue.x, 2) : '' })
      }

      data.push(row)
    }

    return data
  }

  const addCalculatedFrictionFactors = (frictionFactors) => {
    if (!frictionFactors) return []
    if (!frictionFactors.hasOwnProperty('datasets')) return []
    if (!Array.isArray(frictionFactors.datasets)) return []
    if (frictionFactors.datasets.length < 6) return []

    let md = []

    for (let i = 0; i < frictionFactors.datasets.length; i++) {
      if (!frictionFactors.datasets[i].hasOwnProperty('data')) return []
      if (!Array.isArray(frictionFactors.datasets[i].data)) return []
      for (let j = 0; j < frictionFactors.datasets[i].data.length; j++) {
        if (!md.includes(frictionFactors.datasets[i].data[j].y)) {
          md.push(frictionFactors.datasets[i].data[j].y)
        }
      }
    }

    if (md.length === 0) return []
    md.sort((a, b) => a - b)

    let data = []
    data.push(
      [{ text: 'Calculated Friction Factors', columnSpan: 10 }],
      [
        { text: '', isHeader: true },
        { text: 'Slide Sheet Data', isHeader: true, columnSpan: 2 },
        { text: 'EDR Data', isHeader: true, columnSpan: 4 },
      ],
      [
        { text: `MD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: 'SO FF', isHeader: true },
        { text: 'PU FF', isHeader: true },
        { text: 'SO FF', isHeader: true },
        { text: 'PU FF', isHeader: true },
        { text: 'Trip In FF', isHeader: true },
        { text: 'Trip Out FF', isHeader: true },
      ],
    )

    const slideSheetSOIndex = frictionFactors.datasets.findIndex((data) => data.label === 'SO FF (Slide Sheet)')
    if (slideSheetSOIndex === -1) return []
    const slideSheetSO = frictionFactors.datasets[slideSheetSOIndex].data
    const slideSheetPUIndex = frictionFactors.datasets.findIndex((data) => data.label === 'PU FF (Slide Sheet)')
    if (slideSheetPUIndex === -1) return []
    const slideSheetPU = frictionFactors.datasets[slideSheetPUIndex].data
    const edrSOIndex = frictionFactors.datasets.findIndex((data) => data.label === 'SO FF (WITS)')
    if (edrSOIndex === -1) return []
    const edrSO = frictionFactors.datasets[edrSOIndex].data
    const edrPUIndex = frictionFactors.datasets.findIndex((data) => data.label === 'PU FF (WITS)')
    if (edrPUIndex === -1) return []
    const edrPU = frictionFactors.datasets[edrPUIndex].data
    const edrTripInIndex = frictionFactors.datasets.findIndex((data) => data.label === 'Trip In FF (WITS)')
    if (edrTripInIndex === -1) return []
    const edrTripIn = frictionFactors.datasets[edrTripInIndex].data
    const edrTripOutIndex = frictionFactors.datasets.findIndex((data) => data.label === 'Trip Out FF (WITS)')
    if (edrTripOutIndex === -1) return []
    const edrTripOut = frictionFactors.datasets[edrTripOutIndex].data

    for (let i = 0; i < md.length; i++) {
      let depth = md[i]
      const slideSeetSOVal = slideSheetSO.find((data) => data.y === depth)
      const slideSeetPUVal = slideSheetPU.find((data) => data.y === depth)
      const edrSOVal = edrSO.find((data) => data.y === depth)
      const edrPUVal = edrPU.find((data) => data.y === depth)
      const edrTripInVal = edrTripIn.find((data) => data.y === depth)
      const edrTripOutVal = edrTripOut.find((data) => data.y === depth)
      data.push([
        { text: numberWithCommasDecimals(depth, 2) },
        { text: slideSeetSOVal ? numberWithCommasDecimals(slideSeetSOVal.x, 2) : '-' },
        { text: slideSeetPUVal ? numberWithCommasDecimals(slideSeetPUVal.x, 2) : '-' },
        { text: edrSOVal ? numberWithCommasDecimals(edrSOVal.x, 2) : '-' },
        { text: edrPUVal ? numberWithCommasDecimals(edrPUVal.x, 2) : '-' },
        { text: edrTripInVal ? numberWithCommasDecimals(edrTripInVal.x, 2) : '-' },
        { text: edrTripOutVal ? numberWithCommasDecimals(edrTripOutVal.x, 2) : '-' },
      ])
    }

    return data
  }

  const addDrillersHookloadResults = (drillersHookloads) => {
    if (!drillersHookloads) return []
    if (!Array.isArray(drillersHookloads)) return []
    if (drillersHookloads.length === 0) return []

    let md = drillersHookloads[0]
    if (!md) return []
    if (!md.hasOwnProperty('data')) return []
    if (!Array.isArray(md.data)) return []
    if (md.data.length === 0) return []

    for (let i = 0; i < drillersHookloads.length; i++) {
      if (!drillersHookloads[i].hasOwnProperty('data')) return []
      if (!Array.isArray(drillersHookloads[i].data)) return []
      if (drillersHookloads[i]?.data.length !== md.data.length) return []
    }

    const labels = []

    for (let i = 0; i < drillersHookloads.length; i++) {
      let label = drillersHookloads[i].label
        .replace(`PU (${getUnitsText(UNITS_FOR.Weight)}) @ `, '')
        .replace(`SO (${getUnitsText(UNITS_FOR.Weight)}) @ `, '')
        .replace('PU @ ', '')
        .replace('SO @ ', '')
      if (
        label !== `Rotating Off Bottom (${getUnitsText(UNITS_FOR.Weight)})` &&
        label !== `Min wt to Hel buckle (Trip In)(${getUnitsText(UNITS_FOR.Weight)})` &&
        label !== `Rotating Off Bottom` &&
        label !== `Min wt to Hel buckle` &&
        !labels.includes(label)
      ) {
        labels.push(label)
      }
    }

    if (labels.length < 1) return []

    let data = []

    data.push([{ text: "Driller's Hookload Results", columnSpan: labels.length * 2 + 2 }])

    let row = []
    row.push({ text: '', isHeader: true })
    row.push({ text: '', isHeader: true })
    for (let label of labels) {
      row.push({ text: `FF ${label}`, isHeader: true, columnSpan: 2 })
    }
    data.push(row)

    row = []
    row.push({ text: `MD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true })
    row.push({ text: `Rot Off Btm (${getUnitsText(UNITS_FOR.Weight)})`, isHeader: true })
    for (let i = 0; i < labels.length; i++) {
      row.push({ text: `SO (${getUnitsText(UNITS_FOR.Weight)})`, isHeader: true })
      row.push({ text: `PU (${getUnitsText(UNITS_FOR.Weight)})`, isHeader: true })
    }

    data.push(row)

    let dataRotOffBtmIndex = drillersHookloads.findIndex(
      (data) => data.label === `Rotating Off Bottom (${getUnitsText(UNITS_FOR.Weight)})`,
    )
    if (dataRotOffBtmIndex === -1) {
      dataRotOffBtmIndex = drillersHookloads.findIndex((data) => data.label === `Rotating Off Bottom`)

      if (dataRotOffBtmIndex === -1) return []
    }
    const dataRotOffBtm = drillersHookloads[dataRotOffBtmIndex].data

    let dataSO = []
    let dataPU = []

    for (let i = 0; i < labels.length; i++) {
      let SOIndex = drillersHookloads.findIndex(
        (data) => data.label === `SO (${getUnitsText(UNITS_FOR.Weight)}) @ ${labels[i]}`,
      )

      if (SOIndex === -1) {
        SOIndex = drillersHookloads.findIndex((data) => data.label === `SO @ ${labels[i]}`)
        if (SOIndex === -1) continue
      }

      dataSO.push(drillersHookloads[SOIndex].data)

      let PUIndex = drillersHookloads.findIndex(
        (data) => data.label === `PU (${getUnitsText(UNITS_FOR.Weight)}) @ ${labels[i]}`,
      )

      if (PUIndex === -1) {
        PUIndex = drillersHookloads.findIndex((data) => data.label === `PU @ ${labels[i]}`)
        if (PUIndex === -1) continue
      }

      dataPU.push(drillersHookloads[PUIndex].data)
    }

    for (let i = 0; i < md.data.length; i++) {
      let row = []
      row.push({ text: numberWithCommasDecimals(md.data[i].y, 2) })
      row.push({ text: numberWithCommasDecimals(dataRotOffBtm[i].x, 2) })

      for (let j = 0; j < dataSO.length; j++) {
        row.push({ text: numberWithCommasDecimals(dataSO[j][i].x, 2) })
        row.push({ text: numberWithCommasDecimals(dataPU[j][i].x, 2) })
      }

      data.push(row)
    }

    return data
  }

  const getMinMaxAverageFromData = (data) => {
    if (!data) return { min: null, minMD: null, max: null, maxMD: null, avg: null }
    if (!Array.isArray(data)) return { min: null, minMD: null, max: null, maxMD: null, avg: null }
    if (data.length === 0) return { min: null, minMD: null, max: null, maxMD: null, avg: null }

    let min = data[0].x
    let minMD = data[0].y
    let max = data[0].x
    let maxMD = data[0].y
    let sum = 0

    for (let i = 0; i < data.length; i++) {
      if (Math.abs(data[i].x) < Math.abs(min)) {
        min = data[i].x
        minMD = data[i].y
      }
      if (Math.abs(data[i].x) > Math.abs(max)) {
        max = data[i].x
        maxMD = data[i].y
      }
      sum += data[i].x
    }

    return { min: min, minMD: minMD, max: max, maxMD: maxMD, avg: sum / data.length }
  }

  const addFrictionFactorSummary = (frictionFactors) => {
    if (!frictionFactors) return []
    if (!frictionFactors.hasOwnProperty('datasets')) return []
    if (!Array.isArray(frictionFactors.datasets)) return []
    if (frictionFactors.datasets.length < 6) return []

    let data = []
    data.push(
      [{ text: 'Calculated Friction Factor Summary', columnSpan: 7 }],
      [
        { text: '', isHeader: true },
        { text: 'Slide Sheet', isHeader: true, columnSpan: 2 },
        { text: 'EDR Data', isHeader: true, columnSpan: 4 },
      ],
      [
        { text: '', isHeader: true },
        { text: 'SO', isHeader: true },
        { text: 'PU', isHeader: true },
        { text: 'SO', isHeader: true },
        { text: 'PU', isHeader: true },
        { text: 'Trip In', isHeader: true },
        { text: 'Trip Out', isHeader: true },
      ],
    )

    const slideSheetSO = frictionFactors.datasets.find((data) => data.label === 'SO FF (Slide Sheet)')
    const slideSheetSOStats = getMinMaxAverageFromData(slideSheetSO?.data)

    const slideSheetPU = frictionFactors.datasets.find((data) => data.label === 'PU FF (Slide Sheet)')
    const slideSheetPUStats = getMinMaxAverageFromData(slideSheetPU?.data)

    const witsSO = frictionFactors.datasets.find((data) => data.label === 'SO FF (WITS)')
    const witsSOStats = getMinMaxAverageFromData(witsSO?.data)

    const witsPU = frictionFactors.datasets.find((data) => data.label === 'PU FF (WITS)')
    const witsPUStats = getMinMaxAverageFromData(witsPU?.data)

    const witsTripIn = frictionFactors.datasets.find((data) => data.label === 'Trip In FF (WITS)')
    const witsTripInStats = getMinMaxAverageFromData(witsTripIn?.data)

    const witsTripOut = frictionFactors.datasets.find((data) => data.label === 'Trip Out FF (WITS)')
    const witsTripOutStats = getMinMaxAverageFromData(witsTripOut?.data)

    data.push(
      [
        { text: `Min FF @ MD (${getUnitsText(UNITS_FOR.Depth)}):`, isHeader: true },
        {
          text: slideSheetSOStats.min
            ? `${numberWithCommasDecimals(slideSheetSOStats.min, 2)} @ ${numberWithCommasDecimals(
                slideSheetSOStats.minMD,
                2,
              )}`
            : '-',
        },
        {
          text: slideSheetPUStats.min
            ? `${numberWithCommasDecimals(slideSheetPUStats.min, 2)} @ ${numberWithCommasDecimals(
                slideSheetPUStats.minMD,
                2,
              )}`
            : '-',
        },
        {
          text: witsSOStats.min
            ? `${numberWithCommasDecimals(witsSOStats.min, 2)} @ ${numberWithCommasDecimals(witsSOStats.minMD, 2)}`
            : '-',
        },
        {
          text: witsPUStats.min
            ? `${numberWithCommasDecimals(witsPUStats.min, 2)} @ ${numberWithCommasDecimals(witsPUStats.minMD, 2)}`
            : '-',
        },
        {
          text: witsTripInStats.min
            ? `${numberWithCommasDecimals(witsTripInStats.min, 2)} @ ${numberWithCommasDecimals(
                witsTripInStats.minMD,
                2,
              )}`
            : '-',
        },
        {
          text: witsTripOutStats.min
            ? `${numberWithCommasDecimals(witsTripOutStats.min, 2)} @ ${numberWithCommasDecimals(
                witsTripOutStats.minMD,
                2,
              )}`
            : '-',
        },
      ],
      [
        { text: `Max FF @ MD (${getUnitsText(UNITS_FOR.Depth)}):`, isHeader: true },
        {
          text: slideSheetSOStats.max
            ? `${numberWithCommasDecimals(slideSheetSOStats.max, 2)} @ ${numberWithCommasDecimals(
                slideSheetSOStats.maxMD,
                2,
              )}`
            : '-',
        },
        {
          text: slideSheetPUStats.max
            ? `${numberWithCommasDecimals(slideSheetPUStats.max, 2)} @ ${numberWithCommasDecimals(
                slideSheetPUStats.maxMD,
                2,
              )}`
            : '-',
        },
        {
          text: witsSOStats.max
            ? `${numberWithCommasDecimals(witsSOStats.max, 2)} @ ${numberWithCommasDecimals(witsSOStats.maxMD, 2)}`
            : '-',
        },
        {
          text: witsPUStats.max
            ? `${numberWithCommasDecimals(witsPUStats.max, 2)} @ ${numberWithCommasDecimals(witsPUStats.maxMD, 2)}`
            : '-',
        },
        {
          text: witsTripInStats.max
            ? `${numberWithCommasDecimals(witsTripInStats.max, 2)} @ ${numberWithCommasDecimals(
                witsTripInStats.maxMD,
                2,
              )}`
            : '-',
        },
        {
          text: witsTripOutStats.max
            ? `${numberWithCommasDecimals(witsTripOutStats.max, 2)} @ ${numberWithCommasDecimals(
                witsTripOutStats.maxMD,
                2,
              )}`
            : '-',
        },
      ],
      [
        { text: 'Mean Average FF', isHeader: true },
        { text: slideSheetSOStats.avg ? numberWithCommasDecimals(slideSheetSOStats.avg, 2) : '-' },
        { text: slideSheetPUStats.avg ? numberWithCommasDecimals(slideSheetPUStats.avg, 2) : '-' },
        { text: witsSOStats.avg ? numberWithCommasDecimals(witsSOStats.avg, 2) : '-' },
        { text: witsPUStats.avg ? numberWithCommasDecimals(witsPUStats.avg, 2) : '-' },
        { text: witsTripInStats.avg ? numberWithCommasDecimals(witsTripInStats.avg, 2) : '-' },
        { text: witsTripOutStats.avg ? numberWithCommasDecimals(witsTripOutStats.avg, 2) : '-' },
      ],
    )

    return data
  }

  const addStabData = (drillString) => {
    if (!drillString) return []
    if (!Array.isArray(drillString)) return []
    if (drillString.length === 0) return []

    let stabData = []
    drillString.forEach((component) => {
      if (component.type === 'Stabilizer' || component.stabOd > 0) stabData.push(component)
    })

    if (stabData.length === 0) return []

    let data = []
    data.push(
      [{ text: 'Stabilizer Data', columnSpan: 7 }],
      [
        { text: 'Component Number', isHeader: true },
        { text: 'Description', isHeader: true },
        { text: `OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `Blade Length (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `Blade Width (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: 'Blade Count', isHeader: true },
        { text: `Stab->Bit (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
      ],
    )

    for (let i = 0; i < stabData.length; i++) {
      data.push([
        { text: stabData[i].sequenceNo + 1 },
        { text: stabData[i].description.replace('&dq;', '"') },
        { text: numberWithCommasDecimals(stabData[i].stabOd, 3) },
        { text: numberWithCommasDecimals(stabData[i].stabBl, 3) },
        { text: numberWithCommasDecimals(stabData[i].stabBw, 3) },
        { text: numberWithCommasDecimals(stabData[i].stabBc, 0) },
        { text: numberWithCommasDecimals(getStabToBit(drillString, stabData, i), 2) },
      ])
    }

    return data
  }

  const addTortuositySettings = (params) => {
    if (!params) return []
    let data = []
    data.push(
      [{ text: 'Tortuosity Settings', columnSpan: 4 }],
      [
        { text: 'Method', isHeader: true },
        { text: `Start MD (${getUnitsText(UNITS_FOR.Depth)}):`, isHeader: true },
        { text: `End MD (${getUnitsText(UNITS_FOR.Depth)}):`, isHeader: true },
        { text: `Period Length (${getUnitsText(UNITS_FOR.Depth)}):`, isHeader: true },
      ],
      [
        { text: params.tortMethod },
        { text: numberWithCommasDecimals(params.tortStartDepth, 2), textAlign: 'left' },
        {
          text: params?.tortDepthRange
            ? numberWithCommasDecimals(params.tortEndDepth, 2)
            : numberWithCommasDecimals(params.calcDepth, 2),
          textAlign: 'left',
        },
        {
          text: params.tortMethod !== 'Random' ? numberWithCommasDecimals(params.tortPeriod, 2) : 'Random',
          textAlign: 'left',
        },
      ],
    )

    return data
  }

  const addCalculationParameters = (params, fluids, drillStrings) => {
    if (!params) return []
    if (!fluids) return []
    if (!drillStrings) return []

    let bit = null
    for (let i = 0; i < drillStrings.length; i++) {
      if (drillStrings[i].type === 'Drill Bit') {
        bit = drillStrings[i]
        break
      }
    }

    let flowRate = 0
    if (params.hasOwnProperty('flowRates') && Array.isArray(params.flowRates) && params.flowRates.length > 0) {
      flowRate = params.flowRates[0].flowRate
    }

    let data = []
    data.push(
      [{ text: 'Calculation Parameters', columnSpan: 6 }],
      [
        { text: `Calculation Depth (${getUnitsText(UNITS_FOR.Depth)}):`, isHeader: true },
        { text: numberWithCommasDecimals(tndData.current.params?.calcDepth, 2), textAlign: 'left' },
        { text: `Block Weight (${getUnitsText(UNITS_FOR.Weight)}):`, isHeader: true },
        { text: numberWithCommasDecimals(tndData.current.params?.blockWeight, 2), textAlign: 'left' },
        { text: `WOB/WOB Slide (${getUnitsText(UNITS_FOR.Weight)}):`, isHeader: true },
        {
          text: `${numberWithCommasDecimals(tndData.current.params?.wob, 2)}/${numberWithCommasDecimals(
            tndData.current.params?.wobSlide,
            2,
          )}`,
          textAlign: 'left',
        },
      ],
      [
        { text: `Bit Torque (${getUnitsText(UNITS_FOR.Torque)}):`, isHeader: true },
        { text: numberWithCommasDecimals(tndData.current.params?.torqueOnBit, 2), textAlign: 'left' },
        { text: 'RPM', isHeader: true },
        { text: numberWithCommasDecimals(tndData.current.params?.rpm, 2), textAlign: 'left' },
        { text: 'Viscous Drag', isHeader: true },
        { text: tndData.current.params?.viscousDrag ? 'Yes' : 'No' },
      ],
      [
        { text: `Reaming Speed (${getUnitsText(UNITS_FOR.Depth)}/min):`, isHeader: true },
        { text: numberWithCommasDecimals(tndData.current.params?.reamingSpeed, 2), textAlign: 'left' },
        { text: `Overpull (${getUnitsText(UNITS_FOR.Weight)}):`, isHeader: true },
        { text: numberWithCommasDecimals(tndData.current.params?.overpull, 2), textAlign: 'left' },
        { text: `ROP (${getUnitsText(UNITS_FOR.Depth)}/hr):`, isHeader: true },
        { text: numberWithCommasDecimals(tndData.current.params?.rop, 2), textAlign: 'left' },
      ],
      [
        { text: `Mud Weight (${getUnitsText(UNITS_FOR.UnitWeight)}):`, isHeader: true },
        { text: numberWithCommasDecimals(tndData.current.params?.mudWeight, 2), textAlign: 'left' },
        { text: 'PV (cP)', isHeader: true },
        { text: numberWithCommasDecimals(tndData.current.fluids?.pv, 2), textAlign: 'left' },
        { text: 'YP(lbf/100ft\u00B2)', isHeader: true },
        { text: numberWithCommasDecimals(tndData.current.fluids?.yp, 2), textAlign: 'left' },
      ],
      [
        { text: `TFA (${getUnitsText(UNITS_FOR.Diameter)}\u00B2):`, isHeader: true },
        { text: numberWithCommasDecimals(bit?.tfa, 4), textAlign: 'left' },
        { text: `Flow Rate (${getUnitsText(UNITS_FOR.FlowRate)}):`, isHeader: true },
        { text: numberWithCommasDecimals(flowRate, 2), textAlign: 'left' },
        {
          text: `SF Units (${getUnitsText(UNITS_FOR.Weight)}/${numberWithCommasDecimals(
            tndData.current.params?.sfUnits,
            0,
          )}${getUnitsText(UNITS_FOR.Depth)})`,
          isHeader: true,
        },
        { text: numberWithCommasDecimals(tndData.current.params?.sfUnits, 2), textAlign: 'left' },
      ],
    )

    return data
  }

  const getMaxValueFromData = (data) => {
    if (!data) return { max: null, depth: null }
    if (!data.hasOwnProperty('data')) return { max: null, depth: null }
    if (!Array.isArray(data.data)) return { max: null, depth: null }
    if (data.data.length === 0) return { max: null, depth: null }

    let max = null
    let depth = null
    for (let i = 0; i < data.data.length; i++) {
      if (!max || max === null || data.data[i]?.data > max) {
        max = data.data[i]?.data
        depth = data.data[i]?.depth
      }
    }

    return { max: max, depth: depth }
  }

  const getImagesForChart = (
    drillersHookloadsImage,
    calculatedFrictionFactorsImage,
    drillersTorquesImage,
    tensionSnapshotImage,
    tensionOffBtmSnapshotImage,
    sideForceImage,
    appWobImage,
    wrapsImage,
    stretchImage,
    imageHeight,
  ) => {
    let images = []

    let numOfCharts = 0
    const reportDetails = getItemFromLS('engineeringReportDetails', 'engineeringReportDetails')
    if (!Array.isArray(reportDetails)) return images
    const reportDetail = reportDetails.find((detail) => detail.reportName === 'Torque & Drag')
    if (!reportDetail) return images
    if (!reportDetail.hasOwnProperty('reportDetails')) return images
    if (!Array.isArray(reportDetail.reportDetails)) return images
    for (let i = 0; i < reportDetail.reportDetails.length; i++) {
      if (!reportDetail.reportDetails[i].hasOwnProperty('charts')) continue
      if (!reportDetail.reportDetails[i].hasOwnProperty('reportType')) continue
      if (reportDetail.reportDetails[i].charts) {
        numOfCharts++
        if (reportDetail.reportDetails[i].reportType === 'Drillers Hookloads') {
          images.push({
            breakHeader: numOfCharts % 2,
            sectionAfter: 5,
            data: [[{ text: drillersHookloadsImage, isImage: true, width: '100%', height: imageHeight }]],
          })
        }
        if (reportDetail.reportDetails[i].reportType === 'Calculated Friction Factor') {
          images.push({
            breakHeader: numOfCharts % 2,
            sectionAfter: 5,
            data: [[{ text: calculatedFrictionFactorsImage, isImage: true, width: '100%', height: imageHeight }]],
          })
        }
        if (reportDetail.reportDetails[i].reportType === 'Driller Torques') {
          images.push({
            breakHeader: numOfCharts % 2,
            sectionAfter: 5,
            data: [[{ text: drillersTorquesImage, isImage: true, width: '100%', height: imageHeight }]],
          })
        }
        if (reportDetail.reportDetails[i].reportType === 'On Btm Tension Snapshot') {
          images.push({
            breakHeader: numOfCharts % 2,
            sectionAfter: 5,
            data: [[{ text: tensionSnapshotImage, isImage: true, width: '100%', height: imageHeight }]],
          })
        }
        if (reportDetail.reportDetails[i].reportType === 'Off Btm Tension Snapshot') {
          images.push({
            breakHeader: numOfCharts % 2,
            sectionAfter: 5,
            data: [[{ text: tensionOffBtmSnapshotImage, isImage: true, width: '100%', height: imageHeight }]],
          })
        }
        if (reportDetail.reportDetails[i].reportType === 'Side Force') {
          images.push({
            breakHeader: numOfCharts % 2,
            sectionAfter: 5,
            data: [[{ text: sideForceImage, isImage: true, width: '100%', height: imageHeight }]],
          })
        }
        if (reportDetail.reportDetails[i].reportType === 'Apparent WOB') {
          images.push({
            breakHeader: numOfCharts % 2,
            sectionAfter: 5,
            data: [[{ text: appWobImage, isImage: true, width: '100%', height: imageHeight }]],
          })
        }
        if (reportDetail.reportDetails[i].reportType === 'Pipe Twist') {
          images.push({
            breakHeader: numOfCharts % 2,
            sectionAfter: 5,
            data: [[{ text: wrapsImage, isImage: true, width: '100%', height: imageHeight }]],
          })
        }
        if (reportDetail.reportDetails[i].reportType === 'Pipe Stretch') {
          images.push({
            breakHeader: numOfCharts % 2,
            sectionAfter: 5,
            data: [[{ text: stretchImage, isImage: true, width: '100%', height: imageHeight }]],
          })
        }
      }
    }

    return images
  }

  const addWellboreGeometry = (wellGeometry) => {
    if (!wellGeometry) return []
    if (!Array.isArray(wellGeometry)) return []

    let data = []
    data.push(
      [{ text: 'Wellbore Geometry', columnSpan: 5 }],
      [
        { text: 'Component Type', isHeader: true },
        { text: `MD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `TVD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `ID (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
      ],
    )

    for (let i = 0; i < wellGeometry.length; i++) {
      data.push([
        { text: unescapeHtml(wellGeometry[i].compType) },
        { text: numberWithCommasDecimals(wellGeometry[i].md, 2) },
        { text: numberWithCommasDecimals(wellGeometry[i].tvd, 2) },
        { text: numberWithCommasDecimals(wellGeometry[i].od, 3) },
        { text: numberWithCommasDecimals(wellGeometry[i].id, 3) },
      ])
    }

    return data
  }

  const addDrillStrings = (drillStrings, startDepth) => {
    if (!drillStrings) return []
    if (!Array.isArray(drillStrings)) return []

    let data = []
    data.push(
      [{ text: 'String Data', columnSpan: 13 }],

      [
        { text: '', isHeader: true },
        { text: '#', isHeader: true },
        { text: 'Description', isHeader: true },
        { text: 'Type', isHeader: true },
        { text: 'Material', isHeader: true },
        { text: `OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `ID (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `TJ OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `TJ ID (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `Unit Weight (${getUnitsText(UNITS_FOR.UnitWeight)})`, isHeader: true },
        { text: `Length (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `Total Length (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `MD Start/End (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
      ],
    )

    let totalCompWeight = 0
    let cumLength = 0

    for (let i = 0; i < drillStrings.length; i++) {
      totalCompWeight = totalCompWeight + drillStrings[i].casingWeight * drillStrings[i].length
      cumLength += drillStrings[i].length

      let compTop = startDepth - cumLength
      let compBottom = compTop + drillStrings[i].length
      let image = bhaImages.getPrintImage(drillStrings[i], false)

      data.push([
        { text: image, isImage: true, height: 31, rotate: false },
        { text: `${i + 1}` },
        { text: unescapeHtml(drillStrings[i].pipeName.replace('&dq;', '"')) },
        { text: unescapeHtml(drillStrings[i].type.replace('&dq;', '"')) },
        { text: unescapeHtml(drillStrings[i].material?.materialName.replace('&dq;', '"')) },
        { text: numberWithCommasDecimals(drillStrings[i].pipeOd, 3) },
        { text: numberWithCommasDecimals(drillStrings[i].pipeId, 3) },
        { text: drillStrings[i].tjod ? numberWithCommasDecimals(drillStrings[i].tjod, 3) : '-' },
        { text: drillStrings[i].tjid ? numberWithCommasDecimals(drillStrings[i].tjid, 3) : '-' },
        { text: numberWithCommasDecimals(drillStrings[i].casingWeight, 2) },
        { text: numberWithCommasDecimals(drillStrings[i].length, 2) },
        { text: numberWithCommasDecimals(cumLength, 2) },
        {
          text: `${numberWithCommasDecimals(compTop, 2)}/${numberWithCommasDecimals(compBottom, 2)}`,
        },
      ])
    }

    return data
  }

  const getRotatingSummary = (name, summary) => {
    if (!name) return []
    if (!summary) return []
    if (!Array.isArray(summary)) return []
    if (summary.length === 0) return []

    let summaryIndex = summary.findIndex((item) => item.operation === name)
    if (summaryIndex === -1) return []
    let summaryData = summary[summaryIndex]
    if (!summaryData) return []

    let row = []
    row.push({ text: name, isHeader: true })
    row.push({ text: numberWithCommasDecimals(summaryData.surfaceWeight, 2) })
    row.push({ text: numberWithCommasDecimals(summaryData.surfaceTorque, 2) })
    row.push({ text: '-' })
    row.push({ text: numberWithCommasDecimals(summaryData.stretch, 2) })
    row.push({ text: numberWithCommasDecimals(summaryData.wraps, 2) })
    row.push({ text: '-' })
    row.push({ text: numberWithCommasDecimals(tndData.current.params?.rpm, 2) })
    row.push({ text: summaryData.buckling ? unescapeHtml(summary[summaryIndex].buckling.replace('&dq;', '"')) : '-' })

    return row
  }

  const getSlideDrillingSummary = (wob, summary) => {
    if (!wob) return []
    if (!wob.hasOwnProperty('data')) return []
    if (!Array.isArray(wob.data)) return []
    if (wob.data.length === 0) return []
    if (!summary) return []

    let title = wob.title.replace('App WOB', 'Slide Drilling')
    let row = []
    row.push({ text: title, isHeader: true })
    row.push({ text: numberWithCommasDecimals(summary.surfaceWeight, 2) })
    row.push({ text: numberWithCommasDecimals(summary.surfaceTorque, 2) })

    let { max: maxWob } = getMaxValueFromData(wob)

    row.push({ text: maxWob ? numberWithCommasDecimals(maxWob, 2) : '-' })

    row.push({ text: numberWithCommasDecimals(summary.stretch, 2) })
    row.push({ text: numberWithCommasDecimals(summary.wraps, 2) })
    row.push({ text: '-' })
    row.push({ text: '-' })
    row.push({ text: summary?.buckling ? unescapeHtml(summary.buckling.replace('&dq;', '"')) : '-' })

    return row
  }

  const getTripSummary = (tripWeight, tripSideForce, summary) => {
    if (!tripSideForce) return []
    if (!tripSideForce.hasOwnProperty('data')) return []
    if (!Array.isArray(tripSideForce.data)) return []
    if (tripSideForce.data.length === 0) return []
    if (!summary) return []

    let title = tripWeight.title.replace('SO', 'Tripping In')
    title = title.replace('PU', 'Tripping Out')

    let row = []
    row.push({ text: title, isHeader: true })
    row.push({ text: numberWithCommasDecimals(summary.surfaceWeight, 2) })
    row.push({ text: '-' })
    row.push({ text: '-' })
    row.push({ text: numberWithCommasDecimals(summary.stretch, 2) })
    row.push({ text: numberWithCommasDecimals(summary.wraps, 2) })

    let { max: sideForce, depth } = getMaxValueFromData(tripSideForce)
    row.push({
      text: sideForce
        ? `${numberWithCommasDecimals(sideForce, 2)} @ ${numberWithCommasDecimals(depth, 2)}(${getUnitsText(
            UNITS_FOR.Depth,
          )}) MD`
        : '-',
    })
    row.push({ text: '-' })
    row.push({ text: summary?.buckling ? unescapeHtml(summary.buckling.replace('&dq;', '"')) : '-' })

    return row
  }

  const getReamingSummary = (weight, summary) => {
    if (!weight) return []
    if (!summary) return []

    let row = []
    row.push({ text: weight?.title, isHeader: true })
    row.push({ text: numberWithCommasDecimals(summary.surfaceWeight, 2) })
    row.push({ text: numberWithCommasDecimals(summary.surfaceTorque, 2) })
    row.push({ text: '-' })
    row.push({ text: numberWithCommasDecimals(summary.stretch, 2) })
    row.push({ text: numberWithCommasDecimals(summary.wraps, 2) })
    row.push({ text: '-' })
    row.push({ text: numberWithCommasDecimals(tndData.current.params?.rpm, 2) })
    row.push({
      text: summary.buckling ? unescapeHtml(summary.buckling.replace('&dq;', '"')) : '-',
    })

    return row
  }

  const addTndSummary = (outputs, summary, snapShot) => {
    if (!outputs) return []
    if (!summary) return []
    if (!Array.isArray(summary)) return []
    if (summary.length < 22) return []
    if (!snapShot) return []

    let data = []
    data.push(
      [
        {
          text: `Torque And Drag Summary @ ${numberWithCommasDecimals(
            tndData.current.params?.calcDepth,
            2,
          )}  ${getUnitsText(UNITS_FOR.Depth)} MD`,
          columnSpan: 10,
          backgroundColor: '#FFFFFF',
        },
      ],
      [
        { text: 'Operation', isHeader: true },
        { text: `Drillers Hookload (${getUnitsText(UNITS_FOR.Weight)})`, isHeader: true },
        { text: `Drillers Torque (${getUnitsText(UNITS_FOR.Torque)})`, isHeader: true },
        { text: `Apparent WOB (${getUnitsText(UNITS_FOR.Weight)})`, isHeader: true },
        { text: `Stretch (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: 'Wraps', isHeader: true },
        { text: `SF (${getSideForceUnits()})`, isHeader: true },
        { text: 'RPM', isHeader: true },
        { text: 'Buckling', isHeader: true },
      ],
    )

    data.push(getRotatingSummary('Rotating Off Bottom', summary))
    data.push(getRotatingSummary('Rotating On Bottom', summary))

    if (Array.isArray(outputs.apparentWob)) {
      let summaries = summary.filter((item) => item.operation === 'Slide Drilling')
      if (!summaries) return []
      if (!Array.isArray(summaries)) return []
      if (summaries.length === 0) return []
      if (summaries.length !== outputs.apparentWob.length) return []

      for (let i = 0; i < outputs.apparentWob.length; i++) {
        data.push(getSlideDrillingSummary(outputs.apparentWob[i], summaries[i]))
      }
    }

    if (Array.isArray(outputs.tripInWeight)) {
      let summaries = summary.filter((item) => item.operation === 'Tripping In')
      if (!summaries) return []
      if (!Array.isArray(summaries)) return []
      if (summaries.length === 0) return []
      if (summaries.length !== outputs.tripInWeight.length) return []
      if (!snapShot.sideForceTripIn) return []
      if (!Array.isArray(snapShot.sideForceTripIn)) return []
      if (snapShot.sideForceTripIn.length === 0) return []
      if (snapShot.sideForceTripIn.length !== outputs.tripInWeight.length) return []

      for (let i = 0; i < outputs.tripInWeight.length; i++) {
        data.push(getTripSummary(outputs.tripInWeight[i], snapShot.sideForceTripIn[i], summaries[i]))
      }
    }

    if (Array.isArray(outputs.tripOutWeight)) {
      let summaries = summary.filter((item) => item.operation === 'Tripping Out')
      if (!summaries) return []
      if (!Array.isArray(summaries)) return []
      if (summaries.length === 0) return []
      if (summaries.length !== outputs.tripOutWeight.length) return []
      if (!snapShot.sideForceTripOut) return []
      if (!Array.isArray(snapShot.sideForceTripOut)) return []
      if (snapShot.sideForceTripOut.length === 0) return []
      if (snapShot.sideForceTripOut.length !== outputs.tripOutWeight.length) return []

      for (let i = 0; i < outputs.tripOutWeight.length; i++) {
        data.push(getTripSummary(outputs.tripOutWeight[i], snapShot.sideForceTripOut[i], summaries[i]))
      }
    }

    if (Array.isArray(outputs.reamInWeight)) {
      let summaries = summary.filter((item) => item.operation === 'Reaming In')
      if (!summaries) return []
      if (!Array.isArray(summaries)) return []
      if (summaries.length === 0) return []
      if (summaries.length !== outputs.reamInWeight.length) return []

      for (let i = 0; i < outputs.reamInWeight.length; i++) {
        data.push(getReamingSummary(outputs.reamInWeight[i], summaries[i]))
      }
    }

    if (Array.isArray(outputs.reamOutWeight)) {
      let summaries = summary.filter((item) => item.operation === 'Reaming Out')
      if (!summaries) return []
      if (!Array.isArray(summaries)) return []
      if (summaries.length === 0) return []
      if (summaries.length !== outputs.reamOutWeight.length) return []

      for (let i = 0; i < outputs.reamOutWeight.length; i++) {
        data.push(getReamingSummary(outputs.reamOutWeight[i], summaries[i]))
      }
    }

    return data
  }

  const getTndPdfData = (chartElement, wellElement, slideSheetData, witsData) => {
    return new Promise(async (resolve) => {
      if (typeof currentWellRef.current !== 'string') return
      if (currentWellRef.current === '') return
      if (!selectedEngCase) return
      if (!selectedEngCase.hasOwnProperty('recordId')) return

      let title = `Torque and Drag Report`

      let drillersHookloadsElement = chartElement.getChartType('drillersHookloads')
      const drillersHookloadsCanvas = await html2canvas(drillersHookloadsElement.current)
      const drillersHookloadsImage = drillersHookloadsCanvas.toDataURL('image/png', 1.0)

      let calculatedFrictionFactorsElement = chartElement.getChartType('calculatedFrictionFactors')
      const calculatedFrictionFactorsCanvas = await html2canvas(calculatedFrictionFactorsElement.current)
      const calculatedFrictionFactorsImage = calculatedFrictionFactorsCanvas.toDataURL('image/png', 1.0)

      let drillersTorquesElement = chartElement.getChartType('drillersTorques')
      const drillersTorquesCanvas = await html2canvas(drillersTorquesElement.current)
      const drillersTorquesImage = drillersTorquesCanvas.toDataURL('image/png', 1.0)

      let tensionSnapshotElement = chartElement.getChartType('tensionSnapshot')
      const tensionSnapshotCanvas = await html2canvas(tensionSnapshotElement.current)
      const tensionSnapshotImage = tensionSnapshotCanvas.toDataURL('image/png', 1.0)

      let tensionOffBtmSnapshotElement = chartElement.getChartType('tensionOffBtmSnapshot')
      const tensionOffBtmSnapshotCanvas = await html2canvas(tensionOffBtmSnapshotElement.current)
      const tensionOffBtmSnapshotImage = tensionOffBtmSnapshotCanvas.toDataURL('image/png', 1.0)

      let sideForceElement = chartElement.getChartType('sideForce')
      const sideForceCanvas = await html2canvas(sideForceElement.current)
      const sideForceImage = sideForceCanvas.toDataURL('image/png', 1.0)

      let appWobElement = chartElement.getChartType('appWob')
      const appWobCanvas = await html2canvas(appWobElement.current)
      const appWobImage = appWobCanvas.toDataURL('image/png', 1.0)

      let wrapsElement = chartElement.getChartType('wraps')
      const wrapsCanvas = await html2canvas(wrapsElement.current)
      const wrapsImage = wrapsCanvas.toDataURL('image/png', 1.0)

      let stretchElement = chartElement.getChartType('stretch')
      const stretchCanvas = await html2canvas(stretchElement.current)
      const stretchImage = stretchCanvas.toDataURL('image/png', 1.0)

      const pdfDoc = await generatePdfDocument(
        slideSheetData,
        witsData,
        drillersHookloadsImage,
        calculatedFrictionFactorsImage,
        drillersTorquesImage,
        tensionSnapshotImage,
        tensionOffBtmSnapshotImage,
        sideForceImage,
        appWobImage,
        wrapsImage,
        stretchImage,
        300,
      )

      resolve({
        fileName: `${title} - ${replaceEscapeSymbols(removeSpecialSymbols(currentWellRef.current))} - ${
          selectedEngCase?.caseDesc
        }`,
        data: (
          <PdfDocument
            data={pdfDoc}
            multiDocument={false}
            pageOrientation={'portrait'}
            reportSettings={userRole?.userPrefs?.reportSettings}
            footerText={`${userRole.organization} powered by Innova ${new Date().getFullYear()}`}
          />
        ),
      })
    })
  }

  function interpExtrapFrictionFactor(data, measuredValue) {
    if (!Array.isArray(data) || data.length === 0) return -999.25
    data.sort((a, b) => a.value - b.value)

    let point1 = null
    let point2 = null

    for (let i = 0; i < data.length - 1; i++) {
      if (data[i].value <= measuredValue && data[i + 1].value >= measuredValue) {
        point1 = data[i]
        point2 = data[i + 1]
        break
      }
    }

    // If measured value is outside the range of the data, extrapolate
    if (!point1 || !point2) {
      if (measuredValue <= data[0].value) {
        point1 = data[0]
        point2 = data[1]
      } else {
        point1 = data[data.length - 2]
        point2 = data[data.length - 1]
      }
    }

    const slope = (point2.ff.oh - point1.ff.oh) / (point2.value - point1.value)
    const interpolatedFf = point1.ff.oh + slope * (measuredValue - point1.value)
    if (interpolatedFf < 0) return -999.25
    return interpolatedFf
  }

  function interpValue(p1, p2, depth) {
    if (!p1 || !p2) return -999.25

    const slope = (p2.data - p1.data) / (p2.depth - p1.depth)
    const interp = p1.data + slope * (depth - p1.depth)
    return interp
  }

  const extractFrictionFactorsFromTitle = (title) => {
    let output = { ch: -1, oh: -1 }
    if (!title) return output
    if (typeof title !== 'string') return output
    if (title === '') return output

    let splitTitle = title.split('@')

    if (splitTitle.length !== 2) return output
    let splitFrictionFactor = splitTitle[1].split('OH')
    if (splitFrictionFactor.length !== 2) return output

    let chFf = parseFloat(splitFrictionFactor[0].replace('CH', '').trim())
    let ohFf = parseFloat(splitFrictionFactor[1].trim())

    output.ch = chFf
    output.oh = ohFf
    return output
  }

  const getInterpolatedHookloadData = (hookloadData, depth) => {
    if (!Array.isArray(hookloadData) || hookloadData.length === 0) return null
    if (!Array.isArray(hookloadData[0]?.data)) return null
    if (hookloadData[0].data.length <= 1) return null

    let frictionFactors = []
    for (let i = 0; i < hookloadData.length; i++) {
      let ff = extractFrictionFactorsFromTitle(hookloadData[i].title)
      if (ff.oh < 0) continue
      frictionFactors.push(ff)
    }

    if (frictionFactors.length !== hookloadData.length) return null

    let depthIndex1 = -1
    let depthIndex2 = -1

    for (let i = 1; i < hookloadData[0].data.length; i++) {
      if (depth > hookloadData[0].data[i].depth) continue

      depthIndex1 = i - 1
      depthIndex2 = i
      break
    }

    if (depthIndex1 < 0 || depthIndex2 < 0) return null
    return frictionFactors.map((ff, index) => {
      return {
        value: interpValue(hookloadData[index].data[depthIndex1], hookloadData[index].data[depthIndex2], depth),
        ff: ff,
      }
    })
  }

  function movingAverage(points, windowSize) {
    if (points.length < windowSize) {
      windowSize = points.length
    }

    const smoothedPoints = []
    for (let i = 0; i < points.length - windowSize + 1; i++) {
      let sumX = 0
      let sumY = 0

      for (let j = i; j < i + windowSize; j++) {
        sumX += points[j].x
        sumY += points[j].y
      }

      const smoothedPoint = {
        x: sumX / windowSize,
        y: sumY / windowSize,
      }

      smoothedPoints.push(smoothedPoint)
    }

    return smoothedPoints
  }

  const getCalculatedFrictionFactorData = (slideSheetData, witsData) => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!tndData.current) return chartData
    if (!tndData.current.hasOwnProperty('drillersOutput')) return chartData
    if (!tndData.current.drillersOutput.hasOwnProperty('tripInWeight')) return chartData
    if (!tndData.current.drillersOutput.hasOwnProperty('tripOutWeight')) return chartData
    if (!Array.isArray(tndData.current.drillersOutput.tripInWeight)) return chartData
    if (!Array.isArray(tndData.current.drillersOutput.tripOutWeight)) return chartData
    if (tndData.current.drillersOutput.tripInWeight.length === 0) return chartData
    if (tndData.current.drillersOutput.tripOutWeight.length === 0) return chartData

    let hasSlideSheetData = Array.isArray(slideSheetData) && slideSheetData.length > 0
    let hasWitsData = Array.isArray(witsData) && witsData.length > 0
    if (!hasSlideSheetData && !hasWitsData) return chartData

    chartData.datasets.push({
      label: 'PU FF (Slide Sheet)',
      borderColor: interpChartColor('blue', 0, 2),
      backgroundColor: interpChartColor('blue', 0, 2),
      data: [],
    })

    chartData.datasets.push({
      label: 'SO FF (Slide Sheet)',
      borderColor: interpChartColor('red', 0, 2),
      backgroundColor: interpChartColor('red', 0, 2),
      data: [],
    })

    chartData.datasets.push({
      label: 'PU FF (WITS)',
      borderColor: interpChartColor('blue', 1, 2),
      backgroundColor: interpChartColor('blue', 1, 2),
      data: [],
    })

    chartData.datasets.push({
      label: 'SO FF (WITS)',
      borderColor: interpChartColor('red', 1, 2),
      backgroundColor: interpChartColor('red', 1, 2),
      data: [],
    })

    chartData.datasets.push({
      label: 'Trip Out FF (WITS)',
      borderColor: interpChartColor('yellow', 1, 2),
      backgroundColor: interpChartColor('yellow', 1, 2),
      data: [],
    })

    chartData.datasets.push({
      label: 'Trip In FF (WITS)',
      borderColor: interpChartColor('purple', 1, 2),
      backgroundColor: interpChartColor('purple', 1, 2),
      data: [],
    })

    if (hasSlideSheetData) {
      let puIndex = slideSheetData.findIndex((item) => item.label === 'P/U Weight')
      let soIndex = slideSheetData.findIndex((item) => item.label === 'S/O Weight')

      if (puIndex >= 0 && soIndex >= 0) {
        for (let i = 0; i < slideSheetData[puIndex].data.length; i++) {
          let depth = slideSheetData[puIndex].data[i].y
          let slideSheetPu = slideSheetData[puIndex].data[i].x
          let slideSheetSo = slideSheetData[soIndex].data[i].x

          let calcPuData = getInterpolatedHookloadData(tndData.current.drillersOutput.tripOutWeight, depth)
          let calcSoData = getInterpolatedHookloadData(tndData.current.drillersOutput.tripInWeight, depth)

          let puFf = interpExtrapFrictionFactor(calcPuData, slideSheetPu)
          let soFf = interpExtrapFrictionFactor(calcSoData, slideSheetSo)

          if (puFf > 0) chartData.datasets[0].data.push({ x: puFf, y: depth })
          if (soFf > 0) chartData.datasets[1].data.push({ x: soFf, y: depth })
        }

        let windowSize = Math.floor(chartData.datasets[0].data.length / 5)
        if (windowSize <= 0) windowSize = 1

        chartData.datasets[0].data = movingAverage(chartData.datasets[0].data, windowSize)
        chartData.datasets[1].data = movingAverage(chartData.datasets[1].data, windowSize)
      }
    }

    if (hasWitsData) {
      let puIndex = witsData.findIndex((item) => item.label === 'P/U Weight (WITS)')
      let soIndex = witsData.findIndex((item) => item.label === 'S/O Weight (WITS)')
      let tripOutIndex = witsData.findIndex((item) => item.label === 'Trip Out Weight (WITS)')
      let tripInIndex = witsData.findIndex((item) => item.label === 'Trip In Weight (WITS)')

      let indexArray = [
        { index: puIndex, tag: 'tripOutWeight' },
        { index: soIndex, tag: 'tripInWeight' },
        { index: tripOutIndex, tag: 'tripOutWeight' },
        { index: tripInIndex, tag: 'tripInWeight' },
      ]

      let windowSize = Math.floor(chartData.datasets[0].data.length / 5)
      if (windowSize <= 0) windowSize = 1

      for (let i = 0; i < indexArray.length; i++) {
        if (indexArray[i] < 0) continue
        let index = indexArray[i].index
        if (index < 0) continue

        for (let j = 0; j < witsData[index].data.length; j++) {
          let depth = witsData[index].data[j].y
          let val = witsData[index].data[j].x

          let calcData = getInterpolatedHookloadData(tndData.current.drillersOutput[indexArray[i].tag], depth)
          let ff = interpExtrapFrictionFactor(calcData, val)
          if (ff > 0) chartData.datasets[i + 2].data.push({ x: interpExtrapFrictionFactor(calcData, val), y: depth })
        }

        chartData.datasets[i + 2].data = movingAverage(chartData.datasets[i + 2].data, windowSize)
      }
    }

    return chartData
  }

  const getWeightUnits = () => {
    return 'WEIGHT'
  }

  return {
    loading,
    fetchTnd,
    getDrillersHookloadsData,
    getDrillersTorquesData,
    getGeometryAnnotations,
    getTensionSnapshotData,
    getTndWarnings,
    getTndErrors,
    getTndPdfData,
    getTensionOffBtmSnapshotData,
    getSideForceData,
    getAppWobData,
    getWrapsData,
    getStretchData,
    getSideForceUnits,
    getWOBtext,
    getCalculatedFrictionFactorData,
    getWeightUnits,
  }
}

export default useTorqueAndDrag
