import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Box } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useUnits from 'components/common/hooks/useUnits'
import ElementPropertyModal from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/ElementPropertyModal'
import {
  ELEMENT_TYPES as elementType,
  TABLE_TYPES as tableType,
} from 'components/WellPages/WallplotComposer/Elements/elementDefs'
import {
  projectDetailsTableDefn,
  summaryTableDefn,
  referencesTableDefn,
  fieldDetailsTableDefn,
  facilityDetailsTableDefn,
  wellDetailsTableDefn,
  wellboreDetailsTableDefn,
  surveyProgramTableDefn,
  casingDetailsTableDefn,
  lithologyTableDefn,
  targetDetailsTableDefn,
  approvalsTableDefn,
  leaseLineTableDefn,
  annotationsTableDefn,
  mappingGridTableDefn,
  titleBarTableDefn,
  datumElevationTableDefn,
  getWellDataValue,
} from 'components/WellPages/WallplotComposer/Elements/TableDefs'

export const getTableElementDefaults = ({ pageLayout, zIndex }) => {
  return {
    uid: uuidv4(),
    type: elementType.table,
    left: pageLayout.marginLeft,
    top: pageLayout.marginTop,
    width: 2,
    height: 1,
    zIndex: zIndex,
    style: {
      color: '#333',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: '#333',
      backgroundColor: '#fff',
      fontFamily: 'Arial',
      fontSize: '10px',
      fontWeight: '400',
      fontStyle: 'normal',
      opacity: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    tableSettings: {
      tableType: '',
    },
  }
}

const getTemplateFromTableType = (tType) => {
  switch (tType) {
    case tableType.projectDetails:
      return projectDetailsTableDefn
    case tableType.summary:
      return summaryTableDefn
    case tableType.references:
      return referencesTableDefn
    case tableType.fieldDetails:
      return fieldDetailsTableDefn
    case tableType.facilityDetails:
      return facilityDetailsTableDefn
    case tableType.wellDetails:
      return wellDetailsTableDefn
    case tableType.wellboreDetails:
      return wellboreDetailsTableDefn
    case tableType.surveyProgram:
      return surveyProgramTableDefn
    case tableType.casingDetails:
      return casingDetailsTableDefn
    case tableType.lithology:
      return lithologyTableDefn
    case tableType.targetDetails:
      return targetDetailsTableDefn
    case tableType.approvals:
      return approvalsTableDefn
    case tableType.leaseLine:
      return leaseLineTableDefn
    case tableType.annotations:
      return annotationsTableDefn
    case tableType.mappingGrid:
      return mappingGridTableDefn
    case tableType.titleBar:
      return titleBarTableDefn
    case tableType.datumElevation:
      return datumElevationTableDefn
    default:
      return ''
  }
}

const TableElement = forwardRef(({ id, element, wellData, actions, updateContainer, scale }, ref) => {
  const [showProperties, setShowProperties] = useState(false)
  const [currentStyle, setCurrentStyle] = useState(element.style)
  const { getUnitsText } = useUnits()

  useImperativeHandle(ref, () => {
    return {
      showProperties() {
        setShowProperties(true)
      },
    }
  })

  const handleChange = (newData) => {
    actions.update(id, 'update', { style: { ...newData.style } })
    setCurrentStyle({ ...newData.style })
    setShowProperties(false)
  }

  const RenderTable = ({ template, type }) => {
    return (
      <Box sx={{ width: '100%', height: '100%', padding: '10px 0', display: 'flex', flexDirection: 'column' }}>
        {template.head && (
          <Box sx={{ width: '100%', paddingBottom: '10px' }}>
            {template.head.lines.map((line, idx) => (
              <Box
                key={`${type}-table-head-${idx}`}
                sx={{
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center',
                  fontWeight: `${line.style === 'header' ? 700 : 400}`,
                }}>
                {line.label}
              </Box>
            ))}
          </Box>
        )}
        <Box sx={{ className: 'colContainer', display: 'flex', flexDirection: 'row', paddingTop: '8px' }}>
          {template.body &&
            template.body.columns.map((column, cidx) => (
              <Box key={`${type}-table-body-${cidx}`} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                {column.lines &&
                  column.lines.map((line, idx) => (
                    <Box key={`${type}-${cidx}-line-${idx}`} sx={{ display: 'flex', width: '100%' }}>
                      {(line.label || line.dynamicLabel) && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: `${line.labelAlign ? line.labelAlign : 'left'}`,
                            alignItems: 'center',
                            width: `${line.tag || line.value || line.dataExtractor ? '40%' : '100%'}`, // if no tag or value, make label 100% width
                            paddingLeft: '4px',
                            fontWeight: `${line.labelStyle === 'header' ? 700 : 400}`,
                            paddingBottom: `${line.labelStyle === 'header' ? '8px' : '0px'}`,
                          }}>
                          {line.dynamicLabel ? line.dynamicLabel(wellData) : line.label}
                        </Box>
                      )}
                      {(line.tag || line.value) && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'left',
                            alignItems: 'center',
                            width: `${line.label || line.dynamicLabel ? '60%' : '100%'}`,
                            paddingLeft: '4px',
                            fontWeight: `${line.tagStyle === 'header' ? 700 : 400}`,
                            paddingBottom: `${line.labelStyle === 'header' ? '8px' : '0px'}`,
                          }}>
                          {line.tag?.length > 0
                            ? getWellDataValue(wellData, line.tag, line?.valueFormatter, getUnitsText(line.tagUnits))
                            : line.value}
                        </Box>
                      )}
                      {line.dataExtractor && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'left',
                            alignItems: 'center',
                            width: `${line.label || line.dynamicLabel ? '60%' : '100%'}`,
                            paddingLeft: '4px',
                            fontWeight: `${line.tagStyle === 'header' ? 700 : 400}`,
                            paddingBottom: `${line.labelStyle === 'header' ? '8px' : '0px'}`,
                          }}>
                          {line.dataExtractor(wellData, getUnitsText(line.tagUnits))}
                        </Box>
                      )}
                    </Box>
                  ))}
                {column.head && (
                  <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '8px' }}>
                    {column.head.map((header, hidx) => (
                      <Box
                        key={`${type}-table-col-header-${hidx}`}
                        className='wpc-tbl-col-header'
                        sx={{
                          display: 'flex',
                          justifyContent: 'left',
                          alignItems: 'center',
                          width: `calc(100% / ${column.head.length})`,
                          paddingLeft: '4px',
                          fontWeight: `${header.style === 'header' ? 700 : 400}`,
                        }}>
                        {header.label}
                        {header.labelUnits ? ` (${getUnitsText(header.labelUnits)})` : ''}
                      </Box>
                    ))}
                  </Box>
                )}
                {column.array && !column.array.dataExtractor && (
                  <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '8px', height: '100%' }}>
                    {column.array.cols.map((col, cidx) => (
                      <Box
                        key={`col-group-${cidx}`}
                        sx={{ display: 'flex', flexDirection: 'column', width: `calc(100% / ${column.head.length})` }}>
                        {Array.isArray(getWellDataValue(wellData, column.array.tag)) &&
                          getWellDataValue(wellData, column.array.tag).map((line, lidx) => (
                            <Box
                              key={`${type}-table-row-line-${lidx}`}
                              className='wpc-tbl-row-line-dex-none'
                              sx={{
                                display: 'inline-block',
                                justifyContent: 'left',
                                alignItems: 'center',
                                paddingLeft: '4px',
                                fontWeight: column.array.style === 'header' ? 700 : 400,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}>
                              {numberWithCommasDecimals(line[col.tag], col.tagDecimals)}
                            </Box>
                          ))}
                      </Box>
                    ))}
                  </Box>
                )}
                {column.array && column.array.dataExtractor && (
                  <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '8px' }}>
                    {column.array.cols.map((col, cidx) => (
                      <Box
                        key={`col-group-${cidx}`}
                        sx={{ display: 'flex', flexDirection: 'column', width: `calc(100% / ${column.head.length})` }}>
                        {Array.isArray(column.array.dataExtractor(wellData, column.array.tag)) &&
                          column.array.dataExtractor(wellData, column.array.tag).map((line, lidx) => (
                            <Box
                              key={`${type}-table-row-line-${lidx}`}
                              className='wpc-tbl-row-line-dex'
                              sx={{
                                display: 'inline-block',
                                justifyContent: 'left',
                                alignItems: 'center',
                                paddingLeft: '4px',
                                fontWeight: column.array.style === 'header' ? 700 : 400,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}>
                              {numberWithCommasDecimals(line[col.tag], col.tagDecimals)}
                            </Box>
                          ))}
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            ))}
        </Box>
      </Box>
    )
  }

  return (
    <>
      {showProperties && (
        <ElementPropertyModal
          onClose={() => setShowProperties(false)}
          elementStyle={currentStyle}
          elementData={null}
          onApply={handleChange}
          elemType={elementType.text}
        />
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: currentStyle.justifyContent,
          alignItems: currentStyle.alignItems,
          height: '100%',
          width: '100%',
          position: 'absolute',
        }}
        onDoubleClick={(e) => {
          e.stopPropagation()
          setShowProperties(true)
        }}>
        <Box
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            color: currentStyle.color,
            fontFamily: currentStyle.fontFamily,
            fontSize: scale && !isNaN(scale) ? `${parseFloat(currentStyle.fontSize) * scale}px` : currentStyle.fontSize,
            fontWeight: currentStyle.fontWeight,
            fontStyle: currentStyle.fontStyle,
            padding: '0 8px',
            opacity: 1,
          }}>
          <RenderTable
            template={getTemplateFromTableType(element.tableSettings.tableType)}
            type={element.tableSettings.tableType}
          />
        </Box>
      </div>
    </>
  )
})

export default TableElement
