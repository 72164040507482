import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Box } from '@mui/material'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const ChartScalesGrid = forwardRef(({ chartData }, ref) => {
  const gridApiXY = useRef(null)
  const gridApiFixed = useRef(null)
  const { getUnitsText } = useUnits()
  const { theme, getAgGridTheme } = useInnovaTheme()

  const getRowIdXY = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const getRowIdFixed = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  useImperativeHandle(ref, () => ({
    stopEditing: () => {
      if (gridApiXY.current) gridApiXY.current.stopEditing()
      if (gridApiFixed.current) gridApiFixed.current.stopEditing()
    },
    getData: () => {
      let gridData = []
      let gridDataXY = []
      gridApiXY.current?.forEachNode((node) => {
        if (node.data) gridDataXY.push(node.data)
      })

      let gridDataFixed = []
      gridApiFixed.current?.forEachNode((node) => {
        if (node.data) gridDataFixed.push(node.data)
      })

      // return data in property format
      gridData.push({ tag: 'xAxisUserDefined', value: gridDataXY[0].userDefined })
      gridData.push({ tag: 'xMin', value: gridDataXY[0].min })
      gridData.push({ tag: 'xMax', value: gridDataXY[0].max })
      gridData.push({ tag: 'xAxisScale', value: gridDataXY[0].scale })
      gridData.push({ tag: 'yAxisUserDefined', value: gridDataXY[1].userDefined })
      gridData.push({ tag: 'yMin', value: gridDataXY[1].min })
      gridData.push({ tag: 'yMax', value: gridDataXY[1].max })
      gridData.push({ tag: 'yAxisScale', value: gridDataXY[1].scale })
      gridData.push({ tag: 'fixedScaleEnabled', value: gridDataFixed[0].fixedScaleEnabled })
      gridData.push({ tag: 'fixedScaleValue', value: gridDataFixed[0].fixedScaleValue })
      gridData.push({ tag: 'fixedScaleCm', value: gridDataFixed[0].fixedScaleCm })
      gridData.push({ tag: 'showScales', value: gridDataFixed[0].showScales })

      return { props: gridData }
    },
  }))

  const onGridReadyXY = (params) => {
    gridApiXY.current = params.api
  }

  const onGridReadyFixed = (params) => {
    gridApiFixed.current = params.api
  }

  const autoSizeColumnsXY = () => {
    if (gridApiXY.current === null) return
    gridApiXY.current?.autoSizeAllColumns()
  }

  const autoSizeColumnsFixed = () => {
    if (gridApiFixed.current === null) return
    gridApiFixed.current?.autoSizeAllColumns()
  }

  const onFirstDataRenderedXY = (params) => {
    if (gridApiXY.current) autoSizeColumnsXY()
  }

  const onFirstDataRenderedFixed = (params) => {
    if (gridApiFixed.current) autoSizeColumnsFixed()
  }

  const defaultColDefXY = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const defaultColDefFixed = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const gridOptionsXY = {
    suppressRowClickSelection: true,
  }

  const gridOptionsFixed = {
    suppressRowClickSelection: true,
  }

  const getGridDataXY = (params) => {
    let gridData = { x: {}, y: {} }
    gridApiXY.current.forEachNode((node) => {
      Object.keys(node.data).forEach((key) => {
        gridData[node.id === '0' ? 'x' : 'y'][key] = node.data[key]
      })
    })
    return gridData
  }

  const getGridDataFixed = () => {
    let gridData = {}
    gridApiFixed.current.forEachNode((node) => {
      Object.keys(node.data).forEach((key) => {
        gridData[key] = node.data[key]
      })
    })
    return gridData
  }

  const handleCellValueChangedXY = (params) => {
    let gridDataFixed = getGridDataFixed()

    if (params.column.colId === 'userDefined' && params.newValue === true) {
      gridApiFixed.current.forEachNode((node) => {
        node.setDataValue('fixedScaleEnabled', false)
      })
    }

    if (params.column.colId === 'min' || params.column.colId === 'max') {
      params.api.forEachNode((node) => {
        if (node.id === params.node.id) {
          let pixelsPerDisplayUnit = 72 // 72 pixels per inch
          if (gridDataFixed.fixedScaleCm) pixelsPerDisplayUnit = 28.3465
          let diff = params.node.data.max - params.node.data.min // units (ft or m)
          let scale = diff / (chartData.width / pixelsPerDisplayUnit)
          node.setDataValue('scale', scale)
        }
      })
    }
  }

  const handleCellValueChangedFixed = (params) => {
    let gridDataXY = getGridDataXY()
    let gridDataFixed = getGridDataFixed()

    if (params.column.colId === 'fixedScaleCm') {
      let pixelsPerDisplayUnit = 72
      if (gridDataFixed.fixedScaleCm) pixelsPerDisplayUnit = 28.3465
      let xDiff = gridDataXY.x.max - gridDataXY.x.min
      let xScale = xDiff / (chartData.width / pixelsPerDisplayUnit)
      let yDiff = gridDataXY.y.max - gridDataXY.y.min
      let yScale = yDiff / (chartData.height / pixelsPerDisplayUnit)

      const allColDefsXY = gridApiXY.current.getAllGridColumns().map((col) => col.getColDef())
      const allColDefsFixed = params.gridApi.getAllGridColumns().map((col) => col.getColDef())

      allColDefsXY.forEach((colDef) => {
        if (colDef.field === 'scale') {
          colDef.headerName = `Scale (${getUnitsText(UNITS_FOR.Depth) || 'ft'}/${params.newValue ? 'cm' : 'in'})`
        }
      })
      gridApiXY.current.setColumnDefs(allColDefsXY)

      allColDefsFixed.forEach((colDef) => {
        if (colDef.field === 'fixedScaleValue') {
          colDef.headerName = `Fixed Scale Value (${getUnitsText(UNITS_FOR.Depth) || 'ft'}/${
            params.newValue ? 'cm' : 'in'
          })`
        }
      })
      params.api.setColumnDefs(allColDefsFixed)

      gridApiXY.current.forEachNode((node) => {
        switch (node.id) {
          case '0':
            node.setDataValue('scale', xScale)
            break
          case '1':
            node.setDataValue('scale', yScale)
            break
          default:
            break
        }
      })

      params.api.forEachNode((node) => {
        node.setDataValue('fixedScaleValue', xScale > yScale ? xScale : yScale)
      })
    }

    if (params.column.colId === 'fixedScaleEnabled' && params.newValue === true) {
      if (!chartData.hasOwnProperty('width') || !chartData.hasOwnProperty('height')) {
        return
      }
      let pixelsPerDisplayUnit = 72
      if (params.data?.fixedScaleCm) pixelsPerDisplayUnit = 28.3465
      let xDiff = gridDataXY.x.max - gridDataXY.x.min
      let yDiff = gridDataXY.y.max - gridDataXY.y.min
      let xScale = xDiff / (chartData.width / pixelsPerDisplayUnit)
      let yScale = yDiff / (chartData.height / pixelsPerDisplayUnit)

      gridApiXY.current.forEachNode((node) => {
        node.setDataValue('userDefined', false)
        node.setDataValue('scale', xScale > yScale ? xScale : yScale)
      })

      params.api.forEachNode((node) => {
        node.setDataValue('fixedScaleValue', xScale > yScale ? xScale : yScale)
      })
    }

    if (params.column.colId === 'fixedScaleValue') {
      if (!chartData.hasOwnProperty('width') || !chartData.hasOwnProperty('height')) {
        return
      }
      let pixelsPerDisplayUnit = 72 // 72 pixels per inch
      if (params.data?.fixedScaleCm) pixelsPerDisplayUnit = 28.3465 // 28.3465 pixels per cm
      let xDiff = gridDataXY.x.max - gridDataXY.x.min
      let yDiff = gridDataXY.y.max - gridDataXY.y.min
      let xNewDiff = (params.newValue * chartData.width) / pixelsPerDisplayUnit
      let yNewDiff = (params.newValue * chartData.height) / pixelsPerDisplayUnit
      let xOffset = (xNewDiff - xDiff) / 2
      let yOffset = (yNewDiff - yDiff) / 2

      // init fixed scale value to one of the axes
      gridApiXY.current.forEachNode((node) => {
        switch (node.id) {
          case '0':
            node.setDataValue('min', gridDataXY.x.min - xOffset)
            node.setDataValue('max', gridDataXY.x.max + xOffset)
            node.setDataValue('scale', params.newValue)
            break
          case '1':
            node.setDataValue('min', gridDataXY.y.min - yOffset)
            node.setDataValue('max', gridDataXY.y.max + yOffset)
            node.setDataValue('scale', params.newValue)
            break
          default:
            break
        }
      })
    }
  }

  const checkIsEditableXY = (params) => {
    let returnVal = true
    switch (params.column.colId) {
      case 'min':
      case 'max':
        returnVal = false
        if (params.data?.userDefined) returnVal = true
        break
      case 'scale':
        returnVal = false
        break
      default:
        break
    }
    return returnVal
  }

  const checkIsEditableFixed = (params) => {
    let returnVal = true
    switch (params.column.colId) {
      case 'fixedScaleValue':
        returnVal = false
        params.api.forEachNode((node) => {
          if (node.data.fixedScaleEnabled) returnVal = true
        })
        break
      default:
        break
    }
    return returnVal
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const rightAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
    }),
    [],
  )

  const columnDefsXY = useMemo(
    () => [
      {
        headerName: 'Axis',
        field: 'axis',
        colId: 'axis',
        minWidth: 75,
        cellStyle: centerAlignCell,
      },
      {
        headerName: 'Min',
        field: 'min',
        colId: 'min',
        flex: 1,
        editable: (params) => checkIsEditableXY(params),
        cellStyle: rightAlignCell,
        cellEditorSelector: (params) => {
          return {
            component: 'agTextCellEditor',
            params: {
              min: params.data?.min,
              max: params.data?.max,
              precision: params.data?.precision ? params.data?.precision : 0,
            },
          }
        },
        valueFormatter: (params) => {
          return `${numberWithCommasDecimals(params?.value, params.data?.precision || 0)}`
        },
      },
      {
        headerName: 'Max',
        field: 'max',
        colId: 'max',
        flex: 1,
        editable: (params) => checkIsEditableXY(params),
        cellStyle: rightAlignCell,
        cellEditorSelector: (params) => {
          return {
            component: 'agTextCellEditor',
            params: {
              min: params.data?.min,
              max: params.data?.max,
              precision: params.data?.precision ? params.data?.precision : 0,
            },
          }
        },
        valueFormatter: (params) => {
          return `${numberWithCommasDecimals(params?.value, params.data?.precision || 0)}`
        },
      },
      {
        headerName: `Scale ${getUnitsText(UNITS_FOR.Depth) || 'ft'}/${chartData.fixedScaleCm ? 'cm' : 'in'}`,
        field: 'scale',
        colId: 'scale',
        flex: 1,
        editable: false,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return `${numberWithCommasDecimals(params?.value, params.data?.precision || 0)}`
        },
      },
      {
        headerName: 'User Defined',
        field: 'userDefined',
        colId: 'userDefined',
        flex: 1,
        editable: true,
        cellStyle: centerAlignCell,
        cellEditorSelector: (params) => {
          return {
            component: 'agCheckboxCellEditor',
          }
        },
      },
    ],
    [chartData.fixedScaleCm, getUnitsText, rightAlignCell, centerAlignCell],
  )

  const columnDefsFixed = useMemo(
    () => [
      {
        headerName: 'Fixed Scale',
        field: 'fixedScaleEnabled',
        colId: 'fixedScaleEnabled',
        flex: 1,
        editable: true,
        cellStyle: centerAlignCell,
        cellEditorSelector: (params) => {
          return {
            component: 'agCheckboxCellEditor',
          }
        },
      },
      {
        headerName: `Fixed Scale Value (${getUnitsText(UNITS_FOR.Depth) || 'ft'}/${
          chartData.fixedScaleCm ? 'cm' : 'in'
        })`,
        field: 'fixedScaleValue',
        colId: 'fixedScaleValue',
        flex: 1,
        editable: (params) => checkIsEditableFixed(params),
        cellStyle: centerAlignCell,
        cellEditorSelector: (params) => {
          return {
            component: 'agTextCellEditor',
            params: {
              min: params.data?.min,
              max: params.data?.max,
              precision: params.data?.precision ? params.data?.precision : 0,
            },
          }
        },
        valueFormatter: (params) => {
          return `${numberWithCommasDecimals(params?.value, params.data?.precision || 0)}`
        },
      },
      {
        headerName: 'Scale/cm',
        field: 'fixedScaleCm',
        colId: 'fixedScaleCm',
        flex: 1,
        editable: true,
        cellStyle: centerAlignCell,
        cellEditorSelector: (params) => {
          return {
            component: 'agCheckboxCellEditor',
          }
        },
      },
      {
        headerName: 'Show Scales',
        field: 'showScales',
        colId: 'showScales',
        flex: 1,
        editable: true,
        cellStyle: centerAlignCell,
        cellEditorSelector: (params) => {
          return {
            component: 'agCheckboxCellEditor',
          }
        },
      },
    ],
    [centerAlignCell, chartData.fixedScaleCm, getUnitsText],
  )

  const getRowDataXY = useCallback(() => {
    let propertyData = []
    if (!chartData) return propertyData

    propertyData.push({
      axis: `X`,
      min: chartData.xMin, // -1200,
      max: chartData.xMax, // 1200,
      scale: chartData.xAxisScale, // 411,
      userDefined: chartData.hasOwnProperty('xAxisUserDefined') ? chartData.xAxisUserDefined : false,
    })
    propertyData.push({
      axis: `Y`,
      min: chartData.yMin, // - 6000,
      max: chartData.yMax, // 500,
      scale: chartData.yAxisScale, // 711,
      userDefined: chartData.hasOwnProperty('yAxisUserDefined') ? chartData.yAxisUserDefined : false,
    })

    for (let i = 0; i < propertyData.length; i++) {
      propertyData[i].id = i
    }

    let pixelsPerDisplayUnit = 72
    if (chartData.fixedScaleCm) pixelsPerDisplayUnit = 28.3465
    let xDiff = chartData.xMax - chartData.xMin
    let yDiff = chartData.yMax - chartData.yMin
    let xScale = xDiff / (chartData.width / pixelsPerDisplayUnit)
    let yScale = yDiff / (chartData.height / pixelsPerDisplayUnit)

    // show x and y scales
    propertyData[0].scale = xScale
    propertyData[1].scale = yScale

    return propertyData
  }, [chartData, getUnitsText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowDataFixed = useCallback(() => {
    let propertyData = []
    if (!chartData) return propertyData

    propertyData.push({
      fixedScaleEnabled: chartData.hasOwnProperty('fixedScaleEnabled') ? chartData.fixedScaleEnabled : false,
      fixedScaleValue: chartData.hasOwnProperty('fixedScaleValue') ? chartData.fixedScaleValue : 1000,
      fixedScaleCm: chartData.hasOwnProperty('fixedScaleCm') ? chartData.fixedScaleCm : false,
      showScales: chartData.hasOwnProperty('showScales') ? chartData.showScales : true,
    })

    for (let i = 0; i < propertyData.length; i++) {
      propertyData[i].id = i
    }

    let pixelsPerDisplayUnit = 72
    if (chartData.fixedScaleCm) pixelsPerDisplayUnit = 28.3465
    let xDiff = chartData.xMax - chartData.xMin
    let yDiff = chartData.yMax - chartData.yMin
    let xScale = xDiff / (chartData.width / pixelsPerDisplayUnit)
    let yScale = yDiff / (chartData.height / pixelsPerDisplayUnit)
    if (!chartData.hasOwnProperty('fixedScaleValue')) {
      if (chartData.fixedScaleCm) pixelsPerDisplayUnit = 28.3465
      let fixedScaleVal = xScale > yScale ? xScale : yScale
      propertyData[0].fixedScaleValue = fixedScaleVal
    }

    return propertyData
  }, [chartData])

  const HeaderText = ({ text }) => {
    return (
      <Box
        sx={{
          textAlign: 'center',
          color: theme === 'dark' ? '#C0C0C0' : '#222222',
          width: '100%',
          fontSize: '16px',
          fontWeight: 800,
          marginTop: '20px',
          marginBottom: '15px',
        }}>
        {text}
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(100% - 2px)',
        height: '100%',
        border: '1px solid',
        borderColor: '#68686e',
      }}>
      <HeaderText text={'X & Y Axis Defs'} />
      <Box className='gridbox' sx={{ display: 'flex', height: '200px', width: '100%' }}>
        <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
          <AgGridReact
            // ref={gridApiXY}
            columnDefs={columnDefsXY}
            defaultColDef={defaultColDefXY}
            gridOptions={gridOptionsXY}
            onGridReady={onGridReadyXY}
            rowData={getRowDataXY()}
            headerHeight={30}
            onFirstDataRendered={onFirstDataRenderedXY}
            getRowId={getRowIdXY}
            onCellEditingStopped={handleCellValueChangedXY}
          />
        </div>
      </Box>
      <HeaderText text={'Scale Options'} />
      <Box className='gridbox2' sx={{ display: 'flex', height: '100px', width: '100%' }}>
        <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
          <AgGridReact
            columnDefs={columnDefsFixed}
            defaultColDef={defaultColDefFixed}
            gridOptions={gridOptionsFixed}
            onGridReady={onGridReadyFixed}
            rowData={getRowDataFixed()}
            headerHeight={30}
            onFirstDataRendered={onFirstDataRenderedFixed}
            getRowId={getRowIdFixed}
            onCellEditingStopped={handleCellValueChangedFixed}
          />
        </div>
      </Box>
    </Box>
  )
})

export default ChartScalesGrid
