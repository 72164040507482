import React from 'react'
import 'components/Analytics/Charts/chart.css'
import { Box } from '@mui/material'
import { Scatter } from 'react-chartjs-2'
import { DRAWERWIDE, DRAWERSLIM } from 'components/WellPages/EngineeringDashboard/EngineeringToolBar'
import crosshairPlugin from 'components/Analytics/Charts/ParameterComparison/crossHairPlugin'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useUnits from 'components/common/hooks/useUnits'
import { appColors } from 'utils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

export const LineChartCjs = ({ title, data, yAxisTitle, xAxisTitle, tag, isToolbarExpanded }) => {
  const { theme } = useInnovaTheme()

  const interpolate = (distFromBit) => {
    if (!data) return { distanceFromBit: -999.25, inc: -999.25, azm: -999.25 }
    if (!Array.isArray(data)) return { distanceFromBit: -999.25, inc: -999.25, azm: -999.25 }
    if (data.length <= 1) return { distanceFromBit: -999.25, inc: -999.25, azm: -999.25 }
    if (distFromBit === null || distFromBit === undefined)
      return { distanceFromBit: -999.25, inc: -999.25, azm: -999.25 }
    if (typeof distFromBit !== 'number') return { distanceFromBit: -999.25, inc: -999.25, azm: -999.25 }
    if (distFromBit < data[0].distanceFromBit) return { distanceFromBit: -999.25, inc: -999.25, azm: -999.25 }
    if (distFromBit > data[data.length - 1].distanceFromBit)
      return { distanceFromBit: -999.25, inc: -999.25, azm: -999.25 }

    for (let i = 1; i < data.length; i++) {
      if (distFromBit < data[i - 1].distanceFromBit || distFromBit > data[i].distanceFromBit) continue
      const x0 = data[i - 1].distanceFromBit
      const x1 = data[i].distanceFromBit
      const y0 = data[i - 1][tag]
      const y1 = data[i][tag]
      const x = distFromBit

      if (x1 === x0) return { distanceFromBit: -999.25, inc: -999.25, azm: -999.25 }

      let inc = -999.25
      let azm = -999.25

      if (data[i].hasOwnProperty('surveyStation') && data[i - 1].hasOwnProperty('surveyStation')) {
        const inc0 = data[i - 1].surveyStation.inc
        const inc1 = data[i].surveyStation.inc
        const azm0 = data[i - 1].surveyStation.azi
        const azm1 = data[i].surveyStation.azi
        inc = inc0 + (inc1 - inc0) * ((x - x0) / (x1 - x0))
        azm = azm0 + (azm1 - azm0) * ((x - x0) / (x1 - x0))
      }

      return { distanceFromBit: y0 + (y1 - y0) * ((x - x0) / (x1 - x0)), inc, azm }
    }

    return { distanceFromBit: -999.25, inc: -999.25, azm: -999.25 }
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: isToolbarExpanded ? DRAWERWIDE : DRAWERSLIM,
        right: 20,
      },
    },
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: title,
        color: 'white',
        font: {
          size: 14,
        },
      },
      tooltip: {
        mode: 'nearest',
        intersect: false,
        displayColors: false,
        xAlign: 'left',
        callbacks: {
          title: function (a) {
            return `${title}`
          },
          label: function (d) {
            let labelData = [`${xAxisTitle}: ${numberWithCommasDecimals(d.parsed.x, 2)}`]
            let interpValues = interpolate(d.parsed.x)
            if (interpValues.distanceFromBit === -999.25) return ''
            labelData.push(`${yAxisTitle}: ${numberWithCommasDecimals(interpValues.distanceFromBit, 2)}`)
            if (interpValues.inc !== -999.25) labelData.push(`Inc: ${numberWithCommasDecimals(interpValues.inc, 2)}`)
            if (interpValues.azm !== -999.25) labelData.push(`Azm: ${numberWithCommasDecimals(interpValues.azm, 2)}`)
            labelData.push(d.raw.desc)

            return labelData
          },
        },
        filter: function (tooltipItem, index) {
          if (index > 0) return false
          return true
        },
      },
      crosshair: {
        line: {
          color: appColors.headerTextColor,
          width: 0.5,
        },
        sync: {
          enabled: true,
          group: 1,
          suppressTooltips: false,
        },
        zoom: {
          enabled: true,
          zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',
          zoomboxBorderColor: '#48F',
          zoomButtonText: 'Reset',
          zoomButtonClass: 'resetZoom',
        },
      },
    },
    scales: {
      x: {
        type: 'linear',
        align: 'center',
        beginAtZero: true,
        title: {
          display: false,
        },
        ticks: {
          color: appColors.headerTextColor,
        },
        grid: {
          color: appColors.headerTextColor,
        },
      },
      y: {
        type: 'linear',
        beginAtZero: true,
        title: {
          display: false,
        },
        ticks: {
          color: appColors.headerTextColor,
        },
        grid: {
          color: appColors.headerTextColor,
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'nearest',
    },
    animation: {
      duration: 0,
    },
  }

  const getChartData = (tag) => {
    if (!data) return { datasets: [] }
    if (!Array.isArray(data)) return { datasets: [] }

    const chartData = {
      datasets: [
        {
          type: 'line',
          label: 'SERIES',
          data: data.map((d) => ({ x: d.distanceFromBit, y: d[tag], desc: d.description })),
          borderColor: 'red',
          backgroundColor: 'red',
          borderWidth: 2,
        },
      ],
    }

    let maxValue = -999999
    let maxDistance = -999999
    let valActual = -999999
    for (let i = 0; i < data.length; i++) {
      if (Math.abs(data[i][tag]) > maxValue) {
        maxValue = Math.abs(data[i][tag])
        valActual = data[i][tag]
        maxDistance = data[i].distanceFromBit
      }
    }

    chartData.datasets.push({
      type: 'line',
      label: 'Max Value',
      data: [
        { x: maxDistance, y: valActual, desc: `Max Value: ${numberWithCommasDecimals(maxValue, 2)}` },
        { x: maxDistance, y: -valActual, desc: `Max Value: ${numberWithCommasDecimals(maxValue, 2)}` },
      ],
      borderColor: 'magenta',
      backgroundColor: 'magenta',
      borderWidth: 2,
    })

    return chartData
  }

  return (
    <Scatter
      options={options}
      data={getChartData(tag)}
      plugins={[crosshairPlugin]}
      style={{ width: '100%', height: '200px' }}
    />
  )
}

const BhaAnalysisForceDeflectionChart = ({ data, chartData, isToolbarExpanded, height, width }) => {
  const { getUnitsText } = useUnits()
  const { getChartBackColor } = useInnovaTheme()

  return (
    <Box
      sx={{
        backgroundColor: getChartBackColor(),
        padding: '5px',
        display: 'flex',
        height: height,
        width: width,
      }}>
      <LineChartCjs
        isToolbarExpanded={isToolbarExpanded}
        xAxisTitle={`Dist`}
        yAxisTitle={chartData.yAxisTitle}
        data={data}
        tag={chartData.tag}
        title={`${chartData.title} (${chartData.units === 'degrees' ? '°' : getUnitsText(chartData.units)})`}
      />
    </Box>
  )
}

export default BhaAnalysisForceDeflectionChart
