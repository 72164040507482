import { Line } from 'react-chartjs-2'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { appColors } from 'utils'
import { useResizeDetector } from 'react-resize-detector'
import { Box, CircularProgress } from '@mui/material'
import { getTargets2D, getDrillersTargets2D } from './targets2D'
import { pointInterp } from 'utils/surveyFunctions'
import { cloneDeep } from 'lodash'
import PlanViewPrefs from './PlanViewPrefs'
import { Vector2 } from 'three'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const INITIAL_SCALE_LIMITS = {
  maxX: 50,
  minX: -50,
  maxY: 50,
  minY: -50,
  xLen: 100,
  yLen: 100,
}

const PlanViewChart = ({
  data,
  refWellSurveys,
  isLoading,
  projections = [],
  isPlan = false,
  toggleErrorEllipses = null,
  printing = false,
}) => {
  const { theme } = useInnovaTheme()
  const chartRef = useRef(null)
  const { getUnitsText } = useUnits()
  const dataMinMax = useRef(cloneDeep(INITIAL_SCALE_LIMITS)) //Holds the min max of the well data
  const scaleMinMax = useRef(cloneDeep(INITIAL_SCALE_LIMITS)) //Holds the min max values of the chart scales preserving the aspect ratio
  const size = useRef({ width: 0, height: 0 })
  const [displaySettings, setDisplaySettings] = useState({
    showOffsets: !printing ? true : false,
    showTargets: !printing ? true : false,
    showDrillersTargets: !printing ? true : false,
    showCasing: true,
    showAnnotations: false,
    showErrorEllipses: false,
  })

  let CHART_OPTIONS = {
    maintainAspectRatio: false,
    scaleShowLabels: false,
    animation: {
      duration: 0,
    },
    layout: {
      padding: {
        right: 20,
        top: 20,
      },
    },
    plugins: {
      dataLabelPlugin: {
        color: '#ffffff',
        visible: true,
      },
      title: {
        display: false,
      },
      tooltip: {
        mode: 'point',
        intersect: false,
        callbacks: {
          title: function (tooltipItem) {
            return tooltipItem[0].dataset.label
          },
          label: function (context) {
            return [` ${+context.parsed.x.toFixed(2)} EW`, ` ${context.parsed.y.toFixed(2)} NS`]
          },
        },
        filter: function (tooltipItem, index) {
          if (index > 0) return false
          return true
        },
      },
      hover: {
        mode: 'nearest',
        intersect: false,
      },
      legend: {
        display: false,
      },
      zoom: {
        animation: {
          duration: 0,
        },
        pan: {
          enabled: true,
          mode: 'xy',
        },
        limits: {
          x: {
            min: -50,
            max: 50,
          },
          y: {
            min: -50,
            max: 50,
          },
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        },
      },
      GradientPlugIn: {
        showGradient: true,
        theme: 'dark',
      },
    },
    scales: {
      x: {
        type: 'linear',
        title: {
          display: true,
          text: `EW`,
          color: appColors.headerTextColor,
        },
        ticks: {
          color: appColors.headerTextColor,
          count: 20,
          callback: (value, index, values) => {
            const formatter = new Intl.NumberFormat('en-US', {
              style: 'decimal',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })
  
            let roundedNumber = Math.round(value / 10) * 10
            if (Math.abs(value) < 10) {
              roundedNumber = Math.round(value)
            }
  
            return formatter.format(roundedNumber)
          },
        },
        grid: {
          color: '#404040',
        },
      },
      y: {
        type: 'linear',
        title: {
          display: true,
          text: `NS`,
          color: appColors.headerTextColor,
        },
        ticks: {
          color: appColors.headerTextColor,
          count: 20,
          callback: (value, index, values) => {
            const formatter = new Intl.NumberFormat('en-US', {
              style: 'decimal',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })
  
            let roundedNumber = Math.round(value / 10) * 10
            if (Math.abs(value) < 10) {
              roundedNumber = Math.round(value)
            }
  
            return formatter.format(roundedNumber)
          },
        },
        grid: {
          color: '#404040',
        },
      },
    },
  }

  useEffect(() => {
    if (toggleErrorEllipses && displaySettings?.showErrorEllipses) {
      toggleErrorEllipses(displaySettings?.showErrorEllipses)
    }
  }, [displaySettings, toggleErrorEllipses])

  const onResize = useCallback((width, height) => {
    size.current = { width, height }
    adjustChartScale()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const { ref: rszRef } = useResizeDetector({ onResize: onResize, refreshMode: 'debounce', refreshRate: 250 })

  useEffect(() => {
    updateAxisLabels()
  }, [getUnitsText]) // eslint-disable-line react-hooks/exhaustive-deps

  const updateDataMinMax = (x, y) => {
    if (typeof x !== 'number') return
    if (typeof y !== 'number') return
    if (isNaN(x)) return
    if (isNaN(y)) return

    if (x > dataMinMax.current.maxX) dataMinMax.current.maxX = x
    if (x < dataMinMax.current.minX) dataMinMax.current.minX = x
    if (y > dataMinMax.current.maxY) dataMinMax.current.maxY = y
    if (y < dataMinMax.current.minY) dataMinMax.current.minY = y
  }

  const getWellColor = (type) => {
    return type === 'Actual' ? appColors.surveyTrajectoryColor : appColors.planTrajectoryColor
  }

  const translatePoints = (svy1, svy2, translationDistance) => {
    if (!translationDistance) translationDistance = 0
    let p1 = new Vector2(svy1.ew, svy1.ns)
    let p2 = new Vector2(svy2.ew, svy2.ns)
    const lineDirection = new Vector2().copy(p2).sub(p1).normalize()
    const perpendicularDirection = new Vector2(-lineDirection.y, lineDirection.x)
    const translation = perpendicularDirection.clone().multiplyScalar(translationDistance)
    const translatedP2 = p2.clone().add(translation)

    let newSvy = { ...svy2 }
    newSvy.ew = translatedP2.x
    newSvy.ns = translatedP2.y
    return newSvy
  }

  const AddErrorEllipseSeries = (chartData, svys) => {
    if (!displaySettings.showErrorEllipses) return
    if (!Array.isArray(svys)) return

    chartData.datasets.push({
      type: 'line',
      label: '-',
      borderWidth: 1,
      pointRadius: 0,
      fillBetweenSeries: chartData.datasets.length + 1,
      fillBetweenColor: 'rgba(255,0,0, 0.2)',
      data: svys.map((svy, index) => {
        if (index === 0) return { x: svy.ew, y: svy.ns }
        let newPoint = translatePoints(svys[index - 1], svy, -svy.semiMajor)
        return { x: newPoint.ew, y: newPoint.ns }
      }),
    })

    chartData.datasets.push({
      type: 'line',
      label: '-',
      borderWidth: 1,
      pointRadius: 0,
      data: svys.map((svy, index) => {
        if (index === 0) return { x: svy.ew, y: svy.ns }
        let newPoint = translatePoints(svys[index - 1], svy, svy.semiMajor)
        return { x: newPoint.ew, y: newPoint.ns }
      }),
    })
  }

  const createChartData = () => {
    const chartData = { datasets: [] }
    dataMinMax.current = cloneDeep(INITIAL_SCALE_LIMITS)

    if (!data) return chartData
    CHART_OPTIONS.plugins.GradientPlugIn.showGradient = !printing
    CHART_OPTIONS.plugins.GradientPlugIn.theme = theme
    
    if (printing || theme !== 'dark') {
      CHART_OPTIONS.scales.x.ticks.color = '#000'
      CHART_OPTIONS.scales.y.ticks.color = '#000'
      CHART_OPTIONS.scales.x.title.color = '#000'
      CHART_OPTIONS.scales.y.title.color = '#000'
    }
    let svys = Array.isArray(refWellSurveys) ? cloneDeep(refWellSurveys) : cloneDeep(data.surveys)

    //Get Error ellipse data for ref well
    if (data.hasOwnProperty('offsetWells') && Array.isArray(data.offsetWells)) {
      data?.offsetWells.forEach((well) => {
        if (well.offsetWellbore !== data.wellName) return
        if (!Array.isArray(svys)) return
        if (svys === 0) return
        if (!Array.isArray(well.surveyData)) return

        for (let i = 0; i < svys.length; i++) {
          let index = well.surveyData.findIndex((x) => Math.abs(x.md - svys[i].md) < 0.1)
          if (index < 0) continue
          svys[i].semiMajor = well.surveyData[index].semiMajor
        }
      })
    }

    if (svys.length > 1) {
      chartData.datasets.push({
        type: 'line',
        label: data.wellName,
        borderWidth: 2,
        pointRadius: 1,
        borderColor: getWellColor(isPlan ? 'Planned' : 'Actual'),
        backgroundColor: getWellColor(isPlan ? 'Planned' : 'Actual'),
        data: svys.map((svy) => {
          updateDataMinMax(svy.ew, svy.ns)
          return { x: svy.ew, y: svy.ns }
        }),
      })

      AddErrorEllipseSeries(chartData, svys)
    }

    if (projections?.length > 1) {
      chartData.datasets.push({
        type: 'line',
        label: data.wellName,
        borderWidth: 2,
        pointRadius: 1,
        borderColor: '#59FF00',
        backgroundColor: '#59FF00',
        data: projections.map((svy) => {
          updateDataMinMax(svy.ew, svy.ns)
          return { x: svy.ew, y: svy.ns }
        }),
      })
    }

    if (displaySettings.showOffsets && Array.isArray(data.offsetWells)) {
      for (let i = 0; i < data.offsetWells.length; i++) {
        if (!Array.isArray(data.offsetWells[i]?.surveyData)) continue
        if (data.offsetWells[i].surveyData.length < 2) continue

        chartData.datasets.push({
          type: 'line',
          borderWidth: 1,
          pointRadius: 0,
          label: data.offsetWells[i].offsetWellbore,
          borderColor: getWellColor(data.offsetWells[i].wellStatus),
          backgroundColor: getWellColor(data.offsetWells[i].wellStatus),
          data: data.offsetWells[i].surveyData.map((svy) => {
            updateDataMinMax(svy.ew, svy.ns)
            return { x: svy.ew, y: svy.ns }
          }),
        })

        AddErrorEllipseSeries(chartData, data.offsetWells[i].surveyData)
      }
    }

    if (displaySettings.showTargets) {
      let targetData = []
      getTargets2D(data?.targets, targetData, 'plan', null, displaySettings.showTargets)

      for (let i = 0; i < targetData.length; i++) {
        if (!Array.isArray(targetData[i]?.points)) continue

        chartData.datasets.push({
          type: 'line',
          borderWidth: 2,
          label: targetData[i].text,
          borderColor: targetData[i].color,
          backgroundColor: targetData[i].color,
          data: targetData[i].points.map((pt) => {
            updateDataMinMax(pt.x, pt.y)
            return { x: pt.x, y: pt.y }
          }),
          pointRadius: 0,
        })

        chartData.datasets.push({
          type: 'line',
          borderWidth: 2,
          label: targetData[i].text,
          borderColor: targetData[i].color,
          backgroundColor: targetData[i].color,
          data: [{ x: targetData[i].x, y: targetData[i].y, label: targetData[i].text, color: targetData[i].color }],
          pointRadius: 3,
        })
      }
    }

    if (displaySettings.showDrillersTargets) {
      let drillersTargetData = []
      getDrillersTargets2D(data?.targets, drillersTargetData, 'plan', null, displaySettings.showDrillersTargets)

      for (let i = 0; i < drillersTargetData.length; i++) {
        if (!Array.isArray(drillersTargetData[i]?.points)) continue

        chartData.datasets.push({
          type: 'line',
          borderWidth: 2,
          label: '',
          borderColor: drillersTargetData[i].color,
          backgroundColor: drillersTargetData[i].color,
          data: drillersTargetData[i].points.map((pt) => {
            updateDataMinMax(pt.x, pt.y)
            return { x: pt.x, y: pt.y }
          }),
          pointRadius: 0,
        })

        chartData.datasets.push({
          type: 'line',
          borderWidth: 2,
          label: '',
          borderColor: drillersTargetData[i].color,
          backgroundColor: drillersTargetData[i].color,
          data: [
            {
              x: drillersTargetData[i].x + 5,
              y: drillersTargetData[i].y + 5,
              label: drillersTargetData[i].text,
              color: drillersTargetData[i].color,
            },
          ],
          pointRadius: 3,
        })
      }
    }

    dataMinMax.current.yLen = dataMinMax.current.maxY - dataMinMax.current.minY
    dataMinMax.current.xLen = dataMinMax.current.maxX - dataMinMax.current.minX

    if (displaySettings.showCasing && Array.isArray(data?.actualWellData?.casing)) {
      data.actualWellData.casing.forEach((csg) => {
        const { md, ew, ns } = pointInterp(svys, csg.MD)
        if (md < 0) return
        let label = `${csg.OD} ${csg.Type}`

        let textColor = appColors.headerTextColor
        if (printing || theme !== 'dark') textColor = '#000'

        chartData.datasets.push({
          type: 'line',
          borderWidth: 2,
          label: label,
          borderColor: textColor,
          backgroundColor: textColor,
          data: [{ x: ew, y: ns, label: label, color: textColor }],
          pointRadius: 3,
        })
      })
    }

    if (displaySettings.showAnnotations && Array.isArray(data?.actualWellData?.annotations)) {
      data.actualWellData.annotations.forEach((annot) => {
        const { md, ew, ns } = pointInterp(svys, annot.md)
        if (md < 0) return
        chartData.datasets.push({
          type: 'line',
          borderWidth: 2,
          label: annot.annotation,
          borderColor: appColors.itemTextColor,
          backgroundColor: appColors.itemTextColor,
          data: [{ x: ew, y: ns, label: annot.annotation, color: appColors.itemTextColor }],
          pointRadius: 1,
        })
      })
    }

    adjustChartScale()
    return chartData
  }

  const updateAxisLabels = () => {
    if (!chartRef.current) return
    chartRef.current.config.options.scales.x.title.text = `EW (${getUnitsText(UNITS_FOR.Depth)})`
    chartRef.current.config.options.scales.y.title.text = `NS (${getUnitsText(UNITS_FOR.Depth)})`
    chartRef.current.update()
  }

  const adjustChartScale = () => {
    let xScale = 1
    if (dataMinMax.current.xLen > 0 && size.current.width > 0) xScale = dataMinMax.current.xLen / size.current.width

    let yScale = 1
    if (dataMinMax.current.yLen > 0 && size.current.height > 0) yScale = dataMinMax.current.yLen / size.current.height

    let addXlen = xScale < yScale ? (yScale * size.current.width - dataMinMax.current.xLen) / 2.0 : 0
    let addYlen = xScale < yScale ? 0 : (xScale * size.current.height - dataMinMax.current.yLen) / 2.0

    let xOffset = Math.abs(((dataMinMax.current.maxX + addXlen) * 0.2 + (dataMinMax.current.minX - addXlen) * 0.2) / 2)
    let yOffset = Math.abs(((dataMinMax.current.maxY + addYlen) * 0.2 + (dataMinMax.current.minY - addYlen) * 0.2) / 2)
    scaleMinMax.current.maxX = dataMinMax.current.maxX + xOffset
    scaleMinMax.current.minX = dataMinMax.current.minX - xOffset
    scaleMinMax.current.maxY = dataMinMax.current.maxY + yOffset
    scaleMinMax.current.minY = dataMinMax.current.minY - yOffset

    if (!chartRef.current) return
    const { minX, maxX, minY, maxY } = scaleMinMax.current
    const { scales, plugins } = chartRef.current.config.options

    plugins.zoom.limits.x.min = minX
    plugins.zoom.limits.x.max = maxX
    plugins.zoom.limits.y.min = minY
    plugins.zoom.limits.y.max = maxY

    if (!chartRef.current.isZoomedOrPanned()) {
      scales.x.min = minX
      scales.x.max = maxX
      scales.y.min = minY
      scales.y.max = maxY
    }

    if (chartRef.current.isZoomedOrPanned()) {
      let xLen = scales.x.max - scales.x.min
      let yLen = scales.y.max - scales.y.min

      xScale = 1
      if (xLen > 0 && size.current.width > 0) xScale = xLen / size.current.width

      yScale = 1
      if (yLen > 0 && size.current.height > 0) yScale = yLen / size.current.height

      let addXlen = xScale < yScale ? (yScale * size.current.width - xLen) / 2.0 : 0
      let addYlen = xScale < yScale ? 0 : (xScale * size.current.height - yLen) / 2.0

      scales.x.max += addXlen
      scales.x.min -= addXlen
      scales.y.max += addYlen
      scales.y.min -= addYlen
    }

    updateAxisLabels()
  }

  return (
    <Box ref={rszRef} sx={{ height: '100%', width: '100%' }}>
      <Line ref={chartRef} type='line' options={CHART_OPTIONS} data={createChartData()} />
      {isLoading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : null}
      {!isLoading && !printing ? (
        <PlanViewPrefs
          showStatusPrefs={true}
          displaySettings={displaySettings}
          setDisplaySettings={setDisplaySettings}
        />
      ) : null}
    </Box>
  )
}

export default PlanViewChart
