import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import ColorPickerCellEditor from 'components/common/CellEditors/ColorPickerCellEditor'
import LineCellRenderer from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/LineCellRenderer'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { Box, Checkbox, Typography } from '@mui/material'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import { appColors } from 'utils'
import { getRandomColor } from 'utils/colorFunctions'

const MarkerStyles = [
  'None',
  'Dash',
  'Plus',
  'Cross',
  'Dot',
  'Dot Solid',
  'Square',
  'Square Solid',
  'Diamond',
  'Diamond Solid',
  'Up Triangle',
  'Up Triangle Solid',
  'Down Triangle',
  'Down Triangle Solid',
]

const ChartSeriesStyleGrid = forwardRef(({ chartData }, ref) => {
  const [showOffsets, setShowOffsets] = useState(chartData.showOffsets ? chartData.showOffsets : false)
  const [showPrincipalPlan, setShowPrincipalPlan] = useState(
    chartData.showPrincipalPlan ? chartData.showPrincipalPlan : false,
  )
  const [showOffsetRandomColors, setShowOffsetRandomColors] = useState(
    chartData.showOffsetRandomColors ? chartData.showOffsetRandomColors : false,
  )
  const [showOffsetMarkers, setShowOffsetMarkers] = useState(
    chartData.showOffsetMarkers ? chartData.showOffsetMarkers : false,
  )
  const seriesDataRef = useRef(chartData.series) // for holding changes not managed by the grid
  const gridApi = useRef(null)
  const { theme, getAgGridTheme } = useInnovaTheme()

  useImperativeHandle(ref, () => ({
    stopEditing: () => {
      if (gridApi.current) gridApi.current.stopEditing()
    },
    getData: () => {
      let gridData = []
      gridApi.current?.forEachNode((node) => {
        if (node.data) gridData.push(node.data)
      })
      chartData.series.forEach((series, idx) => {
        let data = gridData.find((data) => data.wellName === series.wellName)
        if (data) {
          data.type = series.type
          data.color = showOffsetRandomColors ? series.randomColor : series.defaultColor
          data.randomColor = series.randomColor
          data.defaultColor = series.defaultColor
        } else {
          gridData.push({ ...series, randomColor: seriesDataRef.current[idx].randomColor })
        }
      })

      return {
        props: [
          { tag: 'showOffsets', value: showOffsets },
          { tag: 'showPrincipalPlan', value: showPrincipalPlan },
          { tag: 'showOffsetRandomColors', value: showOffsetRandomColors },
          { tag: 'showOffsetMarkers', value: showOffsetMarkers },
        ],
        series: gridData,
      }
    },
  }))

  const getSeriesColor = (series) => {
    if (!series) return '#008800'
    if (showOffsetRandomColors === true) {
      const idx = seriesDataRef.current.findIndex((s) => s.wellName === series.wellName)
      if (idx > -1) return seriesDataRef.current[idx].randomColor
    }
    if (!showOffsetRandomColors) {
      const idx = seriesDataRef.current.findIndex((s) => s.wellName === series.wellName)
      if (idx > -1) return seriesDataRef.current[idx].defaultColor
    }
    return series.color ? series.color : '#008800'
  }

  const getRowData = (data) => {
    let rowData = []
    if (!data) return rowData
    rowData.push({
      id: 0,
      wellName: data.series[0].wellName,
      type: data.series[0].type,
      color: getSeriesColor(data.series[0]),
      visible: data.series[0].visible === false ? false : true, // default to true if prop doesn't exist yet
      lineStyle: data.series[0].lineStyle ? data.series[0].lineStyle : 'solid',
      lineThickness: data.series[0].lineThickness ? data.series[0].lineThickness : 1,
      marker: data.series[0].marker ? data.series[0].marker : MarkerStyles[5], // 'Dot Solid'
    })

    if (showOffsets) {
      for (let i = 1; i < data.series.length; i++) {
        rowData.push({
          id: i,
          wellName: data.series[i].wellName,
          type: data.series[i].type,
          color: getSeriesColor(data.series[i]),
          visible: data.series[i].visible === false ? false : true, // default to true if prop doesn't exist yet
          lineStyle: data.series[i].lineStyle ? data.series[i].lineStyle : 'solid',
          lineThickness: data.series[i].lineThickness ? data.series[i].lineThickness : 1,
          marker: data.series[i].marker
            ? data.series[i].marker
            : MarkerStyles[((i - 1 + MarkerStyles.length) % (MarkerStyles.length - 1)) + 1], // rotate through the marker styles, avoiding 'None'
        })
      }
    }
    return rowData
  }

  useEffect(() => {
    if (showOffsets === false) setShowPrincipalPlan(false)
    if (gridApi.current) {
      gridApi.current.setGridOption('rowData', getRowData(chartData))
    }
  }, [showOffsets, showPrincipalPlan, showOffsetRandomColors, showOffsetMarkers]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (gridApi.current) {
      if (showOffsetRandomColors === true) {
        seriesDataRef.current.forEach((series, i) => {
          if (!series.randomColor || series.randomColor === '') {
            series.randomColor = getRandomColor()
          }
        })
      }
      gridApi.current.setGridOption('rowData', getRowData(chartData))
    }
  }, [showOffsetRandomColors]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
    gridApi.current.setGridOption('rowData', getRowData(chartData))
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const toggleOffsetWells = () => {
    setShowOffsets((prevVal) => !prevVal)
  }

  const togglePrincipalPlan = () => {
    setShowPrincipalPlan((prevVal) => !prevVal)
  }

  const toggleOffsetRandomColors = () => {
    setShowOffsetRandomColors((prevVal) => !prevVal)
  }

  const toggleOffsetMarkers = () => {
    setShowOffsetMarkers((prevVal) => !prevVal)
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const gridOptions = {
    suppressRowClickSelection: true,
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const leftAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
    }),
    [],
  )

  const columnDefs = useMemo(
    () => [
      {
        headerName: 'Well',
        field: 'wellName',
        colId: 'wellName',
        pinned: 'left',
        lockPosition: 'left',
        autoHeight: true,
        minWidth: 100,
        width: 200,
        maxWidth: 250,
        cellStyle: leftAlignCell,
      },
      {
        headerName: 'Type',
        field: 'type',
        colId: 'type',
        minWidth: 100,
        width: 200,
        maxWidth: 250,
        cellStyle: centerAlignCell,
      },
      {
        headerName: 'Color',
        field: 'color',
        colId: 'color',
        minWidth: 72,
        width: 72,
        maxWidth: 72,
        flex: 1,
        editable: true,
        cellDataType: false,
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            backgroundColor: params.value,
            color: params.value,
          }
        },
        cellEditorSelector: (params) => {
          return {
            component: ColorPickerCellEditor,
            params: {
              color: params.value,
              defaultColor: params.data?.defaultValue,
            },
            popup: true,
          }
        },
      },
      {
        headerName: 'Visible',
        field: 'visible',
        colId: 'visible',
        flex: 1,
        width: 100,
        editable: (params) => {
          return params.data?.wellName !== chartData.series[0].wellName
        },
        cellStyle: centerAlignCell,
        cellEditorSelector: () => {
          return {
            component: 'agCheckboxCellEditor',
          }
        },
      },
      {
        headerName: 'Line',
        field: 'lineStyle',
        colId: 'lineStyle',
        width: 100,
        flex: 1,
        editable: true,
        cellDataType: false,

        cellStyle: centerAlignCell,
        cellEditorSelector: () => {
          return {
            component: 'agRichSelectCellEditor',
            popup: true,
            params: {
              cellRenderer: LineCellRenderer,
              values: ['solid', 'dotted', 'dashed'],
              valueListMaxHeight: 125,
            },
          }
        },
        cellRendererSelector: () => {
          return {
            component: LineCellRenderer,
          }
        },
      },
      {
        headerName: 'Thickness',
        field: 'lineThickness',
        colId: 'lineThickness',
        width: 100,
        maxWidth: 100,
        flex: 1,
        editable: true,
        cellDataType: false,
        cellStyle: centerAlignCell,
      },
      {
        headerName: 'Marker',
        field: 'marker',
        colId: 'marker',
        width: 100,
        maxWidth: 100,
        flex: 1,
        editable: true,
        cellDataType: false,
        cellStyle: centerAlignCell,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: [
            'None',
            'Dash',
            'Plus',
            'Cross',
            'Dot',
            'Dot Solid',
            'Square',
            'Square Solid',
            'Diamond',
            'Diamond Solid',
            'Up Triangle',
            'Up Triangle Solid',
            'Down Triangle',
            'Down Triangle Solid',
          ],
        },
      },
    ],
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'start',
          marginBottom: '5px',
          width: '100%',
        }}>
        <Checkbox
          checked={showOffsets}
          onChange={toggleOffsetWells}
          checkedIcon={
            <RadioButtonCheckedIcon fontSize='small' style={{ color: theme === 'dark' ? 'lime' : 'green' }} />
          }
          indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
          icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}></Checkbox>
        <Typography
          sx={{
            color: appColors.itemTextColor,
            marginRight: '16px',
          }}>
          Show Offset Wells
        </Typography>
        <Checkbox
          checked={showPrincipalPlan}
          onChange={togglePrincipalPlan}
          checkedIcon={
            <RadioButtonCheckedIcon fontSize='small' style={{ color: theme === 'dark' ? 'lime' : 'green' }} />
          }
          indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
          icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}></Checkbox>
        <Typography
          sx={{
            color: appColors.itemTextColor,
            marginRight: '16px',
          }}>
          Show Principal Plan Only
        </Typography>
        <Checkbox
          checked={showOffsetRandomColors}
          onChange={toggleOffsetRandomColors}
          checkedIcon={
            <RadioButtonCheckedIcon fontSize='small' style={{ color: theme === 'dark' ? 'lime' : 'green' }} />
          }
          indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
          icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}></Checkbox>
        <Typography
          sx={{
            color: appColors.itemTextColor,
            marginRight: '16px',
          }}>
          Randomize Colors
        </Typography>
        <Checkbox
          checked={showOffsetMarkers}
          onChange={toggleOffsetMarkers}
          checkedIcon={
            <RadioButtonCheckedIcon fontSize='small' style={{ color: theme === 'dark' ? 'lime' : 'green' }} />
          }
          indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
          icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}></Checkbox>
        <Typography
          sx={{
            color: appColors.itemTextColor,
            marginRight: '16px',
          }}>
          Show Markers
        </Typography>
      </Box>
      <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
        <AgGridReact
          className='ag-grid-theme-dark'
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          headerHeight={30}
          onFirstDataRendered={onFirstDataRendered}
          getRowId={getRowId}
        />
      </div>
    </>
  )
})

export default ChartSeriesStyleGrid
