import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { Box } from '@mui/system'
import { Menu, MenuItem, ListItemIcon, ListItemText, Divider } from '@mui/material'
import Selecto from 'react-selecto'
import Moveable from 'react-moveable'
import {
  extractRotateFromStyle,
  extractRotateValue,
  createRotateStyle,
  extractTranslate,
  convertUserToPixels,
  convertPixelsToUser,
  DPI,
} from './cssTransformUtils'
import HorizontalRuler from 'components/WellPages/WallplotComposer/Viewport/HorizontalRuler'
import VerticalRuler from 'components/WellPages/WallplotComposer/Viewport/VerticalRuler'
import PlotPage from 'components/WellPages/WallplotComposer/Viewport/Page'
import ElementContainer from 'components/WellPages/WallplotComposer/Elements/ElementContainer'
import {
  ELEMENT_TYPES as elementType,
  TABLE_TYPES as tableType,
  CHART_TYPES as chartType,
} from 'components/WellPages/WallplotComposer/Elements/elementDefs'
import TextElement from 'components/WellPages/WallplotComposer/Elements/TextElement'
import ImageElement from 'components/WellPages/WallplotComposer/Elements/ImageElement'
import TableElement from 'components/WellPages/WallplotComposer/Elements/TableElement'
import ChartElement from 'components/WellPages/WallplotComposer/Elements/ChartElements/ChartElement'
import { cloneDeep } from 'lodash'
import { getContextMenuItems } from './ContextMenuItems'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { useReactToPrint } from 'react-to-print'

const arraysContainSame = (a, b) => {
  a = Array.isArray(a) ? a : []
  b = Array.isArray(b) ? b : []
  return a.length === b.length && a.every((el) => b.includes(el))
}

const Viewport = forwardRef(({ actions, elements, data, selectedUids, units, config, pageLayout }, ref) => {
  const _isMounted = useRef(false)
  const containerRefs = useRef({})
  const elementRefs = useRef({})
  const viewRef = useRef(null)
  const viewZoomPosRef = useRef({ scrollLeft: 0, scrollTop: 0 })
  const mouseCoords = useRef({ startX: 0, startY: 0, scrollLeft: 0, scrollTop: 0 })
  const [contextMenu, setContextMenu] = useState(null)
  const [zoomScale, setZoomScale] = useState(1)
  const horizontalRulerRef = useRef(null)
  const verticalRulerRef = useRef(null)
  const shiftRef = useRef(false)
  const pageRef = useRef(null)
  const moveableRef = useRef(null)
  const selectoRef = useRef(null)
  const [, setRerender] = useState(false)
  const [showImageMenuItems, setShowImageMenuItems] = useState(false)
  const [showTableMenuItems, setShowTableMenuItems] = useState(false)
  const [showChartMenuItems, setShowChartMenuItems] = useState(false)
  const { theme } = useInnovaTheme()
  const printPageRef = useRef(null)
  const printNameRef = useRef(null)
  const [isPrinting, setIsPrinting] = useState(false)

  useEffect(() => {
    _isMounted.current = true
    if (viewRef.current) viewRef.current.focus()
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (viewRef.current) {
      viewRef.current.focus()
    }
  }, [viewRef])

  useEffect(() => {
    if (viewZoomPosRef.current) {
      viewRef.current.scrollLeft = viewZoomPosRef.current.scrollLeft
      viewRef.current.scrollTop = viewZoomPosRef.current.scrollTop
    }
  }, [zoomScale])

  const calcPageBasedZoom = (widthOnly) => {
    // !widthOnly means width and height
    if (!viewRef.current) return 1

    let pageMargin = parseInt(pageRef.current.getPageRef().current.style.margin)
    const rectVp = viewRef.current.getBoundingClientRect()
    let newZoomWidth = (rectVp.width - pageMargin) / (pageLayout.width + 0.35) / DPI
    if (widthOnly) {
      return newZoomWidth
    }

    let newZoomHeight = (rectVp.height - pageMargin) / (pageLayout.height + 0.35) / DPI
    return Math.min(newZoomWidth, newZoomHeight)
  }

  useImperativeHandle(ref, () => {
    return {
      setZoom(zoomLevel, pageLayout) {
        let zoom = 1
        // Check if zoomLevel is a number
        if (typeof zoomLevel === 'number') {
          zoom = zoomLevel
        }
        if (typeof zoomLevel === 'string') {
          switch (zoomLevel) {
            case '50%':
              zoom = 0.5
              break
            case '75%':
              zoom = 0.75
              break
            case '100%':
              // calc zoom based on page width and height
              zoom = calcPageBasedZoom(false)
              break
            case '200%':
              zoom = 2
              break
            case '300%':
              zoom = 3
              break
            case 'pageWidth':
              // calc zoom based on page width
              zoom = calcPageBasedZoom(true)
              break
            default:
              console.warn('[Innova] Invalid zoom level:', zoomLevel)
              break
          }
        }
        // Ensure pageRef.current exists and has a setZoom method
        if (pageRef.current && typeof pageRef.current.setZoom === 'function') {
          pageRef.current.setZoom(zoom)
        }
        setZoomScale(zoom)
      },
      setZIndex(uid, direction) {
        handleZIndex(uid, direction)
      },
      getPageRef() {
        return pageRef
      },
      getPrintPageRef() {
        return printPageRef
      },
      showElementProperties() {
        showElementProperties()
      },
      clearSelection() {
        if (selectedUids.current.length === 0) return

        selectedUids.current = []
        setRerender((prev) => !prev)
      },
      getElementAddPosition() {
        return {
          x: convertPixelsToUser(viewRef.current.offsetLeft + viewRef.current.scrollLeft, units, zoomScale),
          y: convertPixelsToUser(viewRef.current.offsetTop + viewRef.current.scrollTop, units, zoomScale),
        }
      },
      print(name) {
        selectedUids.current = []
        printNameRef.current = name
        setIsPrinting(true)
      },
    }
  })

  const handlePrint = useReactToPrint({
    documentTitle: printNameRef.current,
    onBeforeGetContent: () => {
      if (printPageRef && printPageRef.current) {
        printPageRef.current.prepPrinting()
      }
    },
    onAfterPrint: () => {
      if (printPageRef && printPageRef.current) {
        printPageRef.current.donePrinting()
      }
      setIsPrinting(false)
    },
    removeAfterPrint: false,
    content: () => {
      return printPageRef.current.getPageRef().current
    },
  })

  useEffect(() => {
    if (isPrinting) {
      if (printPageRef.current) {
        handlePrint()
      }
    }
  }, [isPrinting]) // eslint-disable-line react-hooks/exhaustive-deps

  // need custom wheel handler for the viewRef defined in the useEffect in order to make it non-passive
  // so the preventDefault can be called without a browser error.
  useEffect(() => {
    const div = viewRef.current

    const convertPoint = (pt, zoomLevel, pixelToUser) => {
      if (!pt) return { x: 0, y: 0 }
      if (!pt.hasOwnProperty('x') || !pt.hasOwnProperty('y')) return { x: 0, y: 0 }

      return {
        x: pixelToUser ? convertPixelsToUser(pt.x, units, zoomLevel) : convertUserToPixels(pt.x, units, zoomLevel),
        y: pixelToUser ? convertPixelsToUser(pt.y, units, zoomLevel) : convertUserToPixels(pt.y, units, zoomLevel),
      }
    }

    const onMouseWheel = (e) => {
      if (!e.shiftKey) return
      e.preventDefault()

      //Clamp zoom return if no change
      let newZoomScale = Math.min(Math.max(0.125, zoomScale + e.deltaY * -0.002), 3)
      if (newZoomScale === zoomScale) return

      //Get mouse position relative to page top left in user units
      let pageMargin = parseInt(pageRef.current.getPageRef().current.style.margin)
      let mousePosPixels = {
        x: e.clientX - viewRef.current.offsetLeft - pageMargin + viewRef.current.scrollLeft,
        y: e.clientY - viewRef.current.offsetTop - pageMargin + viewRef.current.scrollTop,
      }

      let mouseOffsetUserUnits = convertPoint(mousePosPixels, zoomScale, true)
      let mouseOffsetUserUnitsNewZoom = convertPoint(mousePosPixels, newZoomScale, true)

      //Calcualtes the difference in pixels of the mouse position between pre and post zoom
      let deltaPixels = convertPoint(
        {
          x: mouseOffsetUserUnitsNewZoom.x - mouseOffsetUserUnits.x,
          y: mouseOffsetUserUnitsNewZoom.y - mouseOffsetUserUnits.y,
        },
        newZoomScale,
        false,
      )

      let newScrollLeft = viewRef.current.scrollLeft - deltaPixels.x
      let newScrollTop = viewRef.current.scrollTop - deltaPixels.y
      if (newScrollLeft < 0) newScrollLeft = 0
      if (newScrollTop < 0) newScrollTop = 0

      viewZoomPosRef.current.scrollLeft = newScrollLeft
      viewZoomPosRef.current.scrollTop = newScrollTop

      pageRef.current?.setZoom(newZoomScale)
      setZoomScale(newZoomScale)
    }

    div.addEventListener('wheel', onMouseWheel, { passive: false })

    return () => {
      div.removeEventListener('wheel', onMouseWheel)
    }
  }, [zoomScale, setZoomScale, units])

  const onClose = () => {
    setShowImageMenuItems(false)
    setShowTableMenuItems(false)
    setShowChartMenuItems(false)
    setContextMenu(null)
  }

  const handleContextMenu = (event) => {
    event.preventDefault()
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    )
  }

  const handleZIndex = (uids, direction) => {
    switch (direction) {
      case 'front':
        const newMaxZ = getMaxZIndex() + 1
        for (let i = 0; i < uids.length; i++) {
          containerRefs.current[uids[i]].current.style.zIndex = newMaxZ
          actions.updateElement(uids[i], 'zindex', { zIndex: newMaxZ })
        }
        break
      case 'back':
        const newMinZ = getMinZIndex() - 1
        for (let i = 0; i < uids.length; i++) {
          containerRefs.current[uids[i]].current.style.zIndex = newMinZ
          actions.updateElement(uids[i], 'zindex', { zIndex: newMinZ })
        }
        break
      default:
        break
    }
  }

  const showElementProperties = () => {
    if (!selectedUids.current || selectedUids.current.length === 0) return
    const elementRef = elementRefs.current[selectedUids.current[0]]

    if (!elementRef) return
    if (elementRef.current?.showProperties) {
      elementRef.current.showProperties()
    }
  }

  const onMouseDown = (e) => {
    if (!viewRef.current) return
    if (e.shiftKey && e.buttons === 1) {
      viewRef.current.style.cursor = 'grabbing'
      const startX = e.pageX - viewRef.current.offsetLeft
      const startY = e.pageY - viewRef.current.offsetTop
      const scrollLeft = viewRef.current.scrollLeft
      const scrollTop = viewRef.current.scrollTop
      mouseCoords.current = { startX, startY, scrollLeft, scrollTop }
    }
  }

  const onMouseUp = (e) => {
    if (!viewRef.current) return
    viewRef.current.style.cursor = 'auto'
  }

  const onMouseDrag = (e) => {
    if (!viewRef.current) return
    const horizontalCursor = document.getElementById('wpc-horizontal-cursor')
    if (horizontalCursor && horizontalRulerRef.current) {
      const rulerRect = horizontalRulerRef.current.getBoundingClientRect()
      let cursorPosition = e.clientX - rulerRect.left
      cursorPosition = Math.max(0, cursorPosition)
      cursorPosition = Math.min(cursorPosition, rulerRect.width)
      horizontalCursor.style.left = `${cursorPosition}px`
    }

    const verticalCursor = document.getElementById('wpc-vertical-cursor')
    if (verticalCursor && verticalRulerRef.current) {
      const rulerRect = verticalRulerRef.current.getBoundingClientRect()
      let cursorPosition = e.clientY - rulerRect.top
      cursorPosition = Math.max(0, cursorPosition)
      cursorPosition = Math.min(cursorPosition, rulerRect.height)
      verticalCursor.style.top = `${cursorPosition}px`
    }

    if (e.shiftKey && e.buttons === 1) {
      // e.preventDefault()
      const x = e.pageX - viewRef.current.offsetLeft
      const y = e.pageY - viewRef.current.offsetTop
      const walkX = (x - mouseCoords.current.startX) * 1.5
      const walkY = (y - mouseCoords.current.startY) * 1.5
      viewRef.current.scrollLeft = mouseCoords.current.scrollLeft - walkX
      viewRef.current.scrollTop = mouseCoords.current.scrollTop - walkY
    }
  }

  let contextMenuItems = getContextMenuItems(
    selectedUids,
    handleZIndex,
    viewRef,
    actions,
    showImageMenuItems,
    showTableMenuItems,
    elementType,
    tableType,
    setShowImageMenuItems,
    setShowTableMenuItems,
    showChartMenuItems,
    chartType,
    setShowChartMenuItems,
    showElementProperties,
    containerRefs,
    units,
    zoomScale,
    convertPixelsToUser,
  )

  containerRefs.current = {}
  elementRefs.current = {}

  const onKeyDown = (e) => {
    if (e.key === 'Shift' || e.key === 'ShiftLeft' || e.key === 'ShiftRight') {
      shiftRef.current = true
    }

    if (selectedUids.current && selectedUids.current.length > 0) {
      let moveDist = 0.1
      if (e.shiftKey) moveDist = 0.01 //shift key for fine movement
      let moveDistPixels = convertUserToPixels(moveDist, units, zoomScale)

      let marginOffset = { left: 0, top: 0 }
      let gridPix = convertUserToPixels(pageLayout.gridSpacing, units, zoomScale)
      if (config.snapToGrid) {
        marginOffset = {
          left: convertUserToPixels(pageLayout.marginLeft, units, zoomScale),
          top: convertUserToPixels(pageLayout.marginTop, units, zoomScale),
        }
      }
      if (e.key === 'ArrowUp') {
        for (let i = 0; i < selectedUids.current.length; i++) {
          let top = parseFloat(containerRefs.current[selectedUids.current[i]].current.style.top)
          if (
            config.snapToGrid &&
            !e.shiftKey &&
            extractRotateValue(containerRefs.current[selectedUids.current[i]].current.style.transform) === 0.0
          ) {
            moveDistPixels = (top - marginOffset.top) % gridPix
            if (moveDistPixels === 0) moveDistPixels = gridPix
            moveDist = convertPixelsToUser(moveDistPixels, units, zoomScale)
          }
          containerRefs.current[selectedUids.current[i]].current.style.top = `${top - moveDistPixels}px`
          actions.moveElement(selectedUids.current[i], 0, -moveDist)
        }
        e.stopPropagation()
      }
      if (e.key === 'ArrowDown') {
        for (let i = 0; i < selectedUids.current.length; i++) {
          let top = parseFloat(containerRefs.current[selectedUids.current[i]].current.style.top)
          if (
            config.snapToGrid &&
            !e.shiftKey &&
            extractRotateValue(containerRefs.current[selectedUids.current[i]].current.style.transform) === 0.0
          ) {
            moveDistPixels = (top - marginOffset.top) % gridPix
            if (moveDistPixels === 0) moveDistPixels = gridPix
            moveDist = convertPixelsToUser(moveDistPixels, units, zoomScale)
          }
          containerRefs.current[selectedUids.current[i]].current.style.top = `${top + moveDistPixels}px`
          actions.moveElement(selectedUids.current[i], 0, moveDist)
        }
        e.stopPropagation()
      }
      if (e.key === 'ArrowLeft') {
        for (let i = 0; i < selectedUids.current.length; i++) {
          let left = parseFloat(containerRefs.current[selectedUids.current[i]].current.style.left)
          if (
            config.snapToGrid &&
            !e.shiftKey &&
            extractRotateValue(containerRefs.current[selectedUids.current[i]].current.style.transform) === 0.0
          ) {
            moveDistPixels = (left - marginOffset.left) % gridPix
            if (moveDistPixels === 0) moveDistPixels = gridPix
            moveDist = convertPixelsToUser(moveDistPixels, units, zoomScale)
          }
          containerRefs.current[selectedUids.current[i]].current.style.left = `${left - moveDistPixels}px`
          actions.moveElement(selectedUids.current[i], -moveDist, 0)
        }
        e.stopPropagation()
      }
      if (e.key === 'ArrowRight') {
        for (let i = 0; i < selectedUids.current.length; i++) {
          let left = parseFloat(containerRefs.current[selectedUids.current[i]].current.style.left)
          if (
            config.snapToGrid &&
            !e.shiftKey &&
            extractRotateValue(containerRefs.current[selectedUids.current[i]].current.style.transform) === 0.0
          ) {
            moveDistPixels = (left - marginOffset.left) % gridPix
            if (moveDistPixels === 0) moveDistPixels = gridPix
            moveDist = convertPixelsToUser(moveDistPixels, units, zoomScale)
          }
          containerRefs.current[selectedUids.current[i]].current.style.left = `${left + moveDistPixels}px`
          actions.moveElement(selectedUids.current[i], moveDist, 0)
        }
        e.stopPropagation()
      }
    }
  }

  const onKeyUp = (e) => {
    shiftRef.current = false
  }

  const getMaxZIndex = () => {
    let maxZIndex = 0
    for (let uid in containerRefs.current) {
      let currZIndex = parseInt(containerRefs.current[uid].current.style.zIndex)
      if (currZIndex > maxZIndex) {
        maxZIndex = currZIndex
      }
    }
    return maxZIndex
  }

  const getMinZIndex = () => {
    let minZIndex = 10000
    for (let uid in containerRefs.current) {
      let currZIndex = parseInt(containerRefs.current[uid].current.style.zIndex)
      if (currZIndex < minZIndex) {
        minZIndex = currZIndex
      }
    }
    return minZIndex
  }

  const getElementRefs = (uids) => {
    if (!Array.isArray(uids)) return []
    if (uids.length === 0) return []

    let elementRefs = []
    for (let i = 0; i < uids.length; i++) {
      if (!containerRefs.current[uids[i]]) continue
      elementRefs.push(containerRefs.current[uids[i]])
    }

    return elementRefs
  }

  const getUidsFromRefs = (refs) => {
    let newUids = []
    Object.keys(containerRefs.current).forEach((uid) => {
      const ref = containerRefs.current[uid]
      if (refs === ref.current || (Array.isArray(refs) && refs.contains(ref.current))) {
        newUids.push(uid)
      }
    })
    return newUids
  }

  const getUidFromRef = (ref) => {
    let uid = null
    Object.keys(containerRefs.current).forEach((key) => {
      if (containerRefs.current[key].current === ref) {
        uid = key
      }
    })
    return uid
  }

  const getElementFromRef = (ref) => {
    let uid = getUidFromRef(ref)
    return elements.current.find((el) => el.uid === uid)
  }

  const onMoveableResize = (e) => {
    e.target.style.width = `${e.width}px`
    e.target.style.height = `${e.height}px`
    if (e.drag) {
      e.target.style.left = `${e.drag.left}px`
      e.target.style.top = `${e.drag.top}px`
    }
  }

  const onMoveableResizeEnd = (e) => {
    if (e.isDrag && e.lastEvent) {
      let updateProps = {}
      let width = e.lastEvent.width
      let height = e.lastEvent.height
      if (e.lastEvent?.drag) {
        if (e.lastEvent.drag.left) updateProps.left = convertPixelsToUser(e.lastEvent.drag.left, units, zoomScale)
        if (e.lastEvent.drag.top) updateProps.top = convertPixelsToUser(e.lastEvent.drag.top, units, zoomScale)
      }

      if (config.snapToGrid && extractRotateFromStyle(e.target.style.transform) === null) {
        let left = e.lastEvent.drag.left
        let top = e.lastEvent.drag.top
        let right = left + e.lastEvent.width
        let bottom = top + e.lastEvent.height

        let marginOffset = { left: 0, top: 0 }
        let gridPix = convertUserToPixels(pageLayout.gridSpacing, units, zoomScale)
        marginOffset = {
          left: convertUserToPixels(pageLayout.marginLeft, units, zoomScale),
          top: convertUserToPixels(pageLayout.marginTop, units, zoomScale),
        }

        const changeDir = {
          left: e.lastEvent.direction[0] === -1,
          top: e.lastEvent.direction[1] === -1,
          right: e.lastEvent.direction[0] === 1,
          bottom: e.lastEvent.direction[1] === 1,
        }

        if (changeDir.left) {
          left = marginOffset.left + Math.round((left - marginOffset.left) / gridPix) * gridPix
          // if the left snaps, need to adjust the width so that the right doesn't move
          width = right - left

          e.target.style.left = `${left}px`
          e.target.style.width = `${width}px`

          // fall through to updateProps for width
          updateProps.left = convertPixelsToUser(left, units, zoomScale)
        }

        if (changeDir.top) {
          top = marginOffset.top + Math.round((top - marginOffset.top) / gridPix) * gridPix
          // if the top snaps, need to adjust the height so that the bottom doesn't move
          height = bottom - top

          e.target.style.top = `${top}px`
          e.target.style.height = `${height}px`

          // fall through to updateProps for height
          updateProps.top = convertPixelsToUser(top, units, zoomScale)
        }

        if (changeDir.right) {
          right =
            marginOffset.left +
            Math.round((right - marginOffset.left) / gridPix) * gridPix -
            2 * parseInt(e.lastEvent.target.style.borderWidth)
          width = right - e.lastEvent.drag.left
          e.target.style.width = `${width}px`
        }

        if (changeDir.bottom) {
          bottom =
            marginOffset.top +
            Math.round((bottom - marginOffset.top) / gridPix) * gridPix -
            2 * parseInt(e.lastEvent.target.style.borderWidth)
          height = bottom - e.lastEvent.drag.top
          e.target.style.height = `${height}px`
        }
      }

      updateProps.width = convertPixelsToUser(width, units, zoomScale)
      updateProps.height = convertPixelsToUser(height, units, zoomScale)
      actions.updateElement(selectedUids.current[0], 'resize', updateProps) // needs a closer look
    }
  }

  const onMoveableDragStart = (e) => {
    const isEdgeDrag = e.inputEvent.target.classList.contains('moveable-edgeDraggable')
    const element = getElementFromRef(e.target)
    if (selectedUids.current.length === 1 && element.type === elementType.chart && !isEdgeDrag) {
      e.stopDrag()
    }
  }

  const onMoveableDrag = (e) => {
    e.target.style.top = `${e.top}px`
    e.target.style.left = `${e.left}px`
  }

  const onMoveableDragGroup = (e) => {
    const transformObj = extractTranslate(e.transform)
    if (!transformObj) return
    e.events.forEach((ev) => {
      let top = ev.top + transformObj.topOffset
      let left = ev.left + transformObj.leftOffset
      ev.target.style.top = `${top}px`
      ev.target.style.left = `${left}px`
    })
  }

  const onMoveableDragEnd = (e) => {
    if (!e.isDrag) return

    let left = e.lastEvent.left
    let top = e.lastEvent.top

    if (config.snapToGrid && extractRotateFromStyle(e.target.style.transform) === null) {
      let marginOffset = { left: 0, top: 0 }
      let gridPix = convertUserToPixels(pageLayout.gridSpacing, units, zoomScale)
      marginOffset = {
        left: convertUserToPixels(pageLayout.marginLeft, units, zoomScale),
        top: convertUserToPixels(pageLayout.marginTop, units, zoomScale),
      }

      left = marginOffset.left + Math.round((left - marginOffset.left) / gridPix) * gridPix
      top = marginOffset.top + Math.round((top - marginOffset.top) / gridPix) * gridPix

      e.target.style.top = `${top}px`
      e.target.style.left = `${left}px`
    }

    actions.updateElement(selectedUids.current[0], 'drag', {
      left: convertPixelsToUser(left, units, zoomScale),
      top: convertPixelsToUser(top, units, zoomScale),
    })
  }

  const onMoveableRotate = (e) => {
    // only rotate a single element for now
    if (!selectedUids.current || selectedUids.current.length !== 1) return
    let rotate = extractRotateValue(e.drag.transform)
    if (shiftRef.current) {
      rotate = Math.round(rotate / 15) * 15
    } else {
      rotate = Math.round(rotate) // round to nearest degree
    }
    rotate = rotate % 360
    if (rotate === 0) {
      e.target.style.transform = ''
    } else {
      e.target.style.transform = createRotateStyle(rotate)
    }
  }

  const onMoveableRotateEnd = (e) => {
    if (e.lastEvent && selectedUids.current && selectedUids.current.length === 1) {
      let updateProps = {}
      let rotTransform = extractRotateFromStyle(e.lastEvent.transform)
      if (rotTransform) {
        let rotate = extractRotateValue(rotTransform)
        if (shiftRef.current) {
          rotate = Math.round(rotate / 15) * 15
        } else {
          rotate = Math.round(rotate) // round to nearest degree
        }
        rotate = rotate % 360
        updateProps.rotate = rotate
        actions.updateElement(selectedUids.current[0], 'rotate', updateProps)
      }
    }
  }

  const onSelectoSelectEnd = (e) => {
    if (e.isDragStart) {
      e.inputEvent.preventDefault()
      moveableRef.current.waitToChangeTarget().then(() => {
        moveableRef.current.dragStart(e.inputEvent)
      })
    }

    let newTargets = []
    e.selected.forEach((el) => {
      const uid = getUidFromRef(el.closest('.wp-element'))
      if (uid) {
        newTargets.push(uid)
      }
    })

    if (!arraysContainSame(selectedUids.current, newTargets)) {
      selectedUids.current = cloneDeep(newTargets)
      setRerender((prev) => !prev)
    }
  }

  const onMoveableDragGroupEnd = (e) => {
    if (e.isDrag && e.lastEvent) {
      let updateProps = {}
      selectedUids.current.forEach((uid) => {
        let left = parseFloat(containerRefs.current[uid].current.style.left)
        let top = parseFloat(containerRefs.current[uid].current.style.top)

        if (config.snapToGrid && extractRotateValue(e.target.style.transform) === 0.0) {
          let marginOffset = { left: 0, top: 0 }
          let gridPix = convertUserToPixels(pageLayout.gridSpacing, units, zoomScale)
          marginOffset = {
            left: convertUserToPixels(pageLayout.marginLeft, units, zoomScale),
            top: convertUserToPixels(pageLayout.marginTop, units, zoomScale),
          }

          let newLeft = marginOffset.left + Math.round((left - marginOffset.left) / gridPix) * gridPix
          let newTop = marginOffset.top + Math.round((top - marginOffset.top) / gridPix) * gridPix

          containerRefs.current[uid].current.style.top = `${newTop}px`
          containerRefs.current[uid].current.style.left = `${newLeft}px`
          left = newLeft
          top = newTop
        }
        updateProps = {
          left: convertPixelsToUser(left, units, zoomScale),
          top: convertPixelsToUser(top, units, zoomScale),
        }
        actions.updateElement(uid, 'drag', updateProps)
      })
    }
  }

  const onSelectoDragStart = (e) => {
    const moveable = moveableRef.current
    const target = e.inputEvent.target.closest('.wp-element') // the target may not be the moveable element (likely a child element)
    const targetUids = getUidsFromRefs(target)

    // this block prevents the dragging rectangle from drawing when only one element is selected
    if (selectedUids.current.length === 1) {
      e.preventDrag()
    }

    if (
      moveable.isMoveableElement(target) ||
      selectedUids.current.some((t) => t === targetUids || (Array.isArray(t) && t.contains(targetUids)))
    ) {
      e.stop()
    }
  }

  const getElement = (type, uid, element, data, ref, scale) => {
    switch (type) {
      case elementType.text:
        return (
          <TextElement id={uid} ref={ref} element={element} actions={{ update: actions.updateElement }} scale={scale} />
        )
      case elementType.image:
      case elementType.imageOrgLogo:
      case elementType.imageOperLogo:
      case elementType.imageOperLogo2:
        return (
          <ImageElement
            id={uid}
            ref={ref}
            element={element}
            wellData={data}
            // content={data}
            actions={{ update: actions.updateElement }}
          />
        )
      case elementType.table:
        return (
          <TableElement
            id={uid}
            ref={ref}
            element={element}
            wellData={data}
            actions={{ update: actions.updateElement }}
            scale={scale}
          />
        )
      case elementType.chart:
        return (
          <ChartElement
            id={uid}
            ref={ref}
            element={element}
            wellData={data}
            actions={{ update: actions.updateElement }}
            scale={scale}
          />
        )
      default:
        return null
    }
  }

  // this function is similar to the rendering of PlotPage in the return statement, but is used for printing.
  // notably, the containerRefs and elementRefs are not updated--the existing refs are used so that this function
  // will clone the PlotPage with the same elements and their settings for a 1:1 scale print. This component is 
  // only created and rendered during the print cycle.
  const GetPrintPlotPage = (props) => {
    const { zoomScale, plotPageRef } = props

    return (
      <PlotPage ref={plotPageRef} config={config} pageLayout={pageLayout} units={units} scale={zoomScale}>
        {Array.isArray(elements.current)
          ? elements.current.map((elementObj) => {
              const containerRef = containerRefs.current[elementObj.uid]
              const elementRef = elementRefs.current[elementObj.uid]
              return (
                <ElementContainer
                  key={elementObj.uid}
                  elementRef={containerRef}
                  units={units}
                  scale={zoomScale}
                  top={elementObj.top}
                  left={elementObj.left}
                  width={elementObj.width}
                  height={elementObj.height}
                  style={elementObj.style}
                  zIndex={elementObj.zIndex}
                  rotateDeg={elementObj.rotate}>
                  {getElement(elementObj.type, elementObj.uid, elementObj, data, elementRef, zoomScale)}
                </ElementContainer>
              )
            })
          : null}
      </PlotPage>
    )
  }

  return (
    <Box
      className='wpc-vp'
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        backgroundColor: theme === 'dark' ? '#222222' : '#CCC',
        overflow: 'auto',
        ':focus-visible': { outline: 'none' },
      }}
      ref={viewRef}
      tabIndex={-1}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseDrag}
      onMouseUp={onMouseUp}
      onContextMenu={handleContextMenu}>
      {config.showRulers ? (
        <Box sx={{ position: 'absolute', top: '0px', left: '0px' }}>
          <HorizontalRuler pageLayout={pageLayout} units={units} zoomScale={zoomScale} rulerRef={horizontalRulerRef} />
          <VerticalRuler pageLayout={pageLayout} units={units} zoomScale={zoomScale} rulerRef={verticalRulerRef} />
        </Box>
      ) : null}
      <PlotPage ref={pageRef} config={config} pageLayout={pageLayout} units={units} scale={zoomScale}>
        {Array.isArray(elements.current)
          ? elements.current.map((elementObj) => {
              containerRefs.current[elementObj.uid] = React.createRef()
              elementRefs.current[elementObj.uid] = React.createRef()
              return (
                <ElementContainer
                  key={elementObj.uid}
                  elementRef={containerRefs.current[elementObj.uid]}
                  units={units}
                  scale={zoomScale}
                  top={elementObj.top}
                  left={elementObj.left}
                  width={elementObj.width}
                  height={elementObj.height}
                  style={elementObj.style}
                  zIndex={elementObj.zIndex}
                  rotateDeg={elementObj.rotate}>
                  {getElement(
                    elementObj.type,
                    elementObj.uid,
                    elementObj,
                    data,
                    elementRefs.current[elementObj.uid],
                    zoomScale,
                  )}
                </ElementContainer>
              )
            })
          : null}
      </PlotPage>
      <Moveable
        ref={moveableRef}
        target={getElementRefs(selectedUids.current)}
        rotationPosition={'top'}
        rotatable={true}
        draggable={true}
        resizable={true}
        keepRatio={false}
        edgeDraggable={true}
        linePadding={4}
        throttleResize={1}
        throttleDrag={1}
        startDragRotate={0}
        throttleDragRotate={0}
        useResizeObserver={true}
        useMutationObserver={true}
        renderDirections={['nw', 'n', 'ne', 'w', 'e', 'sw', 's', 'se']}
        onClickGroup={(e) => {
          if (selectoRef.current) selectoRef.current.clickTarget(e.inputEvent, e.inputTarget)
        }}
        onResize={onMoveableResize}
        onResizeEnd={onMoveableResizeEnd}
        onDragStart={onMoveableDragStart}
        onDrag={onMoveableDrag}
        onDragGroup={onMoveableDragGroup}
        onDragEnd={onMoveableDragEnd}
        onDragGroupEnd={onMoveableDragGroupEnd}
        onRotate={onMoveableRotate}
        onRotateEnd={onMoveableRotateEnd}
        origin={false}
      />
      <Selecto
        ref={selectoRef}
        dragContainer={'.wp-page'}
        selectableTargets={['.wp-element']}
        hitRate={0}
        selectByClick={true}
        selectFromInside={true}
        toggleContinueSelect={['ctrl']}
        ratio={0}
        keyContainer={viewRef.current}
        onDragStart={onSelectoDragStart}
        onSelectEnd={onSelectoSelectEnd}
      />
      <Box style={{ visibility: 'hidden', position: 'absolute', zIndex: -1 }}>
        {isPrinting && <GetPrintPlotPage zoomScale={1} plotPageRef={printPageRef} />}
      </Box>
      {contextMenu && (
        <Menu
          sx={{
            mt: '1px',
            '& .MuiListItemText-root': { padding: 0, color: '#000000' },
            '& .MuiListItemIcon-root': { padding: 0, color: '#000000' },
            '& .MuiMenu-paper': { backgroundColor: '#DDDDDD', border: '1px solid gray' },
            zIndex: 3000,
          }}
          open={true}
          onClose={onClose}
          anchorReference='anchorPosition'
          anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}>
          {contextMenuItems.map((item, index) => {
            if (item.isDivider) {
              return <Divider key={index} sx={{ bgcolor: 'primary.main' }} />
            }
            if (item.isCollapsible) {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    if (item.onClick) {
                      if (item.onClick()) onClose()
                    }
                  }}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText sx={{ color: '#000000' }}>{item.label}</ListItemText>
                  <ListItemIcon>{item.endIcon}</ListItemIcon>
                </MenuItem>
              )
            }
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  if (item.onClick) {
                    if (item.onClick(selectedUids.current)) onClose()
                  }
                }}>
                <ListItemIcon sx={{ marginLeft: `${item.submenu ? '12px' : ''}` }}>{item.icon}</ListItemIcon>
                <ListItemText sx={{ color: '#000000' }}>{item.label}</ListItemText>
              </MenuItem>
            )
          })}
        </Menu>
      )}
    </Box>
  )
})

export default Viewport
