import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { UNITS_FOR } from 'components/common/hooks/useUnits'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import * as XLSX from '@sheet/image'
import { getStatorData, getBhaTotalWeight, getJarsDryWt, getStabToBit, getPlugInHrs } from './utils'
import { DEFAULT_REPORT_SETTINGS } from 'components/Admin/Organizations/ReportSettingsModal'

export const getXlsFileName = (bhaData, exportType, wellInfoRef, currentWellRef) => {
  if (!bhaData || !Array.isArray(bhaData)) return 'Performace Report.xlsx'
  let exportDate = new Date(Date.now()).toISOString().substring(0, 10).split('-')
  let bhaLabel = bhaData.length > 1 ? 'All' : bhaData[0]?.bhaNumRep

  return `${exportDate[1]}_${exportDate[2]}_${exportDate[0]}_${wellInfoRef.current?.operator}_${currentWellRef.current}${exportType}${bhaLabel}.xlsx`
}

export const makeEmptyArray = (size) => {
  if (typeof size !== 'number') return
  return Array(size).fill('')
}

const generateXlsBhaPerformanceDataRow = (firstHeader, firstVal, secondHeader, secondVal, thirdHeader, thirdVal) => {
  return [
    firstHeader,
    ...makeEmptyArray(1),
    firstVal,
    ...makeEmptyArray(1),
    secondHeader,
    ...makeEmptyArray(1),
    secondVal,
    ...makeEmptyArray(3),
    thirdHeader,
    ...makeEmptyArray(1),
    thirdVal,
  ]
}

const generatePerformanceXlsHeaderRow = (firstHeader, firstVal, secondHeader, secondVal) => {
  return [
    ...makeEmptyArray(4),
    firstHeader,
    ...makeEmptyArray(1),
    firstVal,
    ...makeEmptyArray(2),
    secondHeader,
    ...makeEmptyArray(1),
    secondVal,
  ]
}

const generateXlsWorksheetBhaDetails = ({ bhaComponents }) => {
  if (!bhaComponents) return []
  if (!Array.isArray(bhaComponents)) return []
  if (bhaComponents.length <= 0) return []

  let bhaDetails = []
  let mergedCellList = []
  let rowCount = 0

  for (let i = 0; i < bhaComponents.length; i++) {
    bhaDetails.push([
      {
        v: unescapeHtml(bhaComponents[i].description),
        t: 's',
      },
      ...makeEmptyArray(3),
      {
        v: bhaComponents[i].sn,
        t: 's',
        s: getValueCellStyle(),
      },
      {
        v: bhaComponents[i].cnxTop,
        t: 's',
        s: getValueCellStyle(),
      },
      {
        v: bhaComponents[i].cnxBtm,
        t: 's',
        s: getValueCellStyle(),
      },
      {
        v: numberWithCommasDecimals(bhaComponents[i].od, 2),
        t: 's',
        s: getValueCellStyle(),
      },
      {
        v: numberWithCommasDecimals(bhaComponents[i].id, 2),
        t: 's',
        s: getValueCellStyle(),
      },
      {
        v: numberWithCommasDecimals(bhaComponents[i].tjOd, 2),
        t: 's',
        s: getValueCellStyle(),
      },
      {
        v: numberWithCommasDecimals(bhaComponents[i].tjId, 2),
        t: 's',
        s: getValueCellStyle(),
      },
      {
        v: numberWithCommasDecimals(bhaComponents[i].weight, 2),
        t: 's',
        s: getValueCellStyle(),
      },
      {
        v: numberWithCommasDecimals(bhaComponents[i].length, 2),
        t: 's',
        s: getValueCellStyle(),
      },
      {
        v: numberWithCommasDecimals(bhaComponents[i].cumulativeLength, 2),
        t: 's',
        s: getValueCellStyle(),
      },
    ])
    mergedCellList.push({
      s: { r: 48 + rowCount, c: 1 },
      e: { r: 48 + rowCount, c: 4 },
      style: getCellStyle('#FFFFFF', 'left', 'center', false, 'thin', 'thin', 'thin', 'thin'),
    })
    rowCount++
  }

  return { bhaDetails: bhaDetails, newMergedCells: mergedCellList }
}

const getHeaderCellStyle = (hAlign = 'center', reportSettings) => {
  return getCellStyle(reportSettings.primaryColor, hAlign, 'center', true, 'thin', 'thin', 'thin', 'thin')
}

const getValueCellStyle = () => {
  return getCellStyle('#FFFFFF', 'center', 'center', false, 'thin', 'thin', 'thin', 'thin')
}

export const handleCreatePerformanceXlsWorksheet = async (
  bhaData,
  motorReportData,
  wellInfoRef,
  getUnitsText,
  reportSettings,
) => {
  if (!bhaData) return null
  if (!motorReportData.hasOwnProperty('motorReports')) return null
  if (!Array.isArray(motorReportData?.motorReports)) return null

  let motorReport = motorReportData.motorReports.find((report) => parseInt(report.bhaNum) === parseInt(bhaData?.bhaNum))

  if (!motorReport) return null

  const { bhaDetails, newMergedCells } = generateXlsWorksheetBhaDetails(bhaData)

  let motorData = bhaData.bhaComponents.find((component) => component?.type === 'Motor')
  let bitData = bhaData.bhaComponents.find((component) => component?.type === 'Drill Bit')
  let mwdData = bhaData.bhaComponents.find((component) => component?.type === 'MWD/LWD')

  let ws = XLSX.utils.aoa_to_sheet(
    [
      generatePerformanceXlsHeaderRow('Job #', wellInfoRef.current?.jobNum, 'Operator:', wellInfoRef.current?.operator),
      generatePerformanceXlsHeaderRow('API Job #', wellInfoRef.current?.apiNum, 'Field:', wellInfoRef.current?.field),
      generatePerformanceXlsHeaderRow('Latitude:', wellInfoRef.current?.lat, 'Well:', wellInfoRef.current?.parentWell),
      generatePerformanceXlsHeaderRow('Longitude:', wellInfoRef.current?.lon, 'State:', wellInfoRef.current?.state),
      generatePerformanceXlsHeaderRow('Rig:', wellInfoRef.current?.rig, 'County:', wellInfoRef.current?.county),
      generatePerformanceXlsHeaderRow('Reason POOH:', motorReport?.reasonPooh, 'BHA #', bhaData?.bhaNumRep),
      generatePerformanceXlsHeaderRow('Lead DD:', motorReport?.leadDD, 'Company Man:', motorReport?.companyMan),
      generatePerformanceXlsHeaderRow(
        'DD Coordinator:',
        wellInfoRef.current?.ddCoordinator,
        'District:',
        wellInfoRef.current?.district,
      ),
      ['BHA Performance Report'],
      [
        'Run Data',
        ...makeEmptyArray(3),
        'Motor Data',
        ...makeEmptyArray(5),
        'Drilling Parameters',
        ...makeEmptyArray(3),
      ],
      generateXlsBhaPerformanceDataRow(
        'Date In:',
        motorReport?.dateIn,
        'SN:',
        motorData?.sn,
        `Temperature (${getUnitsText(UNITS_FOR.Temperature)}):`,
        motorReport.drillingParameters.bhtAvg === 0 || motorReport.drillingParameters.bhtMax === 0
          ? '0'
          : `${Math.round(motorReport?.drillingParameters?.bhtMin)} / ${Math.round(
              motorReport?.drillingParameters?.bhtAvg,
            )} / ${Math.round(motorReport?.drillingParameters?.bhtMax)}`,
      ),
      generateXlsBhaPerformanceDataRow(
        'Date Out:',
        motorReport?.dateOut,
        'Description:',
        motorData?.description,
        'WOB Rotate (klbs):',
        getMinMaxAvg(motorReport, 'wob', 0),
      ),
      generateXlsBhaPerformanceDataRow(
        `Depth In(${getUnitsText(UNITS_FOR.Depth)}):`,
        numberWithCommasDecimals(bhaData?.depthIn, 2),
        'Make:',
        motorData?.model,
        'WOB Slide (klbs):',
        getMinMaxAvg(motorReport, 'wobSlide', 0),
      ),
      generateXlsBhaPerformanceDataRow(
        `Depth Out(${getUnitsText(UNITS_FOR.Depth)}):`,
        numberWithCommasDecimals(bhaData?.depthOut, 2),
        'Model:',
        motorData?.model,
        'RPM:',
        getMinMaxAvg(motorReport, 'rpm', 0),
      ),
      generateXlsBhaPerformanceDataRow(
        'Inc In:',
        numberWithCommasDecimals(motorReport?.IncIn, 2),
        'Re-run:',
        motorData?.reRun,
        `FLow Rate (${getUnitsText(UNITS_FOR.FlowRate)}):`,
        getMinMaxAvg(motorReport, 'flowRate', 0),
      ),
      generateXlsBhaPerformanceDataRow(
        'Inc Out:',
        numberWithCommasDecimals(motorReport?.IncOut, 2),
        'Direct Bill:',
        motorData?.directBill,
        `Off Btm Press (${getUnitsText(UNITS_FOR.Pressure)}):`,
        getMinMaxAvg(motorReport, 'offBtmPress', 0),
      ),
      generateXlsBhaPerformanceDataRow(
        'Azi In:',
        numberWithCommasDecimals(motorReport?.AziIn, 2),
        'Lobes:',
        motorData?.motorLobes,
        `On Btm Press (${getUnitsText(UNITS_FOR.Pressure)}):`,
        getMinMaxAvg(motorReport, 'onBtmPress', 0),
      ),
      generateXlsBhaPerformanceDataRow(
        'Azi Out:',
        numberWithCommasDecimals(motorReport?.AziOut, 2),
        'Stages:',
        motorData?.motorStages,
        `Tq On Btm (${getUnitsText(UNITS_FOR.Torque)}):`,
        getMinMaxAvg(motorReport, 'onBtmTq', 0),
      ),
      generateXlsBhaPerformanceDataRow(
        `Total Drilled (${getUnitsText(UNITS_FOR.Depth)}):`,
        numberWithCommasDecimals(bhaData?.totalDrilled, 2),
        'Bend Angle:',
        motorData?.bendAngle,
        `Tq Off Btm (${getUnitsText(UNITS_FOR.Torque)}):`,
        numberWithCommasDecimals(motorReport?.drillingParameters?.offBtmTq, 2),
      ),
      generateXlsBhaPerformanceDataRow(
        `%Slide (Ftg):`,
        bhaData.totalDrilled === 0
          ? numberWithCommasDecimals(0, 2)
          : numberWithCommasDecimals((bhaData?.slideDrilled / bhaData?.totalDrilled) * 100, 2),
        `Bit to Bend (${getUnitsText(UNITS_FOR.Depth)}):`,
        numberWithCommasDecimals(motorData?.bit2bend, 2),
        `Diff Press (${getUnitsText(UNITS_FOR.Pressure)}):`,
        getMinMaxAvg(motorReport, 'diffPress', 0),
      ),
      generateXlsBhaPerformanceDataRow(
        `%Slide (Time):`,
        bhaData.totalDrillingHours === 0
          ? numberWithCommasDecimals(0, 2)
          : numberWithCommasDecimals((bhaData?.slideHours / bhaData?.totalDrillingHours) * 100, 2),
        `Sleeve Stab OD (${getUnitsText(UNITS_FOR.Diameter)}):`,
        numberWithCommasDecimals(motorData?.stabOd, 2),
        `PU WT (${getUnitsText(UNITS_FOR.Weight)}):`,
        getMinMaxAvg(motorReport, 'pickUpWeight', 0),
      ),
      generateXlsBhaPerformanceDataRow(
        `Slide Drilled (${getUnitsText(UNITS_FOR.Depth)}):`,
        numberWithCommasDecimals(bhaData?.slideDrilled, 2),
        `Bearing Gap (${getUnitsText(UNITS_FOR.Diameter)}):`,
        numberWithCommasDecimals(motorData?.bearingGap, 2),
        `ROT WT (${getUnitsText(UNITS_FOR.Weight)}):`,
        getMinMaxAvg(motorReport, 'rotateWeight', 0),
      ),
      generateXlsBhaPerformanceDataRow(
        `Slide Hours (${getUnitsText(UNITS_FOR.Depth)}):`,
        numberWithCommasDecimals(bhaData?.slideHours, 2),
        `Stab to Bit (${getUnitsText(UNITS_FOR.Depth)}):`,
        numberWithCommasDecimals(motorData?.stabDfb, 2),
        `SO WT (${getUnitsText(UNITS_FOR.Weight)}):`,
        getMinMaxAvg(motorReport, 'slackOffWeight', 0),
      ),
      // insert custom row below to account for unique "Bit Data" cell merge / style in row
      [
        '%Rotary (Ftg):',
        ...makeEmptyArray(1),
        bhaData.totalDrilled === 0
          ? numberWithCommasDecimals(0, 2)
          : numberWithCommasDecimals((bhaData?.rotateDrilled / bhaData?.totalDrilled) * 100, 2),
        ...makeEmptyArray(1),
        `Kickpad OD (${getUnitsText(UNITS_FOR.Diameter)}):`,
        ...makeEmptyArray(1),
        numberWithCommasDecimals(motorData?.kickPadOd, 2),
        ...makeEmptyArray(3),
        'Bit Data',
      ],
      generateXlsBhaPerformanceDataRow(
        '%Rotary (Time):',
        bhaData.totalDrillingHours === 0
          ? numberWithCommasDecimals(0, 2)
          : numberWithCommasDecimals((bhaData?.rotateHours / bhaData?.totalDrillingHours) * 100, 2),
        `Body OD (${getUnitsText(UNITS_FOR.Diameter)}):`,
        numberWithCommasDecimals(motorData?.od, 2),
        'SN:',
        bitData?.sn,
      ),
      generateXlsBhaPerformanceDataRow(
        `Rotary Drilled (${getUnitsText(UNITS_FOR.Depth)})`,
        numberWithCommasDecimals(bhaData?.rotateDrilled, 2),
        `Fishneck OD (${getUnitsText(UNITS_FOR.Diameter)}):`,
        numberWithCommasDecimals(motorData?.fishNeckOd, 2),
        'Type:',
        bitData?.bitType,
      ),
      generateXlsBhaPerformanceDataRow(
        'Rotary Hours:',
        numberWithCommasDecimals(bhaData?.rotateHours, 2),
        `Length (${getUnitsText(UNITS_FOR.Depth)}):`,
        numberWithCommasDecimals(motorData?.length, 2),
        'Make:',
        bitData?.make,
      ),
      generateXlsBhaPerformanceDataRow(
        'Total Drlg Hrs:',
        numberWithCommasDecimals(bhaData?.slideHours + bhaData?.rotateHours, 2),
        'Integral Float',
        motorData?.hasFloat ? 'YES' : 'NO',
        'Model:',
        bitData?.model,
      ),
      generateXlsBhaPerformanceDataRow(
        'Total D&C Hrs:',
        numberWithCommasDecimals(bhaData?.totalDrillingCircHours, 2),
        'Rev per Gal',
        motorData?.revPerGal,
        `OD (${getUnitsText(UNITS_FOR.Diameter)})`,
        bitData?.od,
      ),
      generateXlsBhaPerformanceDataRow(
        'Off Btm Circ Hrs:',
        numberWithCommasDecimals(bhaData?.circHours, 2),
        '# Stalls:',
        motorReport?.numstalls,
        'TFA',
        bitData?.tfa,
      ),
      generateXlsBhaPerformanceDataRow(
        'BRT Hrs:',
        numberWithCommasDecimals(bhaData?.brtHours, 2),
        'Stall Pressure:',
        motorReport?.stallPressure,
        'Bit #',
        bitData?.bitNum,
      ),
      generateXlsBhaPerformanceDataRow(
        `Slide ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`,
        numberWithCommasDecimals(bhaData?.slideRop, 2),
        'Stator Vendor:',
        getStatorData(motorData),
        'Grade In:',
        bitData?.gradeIn,
      ),
      generateXlsBhaPerformanceDataRow(
        `Rot ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`,
        numberWithCommasDecimals(bhaData?.rotateRop, 2),
        'Stator Type:',
        motorData?.statorType,
        'Grade Out:',
        bitData?.gradeOut,
      ),
      generateXlsBhaPerformanceDataRow(
        `Avg ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`,
        numberWithCommasDecimals(bhaData?.avgRop, 2),
        'Stator Fit:',
        motorData?.statorFit,
        'Drill Hrs:',
        bhaData?.totalDrillingHours, // matches current PDF data but unsure if this is the correct field to be using
        //bitData.drillingHours would makes more sense...?
      ),
      generateXlsBhaPerformanceDataRow(
        '',
        '',
        'Pre Run Dyno HP%',
        motorData?.dynoHp,
        'Circ Hrs:',
        bhaData?.circHours, // same thing here - matches PDF but should probably be using bitData.circHours.....
      ),
      generateXlsBhaPerformanceDataRow(
        '',
        '',
        `Max DiffP (${getUnitsText(UNITS_FOR.Pressure)})`,
        motorData?.maxDiffP,
        'IADC Code',
        bitData?.iadcCode,
      ),
      generateXlsBhaPerformanceDataRow(
        '',
        '',
        `Max Torque (${getUnitsText(UNITS_FOR.Torque)})`,
        motorData?.maxTorque,
        '# Blades',
        numberWithCommasDecimals(bitData?.numBlades, 0),
      ),
      generateXlsBhaPerformanceDataRow(
        '',
        '',
        `Flow Range (${getUnitsText(UNITS_FOR.FlowRate)})`,
        motorData?.flowRange,
        'K Revs',
        numberWithCommasDecimals(bhaData?.krevs, 0),
      ),
      // Mud Data section
      ['Mud Data'],
      [
        '600/300',
        '200/100',
        '6/3',
        'PH',
        'Oil / Water',
        ...makeEmptyArray(1),
        'PV',
        'YP',
        'Wt (ppg)',
        'Chlorides',
        'Type',
        '%Solids',
        '%Sand',
        `Temp (${getUnitsText(UNITS_FOR.Temperature)})`,
      ],
      [
        `${motorReport?.mudProperties?.dialReadings?.sixHundred} / ${motorReport?.mudProperties?.dialReadings?.threeHundred}`,
        `${motorReport?.mudProperties?.dialReadings?.twoHundred} / ${motorReport?.mudProperties?.dialReadings?.onehundred}`,
        `${motorReport?.mudProperties?.dialReadings?.six} / ${motorReport?.mudProperties?.dialReadings?.three}`,
        '', // currently not in provided dataset
        motorReport?.mudProperties?.oilwaterRatio,
        ...makeEmptyArray(1),
        motorReport?.mudProperties?.pv,
        motorReport?.mudProperties?.yp,
        motorReport?.mudProperties?.mudWeight,
        motorReport?.mudProperties?.chlorides,
        motorReport?.mudProperties?.description,
        motorReport?.mudProperties?.solids,
        motorReport?.mudProperties?.sand,
        motorReport?.mudProperties?.fluidTemperature,
      ],
      ['Sensor Offsets', ...makeEmptyArray(9), 'Directional Performance', ...makeEmptyArray(3)],
      [
        'Svy',
        'Gam',
        'Res',
        'PWD',
        'Dens',
        'Neu',
        'Sonic',
        'NB Inc',
        'NB Azi',
        'Gyro',
        'Plan DLS',
        ...makeEmptyArray(1),
        'Act/Max DLS',
      ],
      [
        numberWithCommasDecimals(mwdData?.surveyOffset, 2),
        numberWithCommasDecimals(mwdData?.gammaOffset, 2),
        numberWithCommasDecimals(mwdData?.resOffset, 2),
        numberWithCommasDecimals(mwdData?.pwdOffset, 2),
        numberWithCommasDecimals(mwdData?.densOffset, 2),
        numberWithCommasDecimals(mwdData?.neutronOffset, 2),
        numberWithCommasDecimals(mwdData?.sonicOffset, 2),
        numberWithCommasDecimals(mwdData?.nbIncOffset, 2),
        numberWithCommasDecimals(mwdData?.nbAziOffset, 2),
        numberWithCommasDecimals(mwdData?.gyroOffset, 2),
        numberWithCommasDecimals(motorReport?.plannedDls, 2),
        ...makeEmptyArray(1),
        `${numberWithCommasDecimals(motorReport?.actualDls, 2)} / ${numberWithCommasDecimals(motorReport?.maxDls, 2)}`,
      ],
      ['BHA Details'],
      [
        'Description',
        ...makeEmptyArray(3),
        'SN',
        'CNX TOP',
        'CNX BTM',
        'OD',
        'ID',
        'TJ OD',
        'TJ ID',
        'Weight',
        'Length',
        'Total Length',
      ],
      [
        ...makeEmptyArray(7),
        `(${getUnitsText(UNITS_FOR.Diameter)})`,
        `(${getUnitsText(UNITS_FOR.Diameter)})`,
        `(${getUnitsText(UNITS_FOR.Diameter)})`,
        `(${getUnitsText(UNITS_FOR.Diameter)})`,
        '(lb/ft)',
        `(${getUnitsText(UNITS_FOR.Depth)})`,
        `(${getUnitsText(UNITS_FOR.Depth)})`,
      ],
      ...bhaDetails,
      ['Additional Comments'],
      [unescapeHtml(motorReport?.additionalComments)],
      ['icpwebportal'],
    ],
    { origin: 'B2' },
  )

  ws['!merges'] = [
    // logo box
    {
      s: { r: 1, c: 1 },
      e: { r: 8, c: 4 },
      style: null,
    },
    // job # header
    {
      s: { r: 1, c: 5 },
      e: { r: 1, c: 6 },
      style: getHeaderCellStyle('right', reportSettings),
    },
    // job # data
    {
      s: { r: 1, c: 7 },
      e: { r: 1, c: 9 },
      style: getValueCellStyle(),
    },
    // Operator header
    {
      s: { r: 1, c: 10 },
      e: { r: 1, c: 11 },
      style: getHeaderCellStyle('right', reportSettings),
    },
    // operator data
    {
      s: { r: 1, c: 12 },
      e: { r: 1, c: 14 },
      style: getValueCellStyle(),
    },
    // API Job # header
    {
      s: { r: 2, c: 5 },
      e: { r: 2, c: 6 },
      style: getHeaderCellStyle('right', reportSettings),
    },
    // API JOB # data
    {
      s: { r: 2, c: 7 },
      e: { r: 2, c: 9 },
      style: getValueCellStyle(),
    },
    // Field header
    {
      s: { r: 2, c: 10 },
      e: { r: 2, c: 11 },
      style: getHeaderCellStyle('right', reportSettings),
    },
    // Field data
    {
      s: { r: 2, c: 12 },
      e: { r: 2, c: 14 },
      style: getValueCellStyle(),
    },
    // Lat header
    {
      s: { r: 3, c: 5 },
      e: { r: 3, c: 6 },
      style: getHeaderCellStyle('right', reportSettings),
    },
    // Lat data
    {
      s: { r: 3, c: 7 },
      e: { r: 3, c: 9 },
      style: getValueCellStyle(),
    },
    // Well header
    {
      s: { r: 3, c: 10 },
      e: { r: 3, c: 11 },
      style: getHeaderCellStyle('right', reportSettings),
    },
    // Well data
    {
      s: { r: 3, c: 12 },
      e: { r: 3, c: 14 },
      style: getValueCellStyle(),
    },
    // Longitude header
    {
      s: { r: 4, c: 5 },
      e: { r: 4, c: 6 },
      style: getHeaderCellStyle('right', reportSettings),
    },
    // Longitude data
    {
      s: { r: 4, c: 7 },
      e: { r: 4, c: 9 },
      style: getValueCellStyle(),
    },
    // State header
    {
      s: { r: 4, c: 10 },
      e: { r: 4, c: 11 },
      style: getHeaderCellStyle('right', reportSettings),
    },
    // State data
    {
      s: { r: 4, c: 12 },
      e: { r: 4, c: 14 },
      style: getValueCellStyle(),
    },
    // Rig header
    {
      s: { r: 5, c: 5 },
      e: { r: 5, c: 6 },
      style: getHeaderCellStyle('right', reportSettings),
    },
    // Rig data
    {
      s: { r: 5, c: 7 },
      e: { r: 5, c: 9 },
      style: getValueCellStyle(),
    },
    // County header
    {
      s: { r: 5, c: 10 },
      e: { r: 5, c: 11 },
      style: getHeaderCellStyle('right', reportSettings),
    },
    // County data
    {
      s: { r: 5, c: 12 },
      e: { r: 5, c: 14 },
      style: getValueCellStyle(),
    },
    // reason POOH header
    {
      s: { r: 6, c: 5 },
      e: { r: 6, c: 6 },
      style: getHeaderCellStyle('right', reportSettings),
    },
    // reason POOH data
    {
      s: { r: 6, c: 7 },
      e: { r: 6, c: 9 },
      style: getValueCellStyle(),
    },
    // BHA # header
    {
      s: { r: 6, c: 10 },
      e: { r: 6, c: 11 },
      style: getHeaderCellStyle('right', reportSettings),
    },
    // BHA # data
    {
      s: { r: 6, c: 12 },
      e: { r: 6, c: 14 },
      style: getValueCellStyle(),
    },
    // Lead DD header
    {
      s: { r: 7, c: 5 },
      e: { r: 7, c: 6 },
      style: getHeaderCellStyle('right', reportSettings),
    },
    // Lead DD data
    {
      s: { r: 7, c: 7 },
      e: { r: 7, c: 9 },
      style: getValueCellStyle(),
    },
    // Company Man header
    {
      s: { r: 7, c: 10 },
      e: { r: 7, c: 11 },
      style: getHeaderCellStyle('right', reportSettings),
    },
    // Company Man data
    {
      s: { r: 7, c: 12 },
      e: { r: 7, c: 14 },
      style: getValueCellStyle(),
    },
    // DD Coord header
    {
      s: { r: 8, c: 5 },
      e: { r: 8, c: 6 },
      style: getHeaderCellStyle('right', reportSettings),
    },
    // DD Coord data
    {
      s: { r: 8, c: 7 },
      e: { r: 8, c: 9 },
      style: getValueCellStyle(),
    },
    // District header
    {
      s: { r: 8, c: 10 },
      e: { r: 8, c: 11 },
      style: getHeaderCellStyle('right', reportSettings),
    },
    // District data
    {
      s: { r: 8, c: 12 },
      e: { r: 8, c: 14 },
      style: getValueCellStyle(),
    },
    // BHA Performance Report Title
    {
      s: { r: 9, c: 1 },
      e: { r: 9, c: 14 },
      style: getHeaderCellStyle('center', reportSettings),
    },
    // Run Data Header
    {
      s: { r: 10, c: 1 },
      e: { r: 10, c: 4 },
      style: getHeaderCellStyle('center', reportSettings),
    },
    // Motor Data Header
    {
      s: { r: 10, c: 5 },
      e: { r: 10, c: 10 },
      style: getHeaderCellStyle('center', reportSettings),
    },
    // Drilling Parameters Header
    {
      s: { r: 10, c: 11 },
      e: { r: 10, c: 14 },
      style: getHeaderCellStyle('center', reportSettings),
    },
    // generateXlsWorksheetBhaDetails builds the merged cells / styles array down to row 39 of spreadsheet (r: 38)
    // Mud Data Report Title
    {
      s: { r: 39, c: 1 },
      e: { r: 39, c: 14 },
      style: getHeaderCellStyle('center', reportSettings),
    },
    // Oil / Water header
    {
      s: { r: 40, c: 5 },
      e: { r: 40, c: 6 },
      style: getHeaderCellStyle('center', reportSettings),
    },
    // Oil / Water data
    {
      s: { r: 41, c: 5 },
      e: { r: 41, c: 6 },
      style: getValueCellStyle(),
    },
    // Sensor Offsets header
    {
      s: { r: 42, c: 1 },
      e: { r: 42, c: 10 },
      style: getHeaderCellStyle('center', reportSettings),
    },
    // Directional Performance header
    {
      s: { r: 42, c: 11 },
      e: { r: 42, c: 14 },
      style: getHeaderCellStyle('center', reportSettings),
    },
    // Plan DLS header
    {
      s: { r: 43, c: 11 },
      e: { r: 43, c: 12 },
      style: getHeaderCellStyle('center', reportSettings),
    },
    // Act/Max DLS header
    {
      s: { r: 43, c: 13 },
      e: { r: 43, c: 14 },
      style: getHeaderCellStyle('center', reportSettings),
    },
    // Plan DLS data
    {
      s: { r: 44, c: 11 },
      e: { r: 44, c: 12 },
      style: getValueCellStyle(),
    },
    // Act / Max DLS data
    {
      s: { r: 44, c: 13 },
      e: { r: 44, c: 14 },
      style: getValueCellStyle(),
    },
    // BHA Details header
    {
      s: { r: 45, c: 1 },
      e: { r: 45, c: 14 },
      style: getHeaderCellStyle('center', reportSettings),
    },
    // Description header
    {
      s: { r: 46, c: 1 },
      e: { r: 46, c: 4 },
      style: getHeaderCellStyle('center', reportSettings),
    },
    // Blank merged row under Description header
    {
      s: { r: 47, c: 1 },
      e: { r: 47, c: 4 },
      style: getHeaderCellStyle('center', reportSettings),
    },
    ...newMergedCells,
    // Additional Comments header
    {
      s: { r: 48 + newMergedCells.length, c: 1 },
      e: { r: 48 + newMergedCells.length, c: 14 },
      style: getHeaderCellStyle('center', reportSettings),
    },
    // Additional Comments data
    {
      s: { r: 49 + newMergedCells.length, c: 1 },
      e: { r: 49 + newMergedCells.length, c: 14 },
      style: getCellStyle('#FFFFFF', 'left', 'center', false, 'thin', 'thin', 'thin', 'thin', true),
    },
    // footer
    {
      s: { r: 50 + newMergedCells.length, c: 1 },
      e: { r: 50 + newMergedCells.length, c: 14 },
      style: getHeaderCellStyle('right', reportSettings),
    },
  ]

  return ws
}

const getXlsCellRange = (start, end) => {
  if (!start || !end) return 'A1:A2'
  let startAddress = XLSX.utils.encode_cell(start)
  let endAddress = XLSX.utils.encode_cell(end)
  return `${startAddress}:${endAddress}`
}

export const getPerformanceXlsWorksheetStyles = (ws, reportSettings) => {
  if (!ws) return
  if (!ws.hasOwnProperty('!merges')) return

  for (const field in ws) {
    if (field === '!merges' || field === '!ref') continue
    const { r, c } = XLSX.utils.decode_cell(field)

    if (r > 10 && r < 39) {
      //merge and style Run Data section
      if (c === 1 || c === 3) {
        ws['!merges'].push({
          s: { r: r, c: c },
          e: { r: r, c: c + 1 },
          style: c === 1 ? getHeaderCellStyle('right', reportSettings) : getValueCellStyle(),
        })
      }
      //merge and style Motor Data section
      if (c === 5 || c === 7) {
        ws['!merges'].push({
          s: { r: r, c: c },
          e: { r: r, c: c + (c === 5 ? 1 : 3) },
          style: c === 5 ? getHeaderCellStyle('right', reportSettings) : getValueCellStyle(),
        })
      }
      //merge and style Drilling Parameters section
      if (c === 11 || c === 13) {
        ws['!merges'].push({
          s: { r: r, c: c },
          e: { r: r, c: c + (r === 24 ? 3 : 1) },
          style: c === 11 ? getHeaderCellStyle(r === 24 ? 'center' : 'right', reportSettings) : getValueCellStyle(),
        })
      }
    } else if (r === 40 && (c < 5 || c > 6)) {
      // style Mud Data headers
      ws[field].s = getHeaderCellStyle('center', reportSettings)
    } else if (r === 41) {
      //style Mud data values
      ws[field].s = getValueCellStyle()
    } else if (r === 43 && c < 11) {
      //style Sensor Offset headers
      ws[field].s = getHeaderCellStyle('center', reportSettings)
    } else if (r === 44 && c < 11) {
      //style Sensor Offset data values
      ws[field].s = getValueCellStyle()
    } else if ((r === 46 || r === 47) && c > 4) {
      //style BHA Details headers (omitting cols 1-3 since they are already merged / styled)
      ws[field].s = getHeaderCellStyle('center', reportSettings)
    }
  }

  ws['!merges'].forEach((cellAddresses) => {
    const { s, e, style } = cellAddresses

    let cellRange = getXlsCellRange(s, e)

    if (style) XLSX.utils.sheet_set_range_style(ws, cellRange, style)
  })
}

const getMinMaxAvg = (motorReport, param, decPl = 2) => {
  if (!motorReport) return ''
  if (typeof param !== 'string') return ''
  if (!motorReport.hasOwnProperty('drillingParameters')) return ''
  if (!motorReport.drillingParameters.hasOwnProperty('minMaxAvg')) return ''
  if (!motorReport.drillingParameters.minMaxAvg.hasOwnProperty(param)) return ''
  const { min, max, avg } = motorReport.drillingParameters.minMaxAvg[param]
  return `${numberWithCommasDecimals(min < 999999.25 ? min : 0, decPl)} / ${numberWithCommasDecimals(
    avg,
    decPl,
  )} / ${numberWithCommasDecimals(max > -999999.25 ? max : 0, decPl)}`
}

const generateBhaXlsHeaderRow = (firstHeader, firstVal, secondHeader, secondVal, thirdHeader, thirdVal) => {
  return [
    firstHeader,
    ...makeEmptyArray(2),
    firstVal,
    secondHeader,
    ...makeEmptyArray(1),
    secondVal,
    thirdHeader,
    ...makeEmptyArray(1),
    thirdVal,
  ]
}

const generateBhaXlsFooterDetailRow = (cellValues, isHeader = false, reportSettings) => {
  if (!Array.isArray(cellValues)) return []
  if (cellValues.length <= 0) return []

  let newCellValues = []

  cellValues.forEach((val, idx) => {
    newCellValues.push(getStyledCellObject(val, isHeader, reportSettings))

    // vals @ idx 0, 2, and 6 are merged columns and need some extra elements added for proper alignment in the worksheet
    if (idx === 0) newCellValues.push(...makeEmptyArray(2))
    if (idx === 2 || idx === 6) newCellValues.push(...makeEmptyArray(1))
  })

  return newCellValues
}

const getStyledCellObject = (cellValue = '', isHeader = false, reportSettings) => {
  if (typeof cellValue !== 'string') return ''

  let backgroundColor = isHeader ? reportSettings.primaryColor : '#FFFFFF'
  let bold = isHeader
  let borderLine = isHeader ? 'thin' : 'thin'

  return {
    v: cellValue,
    t: 's',
    s: getCellStyle(backgroundColor, 'center', 'center', bold, borderLine, borderLine, borderLine, borderLine, true),
  }
}

const generateBhaXlsRowData = (bhaComps, bhaImages, getUnitsText, reportSettings) => {
  if (!bhaComps) return null
  if (!Array.isArray(bhaComps)) return null
  if (bhaComps.length <= 0) return null

  let totalCompWeight = 0
  let rowData = {
    bhaXlsImages: [],
    bhaXlsRowData: [
      [
        getStyledCellObject('Proposed BHA', true, reportSettings),
        getStyledCellObject('#', true, reportSettings),
        getStyledCellObject('SN', true, reportSettings),
        getStyledCellObject('Description', true, reportSettings),
        getStyledCellObject(`OD (${getUnitsText(UNITS_FOR.Diameter)})`, true, reportSettings),
        getStyledCellObject(`ID (${getUnitsText(UNITS_FOR.Diameter)})`, true, reportSettings),
        getStyledCellObject(
          `FN OD (${getUnitsText(UNITS_FOR.Diameter)}) / FN Len (${getUnitsText(UNITS_FOR.Depth)})`,
          true,
          reportSettings,
        ),
        getStyledCellObject('Cnx Up / Cnx Dn', true, reportSettings),
        getStyledCellObject(
          `Wt (lb/ft) / Comp Wt (${getUnitsText(UNITS_FOR.Weight)}) / Tot Wt (${getUnitsText(UNITS_FOR.Weight)})`,
          true,
          reportSettings,
        ),
        getStyledCellObject(`Length (${getUnitsText(UNITS_FOR.Depth)})`, true, reportSettings),
        getStyledCellObject(`Total Length (${getUnitsText(UNITS_FOR.Depth)})`, true, reportSettings),
      ],
    ],
  }

  for (let i = 0; i < bhaComps.length; i++) {
    totalCompWeight += bhaComps[i].weight * bhaComps[i].length

    let image = bhaImages.getPrintImage(bhaComps[i], false)
    rowData.bhaXlsImages.push(image)

    rowData.bhaXlsRowData.push([
      ...makeEmptyArray(1),
      getStyledCellObject(`${i + 1}`, false, reportSettings),
      getStyledCellObject(bhaComps[i]?.sn, false, reportSettings),
      getStyledCellObject(unescapeHtml(bhaComps[i]?.description), false, reportSettings),
      getStyledCellObject(`${bhaComps[i]?.od}`, false, reportSettings),
      getStyledCellObject(`${bhaComps[i]?.id}`, false, reportSettings),
      getStyledCellObject(
        `${bhaComps[i]?.fishNeckOd ? bhaComps[i]?.fishNeckOd : ''} / ${
          bhaComps[i]?.fishNeck ? bhaComps[i]?.fishNeck : ''
        }`,
        false,
        reportSettings,
      ),
      getStyledCellObject(`${bhaComps[i]?.cnxTop} / ${bhaComps[i]?.cnxBtm}`, false, reportSettings),
      getStyledCellObject(
        `${numberWithCommasDecimals(bhaComps[i].weight, 2)} / ${numberWithCommasDecimals(
          (bhaComps[i].weight * bhaComps[i].length) / 1000,
          2,
        )} / ${numberWithCommasDecimals(totalCompWeight / 1000, 2)}`,
        false,
        reportSettings,
      ),
      getStyledCellObject(`${bhaComps[i]?.length}`, false, reportSettings),
      getStyledCellObject(numberWithCommasDecimals(bhaComps[i].cumulativeLength, 2), false, reportSettings),
    ])
  }

  return rowData
}

const addBhaComponentImages = (ws, images) => {
  if (!Array.isArray(images)) return
  if (images.length <= 0) return

  images.forEach((img, idx) => {
    ws['!rows'][11 + idx] = { hpx: 62 }

    ws['!images'].push({
      '!pos': {
        c: 1,
        r: 11 + idx,
        x: 15,
        y: 1,
        w: 50,
        h: 60,
      },
      '!datatype': 'base64',
      '!data': img,
    })
  })
}

const mergeBhaXlsCells = (rowNum, colNum, colWidth, isHeader = false, reportSettings) => {
  if (!rowNum || !colNum || !colWidth) return
  if (typeof rowNum !== 'number' || typeof colNum !== 'number' || typeof colWidth !== 'number') return

  let cellColor = isHeader ? reportSettings.primaryColor : '#FFFFFF'
  let bold = isHeader
  let borderLine = isHeader ? 'thin' : 'thin'

  return {
    s: { r: rowNum, c: colNum },
    e: { r: rowNum, c: colNum + colWidth },
    style: getCellStyle(cellColor, 'center', 'center', bold, borderLine, borderLine, borderLine, borderLine, true),
  }
}

const getBhaXlsToolOrderData = (offsetData) => {
  if (!offsetData || !offsetData.mwdOrder) return null

  return [...makeEmptyArray(1), ['MWD Tool Order'], [`${offsetData?.mwdOrder?.join(' / ')}`]]
}

const getBhaXlsStabilizerData = (stabData, bhaData, getUnitsText, reportSettings) => {
  if (!Array.isArray(stabData) || stabData?.length === 0) return null

  let xlsStabData = [
    ...makeEmptyArray(1),
    ['Stabilizer Data'],
    generateBhaXlsFooterDetailRow(
      [
        'Component Number',
        'Description',
        `OD (${getUnitsText(UNITS_FOR.Diameter)})`,
        `Blade Length (${getUnitsText(UNITS_FOR.Diameter)})`,
        `Blade Width (${getUnitsText(UNITS_FOR.Diameter)})`,
        'Blade Count',
        'Stab to Bit',
      ],
      true,
      reportSettings,
    ),
  ]

  stabData.forEach((stab, idx) => {
    xlsStabData.push(
      generateBhaXlsFooterDetailRow(
        [
          `${stab.sequenceNo + 1}`,
          unescapeHtml(stab.description),
          numberWithCommasDecimals(stab.stabOd, 3),
          numberWithCommasDecimals(stab.stabBl, 2),
          numberWithCommasDecimals(stab.stabBw, 2),
          numberWithCommasDecimals(stab.stabBc, 2),
          numberWithCommasDecimals(getStabToBit(bhaData, stabData, idx), 2),
        ],
        false,
        reportSettings,
      ),
    )
  })

  return xlsStabData
}

const getBhaXlsJarData = (jarData, getUnitsText, reportSettings) => {
  if (Object.keys(jarData).length === 0) return null

  let xlsJarData = [
    ...makeEmptyArray(1),
    ['Jar Data'],
    generateBhaXlsFooterDetailRow(
      [
        'SN',
        'Description',
        `OD (${getUnitsText(UNITS_FOR.Diameter)})`,
        'Make / Model',
        `Latch Up / Down (${getUnitsText(UNITS_FOR.Weight)})`,
        `Impulse (${getUnitsText(UNITS_FOR.Weight)})`,
        'CNX Top / Btm',
      ],
      true,
      reportSettings,
    ),
    generateBhaXlsFooterDetailRow(
      [
        jarData?.sn,
        unescapeHtml(jarData?.description),
        numberWithCommasDecimals(jarData?.od, 3),
        `${jarData?.make} / ${jarData?.model}`,
        `${numberWithCommasDecimals(jarData?.latchUp, 2)} / ${numberWithCommasDecimals(jarData?.latchDown, 2)}`,
        numberWithCommasDecimals(jarData?.impulse, 2),
        `${jarData?.cnxTop} / ${jarData?.cnxBtm}`,
      ],
      false,
      reportSettings,
    ),
  ]

  return xlsJarData
}

const getXlsOrigin = (ws) => {
  if (!ws) return -1

  let wsRange = ws['!ref'].split(':')
  let decodedAddress = XLSX.utils.decode_cell(wsRange[1])

  let rowNum = decodedAddress.r + 2 // origin was B2 we must add one to find the bottom row num, then one more to add a new row
  return `B${rowNum}`
}

export const handleCreateDrillStringXlsWorksheet = (
  bhaData,
  drillStringData,
  wellInfoRef,
  bhaImages,
  getCurrentOrgIcon,
  getUnitsText,
  reportSettings = DEFAULT_REPORT_SETTINGS,
) => {
  if (!bhaData) return null
  if (!Array.isArray(drillStringData.current)) return null
  if (drillStringData.current.length === 0) return null
  if (!bhaData.hasOwnProperty('bhaComponents')) return null
  if (!Array.isArray(bhaData.bhaComponents)) return null
  if (!wellInfoRef.current) return null

  let bhaIndex = drillStringData.current.findIndex((bha) => {
    return bha?.bhaNum === bhaData?.bhaNum
  })

  if (bhaIndex < 0) return null

  let motorData = {}
  let bitData = {}
  let offsetData = {}
  let jarData = {}
  let stabilizerData = []

  bhaData.bhaComponents.forEach((component) => {
    if (component.type === 'Motor') motorData = component
    else if (component.type === 'Drill Bit') bitData = component
    else if (component.type === 'MWD/LWD') offsetData = component
    else if (component.type === 'Jar') jarData = component
    else if (component.type === 'Stabilizer' || component.stabOd > 0) stabilizerData.push(component)
  })

  let bhaTotalWeight = getBhaTotalWeight(bhaData.bhaComponents)
  let jarsDryWt = getJarsDryWt(bhaData.bhaComponents)

  let bhaAirMudWeight = `${numberWithCommasDecimals(bhaTotalWeight / 1000, 2)} / ${numberWithCommasDecimals(
    (((65.5 - drillStringData.current[bhaIndex].mudWeight) / 65.5) * bhaTotalWeight) / 1000,
    2,
  )} (${getUnitsText(UNITS_FOR.Weight)})`

  let bhaAirMudWeightBelowJars = `${jarsDryWt ? numberWithCommasDecimals(jarsDryWt, 2) : ''} / ${
    jarsDryWt
      ? numberWithCommasDecimals(((65.5 - drillStringData.current[bhaIndex].mudWeight) / 65.5) * jarsDryWt, 2)
      : ''
  } (${getUnitsText(UNITS_FOR.Weight)})`

  const { bhaXlsImages, bhaXlsRowData } = generateBhaXlsRowData(
    bhaData.bhaComponents,
    bhaImages,
    getUnitsText,
    reportSettings,
  )
  const xlsToolOrderData = getBhaXlsToolOrderData(offsetData)
  const xlsStabData = getBhaXlsStabilizerData(stabilizerData, bhaData.bhaComponents, getUnitsText, reportSettings)
  const xlsJarData = getBhaXlsJarData(jarData, getUnitsText, reportSettings)

  let ws = XLSX.utils.aoa_to_sheet(
    [
      [...makeEmptyArray(3), 'BOTTOM HOLE ASSEMBLY'],
      generateBhaXlsHeaderRow(
        'Operator:',
        getStyledCellObject(wellInfoRef.current?.operator, false, reportSettings),
        'Rig:',
        getStyledCellObject(wellInfoRef.current?.rig, false, reportSettings),
        'Total Length',
        `${numberWithCommasDecimals(
          bhaData.bhaComponents[bhaData.bhaComponents.length - 1].cumulativeLength,
          2,
        )} (${getUnitsText(UNITS_FOR.Depth)})`,
      ),
      generateBhaXlsHeaderRow(
        'Well:',
        getStyledCellObject(wellInfoRef.current?.wellName, false, reportSettings),
        'BHA #:',
        getStyledCellObject(`${bhaData?.bhaNumRep}`, false, reportSettings),
        'BHA Weight (Air/Mud):',
        bhaAirMudWeight,
      ),
      generateBhaXlsHeaderRow(
        'Job#:',
        getStyledCellObject(wellInfoRef.current?.jobNum, false, reportSettings),
        'Bit #:',
        getStyledCellObject(`${bitData?.bitNum}`, false, reportSettings),
        'Wt. Below Jars (Air/Mud)',
        bhaAirMudWeightBelowJars,
      ),
      generateBhaXlsHeaderRow(
        'Date In:',
        getStyledCellObject(drillStringData.current[bhaIndex].dateIn, false, reportSettings),
        'Depth In:',
        getStyledCellObject(`${drillStringData.current[bhaIndex].depthIn}`, false, reportSettings),
        'Total Drilled:',
        drillStringData.current[bhaIndex].totalDrilled,
      ),
      generateBhaXlsHeaderRow(
        'Date Out:',
        getStyledCellObject(drillStringData.current[bhaIndex].dateOut, false, reportSettings),
        'Depth Out:',
        getStyledCellObject(`${drillStringData.current[bhaIndex].depthOut}`, false, reportSettings),
        'Drilling / Circ Hours:',
        `${numberWithCommasDecimals(
          drillStringData.current[bhaIndex].totalDrillingHours,
          2,
        )} / ${numberWithCommasDecimals(drillStringData.current[bhaIndex].circHours, 2)}`,
      ),
      generateBhaXlsHeaderRow(
        'Survey Offset:',
        getStyledCellObject(`${offsetData?.surveyOffset ? offsetData.surveyOffset : 'N/A'}`, false, reportSettings),
        'Gamma Offset:',
        getStyledCellObject(`${offsetData?.gammaOffset ? offsetData.gammaOffset : 'N/A'}`, false, reportSettings),
        '',
        '',
      ),
      generateBhaXlsHeaderRow(
        '',
        getStyledCellObject('', false, reportSettings),
        '',
        getStyledCellObject('', false, reportSettings),
        '',
        '',
      ),
      generateBhaXlsHeaderRow(
        '',
        getStyledCellObject('', false, reportSettings),
        '',
        getStyledCellObject('', false, reportSettings),
        '',
        '',
      ),
      //spread main bha component data to worksheet
      ...bhaXlsRowData,
      //continue with footer details
      ...makeEmptyArray(1),
      ['Bit Details'],
      [
        `Size (${getUnitsText(UNITS_FOR.Diameter)})`,
        ...makeEmptyArray(1),
        getStyledCellObject('Type', true, reportSettings),
        getStyledCellObject('Description', true, reportSettings),
        getStyledCellObject('TFA', true, reportSettings),
        getStyledCellObject('Make', true, reportSettings),
        getStyledCellObject('Model', true, reportSettings),
        getStyledCellObject('Grade In', true, reportSettings),
        getStyledCellObject('Grade Out', true, reportSettings),
        `Drilled (${getUnitsText(UNITS_FOR.Depth)})`,
      ],
      [
        bitData?.od,
        ...makeEmptyArray(1),
        getStyledCellObject(bitData?.bitType, false, reportSettings),
        getStyledCellObject(unescapeHtml(bitData?.description), false, reportSettings),
        getStyledCellObject(`${bitData?.tfa}`, false, reportSettings),
        getStyledCellObject(bitData?.make, false, reportSettings),
        getStyledCellObject(bitData?.model, false, reportSettings),
        getStyledCellObject(bitData?.gradeIn, false, reportSettings),
        getStyledCellObject(bitData?.gradeOut, false, reportSettings),
        numberWithCommasDecimals(drillStringData.current[bhaIndex].totalDrilled, 2),
      ],
      [
        getStyledCellObject(`IADC Code`, true, reportSettings),
        ...makeEmptyArray(1),
        getStyledCellObject('# Blades', true, reportSettings),
        getStyledCellObject('K Revs', true, reportSettings),
        getStyledCellObject('', true, reportSettings),
        getStyledCellObject('', true, reportSettings),
        getStyledCellObject('', true, reportSettings),
        getStyledCellObject('', true, reportSettings),
        getStyledCellObject('', true, reportSettings),
        getStyledCellObject('', true, reportSettings),
      ],
      [
        getStyledCellObject(bitData?.iadcCode, false, reportSettings),
        ...makeEmptyArray(1),
        getStyledCellObject(numberWithCommasDecimals(bitData?.numBlades, 0), false, reportSettings),
        getStyledCellObject(
          numberWithCommasDecimals(drillStringData.current[bhaIndex]?.krevs, 0),
          false,
          reportSettings,
        ),
        getStyledCellObject('', false, reportSettings),
        getStyledCellObject('', false, reportSettings),
        getStyledCellObject('', false, reportSettings),
        getStyledCellObject('', false, reportSettings),
        getStyledCellObject('', false, reportSettings),
        getStyledCellObject('', false, reportSettings),
      ],
      ...makeEmptyArray(1),
      ['Motor Details'],

      generateBhaXlsFooterDetailRow(
        [
          'Description',
          'Make / Model',
          `Bit to Bend (${getUnitsText(UNITS_FOR.Depth)}) / Bend (deg)`,
          `Stab OD (${getUnitsText(UNITS_FOR.Diameter)})`,
          `Kick Pad OD (${getUnitsText(UNITS_FOR.Diameter)})`,
          'Press. Loss (psi)',
          'Max Diff Press. (psi)',
        ],
        true,
        reportSettings,
      ),
      generateBhaXlsFooterDetailRow(
        [
          unescapeHtml(motorData?.description),
          `${motorData?.make ? motorData?.make : ''} / ${motorData?.model ? motorData?.model : ''}`,
          `${numberWithCommasDecimals(motorData?.bit2bend, 2)} / ${numberWithCommasDecimals(motorData?.bendAngle, 2)}`,
          `${motorData?.stabOd ? motorData?.stabOd : ''}`,
          `${motorData?.kickPadOd ? motorData?.kickPadOd : ''}`,
          `${motorData?.pressureDrop ? motorData?.pressureDrop : ''}`,
          `${motorData?.maxDiffP ? motorData?.maxDiffP : ''}`,
        ],
        false,
        reportSettings,
      ),
      generateBhaXlsFooterDetailRow(
        [
          `Max Torque (${getUnitsText(UNITS_FOR.Torque)})`,
          'Avg Diff Press (psi)',
          'Max RPM',
          'Flow Range',
          'Rev/Gal',
          'Bend Housing',
          'Bearing Type',
        ],
        true,
        reportSettings,
      ),
      generateBhaXlsFooterDetailRow(
        [
          `${motorData?.maxTorque ? motorData?.maxTorque : ''}`,
          `${motorData?.diffPress ? motorData?.diffPress : ''}`,
          `${motorData?.maxRpm ? motorData?.maxRpm : ''}`,
          motorData?.flowRange ? motorData?.flowRange : '',
          `${motorData?.revPerGal ? motorData?.revPerGal : ''}`,
          motorData?.housingType ? motorData?.housingType : '',
          motorData?.motorBearing ? motorData?.motorBearing : '',
        ],
        false,
        reportSettings,
      ),
      generateBhaXlsFooterDetailRow(
        ['Pre Run Dyno HP%', 'Stator Vendor', 'Stator Type', 'Stator Fit', 'Re-Run', 'Direct Bill', ''],
        true,
        reportSettings,
      ),
      generateBhaXlsFooterDetailRow(
        [
          motorData?.dynoHp ? motorData?.dynoHp : '',
          motorData?.statorVendor ? motorData?.statorVendor : '',
          motorData?.statorType ? motorData?.statorType : '',
          motorData?.statorFit ? motorData?.statorFit : '',
          motorData?.reRun ? motorData?.reRun : '',
          motorData?.directBill ? motorData?.directBill : '',
        ],
        false,
        reportSettings,
      ),
      ...makeEmptyArray(1),
      ['MWD Details'],
      generateBhaXlsFooterDetailRow(
        ['MWD Type', 'Orifice', 'Orifice Type', 'Poppet', 'Make', 'Model', 'Fin Size'],
        true,
        reportSettings,
      ),
      generateBhaXlsFooterDetailRow(
        [
          `${offsetData?.mwdType}`,
          offsetData?.orifice,
          offsetData?.mwdOrificeType,
          offsetData?.poppet,
          offsetData?.make,
          offsetData?.model,
          `${offsetData?.mwdFinSize}`,
        ],
        false,
        reportSettings,
      ),
      generateBhaXlsFooterDetailRow(
        ['Pulser SN', 'Driver SN', 'Dir. Module SN', 'Battery 1 SN', 'Battery 2 SN', 'Battery 3 SN', 'Helix SN'],
        true,
        reportSettings,
      ),
      generateBhaXlsFooterDetailRow(
        [
          offsetData?.pulserSn,
          offsetData?.pulserDriverSn,
          offsetData?.directionalSn,
          offsetData?.battery1Sn,
          offsetData?.battery2Sn,
          offsetData?.battery3Sn,
          offsetData?.helixSn,
        ],
        false,
        reportSettings,
      ),
      generateBhaXlsFooterDetailRow(
        ['Gamma SN', 'EM SN', 'Pulse Width', 'Data Rate', 'EM Power', 'EM Freq', 'Retrievable'],
        true,
        reportSettings,
      ),
      generateBhaXlsFooterDetailRow(
        [
          offsetData?.gammaSn,
          offsetData?.emSn,
          offsetData?.mwdPulseWidth ? numberWithCommasDecimals(parseFloat(offsetData?.mwdPulseWidth), 2) : '',
          offsetData?.mwdDataRate ? numberWithCommasDecimals(parseFloat(offsetData?.mwdDataRate), 2) : '',
          offsetData?.emPower ? numberWithCommasDecimals(parseFloat(offsetData?.emPower), 2) : '',
          offsetData?.emFreq ? numberWithCommasDecimals(parseFloat(offsetData?.emFreq), 2) : '',
          offsetData?.mwdRetre,
        ],
        false,
        reportSettings,
      ),
      generateBhaXlsFooterDetailRow(
        ['Plug in Date', 'Plug in Time', 'Plug Out Date', 'Plug Out Time', 'Plug in Hrs', 'Shock Tool SN', ''],
        true,
        reportSettings,
      ),
      generateBhaXlsFooterDetailRow(
        [
          new Date(
            Date.parse(
              offsetData?.battPlugInDate + `T${offsetData?.battPlugInTime ? offsetData?.battPlugInTime : '00:01'}:00Z`,
            ),
          ).toLocaleDateString('en-US'),
          offsetData?.battPlugInTime,
          new Date(
            Date.parse(
              offsetData?.battPlugOutDate +
                `T${offsetData?.battPlugOutTime ? offsetData?.battPlugOutTime : '00:01'}:00Z`,
            ),
          ).toLocaleDateString('en-US'),
          offsetData?.battPlugOutTime,
          `${getPlugInHrs(offsetData)}`,
          offsetData?.shockToolSn,
          '',
        ],
        false,
        reportSettings,
      ),
    ],
    { origin: 'B2' },
  )

  ws['!merges'] = [
    //logo
    {
      s: { r: 1, c: 1 },
      e: { r: 1, c: 3 },
      style: getCellStyle('#FFFFFF', 'center', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //report title
    {
      s: { r: 1, c: 4 },
      e: { r: 1, c: 8 },
      style: getCellStyle('#FFFFFF', 'center', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //blank area right of report title
    {
      s: { r: 1, c: 9 },
      e: { r: 1, c: 11 },
      style: getCellStyle('#FFFFFF', 'center', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //operator header
    {
      s: { r: 2, c: 1 },
      e: { r: 2, c: 3 },
      style: getCellStyle(reportSettings.primaryColor, 'right', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //rig header
    {
      s: { r: 2, c: 5 },
      e: { r: 2, c: 6 },
      style: getCellStyle(reportSettings.primaryColor, 'right', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //total length header
    {
      s: { r: 2, c: 8 },
      e: { r: 2, c: 9 },
      style: getCellStyle(reportSettings.primaryColor, 'right', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //total length data
    {
      s: { r: 2, c: 10 },
      e: { r: 2, c: 11 },
      style: getCellStyle('#FFFFFF', 'center', 'center', false, 'thin', 'thin', 'thin', 'thin'),
    },
    //well header
    {
      s: { r: 3, c: 1 },
      e: { r: 3, c: 3 },
      style: getCellStyle(reportSettings.primaryColor, 'right', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //bha # header
    {
      s: { r: 3, c: 5 },
      e: { r: 3, c: 6 },
      style: getCellStyle(reportSettings.primaryColor, 'right', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //bha weight air/mud header
    {
      s: { r: 3, c: 8 },
      e: { r: 3, c: 9 },
      style: getCellStyle(reportSettings.primaryColor, 'right', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //bha weight data
    {
      s: { r: 3, c: 10 },
      e: { r: 3, c: 11 },
      style: getCellStyle('#FFFFFF', 'center', 'center', false, 'thin', 'thin', 'thin', 'thin'),
    },
    //field header
    {
      s: { r: 4, c: 1 },
      e: { r: 4, c: 3 },
      style: getCellStyle(reportSettings.primaryColor, 'right', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //bit # header
    {
      s: { r: 4, c: 5 },
      e: { r: 4, c: 6 },
      style: getCellStyle(reportSettings.primaryColor, 'right', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //wt. below jars header
    {
      s: { r: 4, c: 8 },
      e: { r: 4, c: 9 },
      style: getCellStyle(reportSettings.primaryColor, 'right', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //bha weight data
    {
      s: { r: 4, c: 10 },
      e: { r: 4, c: 11 },
      style: getCellStyle('#FFFFFF', 'center', 'center', false, 'thin', 'thin', 'thin', 'thin'),
    },
    //date in header
    {
      s: { r: 5, c: 1 },
      e: { r: 5, c: 3 },
      style: getCellStyle(reportSettings.primaryColor, 'right', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //depth in header
    {
      s: { r: 5, c: 5 },
      e: { r: 5, c: 6 },
      style: getCellStyle(reportSettings.primaryColor, 'right', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //total drilled header
    {
      s: { r: 5, c: 8 },
      e: { r: 5, c: 9 },
      style: getCellStyle(reportSettings.primaryColor, 'right', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //total drilled data
    {
      s: { r: 5, c: 10 },
      e: { r: 5, c: 11 },
      style: getCellStyle('#FFFFFF', 'center', 'center', false, 'thin', 'thin', 'thin', 'thin'),
    },
    //date out header
    {
      s: { r: 6, c: 1 },
      e: { r: 6, c: 3 },
      style: getCellStyle(reportSettings.primaryColor, 'right', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //depth out header
    {
      s: { r: 6, c: 5 },
      e: { r: 6, c: 6 },
      style: getCellStyle(reportSettings.primaryColor, 'right', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //drilling / circ hrs header
    {
      s: { r: 6, c: 8 },
      e: { r: 6, c: 9 },
      style: getCellStyle(reportSettings.primaryColor, 'right', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //drilling / circ hrs data
    {
      s: { r: 6, c: 10 },
      e: { r: 6, c: 11 },
      style: getCellStyle('#FFFFFF', 'center', 'center', false, 'thin', 'thin', 'thin', 'thin'),
    },
    //svy offset header
    {
      s: { r: 7, c: 1 },
      e: { r: 7, c: 3 },
      style: getCellStyle(reportSettings.primaryColor, 'right', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //gamma offset header
    {
      s: { r: 7, c: 5 },
      e: { r: 7, c: 6 },
      style: getCellStyle(reportSettings.primaryColor, 'right', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //blank
    {
      s: { r: 7, c: 8 },
      e: { r: 7, c: 9 },
      style: getCellStyle(reportSettings.primaryColor, 'right', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //blank
    {
      s: { r: 7, c: 10 },
      e: { r: 7, c: 11 },
      style: getCellStyle('#FFFFFF', 'center', 'center', false, 'thin', 'thin', 'thin', 'thin'),
    },
    //blank
    {
      s: { r: 8, c: 1 },
      e: { r: 8, c: 3 },
      style: getCellStyle(reportSettings.primaryColor, 'right', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //blank
    {
      s: { r: 8, c: 5 },
      e: { r: 8, c: 6 },
      style: getCellStyle(reportSettings.primaryColor, 'right', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //blank
    {
      s: { r: 8, c: 8 },
      e: { r: 8, c: 9 },
      style: getCellStyle(reportSettings.primaryColor, 'right', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //blank
    {
      s: { r: 8, c: 10 },
      e: { r: 8, c: 11 },
      style: getCellStyle('#FFFFFF', 'center', 'center', false, 'thin', 'thin', 'thin', 'thin'),
    },
    //blank
    {
      s: { r: 9, c: 1 },
      e: { r: 9, c: 3 },
      style: getCellStyle(reportSettings.primaryColor, 'right', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //blank
    {
      s: { r: 9, c: 5 },
      e: { r: 9, c: 6 },
      style: getCellStyle(reportSettings.primaryColor, 'right', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //blank
    {
      s: { r: 9, c: 8 },
      e: { r: 9, c: 9 },
      style: getCellStyle(reportSettings.primaryColor, 'right', 'center', true, 'thin', 'thin', 'thin', 'thin'),
    },
    //blank
    {
      s: { r: 9, c: 10 },
      e: { r: 9, c: 11 },
      style: getCellStyle('#FFFFFF', 'center', 'center', false, 'thin', 'thin', 'thin', 'thin'),
    },
    // bit details title
    mergeBhaXlsCells(11 + bhaXlsRowData.length, 1, 10, true, reportSettings),
    // (bit details) size header
    mergeBhaXlsCells(12 + bhaXlsRowData.length, 1, 1, true, reportSettings),
    // (bit details) Drilled header
    mergeBhaXlsCells(12 + bhaXlsRowData.length, 10, 1, true, reportSettings),
    // (bit details) size data
    mergeBhaXlsCells(13 + bhaXlsRowData.length, 1, 1, false, reportSettings),
    // (bit details) drilled data
    mergeBhaXlsCells(13 + bhaXlsRowData.length, 10, 1, false, reportSettings),
    // (bit details) iadc code header
    mergeBhaXlsCells(14 + bhaXlsRowData.length, 1, 1, true, reportSettings),
    // (bit details) blank header
    mergeBhaXlsCells(14 + bhaXlsRowData.length, 10, 1, true, reportSettings),
    // (bit details) iadc code data
    mergeBhaXlsCells(15 + bhaXlsRowData.length, 1, 1, false, reportSettings),
    // (bit details) blank data
    mergeBhaXlsCells(15 + bhaXlsRowData.length, 10, 1, false, reportSettings),
    // motor details title
    mergeBhaXlsCells(17 + bhaXlsRowData.length, 1, 10, true, reportSettings),
    // (motor details) description header
    mergeBhaXlsCells(18 + bhaXlsRowData.length, 1, 2, true, reportSettings),
    // (motor details) bit2Bend / bend deg header
    mergeBhaXlsCells(18 + bhaXlsRowData.length, 5, 1, true, reportSettings),
    // (motor details) max diff pressure header
    mergeBhaXlsCells(18 + bhaXlsRowData.length, 10, 1, true, reportSettings),
    // (motor details) description data
    mergeBhaXlsCells(19 + bhaXlsRowData.length, 1, 2, false, reportSettings),
    // (motor details) bit2Bend / bend deg data
    mergeBhaXlsCells(19 + bhaXlsRowData.length, 5, 1, false, reportSettings),
    // (motor details) max diff pressure data
    mergeBhaXlsCells(19 + bhaXlsRowData.length, 10, 1, false, reportSettings),
    // (motor details) max torque header
    mergeBhaXlsCells(20 + bhaXlsRowData.length, 1, 2, true, reportSettings),
    // (motor details) max RPM header
    mergeBhaXlsCells(20 + bhaXlsRowData.length, 5, 1, true, reportSettings),
    // (motor details) bearing type header
    mergeBhaXlsCells(20 + bhaXlsRowData.length, 10, 1, true, reportSettings),
    // (motor details) max torque data
    mergeBhaXlsCells(21 + bhaXlsRowData.length, 1, 2, false, reportSettings),
    // (motor details) max RPM data
    mergeBhaXlsCells(21 + bhaXlsRowData.length, 5, 1, false, reportSettings),
    // (motor details) bearing type data
    mergeBhaXlsCells(21 + bhaXlsRowData.length, 10, 1, false, reportSettings),
    // (motor details) pre run dyno hp header
    mergeBhaXlsCells(22 + bhaXlsRowData.length, 1, 2, true, reportSettings),
    // (motor details) stator type header
    mergeBhaXlsCells(22 + bhaXlsRowData.length, 5, 1, true, reportSettings),
    // (motor details) blank
    mergeBhaXlsCells(22 + bhaXlsRowData.length, 10, 1, true, reportSettings),
    // (motor details) pre run dyno hp data
    mergeBhaXlsCells(23 + bhaXlsRowData.length, 1, 2, false, reportSettings),
    // (motor details) stator type data
    mergeBhaXlsCells(23 + bhaXlsRowData.length, 5, 1, false, reportSettings),
    // (motor details) blank
    mergeBhaXlsCells(23 + bhaXlsRowData.length, 10, 1, false, reportSettings),
    // (MWD details) title
    mergeBhaXlsCells(25 + bhaXlsRowData.length, 1, 10, true, reportSettings),
    // (MWD details) mwd type header
    mergeBhaXlsCells(26 + bhaXlsRowData.length, 1, 2, true, reportSettings),
    // (MWD details) orifice type header
    mergeBhaXlsCells(26 + bhaXlsRowData.length, 5, 1, true, reportSettings),
    // (MWD details) fin size header
    mergeBhaXlsCells(26 + bhaXlsRowData.length, 10, 1, true, reportSettings),
    // (MWD details) mwd type data
    mergeBhaXlsCells(27 + bhaXlsRowData.length, 1, 2, false, reportSettings),
    // (MWD details) orifice type data
    mergeBhaXlsCells(27 + bhaXlsRowData.length, 5, 1, false, reportSettings),
    // (MWD details) fin size data
    mergeBhaXlsCells(27 + bhaXlsRowData.length, 10, 1, false, reportSettings),
    // (MWD details) pulser SN header
    mergeBhaXlsCells(28 + bhaXlsRowData.length, 1, 2, true, reportSettings),
    // (MWD details) dir module SN header
    mergeBhaXlsCells(28 + bhaXlsRowData.length, 5, 1, true, reportSettings),
    // (MWD details) helix SN header
    mergeBhaXlsCells(28 + bhaXlsRowData.length, 10, 1, true, reportSettings),
    // (MWD details) pulser SN data
    mergeBhaXlsCells(29 + bhaXlsRowData.length, 1, 2, false, reportSettings),
    // (MWD details) dir module SN data
    mergeBhaXlsCells(29 + bhaXlsRowData.length, 5, 1, false, reportSettings),
    // (MWD details) helix SN data
    mergeBhaXlsCells(29 + bhaXlsRowData.length, 10, 1, false, reportSettings),
    // (MWD details) gamma SN header
    mergeBhaXlsCells(30 + bhaXlsRowData.length, 1, 2, true, reportSettings),
    // (MWD details) pulse width header
    mergeBhaXlsCells(30 + bhaXlsRowData.length, 5, 1, true, reportSettings),
    // (MWD details) retrievable header
    mergeBhaXlsCells(30 + bhaXlsRowData.length, 10, 1, true, reportSettings),
    // (MWD details) gamma sn data
    mergeBhaXlsCells(31 + bhaXlsRowData.length, 1, 2, false, reportSettings),
    // (MWD details) pulse width data
    mergeBhaXlsCells(31 + bhaXlsRowData.length, 5, 1, false, reportSettings),
    // (MWD details) retrievable data
    mergeBhaXlsCells(31 + bhaXlsRowData.length, 10, 1, false, reportSettings),
    // (MWD details) plug in date header
    mergeBhaXlsCells(32 + bhaXlsRowData.length, 1, 2, true, reportSettings),
    // (MWD details) plug out date header
    mergeBhaXlsCells(32 + bhaXlsRowData.length, 5, 1, true, reportSettings),
    // (MWD details) blank
    mergeBhaXlsCells(32 + bhaXlsRowData.length, 10, 1, true, reportSettings),
    // (MWD details) plug in date data
    mergeBhaXlsCells(33 + bhaXlsRowData.length, 1, 2, false, reportSettings),
    // (MWD details) plug out date data
    mergeBhaXlsCells(33 + bhaXlsRowData.length, 5, 1, false, reportSettings),
    // (MWD details) merged blank row
    mergeBhaXlsCells(33 + bhaXlsRowData.length, 10, 1, false, reportSettings),
  ]

  if (xlsToolOrderData) {
    let originAddress = getXlsOrigin(ws)
    XLSX.utils.sheet_add_aoa(ws, xlsToolOrderData, { origin: originAddress })

    const { r } = XLSX.utils.decode_cell(originAddress)
    let toolOrderMergedCells = [
      mergeBhaXlsCells(r + 1, 1, 10, true, reportSettings), // MWD Tool Order title
      mergeBhaXlsCells(r + 2, 1, 10, false, reportSettings), // MWD Tool Order data
    ]

    ws['!merges'].push(...toolOrderMergedCells)
  }

  if (xlsStabData) {
    let originAddress = getXlsOrigin(ws)
    XLSX.utils.sheet_add_aoa(ws, xlsStabData, { origin: originAddress })

    const { r } = XLSX.utils.decode_cell(originAddress)
    let stabDataMergedCells = [
      mergeBhaXlsCells(r + 1, 1, 10, true, reportSettings), // Stabilizer Data title
      mergeBhaXlsCells(r + 2, 1, 2, true, reportSettings), // Component Number header
      mergeBhaXlsCells(r + 2, 5, 1, true, reportSettings), // OD header
      mergeBhaXlsCells(r + 2, 10, 1, true, reportSettings), // Stab to Bit header
    ]

    for (let i = 0; i < stabilizerData.length; i++) {
      // i + 3 adds new row after Stabilizer Data title and col headers rows
      stabDataMergedCells.push(
        mergeBhaXlsCells(r + (i + 3), 1, 2, false, reportSettings), // Component Number data
        mergeBhaXlsCells(r + (i + 3), 5, 1, false, reportSettings), // OD data
        mergeBhaXlsCells(r + (i + 3), 10, 1, false, reportSettings), // Stab to Bit data
      )
    }

    ws['!merges'].push(...stabDataMergedCells)
  }

  if (xlsJarData) {
    let originAddress = getXlsOrigin(ws)
    XLSX.utils.sheet_add_aoa(ws, xlsJarData, { origin: originAddress })

    const { r } = XLSX.utils.decode_cell(originAddress)
    let jarDataMergedCells = [
      mergeBhaXlsCells(r + 1, 1, 10, true, reportSettings), // Jar Data title
      mergeBhaXlsCells(r + 2, 1, 2, true, reportSettings), // SN header
      mergeBhaXlsCells(r + 2, 5, 1, true, reportSettings), // OD header
      mergeBhaXlsCells(r + 2, 10, 1, true, reportSettings), // CNX top/ btm header
      mergeBhaXlsCells(r + 3, 1, 2, false, reportSettings), // SN data
      mergeBhaXlsCells(r + 3, 5, 1, false, reportSettings), // OD data
      mergeBhaXlsCells(r + 3, 10, 1, false, reportSettings), // CNX top/ btm data
    ]

    ws['!merges'].push(...jarDataMergedCells)
  }

  // add icpwebportal tag to bottom of ws
  let originAddress = getXlsOrigin(ws)
  XLSX.utils.sheet_add_aoa(ws, [...makeEmptyArray(1), ['icpwebportal']], { origin: originAddress })
  const { r } = XLSX.utils.decode_cell(originAddress)
  ws['!merges'].push({
    s: { r: r + 1, c: 1 },
    e: { r: r + 1, c: 11 },
    style: getCellStyle(reportSettings.primaryColor, 'right', 'center', true, 'thin', 'thin', 'thin', 'thin'),
  })

  ws['!rows'] = [...makeEmptyArray(35 + bhaXlsRowData.length)]
  ws['!rows'][1] = { hpx: 80 } //adjust row height for logo
  ws['!rows'][16 + bhaXlsRowData.length] = { hpx: 45 } // adjust row height for bit to bend header in motor details section

  ws['!images'] = [
    {
      '!pos': {
        c: 1,
        r: 1,
        x: 1,
        y: 4,
        w: 75,
        h: 75,
      },
      '!datatype': 'base64',
      '!data': getCurrentOrgIcon(),
    },
  ]

  addBhaComponentImages(ws, bhaXlsImages)

  ws['!cols'] = [...makeEmptyArray(11)]
  // column C
  ws['!cols'][2] = { wch: 5 }
  //column E
  ws['!cols'][4] = { wch: 30 }
  //column H
  ws['!cols'][7] = { wch: 15 }
  //column I
  ws['!cols'][8] = { wch: 18 }
  //column J
  ws['!cols'][9] = { wch: 25 }

  ws['!merges'].forEach((cellAddresses) => {
    const { s, e, style } = cellAddresses

    let cellRange = getXlsCellRange(s, e)

    if (style) XLSX.utils.sheet_set_range_style(ws, cellRange, style)
  })

  return ws
}

const getCellStyle = (
  fgColor = '#FFFFFF',
  horizontalAlign = 'center',
  verticalAlign = 'center',
  bold = false,
  top = '',
  right = '',
  bottom = '',
  left = '',
  wrap = false,
) => {
  return {
    fgColor: { rgb: fgColor },
    alignment: {
      horizontal: horizontalAlign,
      vertical: verticalAlign,
      wrapText: wrap,
    },
    bold: bold,
    top: { style: top },
    right: { style: right },
    bottom: { style: bottom },
    left: { style: left },
  }
}
