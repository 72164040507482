import React, { useRef, useEffect, useState, useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Box, TextField } from '@mui/material'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { useRecoilValue } from 'recoil'
import { currentWellAtom } from 'atoms'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import DrillStringRecommendationsDetailsGrid from './DrillStringRecommendationsDetailsGrid'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { appColors } from 'utils'
import { getItemFromLS, saveItemToLS } from 'utils/localStorage'

const DrillStringRecommendationsGrid = ({ setStatus, setImportBha }) => {
  const _isMounted = useRef(true)
  const gridApi = useRef(null)
  const { getAgGridTheme, theme } = useInnovaTheme()
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const [radius, setRadius] = useState(getItemFromLS(currentWell, 'radius') || 5)
  const [isLoading, setIsLoading] = useState(false)
  const [recommendations, setRecommendations] = useState([])

  const getRecommendedDrillStrings = useInnovaAxios({
    url: '/well/fetchRecommendedDrillStrings',
  })

  const textFieldStyle = useMemo(
    () => ({
      height: '30px',
      margin: '5px',
      backgroundColor: theme === 'dark' ? '#2d2d2d !important' : '#f0f0f0 !important',
      width: '60px',
      maxWidth: '400px',
      justifyContent: 'center',
    }),
    [theme],
  )

  useEffect(() => {
    _isMounted.current = true
    fetchRecommendedDrillStrings()

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      fetchRecommendedDrillStrings()
    }
  }, [currentWell, radius]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchRecommendedDrillStrings = async () => {
    if (!_isMounted.current) return
    if (isLoading) return
    if (currentWell.length < 1) {
      return
    }

    setIsLoading(true)

    let res = await getRecommendedDrillStrings({ wellName: currentWell, c2cDist: radius * 5280, isPlan: false })

    setIsLoading(false)
    if (_isMounted.current) {
      if (!res) {
        setRecommendations([])
        return
      }
    }

    setRecommendations(res.data)
  }

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        field: '',
        autoHeight: true,
        minWidth: 60,
        maxWidth: 60,
        cellRenderer: 'agGroupCellRenderer',
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        headerName: 'Well Name',
        field: 'wellName',
        colId: 'wellName',
      },
      {
        headerName: 'Recommendations',
        field: 'recommendations',
        colId: 'recommendations',
        valueFormatter: (params) => {
          return numberWithCommasDecimals(params.value, 0)
        },
      },
    ],
    [],
  )

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      resizable: true,
      editable: false,
    }),
    [],
  )

  const gridOptions = useMemo(() => ({}), [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const getRowId = (data) => {
    return data.data.wellName
  }

  const onFirstDataRendered = () => {
    if (gridApi.current) autoSizeColumns()
    setRadius(5)
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    setTimeout(() => {
      gridApi.current?.autoSizeAllColumns()
    }, 100)
  }

  const getRecommendationData = () => {
    if (!recommendations) return []
    if (!Array.isArray(recommendations)) return []

    let data = []
    for (let i = 0; i < recommendations.length; i++) {
      let topScore = 0

      if (!recommendations[i]) continue
      if (!Array.isArray(recommendations[i])) continue
      if (recommendations[i].length === 0) continue

      for (let j = 0; j < recommendations[i].length; j++) {
        if (recommendations[i][j].score > topScore) {
          topScore = recommendations[i][j].score
        }
      }

      data.push({
        wellName: recommendations[i][0].actualWell,
        drillString: recommendations[i],
        recommendations: recommendations[i].length,
      })
    }

    data.sort((a, b) => b.topScore - a.topScore)

    return data
  }

  const DetailCellRenderer = (params) => {
    let height = params.data?.recommendations * 235 + 35
    return (
      <Box sx={{ width: '100%', height: height, padding: '5px' }}>
        <DrillStringRecommendationsDetailsGrid
          data={params?.data?.drillString ? params.data.drillString : []}
          setStatus={setStatus}
          setImportBha={setImportBha}
        />
      </Box>
    )
  }

  return (
    <Box className={getAgGridTheme()} sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex', marginBottom: '10px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '20px',
            paddingLeft: '10px',
            paddingTop: '10px',
            color: appColors.itemTextColor,
          }}>
          Radius:
          <TextField
            sx={textFieldStyle}
            fullWidth
            value={radius}
            onChange={(e) => {
              let newVal = e.target.value
              setRadius(newVal)
              saveItemToLS(currentWell, 'radius', newVal)
            }}
          />
        </Box>
      </Box>
      <AgGridReact
        loading={isLoading}
        rowData={getRecommendationData()}
        columnDefs={columnDefs}
        headerHeight={30}
        defaultcolDef={defaultColDef}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        getRowId={getRowId}
        onFirstDataRendered={onFirstDataRendered}
        masterDetail={true}
        detailCellRenderer={DetailCellRenderer}
        detailRowAutoHeight={true}
      />
    </Box>
  )
}

export default DrillStringRecommendationsGrid
