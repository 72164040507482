import React, { useEffect, useRef } from 'react'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import BhaAnalysisForceDeflectionChart from 'components/WellPages/EngineeringDashboard/BhaAnalysis/ForceDeflectionChart'
import { UNITS_FOR } from 'components/common/hooks/useUnits'
import ForceDeflectionContourChart from 'components/WellPages/EngineeringDashboard/BhaAnalysis/ForceDeflectionContour'

const CHARTS = [
  {
    title: 'HS Bending Moment',
    tag: 'hsBendingMoment',
    units: UNITS_FOR.BendingMoment,
    yAxisTitle: 'Bending Moment',
  },
  {
    title: 'RS Bending Moment',
    tag: 'rsBendingMoment',
    units: UNITS_FOR.BendingMoment,
    yAxisTitle: 'Bending Moment',
  },
  {
    title: 'Total Bending Moment',
    tag: 'totalBendingMoment',
    units: UNITS_FOR.BendingMoment,
    yAxisTitle: 'Bending Moment',
  },
  {
    title: 'HS Deflection',
    tag: 'hsDef',
    units: UNITS_FOR.Diameter,
    yAxisTitle: 'Deflection',
  },
  {
    title: 'RS Deflection',
    tag: 'rsDef',
    units: UNITS_FOR.Diameter,
    yAxisTitle: 'Deflection',
  },
  {
    title: 'HS Rotation',
    tag: 'hsRot',
    units: 'degrees',
    yAxisTitle: 'Rotation',
  },
  {
    title: 'RS Rotation',
    tag: 'rsRot',
    units: 'degrees',
    yAxisTitle: 'Rotation',
  },
  {
    title: 'HS Contact Force',
    tag: 'hsContactForce',
    units: UNITS_FOR.Force,
    yAxisTitle: 'Force',
  },
  {
    title: 'RS Contact Force',
    tag: 'rsContactForce',
    units: UNITS_FOR.Force,
    yAxisTitle: 'Force',
  },
  {
    title: 'Total Contact Force',
    tag: 'totalContactForce',
    units: UNITS_FOR.Force,
    yAxisTitle: 'Force',
  },
  {
    title: 'HS Shear Load',
    tag: 'hsShearLoad',
    units: UNITS_FOR.Force,
    yAxisTitle: 'Shear',
  },
  {
    title: 'RS Shear Load',
    tag: 'rsShearLoad',
    units: UNITS_FOR.Force,
    yAxisTitle: 'Shear',
  },
  {
    title: 'Total Shear Load',
    tag: 'totalShearLoad',
    units: UNITS_FOR.Force,
    yAxisTitle: 'Shear',
  },
  {
    title: 'Axial Deflection',
    tag: 'axial',
    units: UNITS_FOR.Diameter,
    yAxisTitle: 'Force',
  },
  {
    title: 'Torsion',
    tag: 'torsion',
    units: 'degrees',
    yAxisTitle: 'Torsion',
  },
  {
    title: 'Tension',
    tag: 'tension',
    units: UNITS_FOR.Weight,
    yAxisTitle: 'Tension',
  },
  {
    title: 'Torque',
    tag: 'torque',
    units: UNITS_FOR.Torque,
    yAxisTitle: 'Torque',
  },
]

const CONTOUR_CHARTS = [
  {
    title: 'HS Bending Moment',
    tag: 'hsBendingMoment',
    units: UNITS_FOR.BendingMoment,
    yAxisTitle: 'Bending Moment',
  },
  {
    title: 'RS Bending Moment',
    tag: 'rsBendingMoment',
    units: UNITS_FOR.BendingMoment,
    yAxisTitle: 'Bending Moment',
  },
  {
    title: 'Total Bending Moment',
    tag: 'totalBendingMoment',
    units: UNITS_FOR.BendingMoment,
    yAxisTitle: 'Bending Moment',
  },
  {
    title: 'HS Contact Force',
    tag: 'hsContactForce',
    units: UNITS_FOR.Force,
    yAxisTitle: 'Force',
  },
  {
    title: 'RS Contact Force',
    tag: 'rsContactForce',
    units: UNITS_FOR.Force,
    yAxisTitle: 'Force',
  },
  {
    title: 'Total Contact Force',
    tag: 'totalContactForce',
    units: UNITS_FOR.Force,
    yAxisTitle: 'Force',
  },
  {
    title: 'HS Shear Load',
    tag: 'hsShearLoad',
    units: UNITS_FOR.Force,
    yAxisTitle: 'Shear',
  },
  {
    title: 'RS Shear Load',
    tag: 'rsShearLoad',
    units: UNITS_FOR.Force,
    yAxisTitle: 'Shear',
  },
  {
    title: 'Total Shear Load',
    tag: 'totalShearLoad',
    units: UNITS_FOR.Force,
    yAxisTitle: 'Shear',
  },
  {
    title: 'Axial Deflection',
    tag: 'axial',
    units: UNITS_FOR.Diameter,
    yAxisTitle: 'Force',
  },
  {
    title: 'Torsion',
    tag: 'torsion',
    units: 'degrees',
    yAxisTitle: 'Torsion',
  },
  {
    title: 'Tension',
    tag: 'tension',
    units: UNITS_FOR.Weight,
    yAxisTitle: 'Tension',
  },
  {
    title: 'Torque',
    tag: 'torque',
    units: UNITS_FOR.Torque,
    yAxisTitle: 'Torque',
  },
]

const BhaAnalysisChartsPage = ({ isToolbarExpanded, chartType, data, isLoading }) => {
  const _isMounted = useRef(true)

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const LineCharts = () => {
    if (chartType !== 'forcesAndDeflections') return null
    return (
      <React.Fragment>
        {CHARTS.map((chart, index) => {
          if (chart?.hide) return null;   
            return (
              <BhaAnalysisForceDeflectionChart
                isToolbarExpanded={isToolbarExpanded}
                data={data?.results}
                chartData={chart}
                key={index}
                height = '200px'
                width = '100%'
              />
            )          
        })}
      </React.Fragment>
    )
  }

  const ContourCharts = () => {
    if (chartType !== 'forcesAndDeflectionsContour') return null
    return (
      <React.Fragment>
        {CONTOUR_CHARTS.map((chart, index) => {
          if (chart?.hide) return null
          return (
            <ForceDeflectionContourChart
              isToolbarExpanded={isToolbarExpanded}
              data={data?.results}
              key={index}
              chartData={chart}
            />
          )
        })}
      </React.Fragment>
    )
  }

  return (
    <Box
      id={'div1'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}>
      {isLoading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : null}
      <LineCharts />
      <ContourCharts />
    </Box>
  )
}

export default BhaAnalysisChartsPage
