import React, { useRef, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Box, Typography } from '@mui/material'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { getStringId, htmlSymbolHandling } from 'components/common/AgGridUtils'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { appColors } from 'utils'
import useUnit, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { currentWellAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'

const DrillStringRecommendationsDetailsGrid = ({ data, setStatus, setImportBha }) => {
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const { getUnitsText } = useUnit()
  const { theme } = useInnovaTheme()
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const currentWellRef = useRef(currentWell)

  const importBha = useInnovaAxios({
    url: '/well/drillString/duplicateBha',
  })

  const CardCellRenderer = ({ params }) => {
    let scoreColor = 'rgba(0, 255, 0, 0.5)'
    if (params.data.score <= 50) {
      scoreColor = 'rgba(255, 0, 0, 0.5)'
    }
    if (params.data.score > 50 && params.data.score <= 80) {
      scoreColor = 'rgba(255, 165, 0, 0.5)'
    }
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'stretch',
          padding: 2,
          border: '1px solid #ddd',
          borderRadius: 1,
          backgroundColor: theme === 'dark' ? appColors.itemBackColor : 'white',
        }}>
        <Box
          sx={{
            width: 10,
            backgroundColor: scoreColor,
            marginRight: 2,
          }}></Box>

        <Box>
          <Typography variant='body1' sx={{ fontWeight: 'bold', marginBottom: 1 }}>
            BHA #:{' '}
            <Typography component='span' sx={{ fontWeight: 'normal' }}>
              {params.data.bhaNumRep}
            </Typography>
          </Typography>
          <Typography variant='body2' sx={{ marginBottom: 0.5 }}>
            <strong>Operator:</strong> {params.data.operator}
          </Typography>
          <Typography variant='body2' sx={{ marginBottom: 0.5 }}>
            <strong>Formation:</strong> {params.data.formation}
          </Typography>
          <Typography variant='body2' sx={{ marginBottom: 0.5 }}>
            <strong>ROP:</strong> {numberWithCommasDecimals(params.data.avgRop, 2)} {getUnitsText(UNITS_FOR.Depth)}/hr
          </Typography>
          <Typography variant='body2' sx={{ marginBottom: 0.5 }}>
            <strong>Total Drilled:</strong> {numberWithCommasDecimals(params.data.totalDrilled, 2)}{' '}
            {getUnitsText(UNITS_FOR.Depth)}
          </Typography>
          <Typography variant='body2'>
            <strong>Hole Size:</strong> {numberWithCommasDecimals(params.data.bitData?.Od, 2)}{' '}
            {getUnitsText(UNITS_FOR.Diameter)}
          </Typography>
          <Box sx={{ marginTop: 1 }}>
            <button onClick={() => handelImportBha(params.data)}>Import BHA</button>
          </Box>
        </Box>
      </Box>
    )
  }

  const handelImportBha = useCallback(
    async (data) => {
      if (!data) return

      data = htmlSymbolHandling(data)
      data.wellName = currentWellRef.current
      data.orgWellName = data.actualWell

      let res = await importBha(data)
      if (res?.error) {
        setStatus({ show: true, severity: 'error', message: res?.error?.response?.data?.error })
        return
      }

      setImportBha(res?.data)
    },
    [importBha, setStatus, setImportBha],
  )

  const columnDefs = [
    {
      headerName: 'Card',
      cellRenderer: (params) => <CardCellRenderer params={params} />,
    },
  ]

  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    editable: false,
  }

  const gridOptions = {}

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const getRowId = (data) => {
    return getStringId(data?.data?.bhaNum)
  }

  const onFirstDataRendered = () => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    setTimeout(() => {
      gridApi.current?.autoSizeAllColumns()
    }, 100)
  }

  return (
    <Box className={getAgGridTheme()} sx={{ width: '100%', height: '100%' }}>
      <AgGridReact
        rowData={data}
        columnDefs={columnDefs}
        headerHeight={30}
        defaultcolDef={defaultColDef}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        getRowId={getRowId}
        onFirstDataRendered={onFirstDataRendered}
        rowHeight={235}
      />
    </Box>
  )
}

export default DrillStringRecommendationsDetailsGrid
