import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Box, Input } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import ElementPropertyModal from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/ElementPropertyModal'
import { ELEMENT_TYPES as elementType } from 'components/WellPages/WallplotComposer/Elements/elementDefs'

export const getTextElementDefaults = ({ pageLayout, zIndex }) => {
  return {
    uid: uuidv4(),
    type: elementType.text,
    textSettings: {
      text: `Text element`,
    },
    left: pageLayout.marginLeft,
    top: pageLayout.marginTop,
    width: 2,
    height: 1,
    zIndex: zIndex,
    style: {
      color: '#333',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: '#333',
      backgroundColor: '#fff',
      fontFamily: 'Arial',
      fontSize: '16px',
      fontWeight: '400',
      fontStyle: 'normal',
      opacity: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
  }
}

const TextElement = forwardRef(({ id, element, actions, updateContainer, scale }, ref) => {
  const [showProperties, setShowProperties] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [inputValue, setInputValue] = useState(element.textSettings.text)
  const [currentStyle, setCurrentStyle] = useState(element.style)
  const inputRef = useRef(null)

  useEffect(() => {
    if (editMode && inputRef.current) {
      inputRef.current.focus()
    }
  }, [editMode])

  useEffect(() => {
    setCurrentStyle(element.style)
  }, [element.style])

  useEffect(() => {
    setInputValue(element.textSettings.text)
  }, [element.textSettings.text])

  useImperativeHandle(ref, () => {
    return {
      showProperties() {
        setShowProperties(true)
      },
    }
  })

  const handleChange = (newData) => {
    actions.update(id, 'update', { textSettings: { text: inputValue }, style: { ...newData.style } })
    setCurrentStyle({ ...newData.style })
    setShowProperties(false)
  }

  const handleEdit = (event) => {
    setInputValue(event.target.value)
    actions.update(id, 'update', { textSettings: { text: event.target.value } })
  }

  return (
    <>
      {showProperties && (
        <ElementPropertyModal
          onClose={() => setShowProperties(false)}
          elementStyle={currentStyle}
          elementData={null}
          onApply={handleChange}
          elemType={elementType.text}
        />
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: currentStyle.justifyContent,
          alignItems: currentStyle.alignItems,
          height: '100%',
          width: '100%',
          position: 'absolute',
        }}
        onDoubleClick={(e) => {
          e.stopPropagation()
          setShowProperties(true)
        }}>
        <Box
          style={{
            color: currentStyle.color,
            fontFamily: currentStyle.fontFamily,
            fontSize: scale && !isNaN(scale) ? `${parseFloat(currentStyle.fontSize) * scale}px` : currentStyle.fontSize,
            fontWeight: currentStyle.fontWeight,
            fontStyle: currentStyle.fontStyle,
            padding: '0 8px',
            opacity: 1,
          }}
          onDoubleClick={(e) => {
            e.stopPropagation()
            setEditMode(true)
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              e.stopPropagation()
              actions.update(id, 'update', { data: { text: inputValue } })
              setEditMode(false)
            }
          }}
          onBlur={(e) => {
            e.stopPropagation()
            actions.update(id, 'update', { data: { text: inputValue } })
            setEditMode(false)
          }}>
          {editMode ? (
            <Input
              inputRef={inputRef}
              sx={{ marginLeft: '8px' }}
              inputProps={{
                style: {
                  textAlign: 'center',
                  fontSize: '16px',
                  fontWeight: '400',
                  width: '100%',
                  backgroundColor: 'white',
                },
              }}
              value={inputValue}
              onChange={(event) => {
                handleEdit(event)
              }}
            />
          ) : (
            inputValue
          )}
        </Box>
      </div>
    </>
  )
})

export default TextElement
