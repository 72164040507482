import { cloneDeep } from 'lodash'
import { appColors } from 'utils'
import { Vector2 } from 'three'
import { getTargets2D, getDrillersTargets2D } from 'components/WellPages/PlanView/targets2D'
import { pointInterp, calcVS } from 'utils/surveyFunctions'
import { calcSurvey } from 'utils/projectionCalcs'

const CHART_TYPE_SECTION = 'section'
const CHART_TYPE_PLAN = 'plan'

const CHART_DEF = {
  plan: {
    xKey: 'ew',
    yKey: 'ns',
  },
  section: {
    xKey: 'vs',
    yKey: 'tvd',
  },
}

const PointStyles = [
  { value: false, label: 'None' },
  { value: 'line', label: 'Dash' },
  { value: 'cross', label: 'Plus' },
  { value: 'crossRot', label: 'Cross' },
  { value: 'circle', label: 'Dot' },
  { value: 'circle', label: 'Dot Solid' },
  { value: 'rect', label: 'Square' },
  { value: 'rect', label: 'Square Solid' },
  { value: 'rectRot', label: 'Diamond' },
  { value: 'rectRot', label: 'Diamond Solid' },
  { value: 'triangle', label: 'Up Triangle' },
  { value: 'triangle', label: 'Up Triangle Solid' },
  { value: 'triangle', label: 'Down Triangle' },
  { value: 'triangle', label: 'Down Triangle Solid' },
]

const updateDataMinMax = (minMax, x, y) => {
  if (!minMax) return
  if (typeof x !== 'number') return
  if (typeof y !== 'number') return
  if (isNaN(x)) return
  if (isNaN(y)) return

  if (x > minMax.maxX) minMax.maxX = x
  if (x < minMax.minX) minMax.minX = x
  if (y > minMax.maxY) minMax.maxY = y
  if (y < minMax.minY) minMax.minY = y
}

const getWellColor = (type, userColor) => {
  if (userColor) return userColor
  return type === 'Actual' ? appColors.surveyTrajectoryColor : appColors.planTrajectoryColor
}

const getLineStyle = (lineStyle) => {
  switch (lineStyle) {
    case 'solid':
      return []
    case 'dashed':
      return [5, 5]
    case 'dotted':
      return [1, 1]
    default:
      return []
  }
}

const getPointStyle = (pointStyle) => {
  let style = PointStyles.find((x) => x.label === pointStyle)
  if (!style) return false
  return style.value
}

const getPointRotation = (pointStyle) => {
  if (pointStyle === 'Down Triangle' || pointStyle === 'Down Triangle Solid') return 180
  return 0
}

const getPointBackgroundColor = (pointStyle, type, userColor) => {
  if (!pointStyle) return 'rgba(0,0,0,0)'
  if (pointStyle.includes('Solid')) return getWellColor(type, userColor)
  return 'rgba(0,0,0,0)'
}

const isChartTypeValid = (chartType) => {
  if (typeof chartType !== 'string') {
    return false
  }

  if (chartType !== CHART_TYPE_SECTION && chartType !== CHART_TYPE_PLAN) {
    return false
  }

  return true
}

const translatePoints = (svy1, svy2, translationDistance, chartDef) => {
  if (!translationDistance) translationDistance = 0
  let p1 = new Vector2(svy1[chartDef.xKey], svy1[chartDef.yKey])
  let p2 = new Vector2(svy2[chartDef.xKey], svy2[chartDef.yKey])
  const lineDirection = new Vector2().copy(p2).sub(p1).normalize()
  const perpendicularDirection = new Vector2(-lineDirection.y, lineDirection.x)
  const translation = perpendicularDirection.clone().multiplyScalar(translationDistance)
  const translatedP2 = p2.clone().add(translation)

  let newSvy = { ...svy2 }
  newSvy[chartDef.xKey] = translatedP2.x
  newSvy[chartDef.yKey] = translatedP2.y
  return newSvy
}

const AddErrorEllipseSeries = (chartData, svys, chartDef) => {
  if (!Array.isArray(svys)) return

  chartData.datasets.push({
    type: 'scatter',
    showLine: true,
    label: '-',
    borderWidth: 1,
    pointRadius: 0,
    fillBetweenSeries: chartData.datasets.length + 1,
    fillBetweenColor: 'rgba(255,0,0, 0.2)',
    data: svys.map((svy, index) => {
      if (index === 0) return { x: svy[chartDef.xKey], y: svy[chartDef.yKey] }
      let newPoint = translatePoints(svys[index - 1], svy, -svy.semiMajor, chartDef)
      return { x: newPoint[chartDef.xKey], y: newPoint[chartDef.yKey] }
    }),
  })

  chartData.datasets.push({
    type: 'scatter',
    showLine: true,
    label: '-',
    borderWidth: 1,
    pointRadius: 0,
    data: svys.map((svy, index) => {
      if (index === 0) return { x: svy[chartDef.xKey], y: svy[chartDef.yKey] }
      let newPoint = translatePoints(svys[index - 1], svy, svy.semiMajor, chartDef)
      return { x: newPoint[chartDef.xKey], y: newPoint[chartDef.yKey] }
    }),
  })
}

function createGeoZone(vsStart, vsEnd, tvdStart, tvdEnd, thickness) {
  let Top = []
  Top.push({ x: vsStart, y: tvdStart - thickness })
  Top.push({ x: vsEnd, y: tvdEnd - thickness })
  return Top
}

function hexWithAlpha(hex, alpha) {
  // Ensure hex color starts with '#'
  hex = hex.replace(/^#/, '')

  // Convert the hex to RGB values
  let r = parseInt(hex.slice(0, 2), 16)
  let g = parseInt(hex.slice(2, 4), 16)
  let b = parseInt(hex.slice(4, 6), 16)

  // Convert alpha to a 0-255 range
  alpha = Math.round(alpha * 255)

  // Convert RGB and alpha back to hex
  let alphaHex = alpha.toString(16).padStart(2, '0')

  // Combine RGB with alpha
  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b
    .toString(16)
    .padStart(2, '0')}${alphaHex}`
}

const transparentize = (value, opacity) => {
  var alpha = opacity === undefined ? 0.5 : 1 - opacity
  return hexWithAlpha(value, alpha)
}

const applyTargetSettings = (targetData, targetSettings) => {
  if (!Array.isArray(targetData)) return
  if (!Array.isArray(targetSettings)) return
  for (let i = 0; i < targetData.length; i++) {
    // let target = targetSettings.find((x) => x.targetName === targetData[i].targetName)
    let target = targetSettings[i]
    if (!target) continue
    targetData[i].visible = target.visible
    if (target.visible === false) {
      targetData[i].points = []
    }
    targetData[i].color = target.color
    targetData[i].lineStyle = target.lineStyle
    targetData[i].filled = target.filled
    targetData[i].fillColor = transparentize(target.fillColor, 0.5)
  }
}

export const getChartData = (
  chartType,
  wellData,
  refWellSurveys,
  projections,
  isPlan,
  displaySettings,
  refGeosteering,
) => {
  if (!isChartTypeValid(chartType)) return null
  if (!wellData) return null

  const minMax = {
    maxX: 50,
    minX: -50,
    maxY: 50,
    minY: -50,
    xLen: 100,
    yLen: 100,
  }

  const chartDef = CHART_DEF[chartType]
  const chartData = { datasets: [] }
  const { VsAzi, VsOrgNorth, VsOrgEast } = wellData.actualWellData.verticalSection
  const chartPolygons = []

  let geosteeringData = Array.isArray(refGeosteering) ? refGeosteering : []

  if (displaySettings.showGeosteering && chartType === CHART_TYPE_SECTION) {
    //Draw geosteering data
    for (let i = 0; i < geosteeringData.length - 1; i++) {
      let start = { ...geosteeringData[i] }
      let end = { ...geosteeringData[i + 1] }

      if (end.md - start.md <= 1) continue

      end.inc = start.inc
      calcSurvey(start, end)
      let Top = createGeoZone(start.vs, end.vs, start.tvd, end.tvd, -start.thickup)
      let Bottom = createGeoZone(start.vs, end.vs, start.tvd, end.tvd, start.thickdown)

      chartData.datasets.push({
        type: 'line',
        showLine: true,
        label: '-',
        borderWidth: 1,
        pointRadius: 0,
        borderColor: '#000000',
        backgroundColor: '#00ff0030',
        fill: false,
        data: Top.map((d) => {
          updateDataMinMax(minMax, d.x, d.y)
          return { x: d.x, y: d.y }
        }),
      })

      chartData.datasets.push({
        type: 'line',
        showLine: true,
        label: '-',
        borderWidth: 1,
        pointRadius: 0,
        borderColor: '#000000',
        backgroundColor: '#00ff0030',
        fill: '-1',
        data: Bottom.map((d) => {
          updateDataMinMax(minMax, d.x, d.y)
          return { x: d.x, y: d.y }
        }),
      })
    }
  }

  let svys = Array.isArray(refWellSurveys) ? cloneDeep(refWellSurveys) : cloneDeep(wellData.surveys)
  wellData.offsetWells.forEach((well) => {
    if (well.offsetWellbore !== wellData.wellName) return
    if (!Array.isArray(svys)) return
    if (svys === 0) return
    if (!Array.isArray(well.surveyData)) return

    for (let i = 0; i < svys.length; i++) {
      let index = well.surveyData.findIndex((x) => Math.abs(x.md - svys[i].md) < 0.1)
      if (index < 0) continue
      svys[i].semiMajor = well.surveyData[index].semiMajor
    }
  })

  if (svys.length > 1) {
    if (
      // if showing principal plan only, skip if this is not the principal well
      !displaySettings.showPrincipalPlan ||
      (displaySettings.showPrincipalPlan === true &&
        wellData.actualWellData.isPlan === true &&
        wellData.actualWellData.principal === true)
    ) {
      chartData.datasets.push({
        type: 'line',
        showLine: true,
        label: wellData.wellName,
        borderWidth: displaySettings?.series[0].lineThickness ? displaySettings?.series[0].lineThickness : 2,
        pointRadius: displaySettings.showOffsetMarkers ? 3 : 0,
        pointStyle: displaySettings.showOffsetMarkers ? getPointStyle(displaySettings?.series[0].marker) : false,
        rotation: displaySettings.showOffsetMarkers ? getPointRotation(displaySettings?.series[0].marker) : false,
        borderColor: getWellColor(isPlan ? 'Planned' : 'Actual', displaySettings?.series[0]?.color),
        backgroundColor: displaySettings.showOffsetMarkers
          ? getPointBackgroundColor(
              displaySettings?.series[0].marker,
              isPlan ? 'Planned' : 'Actual',
              displaySettings?.series[0]?.color,
            )
          : 'rgba(0,0,0,0)',
        borderDash: getLineStyle(displaySettings?.series[0]?.lineStyle),
        data: svys.map((svy) => {
          updateDataMinMax(minMax, svy[chartDef.xKey], svy[chartDef.yKey])
          return { x: svy[chartDef.xKey], y: svy[chartDef.yKey] }
        }),
      })

      if (displaySettings.showErrorEllipses) {
        AddErrorEllipseSeries(chartData, svys, chartDef)
      }
    }
  }

  if (projections?.length > 1) {
    chartData.datasets.push({
      type: 'line',
      showLine: true,
      label: wellData.wellName,
      borderWidth: 2,
      pointRadius: 1,
      borderColor: '#59FF00',
      backgroundColor: '#59FF00',
      data: projections.map((svy) => {
        updateDataMinMax(minMax, svy[chartDef.xKey], svy[chartDef.yKey])
        return { x: svy[chartDef.xKey], y: svy[chartDef.yKey] }
      }),
    })
  }

  if (displaySettings.showOffsets && Array.isArray(wellData.offsetWells)) {
    for (let i = 1; i < wellData.offsetWells.length; i++) {
      // skip past duplicate of main well
      if (!Array.isArray(wellData.offsetWells[i]?.surveyData)) continue
      if (wellData.offsetWells[i].surveyData.length < 2) continue
      if (
        displaySettings.series.find((offsetWell) => offsetWell.wellName === wellData.offsetWells[i].offsetWellbore)
          ?.visible === false
      )
        continue // if a dataset is hidden (via the Series props tab), skip it

      if (displaySettings.showPrincipalPlan && wellData.offsetWells[i].isPrincipal === false) continue

      chartData.datasets.push({
        type: 'line',
        showLine: true,
        borderWidth: displaySettings?.series[i]?.lineThickness ? displaySettings?.series[i].lineThickness : 1,
        pointRadius: displaySettings.showOffsetMarkers ? 3 : 0,
        pointStyle: displaySettings.showOffsetMarkers ? getPointStyle(displaySettings?.series[i].marker) : false,
        rotation: displaySettings.showOffsetMarkers ? getPointRotation(displaySettings?.series[i].marker) : false,
        label: wellData.offsetWells[i].offsetWellbore,
        borderColor: getWellColor(wellData.offsetWells[i].wellStatus, displaySettings?.series[i]?.color),
        backgroundColor: displaySettings.showOffsetMarkers
          ? getPointBackgroundColor(
              displaySettings?.series[i].marker,
              wellData.offsetWells[i].wellStatus,
              displaySettings?.series[i]?.color,
            )
          : 'rgba(0,0,0,0)',
        borderDash: getLineStyle(displaySettings?.series[i]?.lineStyle),
        data: wellData.offsetWells[i].surveyData.map((svy) => {
          updateDataMinMax(minMax, svy[chartDef.xKey], svy[chartDef.yKey])
          return { x: svy[chartDef.xKey], y: svy[chartDef.yKey] }
        }),
      })

      if (displaySettings.showErrorEllipses) {
        AddErrorEllipseSeries(chartData, wellData.offsetWells[i].surveyData, chartDef)
      }
    }
  }

  if (displaySettings.showTargets) {
    let targetData = []
    getTargets2D(
      wellData?.targets,
      targetData,
      chartType,
      wellData?.actualWellData?.verticalSection,
      displaySettings.showTargets,
    )
    applyTargetSettings(targetData, displaySettings.targets) //copy target property settings to targetData

    for (let i = 0; i < targetData.length; i++) {
      if (!targetData[i].visible) continue

      if (targetData[i].filled && Array.isArray(targetData[i]?.pointsTop)) {
        // const points = targetData[i].pointsTop.slice(0, -1).map((pt, i) => {
        //   updateDataMinMax(minMax, pt.x, pt.y)
        //   return { x: pt.x, y: pt.y }
        // })
        const points = targetData[i].pointsTop

        // draw filled-polygon 'behind' the dataset
        chartPolygons.push({
          color: targetData[i].color,
          backgroundColor: targetData[i].fillColor,
          points: points,
        })

        // chartData.datasets.push({
        //   type: 'line',
        //   borderWidth: 2,
        //   label: '',
        //   borderColor: targetData[i].color,
        //   backgroundColor: targetData[i].fillColor,
        //   fill: true,
        //   data: points,
        //   pointRadius: 0,
        // })
      }
      if (targetData[i].filled && Array.isArray(targetData[i]?.pointsBottom)) {
        const points = targetData[i].pointsBottom
        // draw filled-polygon 'behind' the dataset
        chartPolygons.push({
          color: targetData[i].color,
          backgroundColor: targetData[i].fillColor,
          points: points,
        })
      }

      if (Array.isArray(targetData[i]?.points)) {
        chartData.datasets.push({
          type: 'line',
          label: '',
          borderWidth: 2,
          borderColor: targetData[i].color,
          fill: false,
          data: targetData[i].points.map((pt) => {
            updateDataMinMax(minMax, pt.x, pt.y)
            return { x: pt.x, y: pt.y }
          }),
          pointRadius: 0,
        })
      }

      chartData.datasets.push({
        type: 'line',
        showLine: true,
        borderWidth: 2,
        label: '',
        borderColor: targetData[i].color,
        data: [{ x: targetData[i].x, y: targetData[i].y, label: '', color: targetData[i].color }],
        pointRadius: 3,
      })
    }
  }

  if (displaySettings.showDrillersTargets) {
    let drillersTargetData = []
    getDrillersTargets2D(wellData?.targets, drillersTargetData, chartType, null, displaySettings.showDrillersTargets)

    for (let i = 0; i < drillersTargetData.length; i++) {
      if (!Array.isArray(drillersTargetData[i]?.points)) continue

      chartData.datasets.push({
        type: 'line',
        showLine: true,
        borderWidth: 2,
        label: '',
        borderColor: drillersTargetData[i].color,
        backgroundColor: drillersTargetData[i].color,
        data: drillersTargetData[i].points.map((pt) => {
          updateDataMinMax(minMax, pt.x, pt.y)
          return { x: pt.x, y: pt.y }
        }),
        pointRadius: 0,
      })

      chartData.datasets.push({
        type: 'line',
        showLine: true,
        borderWidth: 2,
        label: '',
        borderColor: drillersTargetData[i].color,
        backgroundColor: drillersTargetData[i].color,
        data: [
          {
            x: drillersTargetData[i].x + 5,
            y: drillersTargetData[i].y + 5,
            label: drillersTargetData[i].text,
            color: drillersTargetData[i].color,
          },
        ],
        pointRadius: 3,
      })
    }
  }

  minMax.yLen = minMax.maxY - minMax.minY
  minMax.xLen = minMax.maxX - minMax.minX

  if (displaySettings.showCasing && Array.isArray(wellData?.actualWellData?.casing)) {
    wellData.actualWellData.casing.forEach((csg) => {
      const { md, ew, ns, tvd } = pointInterp(svys, csg.MD)
      if (md < 0) return
      let label = `${csg.OD} ${csg.Type}`

      let x = ew
      let y = ns
      if (chartDef.xKey === 'vs') {
        x = calcVS(VsAzi, ns, ew, VsOrgNorth, VsOrgEast)
      }

      if (chartDef.yKey === 'tvd') {
        y = tvd
      }

      chartData.datasets.push({
        type: 'line',
        showLine: true,
        borderWidth: 2,
        label: label,
        borderColor: appColors.headerTextColor,
        backgroundColor: appColors.headerTextColor,
        data: [{ x: x, y: y, label: label, color: appColors.headerTextColor }],
        pointRadius: 3,
      })
    })
  }

  if (displaySettings.showAnnotations && Array.isArray(wellData?.actualWellData?.annotations)) {
    wellData.actualWellData.annotations.forEach((annot) => {
      const { md, ew, ns, tvd } = pointInterp(svys, annot.md)
      if (md < 0) return

      let x = ew
      let y = ns
      if (chartDef.xKey === 'vs') {
        x = calcVS(VsAzi, ns, ew, VsOrgNorth, VsOrgEast)
      }

      if (chartDef.yKey === 'tvd') {
        y = tvd
      }

      chartData.datasets.push({
        type: 'line',
        showLine: true,
        borderWidth: 2,
        label: annot.annotation,
        borderColor: appColors.itemTextColor,
        backgroundColor: appColors.itemTextColor,
        data: [{ x: x, y: y, label: annot.annotation, color: appColors.itemTextColor }],
        pointRadius: 1,
      })
    })
  }

  let xAxisLabel = 'EW'
  let yAxisLabel = 'NS'

  if (chartType === CHART_TYPE_SECTION) {
    xAxisLabel = `VS @ (${VsAzi?.toFixed(2)})`
    yAxisLabel = 'TVD'
  }

  return { chartData, minMax, xAxisLabel, yAxisLabel, chartPolygons }
}
