import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import ColorPickerCellEditor from 'components/common/CellEditors/ColorPickerCellEditor'
import LineCellRenderer from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/LineCellRenderer'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { Box, Checkbox, Typography } from '@mui/material'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import { appColors } from 'utils'
import { LABEL_CATEGORIES } from '../ChartElements/labelDefinitions'

const CustomHeader = (props) => {
  return (
    <Box
      style={{
        padding: '2px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Box style={{ flex: 1 }}>{props.displayName}</Box>
      <Box style={{ flex: 1 }}>{props.line2}</Box>
    </Box>
  )
}

const ChartTargetsGrid = forwardRef(({ chartData }, ref) => {
  const [showTargets, setShowTargets] = useState(chartData.showTargets ? chartData.showTargets : false)
  const [showTargetNames, setShowTargetNames] = useState(chartData.showTargetNames ? chartData.showTargetNames : false)
  const [showLeaseLineNames, setShowLeaseLineNames] = useState(
    chartData.showLeaseLineNames ? chartData.showLeaseLineNames : false,
  )
  const [showDrillersTargets, setShowDrillersTargets] = useState(
    chartData.showDrillersTargets ? chartData.showDrillersTargets : false,
  )
  const [showLeaseLineIntersect, setShowLeaseLineIntersect] = useState(
    chartData.showLeaseLineIntersect ? chartData.showLeaseLineIntersect : false,
  )
  const gridApi = useRef(null)
  const { theme, getAgGridTheme } = useInnovaTheme()

  useImperativeHandle(ref, () => ({
    stopEditing: () => {
      if (gridApi.current) gridApi.current.stopEditing()
    },
    getData: () => {
      let gridData = []
      gridApi.current?.forEachNode((node) => {
        if (node.data) gridData.push(node.data)
      })

      let data = {
        props: [
          { tag: 'showTargets', value: showTargets },
          { tag: 'showTargetNames', value: showTargetNames },
          { tag: 'showLeaseLineNames', value: showLeaseLineNames },
          { tag: 'showDrillersTargets', value: showDrillersTargets },
          { tag: 'showLeaseLineIntersect', value: showLeaseLineIntersect },
        ],
        targets: gridData,
      }

      // add target labels to label
      let labelCategories = []
      if (chartData.showTargetNames !== showTargetNames && showTargetNames === true) {
        labelCategories.push({
          category: LABEL_CATEGORIES.targets,
          enabled: true,
          defaultColorEnabled: true,
          defaultColor: '#000000', // needs to be overridden in the labelDefinitions.js when the labels for each target are created
          color: '#000000',
          size: 10,
          bold: true,
          hasTether: true,
          angled: false,
          backgroundEnabled: false,
          backgroundColor: '',
        })
      }
      if (chartData.showLeaseLineNames !== showLeaseLineNames && showLeaseLineNames === true) {
        labelCategories.push({
          category: LABEL_CATEGORIES.leaseLines,
          enabled: true,
          defaultColorEnabled: true,
          defaultColor: '#000000', // needs to be overridden in the labelDefinitions.js when the labels for each target are created
          color: '#000000',
          size: 10,
          bold: true,
          hasTether: true,
          angled: false,
          backgroundEnabled: false,
          backgroundColor: '',
        })
      }
      if (labelCategories.length > 0) {
        data = { ...data, labels: { categories: labelCategories, labelData: [] } }
      }
      return data
    },
  }))

  const getRowData = (data) => {
    let rowData = []
    if (!data) return rowData
    if (!data.targets) return rowData
    for (let i = 0; i < data.targets.length; i++) {
      rowData.push({
        id: i,
        uid: data.targets[i].uid,
        targetName: data.targets[i].targetName,
        isLeaseLine: data.targets[i].isLeaseLine,
        color: data.targets[i].color,
        visible: data.targets[i].visible,
        lineStyle: data.targets[i].lineStyle ? data.targets[i].lineStyle : 'solid',
        filled: data.targets[i].filled === false ? false : true,
        fillColor: data.targets[i].fillColor ? data.targets[i].fillColor : 'transparent',
      })
    }

    return rowData
  }

  useEffect(() => {
    if (showTargets === false) {
      setShowTargetNames(false)
      setShowLeaseLineNames(false)
      setShowDrillersTargets(false)
      setShowLeaseLineIntersect(false)
    }
    if (gridApi.current) {
      gridApi.current.setGridOption('rowData', getRowData(chartData))
    }
  }, [showTargets, showTargetNames, showLeaseLineNames, showDrillersTargets, showLeaseLineIntersect]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
    gridApi.current.setGridOption('rowData', getRowData(chartData))
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const toggleTargets = () => {
    setShowTargets((prevVal) => !prevVal)
  }

  const toggleTargetNames = () => {
    setShowTargetNames((prevVal) => !prevVal)
  }

  const toggleLeaseLineNames = () => {
    setShowLeaseLineNames((prevVal) => !prevVal)
  }

  const toggleDrillerTargets = () => {
    setShowDrillersTargets((prevVal) => !prevVal)
  }

  // const toggleLeaseLineIntersect = () => {
  //   setShowLeaseLineIntersect((prevVal) => !prevVal)
  // }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const gridOptions = {
    suppressRowClickSelection: true,
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const leftAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
    }),
    [],
  )

  const columnDefs = useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'targetName',
        colId: 'targetName',
        // minWidth: 100,
        width: 250,
        // maxWidth: 250,
        pinned: 'left',
        lockPosition: 'left',
        autoHeight: true,
        cellStyle: leftAlignCell,
      },
      {
        headerName: '',
        field: 'uid',
        hide: true,
      },
      {
        headerName: 'Lease',
        field: 'isLeaseLine',
        colId: 'isLeaseLine',
        headerComponentParams: {
          line2: 'Line',
        },
        flex: 1,
        width: 100,
        cellStyle: centerAlignCell,
        cellEditorSelector: () => {
          return {
            component: 'agCheckboxCellEditor',
          }
        },
      },
      {
        headerName: 'Color',
        field: 'color',
        colId: 'color',
        minWidth: 80,
        width: 80,
        maxWidth: 80,
        flex: 1,
        editable: true,
        cellDataType: false,
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            backgroundColor: params.value,
            color: params.value,
          }
        },
        cellEditorSelector: (params) => {
          return {
            component: ColorPickerCellEditor,
            params: {
              color: params.value,
              defaultColor: params.data?.defaultValue,
            },
            popup: true,
          }
        },
      },
      {
        headerName: 'Visible',
        field: 'visible',
        colId: 'visible',
        flex: 1,
        width: 100,
        editable: () => {
          return showTargets
        },
        cellStyle: centerAlignCell,
        cellEditorSelector: () => {
          return {
            component: 'agCheckboxCellEditor',
          }
        },
      },
      {
        headerName: 'Line',
        field: 'lineStyle',
        colId: 'lineStyle',
        width: 100,
        flex: 1,
        editable: true,
        cellDataType: false,

        cellStyle: centerAlignCell,
        cellEditorSelector: () => {
          return {
            component: 'agRichSelectCellEditor',
            popup: true,
            params: {
              cellRenderer: LineCellRenderer,
              values: ['solid', 'dotted', 'dashed'],
              valueListMaxHeight: 125,
            },
          }
        },
        cellRendererSelector: () => {
          return {
            component: LineCellRenderer,
          }
        },
      },
      {
        headerName: 'Filled',
        field: 'filled',
        colId: 'filled',
        flex: 1,
        width: 100,
        editable: () => {
          return showTargets
        },
        cellStyle: centerAlignCell,
        cellEditorSelector: () => {
          return {
            component: 'agCheckboxCellEditor',
          }
        },
      },
      {
        headerName: 'Fill',
        field: 'fillColor',
        colId: 'fillColor',
        headerComponentParams: {
          line2: 'Color',
        },
        minWidth: 80,
        width: 80,
        maxWidth: 80,
        flex: 1,
        editable: true,
        cellDataType: false,
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            backgroundColor: params.value,
            color: params.value,
          }
        },
        cellEditorSelector: (params) => {
          return {
            component: ColorPickerCellEditor,
            params: {
              color: params.value,
              defaultColor: params.data?.defaultValue,
            },
            popup: true,
          }
        },
      },
    ],
    [centerAlignCell, leftAlignCell, showTargets],
  )

  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeader,
    }
  }, [])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'start',
          marginBottom: '5px',
          width: '100%',
        }}>
        <Checkbox
          checked={showTargets}
          onChange={toggleTargets}
          checkedIcon={
            <RadioButtonCheckedIcon fontSize='small' style={{ color: theme === 'dark' ? 'lime' : 'green' }} />
          }
          indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
          icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}></Checkbox>
        <Typography
          sx={{
            color: appColors.itemTextColor,
            marginRight: '16px',
          }}>
          Display Targets
        </Typography>
        <Checkbox
          checked={showTargetNames}
          onChange={toggleTargetNames}
          checkedIcon={
            <RadioButtonCheckedIcon fontSize='small' style={{ color: theme === 'dark' ? 'lime' : 'green' }} />
          }
          indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
          icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}></Checkbox>
        <Typography
          sx={{
            color: appColors.itemTextColor,
            marginRight: '16px',
          }}>
          Target Names
        </Typography>
        <Checkbox
          checked={showLeaseLineNames}
          onChange={toggleLeaseLineNames}
          checkedIcon={
            <RadioButtonCheckedIcon fontSize='small' style={{ color: theme === 'dark' ? 'lime' : 'green' }} />
          }
          indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
          icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}></Checkbox>
        <Typography
          sx={{
            color: appColors.itemTextColor,
            marginRight: '16px',
          }}>
          Lease Line Names
        </Typography>
        <Checkbox
          checked={showDrillersTargets}
          onChange={toggleDrillerTargets}
          checkedIcon={
            <RadioButtonCheckedIcon fontSize='small' style={{ color: theme === 'dark' ? 'lime' : 'green' }} />
          }
          indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
          icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}></Checkbox>
        <Typography
          sx={{
            color: appColors.itemTextColor,
            marginRight: '16px',
          }}>
          Drillers Targets
        </Typography>
        {/* enable intersections when fully supported */}
        {/* <Checkbox
          checked={showLeaseLineIntersect}
          onChange={toggleLeaseLineIntersect}
          checkedIcon={
            <RadioButtonCheckedIcon fontSize='small' style={{ color: theme === 'dark' ? 'lime' : 'green' }} />
          }
          indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
          icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}></Checkbox>
        <Typography
          sx={{
            color: appColors.itemTextColor,
            marginRight: '16px',
          }}>
          Lease Line Intersections
        </Typography> */}
      </Box>
      <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
        <AgGridReact
          className='ag-grid-theme-dark'
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          headerHeight={40}
          onFirstDataRendered={onFirstDataRendered}
          getRowId={getRowId}
          components={components} // multiline col header
        />
      </div>
    </>
  )
})

export default ChartTargetsGrid
