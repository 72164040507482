// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react'
import merge from 'lodash/merge'
import axios from 'axios'
import qs from 'qs'

const request = async (options) => {
  try {
    const inputData = options.data
    const data = qs.stringify(
      { ...inputData },
      { allowDots: true, arrayFormat: 'comma' },
    )
    
    const axiosParams = {
      method: 'post',
      baseURL: process.env.REACT_APP_ICP_API,
      timeout: 60000,
      ...options,
      data,
      headers: {
        Authorization: ``,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }

    const response = await axios(axiosParams)

    return {
      data: response.data,
      error: response.error,
      headers: response.headers,
    }
  } catch (error) {
    return {
      data: null,
      error,
    }
  }
}

const useUnprotectedAxios = (options) => {
  const controller = new AbortController()
  options.signal = controller.signal
  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetch = (payload, url, timeout) => {
    if (url && url !== '') options.url = url // url override/replacement
    if (timeout && typeof timeout === 'number') options.timeout = timeout
    const newOptions = merge(options, { data: payload })
    return request(newOptions)
  }

  return fetch
}

export default useUnprotectedAxios
