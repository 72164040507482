import React, { useEffect, useRef } from 'react'
import { Icon as Iconify } from '@iconify/react'
import { Box } from '@mui/material'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import DrillStringRecommendationsGrid from './DrillStringRecommendationsGrid'

const verticalTextStyle = {
  writingMode: 'vertical-rl',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#222628',
    fontWeight: 'bold',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  paddingTop: '5px',
  paddingBottom: '5px',
  borderTop: '1px solid gray',
  borderBottom: '1px solid gray',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

const DrillStringPane = ({ showHidePane, paneVisible, setStatus, setImportBha }) => {
  const _isMounted = useRef(false)
  const { getChartBackColor, getWindowBarColor } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (!paneVisible) {
    }
  }, [paneVisible])

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: getChartBackColor(),
        display: 'flex',
        flexDirection: 'row',
      }}>
      <Box
        sx={{
          width: '25px',
          height: '100%',
          backgroundColor: getWindowBarColor(),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'start',
        }}>
        <Box
          onClick={showHidePane}
          sx={{
            marginLeft: 'auto',
            width: '25px',
            height: '25px',
            backgroundColor: '#222628',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          }}>
          <Iconify icon={'zondicons:view-hide'} width={'20px'} height={'20px'} color={'#808080'} />
        </Box>
        <Box
          sx={{
            ...verticalTextStyle,
            fontWeight: paneVisible ? 'bold' : '400',
            color: paneVisible ? '#429ceb' : '#f0f0f0',
          }}
          onClick={() => {
            if (!paneVisible) showHidePane()
            if (paneVisible) {
              showHidePane()
              return
            }
          }}>
          Recommendations
        </Box>
      </Box>

      {paneVisible ? <DrillStringRecommendationsGrid setStatus={setStatus} setImportBha={setImportBha} /> : null}
    </Box>
  )
}
export default DrillStringPane
